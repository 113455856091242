import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumber900000to999999 } from '@/helpers/utility';

const buildQuerySensorDataMartMap = floorId => {
  const randomFloorId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        tenant_id: ['select'],
        floor_id: ['select'],
        timestamp: ['select'],
        carbon_dioxide: ['select'],
        humidity: ['select'],
        temperature: ['select'],
        noise_level: ['select'],
        position_x: ['select'],
        position_y: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomFloorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.SENSOR_DATA_MART_MAP,
  };
};

const queryGetSensorDataThreshold = ({ floorId }) => {
  const randomFloorId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        carbon_dioxide_bad_high: ['select'],
        carbon_dioxide_fair_high: ['select'],
        carbon_dioxide_poor_high: ['select'],
        floor_id: ['select'],
        humidity_bad_high: ['select'],
        humidity_bad_low: ['select'],
        humidity_fair_high: ['select'],
        humidity_fair_low: ['select'],
        humidity_poor_high: ['select'],
        humidity_poor_low: ['select'],
        noise_level_bad_high: ['select'],
        noise_level_fair_high: ['select'],
        noise_level_poor_high: ['select'],
        temperature_bad_high: ['select'],
        temperature_bad_low: ['select'],
        temperature_fair_high: ['select'],
        temperature_fair_low: ['select'],
        temperature_poor_high: ['select'],
        temperature_poor_low: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomFloorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_id: 'asc',
    },
    table_name: QUERY_TABLES.SENSOR_DATA_THRESHOLDS,
  };
};

export const fetchSensorDataNow = async payload => {
  const { floorId } = payload;
  const request = buildQuerySensorDataMartMap(floorId);
  return executeQuery(request);
};

export const fetchSensorDataThreshold = async ({ floorId }) => {
  const request = queryGetSensorDataThreshold({ floorId });
  return executeQuery(request);
};