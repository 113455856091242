import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CHART_TYPE,
  HEATMAP_SCORE_LEGEND,
  HEATMAP_SNR_LEGEND,
  PERFORMANCE_COMPONENT,
} from '@/helpers/constants';
import {
  formatNumberWithCommas,
  generateRandomString,
} from '@/helpers/utility';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';

export default function NetworkTitle(props) {
  const { type, threshold, thresholds } = props;
  const tooltipRef = useRef(null);
  const [title, setTitle] = useState('');
  const [legendContent, setLegendContent] = useState(<></>);
  const [thresholdValue, setThresholdValue] = useState('');
  const [thresholdsValue, setThresholdsValue] = useState([]);

  const keyString = generateRandomString(10);

  useEffect(() => {
    if (type === PERFORMANCE_COMPONENT.NETWORK_PERFORMANCE_SCORE) {
      setTitle(COMMON_TEXT.NETWORK_PERFORMANCE_SCORE);
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.PERFORMANCE_SCORE_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            {HEATMAP_SCORE_LEGEND.map((item, index) => {
              const { color, label, value } = item;
              return (
                <div className="item-row" key={`legend-item-row-${index}`}>
                  <div className="item-name-container">
                    <div
                      className="item-color"
                      style={{ backgroundColor: color }}
                    ></div>
                    <div className="item-name">
                      <span>{label}</span>
                    </div>
                  </div>
                  <div className="item-value">
                    <span>{formatNumberWithCommas(value)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (type === PERFORMANCE_COMPONENT.WIRELESS_SIGNAL) {
      setTitle(COMMON_TEXT.WIRELESS_SIGNAL);
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.WIRELESS_SIGNAL_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            <div className="legend-floormap">
              <div className="sample-marker">
                <div className="text-count">1</div>
              </div>
              <div className="floormap-text">
                <div>
                  <span>数値：チャネル</span>
                </div>
                <div>
                  <span>色: SNR</span>
                </div>
              </div>
            </div>
            <div className="legend-snr">
              {HEATMAP_SNR_LEGEND.map((item, index) => {
                const { color, label, value } = item;
                return (
                  <div className="item-row" key={`legend-item-row-${index}`}>
                    <div className="item-name-container">
                      <div
                        className="item-color"
                        style={{ backgroundColor: color }}
                      ></div>
                      <div className="item-name">
                        <span>{label}</span>
                      </div>
                    </div>
                    <div className="item-value">
                      <span>{formatNumberWithCommas(value)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (type === PERFORMANCE_COMPONENT.NETWORK_CLIENT_COUNT) {
      setTitle(COMMON_TEXT.NETWORK_CLIENT_COUNT);
      setThresholdValue(threshold ?? '-');
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.NETWORK_CLIENT_COUNT_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            {`${COMMON_TEXT.RESONABLE_VALUE}: < ${formatNumberWithCommas(
              thresholdValue
            )}`}
          </div>
        </div>
      );
    } else if (type === PERFORMANCE_COMPONENT.NETWORK_USAGE) {
      setTitle(COMMON_TEXT.NETWORK_USAGE);
      setThresholdValue(threshold ?? '-');
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.NETWORK_USAGE_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            {`${COMMON_TEXT.RESONABLE_VALUE}: > ${formatNumberWithCommas(
              thresholdValue
            )}`}
          </div>
        </div>
      );
    } else if (type === PERFORMANCE_COMPONENT.WIRELESS_CHANNEL_UTILIZATION) {
      setTitle(COMMON_TEXT.WIRELESS_CHANNEL_UTILIZATION);
      setThresholdsValue(thresholds ?? '-');
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.WIRELESS_CHANNEL_UTILIZATION_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            {thresholdsValue.map((item, index) => {
              return (
                <div key={`threshold-item-${index}`}>
                  {`${COMMON_TEXT.RESONABLE_VALUE} (${
                    item.type === '24' ? '2.4 Ghz' : '5.0 Ghz'
                  }): < ${formatNumberWithCommas(item.value)}`}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (type === PERFORMANCE_COMPONENT.WIRELESS_LATENCY) {
      setTitle(COMMON_TEXT.WIRELESS_LATENCY);
      setThresholdsValue(thresholds ?? '-');
      setLegendContent(
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.WIRELESS_LATENCY_DESCRIPTION}</span>
          </div>
          <div className="legend-content">
            {thresholdsValue.map((item, index) => {
              const { type, value } = item;
              let label = '';
              switch (type) {
                case CHART_TYPE.BACKGROUND:
                  label = `${COMMON_TEXT.RESONABLE_VALUE} (${
                    COMMON_TEXT.BACKGROUND
                  }): < ${formatNumberWithCommas(value)}`;
                  break;
                case CHART_TYPE.BEST_EFFORT:
                  label = `${COMMON_TEXT.RESONABLE_VALUE} (${
                    COMMON_TEXT.BEST_EFFORT
                  }): < ${formatNumberWithCommas(value)}`;
                  break;
                case CHART_TYPE.VIDEO:
                  label = `${COMMON_TEXT.RESONABLE_VALUE} (${
                    COMMON_TEXT.VIDEO
                  }): < ${formatNumberWithCommas(value)}`;
                  break;
                case CHART_TYPE.VOICE:
                  label = `${COMMON_TEXT.RESONABLE_VALUE} (${
                    COMMON_TEXT.VOICE
                  }): < ${formatNumberWithCommas(value)}`;
                  break;
                default:
                  break;
              }
              return <div key={`threshold-item-${index}`}>{label}</div>;
            })}
          </div>
        </div>
      );
    }
  }, [type, thresholdValue, threshold, thresholds, thresholdsValue]);

  const infoButtonOnClick = e => {
    if (tooltipRef.current) {
      tooltipRef.current.show(e);
    }
  };

  return (
    <div className="network-title">
      <div className="">{title}</div>
      <Tooltip
        ref={tooltipRef}
        target={`.custom-legend-button-${keyString}`}
        position="bottom"
        className="custom-legend-tooltip"
        event="hover"
      >
        <div className="data-container-legend">{legendContent}</div>
      </Tooltip>
      <Button
        type="button"
        className={`custom-legend-button custom-legend-button-${keyString}`}
        severity="secondary"
        text
        onClick={e => {
          infoButtonOnClick(e);
        }}
      >
        <i className="pi pi-info-circle"></i>
      </Button>
    </div>
  );
}
