/**
 *
 * VerkadaDeviceSetting
 *
 */

import VerkadaDeviceSetting from './VerkadaDeviceSetting.jsx';
import './VerkadaDeviceSetting.less';

export default VerkadaDeviceSetting;
