/**
 *
 * MeetingRoomOutlook
 *
 */

import MeetingRoomOutlook from './MeetingRoomOutlook.jsx';
import './MeetingRoomOutlook.less';

export default MeetingRoomOutlook;
