import EmployeeInfo from '@/components/CustomComponent/EmployeeInfo/EmployeeInfo';
import { COMMON_TEXT } from '@/helpers/common-text';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  QUERY_STRING_STORE,
  CHAT_TOOL_PRESENCE_COLOR,
} from '@/helpers/constants';
import './EmployeeLocationListRight.less';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocationListRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      isLoading: false,

      sidebarVisible: false,
      selectedEmployeeInfo: null,
    };
    this.copyToast = React.createRef();
  }

  renderEmployeeGroup = ({ employeeList }) => {
    if (!employeeList || employeeList.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }
    employeeList = employeeList.sort((a, b) => {
      const aName = a['employee_name'] ?? '';
      const bName = b['employee_name'] ?? '';
      return aName.localeCompare(bName, 'ja');
    });

    // group employeeList by employee_name and employee_image to show only one employee
    const employeeIdentifierMap = {};
    employeeList.forEach(employee => {
      const { employee_id } = employee;
      if (!employeeIdentifierMap[employee_id]) {
        employeeIdentifierMap[employee_id] = [employee];
      } else {
        employeeIdentifierMap[employee_id].push(employee);
      }
    });

    const employeeNameOnClicked = employee => {
      const empl = {
        ...employee,
        device_type: employee?.location_info[0]?.device_type ?? 'pc',
      };
      this.setState({ sidebarVisible: true, selectedEmployeeInfo: empl });
    };

    const copyTextToClipboard = async text => {
      await navigator.clipboard.writeText(text ?? '');
      this.copyToast.current.show({
        severity: 'success',
        detail: COMMON_TEXT.INFO_COPIED_TO_CLIPBOARD,
        life: 2000,
        content: props => (
          <div className="">
            <span>{props.message.detail}</span>
          </div>
        ),
      });
    };

    return (
      <div className="employee-container" style={{ width: '100%' }}>
        {employeeList &&
          employeeList.length > 0 &&
          employeeList.map((employee, index) => {
            const {
              employee_id,
              is_present: employeePresence,
              employee_name,
              employee_image_url,
              chat_tool_presence,
              chat_tool_presence_color,
              email,
              phone_number,
              phone_number2,
              employee_name_reading,
            } = employee;

            const basicInfoList = [
              {
                title: 'TEL1',
                value: phone_number,
                hasCopyButton: phone_number ? true : false,
                isTel: true,
              },
              {
                title: 'TEL2',
                value: phone_number2,
                hasCopyButton: phone_number2 ? true : false,
                isTel: true,
              },
              {
                title: 'Email',
                value: email,
                hasCopyButton: email ? true : false,
                isEmail: true,
              },
            ];

            return (
              <div
                className="member-group-container"
                key={`employee-info-index-${index}-${employee_id}`}
              >
                <div className="col-1 employee-image">
                  <img src={employee_image_url} alt="employee"></img>
                </div>
                <div
                  className="col-2 employee-name"
                  onClick={() => employeeNameOnClicked(employee)}
                >
                  <span>{employee_name}</span>
                  <span className="employee-name-reading">
                    {employee_name_reading}
                  </span>
                </div>
                <div className="col-6 basic-info basic-info-container">
                  {basicInfoList.map((item, index) => {
                    return (
                      <div
                        className="basic-container"
                        key={`row-index-${index}`}
                      >
                        <div className="left-title">{`${item.title}:`}</div>
                        <div className="right-value">
                          {item.isEmail && (
                            <a href={`mailto: ${item.value}`}>{item.value}</a>
                          )}
                          {item.isTel && (
                            <a href={`tel: ${item.value}`}>{item.value}</a>
                          )}
                          {!item.isEmail && !item.isTel && (
                            <span>{item.value}</span>
                          )}
                        </div>
                        <div className="copy-button-container">
                          {item.hasCopyButton && (
                            <Button
                              className="copy-button"
                              icon="pi pi-copy"
                              text
                              severity="secondary"
                              aria-label="copy-button"
                              onClick={() => copyTextToClipboard(item.value)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-3 employee-status">
                  <div className="status">
                    {chat_tool_presence != null &&
                      chat_tool_presence_color != null && (
                        <>
                          <div
                            className={`presence-status-color`}
                            style={{
                              backgroundColor:
                                CHAT_TOOL_PRESENCE_COLOR[
                                  chat_tool_presence_color.toUpperCase()
                                ],
                            }}
                          ></div>
                          <span
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            {chat_tool_presence}
                          </span>
                        </>
                      )}
                  </div>
                  <div className="employee-presence-status">
                    {employeePresence === null ? (
                      <span>{`該当なし`}</span>
                    ) : employeePresence === true ? (
                      <span className="presence">{`在室`}</span>
                    ) : (
                      <span className="not-presence">{`不在`}</span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  componentDidMount() {
    const { branch, tenant } = this.state;
    const { isLoading } = this.props;

    this.setState({
      branch,
      tenant,
      isLoading,
    });
  }

  componentDidUpdate(prevProps) {
    const { querystring, isLoading } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const branch = querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevBranch =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    const isBranchChanged = branch !== prevBranch;
    if (isTenantChanged || isBranchChanged) {
      this.setState({
        branch,
        tenant,
        isLoading,
      });
    }
  }

  render() {
    const { sidebarVisible, selectedEmployeeInfo } = this.state;
    const { empLocGroupedByTeam, employeeSearchText, selectKeyTeam } =
      this.props;

    return (
      <div className="right-container">
        <Toast ref={this.copyToast} position="top-right" />
        <Sidebar
          visible={sidebarVisible}
          onHide={() =>
            this.setState({ sidebarVisible: false, selectedEmployeeInfo: null })
          }
          closeIcon="pi pi-chevron-left"
          position="right"
          closeOnEscape={true}
          dismissable={true}
        >
          <EmployeeInfo
            employeeId={selectedEmployeeInfo?.['employee_id']}
            deviceType={selectedEmployeeInfo?.['device_type']}
          ></EmployeeInfo>
        </Sidebar>
        <div className="search-container">
          <IconField iconPosition="right" className="input-container">
            {employeeSearchText && (
              <InputIcon
                className="pi pi-times icon-clear"
                onClick={() =>
                  this.props.updateSearchTextChange({
                    value: '',
                    type: 'employee',
                  })
                }
              />
            )}
            <InputText
              className="employee-search"
              placeholder="検索"
              value={employeeSearchText}
              onChange={e =>
                this.props.updateSearchTextChange({
                  value: e.target.value,
                  type: 'employee',
                })
              }
            />
          </IconField>
        </div>
        {selectKeyTeam.length === 0 &&
        employeeSearchText.length === 0 &&
        empLocGroupedByTeam.length === 0 ? (
          <div className="no-data-container no-select-data">
            {COMMON_TEXT.EMPLOYEE_LOCATION_SELECT}
          </div>
        ) : (
          this.renderEmployeeGroup({
            employeeList: empLocGroupedByTeam,
          })
        )}
      </div>
    );
  }
}

EmployeeLocationListRight.propTypes = {
  isLoading: PropTypes.bool,
  employeeSearchText: PropTypes.string,
  empLocGroupedByTeam: PropTypes.array,
  selectKeyTeam: PropTypes.string,

  updateSearchTextChange: PropTypes.func,
};

export default EmployeeLocationListRight;
