class Evol {
  constructor (evol) {
    Object.assign(this, evol)
  }
  merge (evol) {
    Object.assign(this, evol)
  }
}

export default Evol
