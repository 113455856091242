/**
 *
 * EmployeeLocationList
 *
 */

import EmployeeLocationList from './EmployeeLocationList.jsx';
import './EmployeeLocationList.less';

export default EmployeeLocationList;
