/**
 *
 * Account
 *
 */

import Account from './Account.jsx';
import './Account.less';

export default Account;
