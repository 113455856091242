/**
 *
 * TenantSetting
 *
 */

import TenantSetting from './TenantSetting.jsx';
import './TenantSetting.less';

export default TenantSetting;
