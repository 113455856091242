/* eslint-disable react-hooks/exhaustive-deps */
import DynamicSelectBox from '@/components/CustomComponent/DynamicSelectBox';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEMORY_STORE,
  QUERY_STRING_STORE,
  USER_ID_KEY,
  USER_PRIVILEGE,
  USER_PRIVILEGE_KEY,
} from '@/helpers/constants';
import FpDataStore from '@/services/FpDatastore';
import FpSdk from 'forepaas/sdk';
import { set as memoryActionSet } from 'forepaas/store/memory/action';
import { set as queryStringActionSet } from 'forepaas/store/querystring/action';
import { set as sessionActionSet } from 'forepaas/store/session/action';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchDataBranch,
  fetchDataFloor,
  fetchDataTenant,
  fetchDataUserTenant,
  fetchTenantWithSupervisor,
} from './queryRequest';
const dataStore = new FpDataStore();

const DynamicParameter = ({ item }) => {
  const select = useMemo(() => {
    const {
      id,
      type,
      component,
      placeholder,
      sortBy,
      multi,
      notEmpty,
      data,
      // defaultValue,
    } = item ?? {};
    return {
      id: id ?? Math.floor(Math.random() * 101),
      type: type ?? 'filter',
      component: component ?? 'selectbox',
      placeholder: placeholder ?? 'Select value',
      sortBy: sortBy ?? 'none',
      multi: multi ?? false,
      notEmpty: notEmpty ?? true,
      items: data ?? [],
    };
  }, [item]);
  return (
    <div
      style={{ display: item?.isShow ? 'flex' : 'none' }}
      className={`header-text ${item?.customClass || ''}`}
    >
      <div className='header-text-label'>{item?.label}</div>
      <hr></hr>
      <DynamicSelectBox {...select} />
    </div>
  );
};

const DynamicParameterSidebar = () => {
  const { querystring, memory, session } = useSelector(state => state);
  const [tenants, setTenants] = useState([]);
  const [branches, setBranches] = useState([]);
  const [floors, setFloors] = useState([]);
  const [hideSelect] = useState(memory[MEMORY_STORE.ROUTERS]);

  useEffect(() => {
    const getUserTenantInfo = async () => {
      const localSession = dataStore.localSession;
      let userTenantIdSet = new Set();
      let userPrivilege = null;
      let userId = null;
      if (localSession) {
        const { email } = localSession;
        const res = await fetchDataUserTenant(email);
        if (res && res.length > 0) {
          userPrivilege = res[0]?.privilege;
          userId = res[0]?.user_id;
          userTenantIdSet = new Set(res.map(item => item.tenant_id));
        }
      }
      const userTenantArray = Array.from(userTenantIdSet);
      FpSdk.modules.store.dispatch(
        memoryActionSet('user-tenant-id-list', userTenantArray)
      );
      FpSdk.modules.store.dispatch(
        sessionActionSet('user-tenant-id-list', userTenantArray)
      );
      FpSdk.modules.store.dispatch(memoryActionSet(USER_ID_KEY, userId));
      FpSdk.modules.store.dispatch(sessionActionSet(USER_ID_KEY, userId));
      FpSdk.modules.store.dispatch(
        memoryActionSet(USER_PRIVILEGE_KEY, userPrivilege)
      );
      FpSdk.modules.store.dispatch(
        sessionActionSet(USER_PRIVILEGE_KEY, userPrivilege)
      );
      return userTenantArray;
    };

    const getTenantWithSupervisor = async () => {
      let tenants = [];
      const res = await fetchTenantWithSupervisor();
      if (res && res.length > 0) {
        FpSdk.modules.store.dispatch(
          memoryActionSet(MEMORY_STORE.TENANTS, res)
        );
        FpSdk.modules.store.dispatch(
          sessionActionSet(MEMORY_STORE.TENANTS, res)
        );
        tenants = res.map(item => ({
          value: item?.tenant_id,
          label: item?.tenant_name,
        }));
      }
      setTenants(tenants);
      return tenants;
    };

    const getTenant = async tenantIdList => {
      let tenants = [];
      const res = await fetchDataTenant({ tenantIdList });
      if (res && res.length > 0) {
        FpSdk.modules.store.dispatch(
          memoryActionSet(MEMORY_STORE.TENANTS, res)
        );
        FpSdk.modules.store.dispatch(
          sessionActionSet(MEMORY_STORE.TENANTS, res)
        );
        tenants = res.map(item => ({
          value: item?.tenant_id,
          label: item?.tenant_name,
        }));
      }
      setTenants(tenants);
      return tenants;
    };

    const getBranch = async tenantIdList => {
      let branches = [];
      const res = await fetchDataBranch(tenantIdList);
      if (res && res.length > 0) {
        FpSdk.modules.store.dispatch(
          memoryActionSet(MEMORY_STORE.BRANCHES, res)
        );
        FpSdk.modules.store.dispatch(
          sessionActionSet(MEMORY_STORE.BRANCHES, res)
        );

        branches = res.map(item => ({
          value: item?.branch_id,
          label: item?.branch_name,
          tenant_id: item?.tenant_id,
        }));
      }
      setBranches(branches);
      return branches;
    };

    const getFloor = async branchIdList => {
      let floors = [];
      const res = await fetchDataFloor(branchIdList);
      if (res && res.length > 0) {
        getImageUrl(res);
        floors = res.map(item => ({
          value: item?.floor_id,
          label: item?.floor_name,
          branch_id: item?.branch_id,
        }));
      }
      setFloors(floors);
      return floors;
    };

    const componentIsLoaded = async () => {
      let userTenantIdArray = await getUserTenantInfo();
      let userPrivilege = sessionStorage.getItem(USER_PRIVILEGE_KEY);
      userPrivilege = userPrivilege ? JSON.parse(userPrivilege) : null;
      if (userPrivilege === USER_PRIVILEGE.SUPERVISOR) {
        await getTenantWithSupervisor();
      } else {
        await getTenant(userTenantIdArray);
      }
      const branches = await getBranch(userTenantIdArray);
      const branchIdList = branches.map(item => item.value);
      await getFloor(branchIdList);
    };

    componentIsLoaded();
  }, []);

  useEffect(() => {
    // set default branch and floor
    const currentTenant =
      querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const currentBranch =
      querystring[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const currentFloor = querystring[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    if (!currentBranch || !currentFloor) {
      const validBranch = branches.find(
        item => +item?.tenant_id === +currentTenant
      );
      const newBranchId = validBranch?.value;
      updateBranchId({ branchId: newBranchId });
      const validFloor = floors.find(item => +item?.branch_id === +newBranchId);
      const newFloorId = validFloor?.value;
      updateFloorId({ floorId: newFloorId });
    }
  }, [branches, floors]);

  useEffect(() => {
    const setDefaultTenant = () => {
      const currentTenant =
        querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
      // if current tenant is not have value, set tenant is the first item in tenant list
      const userTenantIdArray = session['user-tenant-id-list'];
      if (!userTenantIdArray) {
        return;
      }
      if (!currentTenant) {
        const tenant = userTenantIdArray[0];
        FpSdk.modules.store.dispatch(
          queryStringActionSet(
            QUERY_STRING_STORE.SELECT_BOX_TENANT,
            [tenant],
            false
          ),
          sessionActionSet(QUERY_STRING_STORE.SELECT_BOX_TENANT, [tenant])
        );
      }
    };
    setDefaultTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session['user-tenant-id-list']]);

  const updateBranchId = ({ branchId }) => {
    if (!branchId) {
      branchId = null;
    }
    FpSdk.modules.store.dispatch(
      queryStringActionSet(
        QUERY_STRING_STORE.SELECT_BOX_BRANCH,
        [branchId],
        false
      )
    );
    FpSdk.modules.store.dispatch(
      sessionActionSet(QUERY_STRING_STORE.SELECT_BOX_BRANCH, [branchId])
    );
  };

  const updateFloorId = ({ floorId }) => {
    if (!floorId) {
      floorId = null;
    }
    FpSdk.modules.store.dispatch(
      queryStringActionSet(
        QUERY_STRING_STORE.SELECT_BOX_FLOOR,
        [floorId],
        false
      )
    );
    FpSdk.modules.store.dispatch(
      sessionActionSet(QUERY_STRING_STORE.SELECT_BOX_FLOOR, [floorId])
    );
  };

  useEffect(() => {
    const tenantId = querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const branches = session[MEMORY_STORE.BRANCHES] ?? [];
    const validBranch = branches.find(item => item?.tenant_id === tenantId);
    const newBranchId = validBranch?.branch_id;
    updateBranchId({ branchId: newBranchId });
    const floors = session[MEMORY_STORE.FLOORS] ?? [];
    const validFloor = floors.find(item => item?.branch_id === newBranchId);
    const newFloorId = validFloor?.floor_id;
    updateFloorId({ floorId: newFloorId });
  }, [querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT]]);

  useEffect(() => {
    const branchId = querystring[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const floors = session[MEMORY_STORE.FLOORS] ?? [];
    const validFloor = floors.find(item => item?.branch_id === branchId);
    const newFloorId = validFloor?.floor_id;
    updateFloorId({ floorId: newFloorId });
  }, [querystring[QUERY_STRING_STORE.SELECT_BOX_BRANCH]]);

  const getImageUrl = arrFloors => {
    if (arrFloors && arrFloors.length > 0) {
      const floors = arrFloors.map(item => ({
        ...item,
        floor_map: item?.floor_map
          ? dataStore.getObjectDownloadUrlWithBucket(item?.floor_map)
          : null,
      }));

      FpSdk.modules.store.dispatch(
        memoryActionSet(MEMORY_STORE.FLOORS, floors)
      );
      FpSdk.modules.store.dispatch(
        sessionActionSet(MEMORY_STORE.FLOORS, floors)
      );
    }
  };

  const checkHiddenSelect = ({ selectId }) => {
    try {
      const path = window.location.hash.split('?')[0].replace('#/', '');
      const hideFilter = path ? hideSelect[path] : [];
      if (
        hideFilter &&
        hideFilter.length > 0 &&
        hideFilter.includes(selectId)
      ) {
        return true;
      }
    } catch (error) {}
  };

  const renderSelectBranch = () => {
    const userPrivilege = session[USER_PRIVILEGE_KEY];
    const result = querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT]
      ? branches.filter(
          item =>
            item?.tenant_id ===
            querystring[QUERY_STRING_STORE.SELECT_BOX_TENANT][0]
        )
      : [];

    const item = {
      id: QUERY_STRING_STORE.SELECT_BOX_BRANCH,
      data: result,
      placeholder: COMMON_TEXT.SELECT_BRANCH,
      label: COMMON_TEXT.BRANCH,
      customClass: null,
      isShow: userPrivilege !== USER_PRIVILEGE.SIGNAGE,
    };

    // Hiding select box base on url
    if (
      checkHiddenSelect({
        selectId: QUERY_STRING_STORE.SELECT_BOX_BRANCH,
      })
    ) {
      item.customClass = 'hide-header-text';
    }

    return <DynamicParameter item={item} />;
  };

  const renderSelectFloor = () => {
    const userPrivilege = session[USER_PRIVILEGE_KEY];
    const result = querystring[QUERY_STRING_STORE.SELECT_BOX_BRANCH]
      ? floors.filter(
          item =>
            item?.branch_id ===
            querystring[QUERY_STRING_STORE.SELECT_BOX_BRANCH][0]
        )
      : [];

    const item = {
      id: QUERY_STRING_STORE.SELECT_BOX_FLOOR,
      data: result,
      placeholder: COMMON_TEXT.SELECT_FLOOR,
      label: COMMON_TEXT.FLOOR,
      customClass: null,
      isShow: userPrivilege !== USER_PRIVILEGE.SIGNAGE,
    };

    // Hiding select box base on url
    if (
      checkHiddenSelect({
        selectId: QUERY_STRING_STORE.SELECT_BOX_FLOOR,
      })
    ) {
      item.customClass = 'hide-header-text';
    }

    return <DynamicParameter item={item} />;
  };

  const renderSelectTenant = () => {
    const userPrivilege = session[USER_PRIVILEGE_KEY];
    const item = {
      id: QUERY_STRING_STORE.SELECT_BOX_TENANT,
      data: tenants,
      placeholder: COMMON_TEXT.SELECT_TENANT,
      label: COMMON_TEXT.TENANT,
      isShow: userPrivilege === USER_PRIVILEGE.SUPERVISOR,
    };
    // Hiding select box base on url
    if (
      checkHiddenSelect({
        selectId: QUERY_STRING_STORE.SELECT_BOX_TENANT,
      })
    ) {
      item.customClass = 'hide-header-text';
    }

    return <DynamicParameter item={item} />;
  };

  return (
    <div className="sidebar-menulist-container">
      {renderSelectTenant()}
      {renderSelectBranch()}
      {renderSelectFloor()}
    </div>
  );
};

DynamicParameterSidebar.propTypes = {};
export default DynamicParameterSidebar;
