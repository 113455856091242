import React from 'react';
import { COMMON_TEXT } from '@/helpers/common-text';
import { Tooltip } from 'primereact/tooltip';
import { generateRandomString } from '@/helpers/utility';
import { format } from 'date-fns';

export const EmployeeMarkerRender = props => {
  const { employee_name, department, team, timestamp, employee_image_url, employee_device_name, device_type } = props;

  const targetId = generateRandomString(12);

  return (
    <div
      className={`custom-marker custom-marker-tooltip-target-${targetId}`}
      style={{ backgroundColor: 'white', width: 40, height: 40 }}
    >
      <div className="face-image">
        <img
          src={`${employee_image_url}`}
          alt="employee"
          width="40"
          height="40"
        />
      </div>
      <Tooltip
        className="custom-marker-tooltip"
        target={`.custom-marker-tooltip-target-${targetId}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.EMPLOYEE_NAME}</div>
            <div className="text-value">
              <b>{employee_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEPARTMENT}</div>
            <div className="text-value">
              <b>{department}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.TEAM}</div>
            <div className="text-value">
              <b>{team}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEVICE_NAME}</div>
            <div className="text-value">
              <i style={{ marginTop: '4px' }} className={`pl-1 pr-1 pi ${device_type === 'phone' ? 'pi-mobile' : 'pi-desktop'}`}></i><b>{employee_device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{format(new Date(timestamp), 'MM月dd日 - HH:mm')}</b>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
