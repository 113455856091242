/**
 *
 * EmployeeLocationMap
 *
 */

import EmployeeLocationMap from './EmployeeLocationMap.jsx';
import './EmployeeLocationMap.less';

export default EmployeeLocationMap;
