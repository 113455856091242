/**
 *
 * BCPOverview
 *
 */

import BCPOverview from './BCPOverview.jsx';
import './BCPOverview.less';

export default BCPOverview;
