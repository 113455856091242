class Scale {
  constructor (scale) {
    Object.assign(this, scale)
  }
  merge (scale) {
    Object.assign(this, scale)
  }
}

export default Scale
