import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildRequestQueryBranch = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildRequestQueryFloor = branchIdList => {
  const randomId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const queryGetSensorDataThreshold = ({ floorId }) => {
  const randomFloorId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        carbon_dioxide_bad_high: ['select'],
        carbon_dioxide_fair_high: ['select'],
        carbon_dioxide_poor_high: ['select'],
        floor_id: ['select'],
        humidity_bad_high: ['select'],
        humidity_bad_low: ['select'],
        humidity_fair_high: ['select'],
        humidity_fair_low: ['select'],
        humidity_poor_high: ['select'],
        humidity_poor_low: ['select'],
        noise_level_bad_high: ['select'],
        noise_level_fair_high: ['select'],
        noise_level_poor_high: ['select'],
        temperature_bad_high: ['select'],
        temperature_bad_low: ['select'],
        temperature_fair_high: ['select'],
        temperature_fair_low: ['select'],
        temperature_poor_high: ['select'],
        temperature_poor_low: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomFloorId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_id: 'asc',
    },
    table_name: QUERY_TABLES.SENSOR_DATA_THRESHOLDS,
  };
};

export const fetchBranchListByTenant = async tenentId => {
  const request = buildRequestQueryBranch(tenentId);
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async branchIdList => {
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};

export const fetchSensorDataThreshold = async ({ tenantId, floorId }) => {
  const request = queryGetSensorDataThreshold({ floorId });
  return executeQuery(request);
};
