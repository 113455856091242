import React, { Component } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { COMMON_TEXT } from '@/helpers/common-text';
import LoadingSpinner from '../LoadingSpinner';
import { Message } from 'primereact/message';

class HeatMap extends Component {
  roots = {};

  componentDidMount() {
    const { horizontalData, verticalData, data, name, headerTitle } =
      this.props;

    this.renderChart({ horizontalData, verticalData, data, name, headerTitle });
  }

  componentDidUpdate(prevProps) {
    const { horizontalData, verticalData, data, name, headerTitle } =
      this.props;
    const {
      horizontalData: prevHorizontalData,
      verticalData: prevVerticalData,
    } = prevProps;
    if (
      this.isDataChanged({
        horizontalData,
        prevHorizontalData,
        verticalData,
        prevVerticalData,
      })
    ) {
      this.renderChart({
        horizontalData,
        verticalData,
        data,
        name,
        headerTitle,
      });
    }
  }

  isDataChanged({
    horizontalData,
    prevHorizontalData,
    verticalData,
    prevVerticalData,
  }) {
    horizontalData = horizontalData ?? [];
    prevHorizontalData = prevHorizontalData ?? [];
    verticalData = verticalData ?? [];
    prevVerticalData = prevVerticalData ?? [];

    if (horizontalData.length !== prevHorizontalData.length) {
      return true;
    }
    if (verticalData.length !== prevVerticalData.length) {
      return true;
    }

    const categoryDateSet = new Set(
      horizontalData.map(item => item.categoryDate)
    );
    const prevCategoryDateSet = new Set(
      prevHorizontalData.map(item => item.categoryDate)
    );

    if (categoryDateSet.size !== prevCategoryDateSet.size) {
      return true;
    } else {
      for (const item of categoryDateSet) {
        if (!prevCategoryDateSet.has(item)) {
          return true;
        }
      }
    }

    const deviceIdSet = new Set(verticalData.map(item => item.deviceId));
    const prevDeviceIdSet = new Set(
      prevVerticalData.map(item => item.deviceId)
    );
    if (deviceIdSet.size !== prevDeviceIdSet.size) {
      return true;
    } else {
      for (const item of deviceIdSet) {
        if (!prevDeviceIdSet.has(item)) {
          return true;
        }
      }
    }

    // check if order of deviceId in verticalData is different with prevVerticalData
    if (this.isOrderDifferent(verticalData, prevVerticalData, 'deviceId')) {
      return true;
    }

    return false;
  }

  isOrderDifferent(array1, array2, keyField) {
    // If arrays have different lengths, then order is definitely different
    if (array1.length !== array2.length) {
      return true;
    }

    // Loop through arrays and compare 'x - timestamp' property of each object
    for (let i = 0; i < array1.length; i++) {
      if (array1[i][keyField] !== array2[i][keyField]) {
        return true; // Different order detected
      }
    }

    // If no difference detected in the loop, then order is the same
    return false;
  }

  makeRange(xAxis, start, end, label) {
    const rangeDataItem = xAxis.makeDataItem({
      category: start,
      endCategory: end,
    });

    xAxis.createAxisRange(rangeDataItem);

    rangeDataItem.get('label').setAll({
      fill: am5.color(0x000000),
      text: label,
      fontWeight: 'bold',
      dy: 25,
    });

    rangeDataItem.get('grid').setAll({
      strokeOpacity: 0.2,
      location: 1,
    });
    return xAxis;
  }

  renderChart = ({ horizontalData, verticalData, data, name, headerTitle }) => {
    if (this.roots[name]) {
      this.roots[name].dispose();
    }
    const root = am5.Root.new(`chartdiv-${name}`);
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    // Save this root to our map
    this.roots[name] = root;

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingTop: 33,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout,
      })
    );

    // Create axes and their renderers
    const yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      inversed: false,
      minGridDistance: 1,
    });

    yRenderer.grid.template.set('visible', false);

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: yRenderer,
        categoryField: 'category',
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 2,
      inversed: false,
    });

    xRenderer.grid.template.set('visible', false);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: 'category',
      })
    );

    const result = {};
    horizontalData.forEach(item => {
      if (!result[item.categoryDate]) {
        result[item.categoryDate] = {
          earliest: item.category,
          latest: item.category,
        };
      } else {
        if (item.category < result[item.categoryDate].earliest) {
          result[item.categoryDate].earliest = item.category;
        }
        if (item.category > result[item.categoryDate].latest) {
          result[item.categoryDate].latest = item.category;
        }
      }
    });

    const categoryDateSet = new Set(
      horizontalData.map(item => item.categoryDate)
    );

    for (const categoryDateString of categoryDateSet) {
      xAxis = this.makeRange(
        xAxis,
        result[categoryDateString].earliest,
        result[categoryDateString].latest,
        categoryDateString
      );
    }

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        valueField: 'value',
        categoryXField: 'x',
        categoryYField: 'y',
      })
    );

    const { tooltipLabelList } = this.props;
    series.columns.template.setAll({
      strokeOpacity: 1,
      strokeWidth: 1,
      width: am5.percent(100),
      height: am5.percent(100),
      templateField: 'columnSettings',
    });

    series.columns.template.adapters.add('tooltipHTML', (text, target) => {
      const value = target.dataItem.get('value');
      return `
        <div style='width: 240px; text-align: center; display: flex; align-items: flex-start; justify-content: space-between; flex-direction: column;'>
        <div style='width: 100%; display: flex; align-items: center; justify-content: space-between;'>
          <div>${tooltipLabelList[0]}</div>
          <b>{categoryY}</b>
        </div>
        <div style='width: 100%; display: flex; align-items: center; justify-content: space-between;'>
          <div>${tooltipLabelList[1]}</div>
          <b>{categoryX}</b>
        </div>
        <div style='width: 100%; display: flex; align-items: center; justify-content: space-between;'>
          <div>${tooltipLabelList[2]}</div>
          <b>${value < 0 ? COMMON_TEXT.NO_DATA : value}</b>
        </div>
      </div>`;
    });

    series.data.setAll(data);

    yAxis.data.setAll(verticalData);

    xAxis.data.setAll(horizontalData);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);

    // Edit xAxis labels
    const xAxisRenderer = xAxis.get('renderer');
    xAxisRenderer.labels.template.adapters.add('text', function (text, target) {
      if (target.dataItem?.dataContext) {
        return target.dataItem.dataContext.categoryLabel;
      }
      return ''; //text;
    });

    xAxisRenderer.labels.template.adapters.add(
      'rotation',
      function (value, target) {
        return -45;
      }
    );

    xAxisRenderer.labels.template.adapters.add(
      'textAlign',
      function (value, target) {
        return 'end';
      }
    );

    xAxisRenderer.labels.template.adapters.add('dx', function (value, target) {
      return 0;
    });

    xAxisRenderer.labels.template.adapters.add(
      'fontWeight',
      function (value, target) {
        return '500';
      }
    );

    xAxisRenderer.labels.template.adapters.add(
      'fontSize',
      function (value, target) {
        return '0.875rem';
      }
    );

    // Edit yAxis labels style
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p0,
      scale: 0.85,
      paddingRight: 20,
    });

    yAxis.axisHeader.get('background').setAll({
      fillOpacity: 0,
    });

    yAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: headerTitle ?? 'HEADER_TITLE',
        fontWeight: '500',
        x: -10,
        y: 0,
        background: am5.Rectangle.new(root, {
          fillOpacity: 0,
        }),
      })
    );
  };

  componentWillUnmount() {
    for (let name in this.roots) {
      this.roots[name].dispose();
    }
  }

  render() {
    let { name, isLoading, data } = this.props;
    const loadingLayerStyle = {
      position: 'absolute',
      bottom: name === 'heatmapwirelesssignal' ? 59 : 67,
      left: 152,
      top: name === 'heatmapwirelesssignal' ? 41 : 33,
      right: 20,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    const isEmptyData = data.length === 0;
    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <div id={`chartdiv-${name}`} />
        {!isLoading && isEmptyData && (
          <div style={loadingLayerStyle}>
            <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
          </div>
        )}
        {isLoading && (
          <div style={loadingLayerStyle}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }
}

HeatMap.propTypes = {};
export default HeatMap;
