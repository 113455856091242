import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';
import { getRandomNumberRange } from 'src/helpers/utility';

const buildRequestQueryBCPFloorTenantId = tenantId => {
  const randomTenantId = getRandomNumberRange({ min: 900000, max: 999999 });
  return {
    data: {
      fields: {
        branch_id: ['select'],
        floor_id: ['select'],
        pc_count: ['select'],
        phone_count: ['select'],
        tenant_id: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.BCP_FLOOR,
  };
};

const buildQueryEmployeeByFloorId = floorIds => {
  return {
    data: {
      fields: {
        employee_id: ['select'],
        employee_name: ['select'],
        department: ['select'],
        team: ['select'],
        device_type: ['select'],
        floor_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: floorIds,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEE_LOCATIONS_MART,
  };
};

export const fetchDataBCPFloorByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildRequestQueryBCPFloorTenantId(tenantId);
  return executeQuery(request);
};

export const fetchDataEmployeeByFloorId = async payload => {
  const { floorIds } = payload;
  const request = buildQueryEmployeeByFloorId(floorIds);
  return executeQuery(request);
};
