/**
 *
 * Dashboard
 *
 */

import Dashboard from './Dashboard.jsx';
import './Dashboard.less';

export default Dashboard;
