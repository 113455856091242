export const EMPLOYEE_CSV_COLUMN = {
    NAME: '名前',
    NAME_READING: '読み仮名',
    EMPLOYEE_NUMBER: '社員番号',
    PHONE_NUMBER: '電話番号1',
    PHONE_NUMBER2: '電話番号2',
    EMAIL: 'メールアドレス',
    CHAT_TOOL: 'チャットツール',
    DEPARTMENT: '部署',
    TEAM: 'チーム',
    BRANCH: '拠点',
    FLOOR: 'フロア',
    VERKADA_ID: 'Verkada社員ID',
    PC_MAC_ADDRESS: 'PC_MACアドレス',
    PC_HOST_NAME: 'PC_ホスト名',
    MOBILE_MAC_ADDRESS: '携帯_MACアドレス',
    MOBILE_HOST_NAME: '携帯_ホスト名',
    ID: 'ID(編集しないでください)',
};
