export const DEVICE_TYPE = {
  MR: 'アクセスポイント',
  MS: 'スイッチ',
  MX: 'セキュリティアプライアンス',
  MV: 'カメラ - Meraki',
  MT30: 'センサー - ボタン',
  MT20: 'センサー - ドア',
  Z: 'テレワーカーゲートウェイ',
  MG: 'セルラーゲートウェイ',
  MT: 'センサー - その他',
  CD: 'カメラ - Verkada',
};
