import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import EmployeeLocationMap from './EmployeeLocationMap';
import EmployeeLocationList from './EmployeeLocationList';
import FpDataStore from '@/services/FpDatastore';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchDataByBranchId,
  fetchDataByCurrentBranchId,
  fetchEmployeeLocationByEmployeeIdList,
} from './query-request';
import { MEMORY_STORE } from '@/helpers/constants';
import { isEmpty } from 'lodash';

const fpDataStore = new FpDataStore();
@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      isLoading: false,

      // new below
      tabActiveIndex: 0,

      // List
      departmentList: [],
      departmentSearchText: '',
      teamSearchText: '',
      employeeSearchText: '',
      departmentActiveIndex: [],
      otherDepartmentActiveIndex: [],
      filteredEmpLocGroupedByDeptAndTeam: {},
      empLocGroupedByTeam: [],
      selectKeyTeam: '',

      // Map
      floorMapError: false,
      floorMapUrl: '',

      sidebarVisible: false,
      isDisplayAllDeviceChecked: false,
    };
    this.branchName = '';
    this.employeeLocationList = [];
    this.empLocGroupedByDeptAndTeam = {};
    this.totalEmployeeMap = {};
    this.employeeLocationOptions = [
      {
        label: COMMON_TEXT.EMPLOYEE_LOCATIONS_LIST,
        value: 0,
      },
      {
        label: COMMON_TEXT.EMPLOYEE_LOCATIONS_MAP,
        value: 1,
      },
    ];
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    const floorMap = floor?.floor_map ?? '';
    this.setState({ floorMapUrl: floorMap, floorMapError: isEmpty(floorMap) });
  };

  componentDidMount() {
    const { floor, branch, tenant } = this.state;
    if (floor && tenant && branch) {
      this.getFloorMapUrl(floor);
      this.getDataByBranchId();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const branch = querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevBranch =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const isFloorChanged = floor !== prevFloor;
    const isBranchChanged = branch !== prevBranch;
    if (isBranchChanged || isFloorChanged) {
      this.setState({ branch, tenant, floor }, () => {
        this.getFloorMapUrl(floor);
        this.getDataByBranchId();
      });
    }
  }

  groupEmployeeToDepartmentAndTeam = ({ dataArray, branchId }) => {
    let resultMap = {
      [branchId]: {}
    };
    // Group the data
    dataArray.forEach(employeeInfo => {
      let { department, team, current_branch_id, base_branch_id } =
        employeeInfo;

      if (!current_branch_id) {
        current_branch_id = 'NO_GROUP'; // 他拠点
      }

      if (!department) {
        department = 'NO_DEPARTMENT'; // 部署なし
      }
      if (!team) {
        team = 'NO_TEAM'; // チームなし
      }

      if (branchId !== base_branch_id && branchId === current_branch_id) {
        if (!resultMap['NO_GROUP']) {
          resultMap['NO_GROUP'] = {};
        }

        if (!resultMap['NO_GROUP'][department]) {
          resultMap['NO_GROUP'][department] = {};
        }

        if (!resultMap['NO_GROUP'][department][team]) {
          resultMap['NO_GROUP'][department][team] = [];
        }

        resultMap['NO_GROUP'][department][team].push(employeeInfo);
      } else if (branchId === base_branch_id) {
        if (!resultMap[base_branch_id]) {
          resultMap[base_branch_id] = {};
        }

        if (!resultMap[base_branch_id][department]) {
          resultMap[base_branch_id][department] = {};
        }

        if (!resultMap[base_branch_id][department][team]) {
          resultMap[base_branch_id][department][team] = [];
        }
        resultMap[base_branch_id][department][team].push(employeeInfo);
      }
    });

    const totalEmployeeMap = {};
    // count the number of employees for each department
    Object.keys(resultMap).forEach(groupName => {
      Object.keys(resultMap[groupName]).forEach(department => {
        if (!totalEmployeeMap[groupName]) {
          totalEmployeeMap[groupName] = {};
        }
        let employeeCount = 0;
        Object.keys(resultMap[groupName][department]).forEach(team => {
          const employeeList = resultMap[groupName][department][team];
          const employeeIdSet = new Set(
            employeeList.map(item => item.employee_id)
          );
          employeeCount += employeeIdSet.size;
          if (!totalEmployeeMap[groupName][department]) {
            totalEmployeeMap[groupName][department] = {
              count: 0,
              [team]: 0,
            };
          }
          totalEmployeeMap[groupName][department][team] = employeeIdSet.size;
        });
        totalEmployeeMap[groupName][department]['count'] = employeeCount;
      });
    });

    // if resultMap has no key is NO_DEPARTMENT, add NO_DEPARTMENT
    if (!resultMap['NO_GROUP']) {
      resultMap['NO_GROUP'] = {};
    }

    return {
      empLocGroupedByDeptAndTeam: resultMap,
      totalEmployeeMap,
    };
  };

  getBranchName = ({ branchId }) => {
    const { sessionStore } = this.props;
    const branches = sessionStore[MEMORY_STORE.BRANCHES] || [];
    if (branchId) {
      const foundBranches = branches.filter(branch => branch.branch_id === branchId);
      return foundBranches?.[0]?.branch_name ?? '-';
    }
    return '-';
  };

  getDataByBranchId = async () => {
    const { tenant, branch, floor: selectedFloorId } = this.state;
    if (!tenant || !branch || !selectedFloorId) {
      return;
    }
    this.setState({ isLoading: true });
    this.branchName = this.getBranchName({ branchId: branch });
    let employeeDataByBranch = await fetchDataByBranchId({
      branchId: branch,
    });
    let employeeDataByCurrentBranch = await fetchDataByCurrentBranchId({
      currentBranchId: branch,
    });

    if (!employeeDataByBranch) {
      employeeDataByBranch = [];
    }
    if (!employeeDataByCurrentBranch) {
      employeeDataByCurrentBranch = [];
    }

    // filter out employee data by tenant
    employeeDataByBranch = employeeDataByBranch.filter(
      item => +item['tenant_id'] === +tenant
    );
    employeeDataByCurrentBranch = employeeDataByCurrentBranch.filter(
      item => +item['tenant_id'] === +tenant
    );

    // add presence status to employee data
    employeeDataByBranch.forEach(employee => {
      const { current_branch_id: emplCurrentBranchId, employee_image } =
        employee;

      employee['is_present'] = +emplCurrentBranchId === +branch;
      employee['is_out_of_office'] = !emplCurrentBranchId;
      employee['employee_image_url'] =
        'assets/images/default_employee_avatar.jpg';
      if (employee_image) {
        employee['employee_image_url'] =
          fpDataStore.getObjectDownloadUrlWithBucket(employee_image);
      }
    });

    employeeDataByCurrentBranch.forEach(employee => {
      const { current_branch_id: emplCurrentBranchId, employee_image } =
        employee;
      employee['is_present'] = +emplCurrentBranchId === +branch;
      employee['is_out_of_office'] = !emplCurrentBranchId;
      employee['employee_image_url'] =
        'assets/images/default_employee_avatar.jpg';
      if (employee_image) {
        employee['employee_image_url'] =
          fpDataStore.getObjectDownloadUrlWithBucket(employee_image);
      }
    });

    // Handle check duplicate data and merge
    const duplicatesId = employeeDataByBranch
      .filter(value =>
        employeeDataByCurrentBranch.some(
          oneElement =>
            oneElement.base_branch_id === value.base_branch_id &&
            oneElement.employee_name === value.employee_name
        )
      )
      .map(oneElement => oneElement.base_branch_id);

    employeeDataByBranch = [
      ...employeeDataByBranch,
      ...employeeDataByCurrentBranch.filter(
        value =>
          !duplicatesId.some(
            oneDuplicate => oneDuplicate === value.base_branch_id
          )
      ),
    ];

    // Extract presence employee id list
    const presenceEmployeeIdList = employeeDataByBranch
      .filter(employee => employee['is_present'])
      .map(employee => +employee['employee_id']);

    // Fetch coordinate by employee id list
    let employeeCoordinateList = await fetchEmployeeLocationByEmployeeIdList({
      employeeIdList: presenceEmployeeIdList,
      tenantId: tenant,
      floorId: selectedFloorId,
    });
    if (!employeeCoordinateList) {
      employeeCoordinateList = [];
    }

    employeeDataByBranch.forEach(employee => {
      const { employee_id } = employee;
      const employeeLocations = employeeCoordinateList.filter(
        item => +item['employee_id'] === +employee_id
      );
      employee['location_info'] = employeeLocations ?? [];
    });

    // set employeeLocationList, totalEmployeeMap
    this.employeeLocationList = cloneDeep(employeeDataByBranch);
    const { empLocGroupedByDeptAndTeam, totalEmployeeMap } =
      this.groupEmployeeToDepartmentAndTeam({
        dataArray: this.employeeLocationList,
        branchId: branch,
      });

    this.empLocGroupedByDeptAndTeam = cloneDeep(empLocGroupedByDeptAndTeam);
    this.totalEmployeeMap = cloneDeep(totalEmployeeMap);

    let newGroupList = Object.keys(this.empLocGroupedByDeptAndTeam)
      .filter(item => item !== 'NO_GROUP')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    newGroupList.push('NO_GROUP');

    const newUpdatedState = {
      data: this.employeeLocationList,
      departmentList: newGroupList,
      filteredEmpLocGroupedByDeptAndTeam: cloneDeep(
        this.empLocGroupedByDeptAndTeam
      ),
      isLoading: false,
    };

    this.setState(newUpdatedState);
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
          className="mb-3"
        />
      );
    }
  };

  refreshData() {
    const { floor } = this.state;
    this.getFloorMapUrl(floor);
    this.getDataByBranchId();
    this.setState({
      // List
      departmentSearchText: '',
      teamSearchText: '',
      employeeSearchText: '',
      departmentActiveIndex: [],
      otherDepartmentActiveIndex: [],
      filteredEmpLocGroupedByDeptAndTeam: {},
      empLocGroupedByTeam: [],
      selectKeyTeam: '',

      // Map
      sidebarVisible: false,
      isDisplayAllDeviceChecked: false,
    });
  }

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => this.refreshData()}
      />
    );
  }

  updateAllDeviceChecked = ({ isCheck }) => {
    this.setState({ isDisplayAllDeviceChecked: isCheck });
  };

  updateSearchTextChange = ({ value, type }) => {
    switch (type) {
      case 'department':
        this.setState({
          departmentSearchText: value,
        });
        break;
      case 'team':
        this.setState({
          teamSearchText: value,
        });
        break;
      case 'employee':
        this.setState({
          employeeSearchText: value,
        });
        break;
      default:
        break;
    }
    this.handleSearchTextChange({ value: value.trim(), type });
  };

  handleSearchTextChange = debounce(({ value, type }) => {
    const keys = [
      'department',
      'team',
      'employee_number',
      'employee_name',
      'employee_name_reading',
      'phone_number',
      'phone_number2',
      'email',
    ];

    const getFilteredValue = ({ filterText, employeeList }) => {
      let newValues = [];

      const newEmployees = employeeList.filter(employee =>
        keys.some(
          key =>
            employee[key] &&
            employee[key]
              .toLocaleLowerCase()
              .includes(filterText.toLocaleLowerCase())
        )
      );

      if (newEmployees != null && newEmployees.length > 0) {
        newValues = [...newValues, ...newEmployees];
      }

      return newValues;
    };
    const searchText = value;
    let {
      departmentSearchText: currentDepartmentSearchText,
      teamSearchText: currentTeamSearchText,
      employeeSearchText: currentEmployeeSearchText,
      selectKeyTeam,
    } = this.state;

    // Remove space text search
    currentDepartmentSearchText = currentDepartmentSearchText.trim();
    currentTeamSearchText = currentTeamSearchText.trim();
    currentEmployeeSearchText = currentEmployeeSearchText.trim();

    if (type === 'department') {
      currentDepartmentSearchText = searchText;
    } else if (type === 'team') {
      currentTeamSearchText = searchText;
    } else if (type === 'employee') {
      currentEmployeeSearchText = searchText;
    }

    // filter employee by search department/team text
    const filteredEmpLocGroupedByDeptAndTeam = cloneDeep(
      this.empLocGroupedByDeptAndTeam
    );
    // filter employee by search employee text
    const filteredEmpLocGroupedByEmployee = cloneDeep(
      this.empLocGroupedByDeptAndTeam
    );
    let filteredEmpLocGroupedByTeam = [];

    let groupList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
    if (currentDepartmentSearchText.length > 0) {
      // filter filteredEmpLocGroupedByDeptAndTeam by departmentSearchText
      groupList.forEach(groupName => {
        const departmentMap = filteredEmpLocGroupedByDeptAndTeam[groupName];
        if (!departmentMap) {
          return;
        }
        const departmentList = Object.keys(departmentMap);
        let isTeamHasMatchedEmployee = false;
        departmentList.forEach(departmentName => {
          if (
            !departmentName
              .toLocaleLowerCase()
              .includes(currentDepartmentSearchText.toLocaleLowerCase())
          ) {
            delete filteredEmpLocGroupedByDeptAndTeam[groupName][
              departmentName
            ];
          } else {
            isTeamHasMatchedEmployee = true;
          }
        });
        if (!isTeamHasMatchedEmployee) {
          delete filteredEmpLocGroupedByDeptAndTeam[groupName];
        }

        // Check list department when delete
        const newDepartmentList = Object.keys(departmentMap);
        if (newDepartmentList.length === 0) {
          delete filteredEmpLocGroupedByDeptAndTeam[groupName];
        }
      });
    }

    if (currentTeamSearchText.length > 0) {
      groupList.forEach(groupName => {
        const departmentMap = filteredEmpLocGroupedByDeptAndTeam[groupName];
        if (!departmentMap) {
          return;
        }
        const departmentList = Object.keys(departmentMap);
        departmentList.forEach(departmentName => {
          const teamMap =
            filteredEmpLocGroupedByDeptAndTeam[groupName][departmentName];
          if (!teamMap) {
            return;
          }
          const teamList = Object.keys(teamMap);
          let isTeamHasMatchedEmployee = false;
          teamList.forEach(teamName => {
            if (
              !teamName
                .toLocaleLowerCase()
                .includes(currentTeamSearchText.toLocaleLowerCase())
            ) {
              delete filteredEmpLocGroupedByDeptAndTeam[groupName][
                departmentName
              ][teamName];
            } else {
              isTeamHasMatchedEmployee = true;
            }
          });
          if (!isTeamHasMatchedEmployee) {
            delete filteredEmpLocGroupedByDeptAndTeam[groupName][
              departmentName
            ];
          }
        });

        // Check list department when delete
        const newDepartmentList = Object.keys(departmentMap);
        if (newDepartmentList.length === 0) {
          delete filteredEmpLocGroupedByDeptAndTeam[groupName];
        }
      });
    }

    if (currentEmployeeSearchText.length > 0) {
      groupList.forEach(groupName => {
        const departmentMap = filteredEmpLocGroupedByEmployee[groupName];
        if (!departmentMap) {
          return;
        }
        const departmentList = Object.keys(departmentMap);
        departmentList.forEach(departmentName => {
          const teamMap =
            filteredEmpLocGroupedByEmployee[groupName][departmentName];
          if (!teamMap) {
            return;
          }
          const teamList = Object.keys(teamMap);
          teamList.forEach(teamName => {
            const employeeList =
              filteredEmpLocGroupedByEmployee[groupName][departmentName][
                teamName
              ];

            const filteredEmployeeList = getFilteredValue({
              filterText: currentEmployeeSearchText,
              employeeList,
            });

            if (
              filteredEmployeeList != null &&
              filteredEmployeeList.length > 0
            ) {
              filteredEmpLocGroupedByTeam = [
                ...filteredEmpLocGroupedByTeam,
                ...filteredEmployeeList,
              ];
            }
          });
        });
      });
    } else if (selectKeyTeam.length > 0) {
      groupList.forEach(groupName => {
        const departmentMap = filteredEmpLocGroupedByDeptAndTeam[groupName];
        if (!departmentMap) {
          return;
        }
        const departmentList = Object.keys(departmentMap);
        departmentList.forEach(departmentName => {
          const teamMap =
            filteredEmpLocGroupedByDeptAndTeam[groupName][departmentName];
          if (!teamMap) {
            return;
          }
          const teamList = Object.keys(teamMap);
          teamList.forEach(teamName => {
            const key = `${groupName}###${departmentName}###${teamName}`;
            if (selectKeyTeam !== key) {
              return;
            }

            const employeeList =
              filteredEmpLocGroupedByDeptAndTeam[groupName][departmentName][
                teamName
              ];
            filteredEmpLocGroupedByTeam = employeeList;
          });
        });
      });
    }

    let newGroupList = Object.keys(filteredEmpLocGroupedByDeptAndTeam).sort(
      (a, b) => {
        return a.localeCompare(b, 'ja');
      }
    );

    if (type === 'department') {
      this.setState({
        departmentList: newGroupList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'team') {
      this.setState({
        departmentList: newGroupList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'employee') {
      this.setState({
        empLocGroupedByTeam: filteredEmpLocGroupedByTeam,
      });
    }
  }, 500);

  updateSelectKeyTeam = ({
    selectKeyTeam,
    empLocGroupedByTeam,
    employeeSearchText,
  }) => {
    this.setState({
      selectKeyTeam,
      empLocGroupedByTeam,
      employeeSearchText,
    });
  };

  updateActiveIndex = ({ isOther, index }) => {
    switch (isOther) {
      case true:
        this.setState({ otherDepartmentActiveIndex: index });
        break;
      default:
        this.setState({ departmentActiveIndex: index });
        break;
    }
  };

  render() {
    const {
      data: floorMapData,
      tenant,
      floor,
      branch,
      isLoading,
      filteredEmpLocGroupedByDeptAndTeam,
      departmentList,
      tabActiveIndex,
      floorMapError,
      floorMapUrl,
      departmentSearchText,
      teamSearchText,
      employeeSearchText,
      empLocGroupedByTeam,
      isDisplayAllDeviceChecked,
      departmentActiveIndex,
      otherDepartmentActiveIndex,
      selectKeyTeam,
    } = this.state;

    return (
      <div className="config-container custom-config-container employee-locations">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.EMPLOYEE_LOCATIONS}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div
                id="left-right-component-container-id"
                className="left-right-component-container"
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <SelectButton
                      id="listmapselectbutton"
                      value={tabActiveIndex}
                      onChange={e => this.setState({ tabActiveIndex: e.value })}
                      options={this.employeeLocationOptions}
                      allowEmpty={false}
                    />
                    <div className="m-0 select-component">
                      {tabActiveIndex === 0 && (
                        <EmployeeLocationList
                          isLoading={isLoading}
                          departmentList={departmentList}
                          empLocGroupedByTeam={empLocGroupedByTeam}
                          departmentActiveIndex={departmentActiveIndex}
                          otherDepartmentActiveIndex={
                            otherDepartmentActiveIndex
                          }
                          selectKeyTeam={selectKeyTeam}
                          filteredEmpLocGroupedByDeptAndTeam={
                            filteredEmpLocGroupedByDeptAndTeam
                          }
                          employeeSearchText={employeeSearchText}
                          departmentSearchText={departmentSearchText}
                          teamSearchText={teamSearchText}
                          branchName={this.branchName}
                          totalEmployeeMap={this.totalEmployeeMap}
                          updateSearchTextChange={this.updateSearchTextChange}
                          updateSelectKeyTeam={this.updateSelectKeyTeam}
                          updateActiveIndex={this.updateActiveIndex}
                        />
                      )}
                    </div>
                    <div className="m-0 select-component">
                      {tabActiveIndex === 1 && (
                        <EmployeeLocationMap
                          data={floorMapData}
                          floor={floor}
                          branch={branch}
                          tenant={tenant}
                          isLoading={isLoading}
                          floorMapError={floorMapError}
                          floorMapUrl={floorMapUrl}
                          isDisplayAllDeviceChecked={isDisplayAllDeviceChecked}
                          updateAllDeviceChecked={this.updateAllDeviceChecked}
                        ></EmployeeLocationMap>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

EmployeeLocations.propTypes = {};

export default EmployeeLocations;
