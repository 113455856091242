import { getCurrentFullDateFormattedInTimeZone } from '@/helpers/utility';
import React, { useEffect, useState } from 'react';
export default function CustomCurrentTime(props) {
  const [currentTime, setCurrentTime] = useState('-');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(
        getCurrentFullDateFormattedInTimeZone('yyyy年MM月dd日 (EEE) HH:mm')
      );
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <div>{currentTime}</div>;
}
