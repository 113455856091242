/**
 *
 * EmployeeInfoDialog
 *
 */

import EmployeeInfoDialog from './EmployeeInfoDialog.jsx';
import './EmployeeInfoDialog.less';

export default EmployeeInfoDialog;
