import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';

const buildQueryPeopleCountAreaMartLine = ({ floorId, fromDate, toDate }) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        area_id: ['select'],
        area_name: ['select'],
        counts: ['select'],
        capacity_rate: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      limit: null,
      skip: null,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PEOPLE_COUNTS_AREA_MART_LINE,
  };
};

export const fetchPeopleCountAreaMartLine = async payload => {
  const { floorId, fromDate, toDate } = payload;
  const request = buildQueryPeopleCountAreaMartLine({ floorId, fromDate, toDate });
  return executeQuery(request);
};
