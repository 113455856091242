import React, { Component } from 'react';
import { connect } from 'react-redux';
import './UnderConstructionView.less';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class UnderConstructionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="underconstruction-container">
        <div id="backgroundImage"></div>
        <div className="hide" id="backgroundImage1"></div>
        <div className="show" id="backgroundImage2"></div>
        <div className="ovarlay"></div>
        <div className="infoText">UNDER CONSTRUCTION</div>
      </div>
    );
  }
}

UnderConstructionPage.propTypes = {};

export default UnderConstructionPage;
