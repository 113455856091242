import { formatNumberWithCommas } from '@/helpers/utility';
import React, { Fragment, PureComponent } from 'react';

export class CustomTooltipLineChart extends PureComponent {
  render() {
    const { active, payload, label, dataKeyLabel } = this.props;
    if (active && payload && payload.length) {
      const { dataKey } = payload[0];
      const dataKeyStr = dataKeyLabel ? dataKeyLabel : dataKey;
      return (
        <div
          className="custom-tooltip pt-1 pb-1 pl-2 pr-2"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-center',
            justifyContent: 'flex-start',
            minWidth: '300px',
            width: 'auto',
            maxWidth: '300px',
            backgroundColor: '#F5F5F5',
            border: '2px solid #555555',
            boxShadow: '0px 0px 5px rgba(245, 245, 245, 0.4)',
            borderRadius: '5px',
          }}
        >
          <div className="tooltip-title" style={{ fontSize: '0.875rem' }}>
            <b>{label}</b>
            <i>({dataKeyStr})</i>
          </div>
          <div className="grid grid-nogutter w-full">
            {payload.map((item, index) => {
              const { value, name, color, stroke } = item;
              if (stroke === 'transparent') {
                return <Fragment key={`empty-tooltip-${index}`}></Fragment>;
              }
              return (
                <div
                  key={`custom-chart-tooltip-${index}`}
                  style={{
                    color: '#333333',
                    fontSize: '0.9rem',
                    marginTop: '1px',
                    marginBottom: '1px',
                  }}
                  className="col-12 grid grid-nogutter align-items-center justify-content-between"
                >
                  <div
                    className="col-fixed"
                    style={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: color,
                      border: '1px solid #333333',
                    }}
                  ></div>
                  <div className="col text-left ml-1">{name}</div>
                  <div className="col-2 text-right" style={{}}>
                    {formatNumberWithCommas(value)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  }
}
