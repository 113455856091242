/**
 *
 * AirQualitySetting
 *
 */

import AirQualitySetting from './AirQualitySetting.jsx';
import './AirQualitySetting.less';

export default AirQualitySetting;
