import { ScrollTop } from 'primereact/scrolltop';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COMMON_TEXT } from 'src/helpers/common-text';
import MeetingRoomOutlook from './MeetingRoomOutlook';
import MeetingRoomOutlookBooking from './MeetingRoomOutlookBooking';
import MeetingRoomOutlookTable from './MeetingRoomOutlookTable';
import MeetingRoomOutlookUnusedBooking from './MeetingRoomOutlookUnusedBooking';

@connect(state => ({
  querystring: state.querystring,
}))
class MeetingRoomOutlookUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartType: 'weekly', //[weekly, monthly]
      selectedDate: null,
    };
    this.handleBarChartTypeChange = this.handleBarChartTypeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleBarChartTypeChange = value => {
    this.setState({ barChartType: value });
  };

  handleDateChange = value => {
    this.setState({ selectedDate: value });
  };

  render() {
    const { barChartType, selectedDate } = this.state;
    return (
      <div className="config-container custom-config-container">
        <div className="config-title-container grid grid-nogutter">
          <div className="text-left col-fixed">
            <div className="title-text">
              {COMMON_TEXT.MEETING_ROOM_OUTLOOK_USAGE}
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div className="config-content">
          <MeetingRoomOutlook onDateChanged={this.handleDateChange} />
          <MeetingRoomOutlookTable selectedDate={selectedDate} />
          <MeetingRoomOutlookBooking
            onBarChartTypeChange={this.handleBarChartTypeChange}
          />
          <MeetingRoomOutlookUnusedBooking barChartType={barChartType} />
          <ScrollTop
            target="parent"
            threshold={400}
            className=""
            style={{
              height: 40,
              width: 40,
              maxHeight: 40,
              minHeight: 40,
              maxWidth: 40,
              minWidth: 40,
            }}
            icon="pi pi-arrow-up text-base"
          />
        </div>
      </div>
    );
  }
}

MeetingRoomOutlookUsage.propTypes = {};

export default MeetingRoomOutlookUsage;
