export const SESSION_STORAGE_KEY = {
  MEETING_ROOM_SLIDER_STATE: 'meeting-room-slider-state',
  EMPLOYEE_LOG_SLIDER_STATE: 'employee-log-slider-state',
  SETTING_EXECUTION_ID: 'setting-execution-id',
};

export const QUERY_STRING_STORE = {
  SELECT_BOX_TENANT: 'select-tenant',
  SELECT_BOX_BRANCH: 'select-branch',
  SELECT_BOX_FLOOR: 'select-floor',
};

export const MEMORY_STORE = {
  FLOORS: 'floors',
  BRANCHES: 'branches',
  TENANTS: 'tenants',
  ROUTERS: 'routers',
};
