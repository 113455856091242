import FpToaster from 'forepaas/toaster'
import FpTranslate from 'forepaas/translate'
import _get from 'lodash/get'
import React from 'react'
import FpAuthentication from '../FpAuthentication'

class FpMfaMail extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      alreadySent: false,
      canBeResend: true
    }
  }

  get buttonStyle () {
    if (!this.state.alreadySent) {
      return {
        color: 'white',
        backgroundColor: this.props.color,
        cursor: 'pointer',
        opacity: 1
      }
    }
    if (!this.state.canBeResend) {
      return {
        color: '#888',
        backgroundColor: 'white',
        cursor: 'not-allowed',
        opacity: 0.5
      }
    }
    return {
      color: '#888',
      backgroundColor: 'white',
      cursor: 'pointer',
      opacity: 1
    }

  }

  async send() {
    try {
      if (this.state.alreadySent && !this.state.canBeResend) return
      this.setState({
        alreadySent: true,
        canBeResend: false
      })
      await FpAuthentication.sendMFACode('email', this.props.token)

      FpToaster.success('authentication.mfa.email.send.success')

      setTimeout(() => {
        this.setState({
          alreadySent: true,
          canBeResend: true
        })
      }, 5000)
    } catch (err) {
      this.setState({
        alreadySent: false,
        canBeResend: true
      })
      err.message = _get(err,'response.data.message',err.message)
      console.error(err)
      FpToaster.error(err.message)
    }

  }


  render () {
    return (
      <div className="mfa-mail">
        <div className="text-center">
          <button
            className="mfa-button"
            style={this.buttonStyle}
            onClick={this.send.bind(this)}
            type="button"
          >
            { this.state.alreadySent ? FpTranslate('authentication.mfa.email.resend') : FpTranslate('authentication.mfa.email.send') }
          </button>
        </div>
        { this.state.alreadySent &&

          <div className="text-center" style={{'marginTop': 20}} >
            <label>{FpTranslate('authentication.mfa.email.code_label')}</label>
            <input
              type="text"
              className="text-center"
              value={this.props.code}
              onChange={(event)=>this.props.onChange(event.target.value)}
              placeholder={FpTranslate('authentication.mfa.code')}
            />
          </div>
        }
      </div>

    )
  }
}
export default FpMfaMail
