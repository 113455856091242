import EmployeeInfo from '@/components/CustomComponent/EmployeeInfo/EmployeeInfo';
import ReactImageMarkerNotDraggable from '@/components/CustomComponent/ReactImageMarkerNotDraggable';
import { COMMON_TEXT } from '@/helpers/common-text';
import { EmployeeMarkerRender } from '@/helpers/employee-marker';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { Sidebar } from 'primereact/sidebar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { transformPositionToMarkerCoordinate } from 'src/helpers/utility';
import { QUERY_STRING_STORE } from '@/helpers/constants';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      isLoading: false,
      floorMapUrl: '',

      sidebarVisible: false,
      selectedEmployeeInfo: null,
    };
  }

  componentDidMount() {
    const { data: floorMapData, isLoading, floorMapUrl } = this.props;
    this.setState({
      data: floorMapData,
      isLoading,
      floorMapUrl,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      querystring,
      data: floorMapData,
      isLoading,
      floorMapUrl,
    } = this.props;

    const { querystring: prevQuerystring } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const branch = querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevBranch =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const isFloorChanged = floor !== prevFloor;
    const isBranchChanged = branch !== prevBranch;
    if (isBranchChanged || isFloorChanged) {
      this.setState({
        branch,
        tenant,
        data: floorMapData,
        isLoading,
        floorMapUrl,
      });
    }
  }

  getFloorMapDataMarkers = ({ floorMapData, isDisplayAllDeviceChecked }) => {
    const cloneFloorMapDataList = floorMapData.map(item => {
      return { ...item };
    });

    let floorMapDataMarkers = cloneFloorMapDataList.filter(item => {
      const { employee_id, location_info } = item;
      if (!employee_id || !location_info || location_info.length === 0) {
        return false;
      }
      return true;
    });

    floorMapDataMarkers = floorMapDataMarkers.reduce((array, item) => {
      const { location_info } = item;
      // location_info is an array of location info
      // flaten location_info to array of marker
      if (location_info && location_info.length > 0) {
        if (isDisplayAllDeviceChecked) {
          location_info.forEach(info => {
            const { position_x, position_y } = info;
            const { top, left } = transformPositionToMarkerCoordinate({
              x: position_x,
              y: position_y,
            });
            array.push({
              ...item,
              ...info,
              top,
              left,
            });
          });
        } else {
          // priority display mobile marker first
          let pcMarker = null;
          let mobileMarker = null;
          location_info.forEach(info => {
            if (info.device_type === 'pc') {
              pcMarker = info;
            } else if (info.device_type === 'phone') {
              mobileMarker = info;
            }
          });
          let displayMarker = null;
          if (mobileMarker) {
            displayMarker = mobileMarker;
          } else if (pcMarker) {
            displayMarker = pcMarker;
          }
          const { position_x, position_y } = displayMarker;
          if (position_x && position_y) {
            const { top, left } = transformPositionToMarkerCoordinate({
              x: position_x,
              y: position_y,
            });
            array.push({
              ...item,
              ...displayMarker,
              top,
              left,
            });
          }
        }
      }
      return array;
    }, []);

    for (const dataMarker of floorMapDataMarkers) {
      delete dataMarker.location_info;
    }
    return floorMapDataMarkers;
  };

  updateAllDeviceChecked = ({ isCheck }) => {
    this.props.updateAllDeviceChecked({
      isCheck: isCheck,
    });
  };

  render() {
    const {
      data: floorMapData,
      floorMapUrl,
      sidebarVisible,
      selectedEmployeeInfo,
    } = this.state;
    const { isDisplayAllDeviceChecked, floorMapError } = this.props;

    const floorMapDataMarkers = this.getFloorMapDataMarkers({
      floorMapData,
      isDisplayAllDeviceChecked,
    });

    const triggerEmployeeDetailSidebar = marker => {
      this.setState({
        sidebarVisible: true,
        selectedEmployeeInfo: { ...marker },
      });
    };

    return (
      <div>
        <Sidebar
          visible={sidebarVisible}
          onHide={() =>
            this.setState({ sidebarVisible: false, selectedEmployeeInfo: null })
          }
          closeIcon="pi pi-chevron-left"
          position="right"
          closeOnEscape={true}
          dismissable={true}
        >
          <EmployeeInfo
            employeeId={selectedEmployeeInfo?.['employee_id']}
            deviceType={selectedEmployeeInfo?.['device_type']}
          ></EmployeeInfo>
        </Sidebar>
        <div className="image-container">
          <div className="switch-all-devices-container">
            <InputSwitch
              checked={isDisplayAllDeviceChecked}
              onChange={e =>
                this.updateAllDeviceChecked({
                  isCheck: e.target.value,
                })
              }
            />
            <label>すべてのデバイスを表示する</label>
          </div>
          <ReactImageMarkerNotDraggable
            triggerEmployeeDetailSidebar={triggerEmployeeDetailSidebar}
            extraClass="custom-image-marker"
            bufferLeft={0}
            bufferTop={0}
            src={floorMapUrl}
            markerComponent={EmployeeMarkerRender}
            markers={floorMapError ? [] : floorMapDataMarkers || []}
            alt=""
          />
          <>
            {floorMapError && (
              <Message
                severity="error"
                text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
              />
            )}
          </>
        </div>
      </div>
    );
  }
}

EmployeeLocationMap.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  floorMapError: PropTypes.bool,
  floorMapUrl: PropTypes.string,

  isDisplayAllDeviceChecked: PropTypes.bool,
  updateAllDeviceChecked: PropTypes.func,
};

export default EmployeeLocationMap;
