import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildQueryMeetingRoomMartUnusedDataByBranchId = ({ branchId, limit, type }) => {
  const tableName =
    type === 'weekly'
      ? QUERY_TABLES.MEETING_ROOM_MART_TABLE_UNUSED_WEEKLY
      : QUERY_TABLES.MEETING_ROOM_MART_TABLE_UNUSED_MONTHLY;
  if (!limit) {
    limit = 0;
  }
  return {
    data: {
      fields: {
        branch_id: ['select'],
        floor_id: ['select'],
        organizer: ['select'],
        tenant_id: ['select'],
        unused_minutes: ['select'],
      },
      skip: 0,
      limit: limit,
    },
    filter: {
      branch_id: [branchId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      unused_minutes: 'desc',
    },
    table_name: tableName,
  };
};

export const fetchMeetingRoomMartUnusedDataByBranchId = async ({ branchId, limit, type }) => {
  const request = buildQueryMeetingRoomMartUnusedDataByBranchId({ branchId, limit, type });
  return executeQuery(request);
};
