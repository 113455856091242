import { CustomAxisTick } from '@/components/CustomComponent/CustomAxisTick';
import { CustomYAxisTick } from '@/components/CustomComponent/CustomYAxisTick';
import { COMMON_TEXT } from '@/helpers/common-text';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { CustomTooltipBarChart } from './CustomTooltipBarChart';

class CustomBarChart extends Component {
  renderLabelContent = props => {
    return (
      <text
        fill="#333333"
        offset="0"
        x={props.viewBox.cx}
        y={props.viewBox.cy}
        textAnchor="middle"
        fontWeight={700}
      >
        <tspan x="56" dy="16">
          {`(${props.value})`}
        </tspan>
      </text>
    );
  };

  render() {
    let {
      data = [],
      xAxisDataKey,
      yAxisDataKey,
      yAxisLabel,
      yAxisUnit,
      axisHeight,
      chartHeight,
      isLoading,
    } = this.props;
    yAxisLabel = yAxisLabel ?? yAxisDataKey;
    const isEmptyData = data?.length === 0;
    chartHeight = chartHeight ?? 360;
    const loadingLayerStyle = {
      position: 'absolute',
      left: 70,
      top: 40,
      right: 10,
      bottom: chartHeight === 360 ? 105 : 205,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    return (
      <div style={{ position: 'relative' }}>
        <ResponsiveContainer
          width={'100%'}
          height={chartHeight}
          minHeight={chartHeight}
        >
          <BarChart
            data={data || []}
            margin={{ top: 40, right: 10, left: 10, bottom: 5 }}
            layout={'horizontal'}
          >
            <Tooltip content={<CustomTooltipBarChart />} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={xAxisDataKey}
              tick={<CustomAxisTick rotate={-45} textAnchor="end" />}
              height={axisHeight ?? 100}
              interval={0}
            />
            <YAxis
              tick={<CustomYAxisTick yAxisUnit={yAxisUnit ?? ''} />}
              domain={[0, yAxisUnit === '%' ? 100 : 'auto']}
            >
              <Label
                content={this.renderLabelContent}
                offset={0}
                position="top"
                value={yAxisLabel}
              />
            </YAxis>
            <Bar dataKey={yAxisDataKey} maxBarSize={70}>
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fillColor} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {!isLoading && isEmptyData && (
          <div style={loadingLayerStyle}>
            <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
          </div>
        )}
        {isLoading && (
          <div style={loadingLayerStyle}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }
}

export default CustomBarChart;
