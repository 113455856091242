import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryBranches = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        branch_order: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_name: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildQueryFloors = ({ branchIdList }) => {
  const randomBranchId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomBranchId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
        scale_x: ['select'],
        scale_y: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

export const fetchBranchList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryBranches({ tenantId });
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async payload => {
  const { branchIdList } = payload;
  const request = buildQueryFloors({ branchIdList });
  return executeQuery(request);
};
