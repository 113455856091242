/**
 *
 * BCPOverviewEmployee
 *
 */

import BCPOverviewEmployee from './BCPOverviewEmployee.jsx';
import './BCPOverviewEmployee.less';

export default BCPOverviewEmployee;
