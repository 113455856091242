/**
 *
 * EmployeeAccessLog
 *
 */

import EmployeeAccessLog from './EmployeeAccessLog.jsx';
import './EmployeeAccessLog.less';

export default EmployeeAccessLog;
