/**
 *
 * MerakiChangelog
 *
 */

import MerakiChangelog from './MerakiChangelog.jsx';
import './MerakiChangelog.less';

export default MerakiChangelog;
