import React, { Component } from 'react';
import './CustomCalendar.less';
import { Calendar } from 'primereact/calendar';

class CustomCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const { props } = this;
    const { label, className } = props;
    return (
      <div className={`custom-calendar ${className ?? ''}`}>
        <div className="title">{`${label ?? '日付'}`}</div>
        <Calendar
          {...props}
          className="custom-date-picker"
          dateFormat="yy年mm月dd日"
          readOnlyInput
          showIcon
          showButtonBar
          selectionMode="single"
          clearButtonClassName="hidden"
        />
      </div>
    );
  }
}

CustomCalendar.propTypes = {};

export default CustomCalendar;
