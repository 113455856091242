import CustomCurrentTime from '@/components/CustomComponent/CustomCurrentTime';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import MeetingRoomUsage from '@/components/communication/MeetingRoomUsage';
import { fetchData } from '@/components/communication/MeetingRoomUsage/query-request';
import PeopleCounts from '@/components/communication/PeopleCounts';
import { getPeopleCountsAreaByFloorId } from '@/components/communication/PeopleCounts/query-request';
import AirQualityNow from '@/components/facility_management/AirQuality/AirQualityNow';
import {
  fetchSensorDataNow,
  fetchSensorDataThreshold,
} from '@/components/facility_management/AirQuality/AirQualityNow/query-request';
import {
  COMPONENT_CHILD_ID_GROUP,
  COMPONENT_ID_GROUP,
} from '@/helpers/constants';
import {
  calculateRectangle,
  transformCoordinateBottomLeftToTopLeft,
  generateRandomString
} from '@/helpers/utility';
import '@egjs/flicking-plugins/dist/arrow.css';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import { format } from 'date-fns';
import { isEqual, isNil } from 'lodash';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import Countdown from '@/components/CustomComponent/CustomCountDown/CustomCountDown'; // Import the Countdown component


@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class SignagePlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlickingPlay: false,
      fetchedData: {},
      isFetchingAllData: false,
      buttonsDisabled: false,
      countdownDuration: 0,
      countdownKey: generateRandomString(10)
    };
    this.flickingDurationArr = [];
    this.autoPlayTimeout = null;
    this.flicking = createRef();
  }

  componentWillMount() {}

  componentDidMount() {
    const { items } = this.props;
    this.flickingDurationArr = items.map(item => item.interval * 1000);
    this.getAllAndUpdateAllData({ items });
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
    clearTimeout(this.autoplayTimeout);
  }

  getDataRoomUsageByFloor = async ({ floorId }) => {
    let dataFloorMap = await fetchData({
      floorId: floorId,
    });
    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    dataFloorMap = dataFloorMap
      .sort((a, b) => {
        if (a?.device_name < b?.device_name) {
          return -1;
        }
        if (a?.device_name > b?.device_name) {
          return 1;
        }
        return 0;
      })
      .map(item => ({
        ...item,
        timestamp: format(new Date(item.timestamp), 'MM月dd日 - HH:mm'),
      }));

    const updatedFloorMapData = dataFloorMap.map(item => ({
      position: {
        x: parseFloat(item?.position_x),
        y: parseFloat(item?.position_y),
      },
      data: item ?? null,
    }));

    return { updatedFloorMapData, dataFloorMap };
  };

  getDataPeopleCountsByFloor = async ({ floorId }) => {
    let dataFloorMap = await getPeopleCountsAreaByFloorId({
      floorId: floorId,
    });

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    const dataPeopleCounts = dataFloorMap.map(item => {
      const { position_x, position_y, position_x1, position_y1 } = item;
      const { pointC, width, height } = calculateRectangle({
        positionX: position_x,
        positionY: position_y,
        positionX1: position_x1,
        positionY1: position_y1,
      });
      const { x, y } = transformCoordinateBottomLeftToTopLeft({
        x: pointC.x,
        y: pointC.y,
      });
      item.rect = {
        top: y,
        left: x,
        width,
        height,
      };
      return item;
    });

    return { dataPeopleCounts };
  };

  getSensorDataByFloor = async ({ floorId }) => {
    let sensorDataRes = await fetchSensorDataNow({
      floorId: floorId,
    });

    let sensorData = [];
    if (sensorDataRes && sensorDataRes.length > 0) {
      sensorData = sensorDataRes.map(item => ({
        position: {
          x: parseFloat(item?.position_x),
          y: parseFloat(item?.position_y),
        },
        data: item ?? null,
        // For testing
        // data: {
        //   ...item ?? null,
        //   carbon_dioxide: Math.floor((Math.random() * 1000) + 1)
        // },
      }));
    }

    let sensorDataThreshold = await fetchSensorDataThreshold({
      floorId: floorId,
    });
    sensorDataThreshold = sensorDataThreshold?.[0] ?? {};
    return { sensorData, sensorDataThreshold };
  };

  getAllAndUpdateAllData = async ({ items }) => {
    this.setState({ isFetchingAllData: true });
    const newFetchedData = {};
    const fetchPromises = items.map(
      async ({ component_id, floor_id, rowId }) => {
        const { data } = await this.getDataByComponentAndFloor({
          componentId: component_id,
          floorId: floor_id,
        });
        newFetchedData[rowId] = data;
      }
    );
    await Promise.all(fetchPromises);
    const { fetchedData } = this.state;

    const isDataChanged = !isEqual(fetchedData, newFetchedData);
    this.setState(
      {
        fetchedData: isDataChanged ? newFetchedData : fetchedData,
        isFetchingAllData: false,
        isFlickingPlay: true,
      },
      () => {
        this.startAutoplay();
      }
    );
  };

  getAndUpdateData = async ({ componentId, floorId, rowId, itemInterval }) => {
    const { data } = await this.getDataByComponentAndFloor({
      componentId,
      floorId,
    });
    const currentDataByRowId = this.state.fetchedData[rowId] ?? [];
    if (!isEqual(currentDataByRowId, data)) {
      this.setState({
        fetchedData: {
          ...this.state.fetchedData,
          [rowId]: data,
        },
      });
    }
  };

  getDataByComponentAndFloor = async ({ componentId, floorId }) => {
    let data = [];
    switch (componentId) {
      case COMPONENT_ID_GROUP.ROOM_AVAILABILITIES:
        data = await this.getDataRoomUsageByFloor({ floorId: floorId });
        break;
      case COMPONENT_ID_GROUP.PEOPLE_COUNTS:
        data = await this.getDataPeopleCountsByFloor({ floorId: floorId });
        break;
      case COMPONENT_ID_GROUP.ENVIRONMENTS:
        data = await this.getSensorDataByFloor({ floorId: floorId });
        break;
      default:
        break;
    }
    return { data: data ?? [] };
  };

  // Flicking event listener
  flickingOnChanged = ({
    currentTarget,
    eventType,
    index,
    panel,
    prevIndex,
    prevPanel,
    isTrusted,
    direction,
  }) => {
    const { isFlickingPlay } = this.state;
    if (!isFlickingPlay) {
      return;
    }
    const { items } = this.props;
    const nextSlideIndex = (index + 1) % items.length;
    const nextSlideItem = items[nextSlideIndex];
    const { component_id, floor_id, rowId, interval } = nextSlideItem;
    this.getAndUpdateData({
      componentId: component_id,
      floorId: floor_id,
      rowId,
      itemInterval: interval,
    });
  };

  getCurrentFlickingIndex = () => {
    return this.flicking.current.index;
  };

  startAutoplay = () => {
    const { isFlickingPlay } = this.state;
    if (isFlickingPlay) {
      clearTimeout(this.autoplayTimeout);
      const currentIndex = this.getCurrentFlickingIndex();
      const duration = this.flickingDurationArr[currentIndex];
      this.setState({ countdownDuration: duration, countdownKey: generateRandomString(10) }); // Set the duration for Countdown component
      this.autoplayTimeout = setTimeout(() => {
        if (!this.flicking.current.animating) {
          const newIndex =
            (currentIndex + 1) % this.flicking.current.panelCount;
          this.flicking.current
            .moveTo(newIndex)
            .then(() => {
              this.startAutoplay(); // Recursively call to continue autoplay
            })
            .catch(() => {
              // Handle potential moveTo error
            });
        }
      }, duration);
    }
  };

  // flicking button control
  flickingButtonGroup = () => {
    const { isFlickingPlay, buttonsDisabled } = this.state;

    const pauseTheFlicking = () => {
      if (isFlickingPlay) {
        this.setState({ isFlickingPlay: false });
        clearTimeout(this.autoplayTimeout);
      }
    };

    const disableButtonsTemporarily = () => {
      this.setState({ buttonsDisabled: true });
      setTimeout(() => {
        this.setState({ buttonsDisabled: false });
      }, 700);
    };

    const playBtnOnClicked = async () => {
      if (!isFlickingPlay) {
        this.setState({ isFlickingPlay: true }, this.startAutoplay);
      } else {
        pauseTheFlicking();
      }
      disableButtonsTemporarily();
    };

    const nextBtnOnClicked = async () => {
      // Pause autoplay before moving to the next panel
      pauseTheFlicking();

      const currentIndex = this.getCurrentFlickingIndex();
      const newIndex = (currentIndex + 1) % this.flicking.current.panelCount;
      if (!this.flicking.current.animating) {
        this.flicking.current
          .moveTo(newIndex)
          .then(() => {
            if (this.state.isPlaying) {
              this.startAutoplay(); // Restart autoplay after manual control
            }
          })
          .catch(() => {
            // Handle potential moveTo error
          });
      }
      disableButtonsTemporarily();
    };

    const prevBtnOnClicked = async () => {
      // Pause autoplay before moving to the next panel
      pauseTheFlicking();

      const currentIndex = this.getCurrentFlickingIndex();
      const totalCount = this.flicking.current.panelCount;
      const newIndex = (currentIndex - 1 + totalCount) % totalCount;
      if (!this.flicking.current.animating) {
        this.flicking.current
          .moveTo(newIndex)
          .then(() => {
            if (this.state.isPlaying) {
              this.startAutoplay(); // Restart autoplay after manual control
            }
          })
          .catch(() => {
            // Handle potential moveTo error
          });
      }
      disableButtonsTemporarily();
    };

    return (
      <div className="control-button-container">
        <Button
          text
          className="control-button control-back-btn"
          icon="pi pi-backward"
          severity="secondary"
          disabled={buttonsDisabled}
          onClick={() => prevBtnOnClicked()}
        ></Button>
        <Button
          text
          className="control-button control-play-btn"
          icon={`pi pi-${isFlickingPlay ? 'pause' : 'play'}`}
          severity="secondary"
          disabled={buttonsDisabled}
          onClick={() => playBtnOnClicked()}
        ></Button>
        <Button
          text
          className="control-button control-next-btn"
          icon="pi pi-forward"
          severity="secondary"
          disabled={buttonsDisabled}
          onClick={() => nextBtnOnClicked()}
        ></Button>
      </div>
    );
  };

  renderComponent = ({ item, fetchedData, index }) => {
    const { component_id, floor_id, component_child_id, rowId } = item;
    const componentData = fetchedData[rowId];
    const signageModeData = {
      data: componentData ?? [],
      floor: floor_id,
    };
    if (isNil(componentData)) {
      return <Fragment key={-1}></Fragment>;
    }
    switch (component_id) {
      case COMPONENT_ID_GROUP.ROOM_AVAILABILITIES:
        return (
          <MeetingRoomUsage
            key={index}
            floor={floor_id}
            isSignageMode={true}
            signageModeData={signageModeData}
          />
        );
      case COMPONENT_ID_GROUP.PEOPLE_COUNTS:
        return (
          <PeopleCounts
            key={index}
            signageModeData={signageModeData}
            isSignageMode={true}
          />
        );
      case COMPONENT_ID_GROUP.ENVIRONMENTS:
        switch (component_child_id) {
          case COMPONENT_CHILD_ID_GROUP.TEMPERATURE:
            return (
              <AirQualityNow
                key={index}
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'temperature',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.HUMIDITY:
            return (
              <AirQualityNow
                key={index}
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'humidity',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.CARBON_DIOXIDE:
            return (
              <AirQualityNow
                key={index}
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'carbon_dioxide',
                }}
                isSignageMode={true}
              />
            );
          case COMPONENT_CHILD_ID_GROUP.NOISE_LEVEL:
            return (
              <AirQualityNow
                key={index}
                signageModeData={{
                  ...signageModeData,
                  selectedSensorType: 'noise_level',
                }}
                isSignageMode={true}
              />
            );
          default:
            return <Fragment key={-2}></Fragment>;
        }
      default:
        break;
    }
    return <Fragment key={-3}></Fragment>;
  };

  flickingComponent = () => {
    const { items } = this.props;
    const { fetchedData } = this.state;

    const RenderComponentMemo = React.memo(this.renderComponent);
    const FlickingButtonGroupMemo = React.memo(this.flickingButtonGroup);

    return (
      <Flicking
        ref={this.flicking}
        panelsPerView={1}
        duration={1000}
        align={'center'}
        moveType={['strict', { count: 1 }]}
        preventClickOnDrag={true}
        preventDefaultOnDrag={true}
        changeOnHold={true}
        renderOnlyVisible={true}
        circular={true}
        onChanged={this.flickingOnChanged}
        autoResize={true}
        useResizeObserver={true}
      >
        {items.map((item, idx) => (
          <div className="panel" key={idx}>
            <RenderComponentMemo
              key={idx}
              index={idx}
              item={item}
              fetchedData={fetchedData}
            />
          </div>
        ))}
        <ViewportSlot>
          <FlickingButtonGroupMemo></FlickingButtonGroupMemo>
        </ViewportSlot>
      </Flicking>
    );
  };

  render() {
    const { isFetchingAllData, countdownDuration, countdownKey } = this.state;
    const FlickingMemo = this.flickingComponent;
    return (
      <div className="signage-play-container">
        <div className="card">
          {isFetchingAllData ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="custom-current-time-container">
                <CustomCurrentTime />
              </div>
              <FlickingMemo />
              <Countdown duration={countdownDuration} key={countdownKey} />
            </>
          )}
        </div>
      </div>
    );
  }
}

SignagePlay.propTypes = {
  items: PropTypes.array,
};
export default SignagePlay;
