import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CustomCountDown.less';

const Countdown = ({ duration }) => {
  const [countdown, setCountdown] = useState(duration / 1000);

  useEffect(() => {
    setCountdown(duration / 1000);
    let intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 1) {
          return prevCountdown - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    // Clear interval on component unmount or duration change
    return () => clearInterval(intervalId);
  }, [duration]);

  return (
    (countdown <= 3 && countdown > 0) && (
      <>
        <div id="countdown" className="custom-countdown">
          <div id="countdown-number">{countdown}</div>
          <svg width="54" height="54">
            <circle r="26" cx="27" cy="27"></circle>
          </svg>
        </div>
      </>
    )
  );
};

Countdown.propTypes = {
  duration: PropTypes.number.isRequired,
};

export default Countdown;
