import React, { PureComponent } from 'react';
import { formatNumberWithCommas } from '@/helpers/utility';

export class CustomTooltipBarChart extends PureComponent {
  render() {
    const { active, payload, label } = this.props;
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip pt-1 pb-1 pl-2 pr-2"
          style={{
            minWidth: '260px',
            width: 'auto',
            maxWidth: '460px',
            backgroundColor: '#F5F5F5',
            border: '2px solid #555555',
            boxShadow: '0px 0px 5px rgba(245, 245, 245, 0.4)',
            borderRadius: '5px',
          }}
        >
          <div className="tooltip-title" style={{ fontSize: '1rem' }}>
            <b>{label}</b>
          </div>
          <div className="grid grid-nogutter">
            {payload.map((item, index) => {
              const { value, name, color } = item;
              return (
                <div
                  key={`custom-chart-tooltip-${index}`}
                  style={{ color: color, fontSize: '1rem' }}
                  className="col-12 grid grid-nogutter align-items-center justify-content-between"
                >
                  <div
                    className="col text-left"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {name}
                  </div>
                  <div className="col text-right" style={{}}>
                    {formatNumberWithCommas(value)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  }
}
