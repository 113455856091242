/**
 *
 * SignagePlay
 *
 */

import SignagePlay from './SignagePlay.jsx';
import './SignagePlay.less';

export default SignagePlay;
