/**
 *
 * LocationSetting
 *
 */

import LocationSetting from './LocationSetting.jsx';
import './LocationSetting.less';

export default LocationSetting;
