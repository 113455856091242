/**
 *
 * Summary
 *
 */

import Summary from './Summary.jsx';
import './Summary.less';

export default Summary;
