import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { format } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class BCPOverviewFloor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ?? [],
      dataTableFloor: this.props.dataTableFloor ?? [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: this.props.isLoading ?? false,

      selectedFloor: {},
      textSearchBranchName: '',
      textSearchFloorName: '',
    };

    // Data get from API
    this.dataFloor = [];

    this.dataTableFloorRef = React.createRef();
    this.filters = {
      branch_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      floor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };

    this.dataColumns = [
      {
        field: 'branch_name',
        header: COMMON_TEXT.BRANCH,
        align: 'left',
        headerClassName: 'text-center',
        bodyClassName: 'text-align-left',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
        },
        bodyStyle: { fontSize: '0.85rem' },
        hasFilter: true,
      },
      {
        field: 'floor_name',
        header: COMMON_TEXT.FLOOR,
        align: 'left',
        headerClassName: 'text-center',
        bodyClassName: 'text-align-left',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
        },
        bodyStyle: { fontSize: '0.85rem' },
        hasFilter: true,
      },
      {
        field: 'phone_count',
        header: 'スマホ',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '80px',
        },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'pc_count',
        header: 'PC',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '80px',
        },
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'timestamp',
        header: COMMON_TEXT.LAST_UPDATE_TS,
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '200px',
        },
        bodyStyle: { fontSize: '0.85rem', width: '200px' },
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    const { data, dataTableFloor, isLoading } = this.props;

    this.setState({
      tenant,
      data,
      dataTableFloor,
      isLoading,
    });
    this.dataFloor = dataTableFloor;
  }

  componentDidUpdate(prevProps) {
    const { data, dataTableFloor, isLoading } = this.props;
    const {
      data: prevData,
      dataTableFloor: prevDataTable,
      isLoading: prevIsLoading,
    } = prevProps;

    const isDataChanged = data !== prevData;
    const isDataTableChanged = dataTableFloor !== prevDataTable;
    const isLoadingChanged = isLoading !== prevIsLoading;

    if (isDataChanged || isDataTableChanged || isLoadingChanged) {
      this.setState({
        data,
        dataTableFloor,
        isLoading,
      });
      this.dataFloor = dataTableFloor;
    }
  }

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  rowClass = data => {
    const { selectedFloor } = this.state;
    return {
      'is-selected': data.floor_id === selectedFloor.floor_id,
    };
  };

  updateSelectedFloor = ({ floor }) => {
    this.setState({
      selectedFloor: floor,
    });
    this.props.updateSelectedFloor({
      floor,
    });
  };

  renderDataTable({ tableData }) {
    const { isLoading } = this.state;

    return (
      <>
        <DataTable
          ref={this.dataTableFloorRef}
          className="outlook-data-table"
          value={tableData}
          tableStyle={{ width: '100%' }}
          size="small"
          stripedRows
          scrollable
          scrollHeight="630px"
          showGridlines
          loading={isLoading}
          filterDisplay="row"
          filters={this.filters}
          rowClassName={this.rowClass}
          selectionMode="single"
          onSelectionChange={e => this.updateSelectedFloor({ floor: e.value })}
          emptyMessage={() => {
            return (
              <div className="flex justify-content-center align-items-center">
                <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
              </div>
            );
          }}
        >
          {this.dataColumns.map(col => {
            return (
              <Column
                dataType={col.dataType ?? 'string'}
                key={col.field}
                field={col.field}
                header={col.header}
                align={col.align}
                alignHeader={col.align}
                headerClassName={col.headerClassName}
                bodyClassName={col.bodyClassName}
                headerStyle={col.headerStyle}
                bodyStyle={col.bodyStyle}
                filter={col.hasFilter}
                filterMatchMode={'contains'}
                body={dataItem => {
                  switch (col.field) {
                    case 'timestamp':
                      return dataItem[col.field] != null
                        ? format(
                            new Date(dataItem[col.field]),
                            'yyyy年MM月dd日 - HH:mm'
                          )
                        : null;

                    default:
                      return dataItem[col.field];
                  }
                }}
              />
            );
          })}
        </DataTable>
      </>
    );
  }

  render() {
    const { isLoading, dataTableFloor } = this.state;

    return (
      <div className="bcp-overview-floor overview-floor-container">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="table-container">
              {this.renderDataTable({
                tableData: dataTableFloor,
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

BCPOverviewFloor.propTypes = {
  dataTableFloor: PropTypes.array,

  updateSelectedFloor: PropTypes.func,
};

export default BCPOverviewFloor;
