import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import React from 'react'

import FpLoader from 'forepaas/core-ui/loader'
import FpSdk from 'forepaas/sdk'
import { del, set } from 'forepaas/store/local/action'
import FpXhr from 'forepaas/xhr'

class FpAuthentication extends FpXhr {
  get localSession () {
    try {
      return JSON.parse(localStorage.getItem('client-authority-manager-session'))
    } catch (_) { return null }
  }
  get token () { return this.localSession && this.localSession.token }
  getBaseUrl () {
    return `${FpSdk.config.authentication.split('?')[0]}/v4`
  }

  getUsername () {
    let cam = FpSdk.modules.store.getState().local['client-authority-manager-session']
    if (cam) return cam.user || FpSdk.config.username || ''
    return FpSdk.config.username || ''
  }

  getLogin () {
    let cam = FpSdk.modules.store.getState().local['client-authority-manager-session']
    if (cam) return cam.login || FpSdk.config.login || ''
    return FpSdk.config.login || ''
  }

  getAppId () {
    return this._getQueryStringFromUrl(FpSdk.config && FpSdk.config.authentication).app_id || null
  }

  openAuthMode (authMode, props) {
    let authorizationCode = ['king_id', 'openam_authorization_code', 'authorization_code']
    if (authorizationCode.indexOf(authMode.type) !== -1) {
      return this.loginOauth(authMode)
    }
    return React.createElement(FpSdk.modules['client-authority-manager'].FpStandardLogin, props || {})
  }

  loginOauth (authMode, options) {
    var redirectUri = encodeURIComponent(window.location.origin + window.location.pathname + '#/reply/')
    var url = this.getBaseUrl() + '/login?app_id=' + this.getAppId() + '&auth_mode_id=' + authMode._id + '&redirect_uri=' + redirectUri
    window.location.replace(url)
    return React.createElement(FpLoader, {})
  }

  async standardLogin (form) {
    let session = await this.post({
      data: form,
      url: 'v4/login'
    })

    return {
      session,
      message: 'AuthenticationLoginSuccess'
    }
  }

  async sendMFACode (type, token) {
    return this.request({
      method: 'post',
      url: `v4/mfa/send/${type}`,
      queryString: {
        token
      }
    })
  }

  async validateMfa (type, token, form) {
    return await this.post({
      data: form,
      url: `v4/mfa/validate/${type}`,
      queryString: {
        token
      }
    })
  }

  async errorLogin () {
    let error = new RegExp('^#/errorMessage/(.*)$').exec(window.location.hash)
    let content = error && error[1]
    if (!content) return
    content = decodeURIComponent(content)
    try {
      content = JSON.parse(content)
    } catch (error) {}
    let err = new Error(content.error)
    err.status = content.status
    throw err
  }

  async replyLogin () {
    let reply = new RegExp('^#/reply/(.*)$').exec(window.location.hash)
    let content = reply && reply[1]
    if (!content) return
    if (content && content.split('?')[0] === 'error') {
      let message = this._getQueryStringFromUrl(window.location.hash).message
      window.location.hash = '/'
      throw new Error(message)
    } else {
      return this.checkSession(content)
    }
  }

  async checkSession (token, redirect = true) {
    if (!token) return
    try {
      let session = await this.get({
        url: 'v4/checkSession',
        queryString: {
          token: token
        }
      })
      if (redirect === true) {
        document.location.hash = `${FpSdk.config.root || ''}?${document.location.hash.split('?')[1] || ''}`
        window.history.pushState({}, '', '')
      }
      FpSdk.modules.store.dispatch(set('client-authority-manager-session', session))
      return 'AuthenticationLoginSuccess'
    } catch (err) {
      throw get(err, 'response.data.error') || err
    }
  }

  async logout () {
    let session = FpSdk.modules.store.getState().local['client-authority-manager-session']
    if (!session) return FpSdk.modules.store.dispatch(del('client-authority-manager-session'))
    try {
      let data = await this.delete({
        url: 'v4/logout',
        queryString: {
          auth_mode_id: session.auth_mode_id
        }
      })
      FpSdk.modules.store.dispatch(del('client-authority-manager-session'))
      FpSdk.modules.store.dispatch(set('client-authority-manager-session', { logout: true }))
      if (data.redirect_url) {
        document.location.href = data.redirect_url
      } else {
        document.location.href = document.location.href.split('?')[0]
        document.location.reload()
      }
    } catch (err) {

      console.error(err)
      FpSdk.modules.store.dispatch(del('client-authority-manager-session'))
      FpSdk.modules.store.dispatch(set('client-authority-manager-session', { logout: true }))
      document.location.href = document.location.href.split('?')[0]
      document.location.reload()
    }
  }

  applicationsPreferences () {
    return this.get({
      cache: true,
      url: 'v1/applications/preferences'
    }).then((data) => {
      data = cloneDeep(data)
      data.auth_mode = data.auth_mode
        .filter((am) => {
          return am.type !== 'apikey'
        })
        .map((am) => {
          am.icon = 'data:image/png;base64,' + am.icon
          am.open = (props) => {
            return this.openAuthMode(am, props)
          }
          return am
        })
      return data
    })
  }

  async passwordChange (oldPassword, newPassword) {
    try {
      await this.post({
        url: 'v4/passwordChange',
        data: {
          password: newPassword,
          oldpassword: oldPassword
        }
      })
      let cam = FpSdk.modules.store.getState().local['client-authority-manager-session']
      cam.password_renew = false
      FpSdk.modules.store.dispatch(set('client-authority-manager-session', cam))
      return 'AuthenticationPasswordChangeSuccess'
    } catch (err) {
      if (err.response) throw err.response.data.error
      throw err
    }
  }

  async passwordRenew (email) {
    try {
      await this.post({
        url: 'v4/sendRenewRequest',
        data: {
          email
        }
      })
      return 'AuthenticationPasswordRenewSuccess'
    } catch (err) {
      throw err.response.data.error
    }
  }

  async request (options) {
    options.baseURL = FpSdk.config.authentication
    options.url = options.url
    let {data} = await FpXhr.prototype.request(options)
    return data
  }

  _getQueryStringFromUrl (url) {
    if (typeof url !== 'string' || url.indexOf('?') === -1) return {}
    var qs = url.split('?')[1]
    if (!qs) return {}
    qs = qs.split('&').reduce((prev, curr, i, arr) => {
      var p = curr.split('=')
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1])
      return prev
    }, {})
    return qs
  }
}

export default new FpAuthentication()
