/**
 *
 * CameraZoneSetting
 *
 */

import CameraZoneSetting from './CameraZoneSetting.jsx';
import './CameraZoneSetting.less';

export default CameraZoneSetting;
