/**
 *
 * WirelessChannelUtilization
 *
 */

import WirelessChannelUtilization from './WirelessChannelUtilization.jsx';
import './WirelessChannelUtilization.less';

export default WirelessChannelUtilization;
