/**
 *
 * WorkingHourSetting
 *
 */

import WorkingHourSetting from './WorkingHourSetting.jsx';
import './WorkingHourSetting.less';

export default WorkingHourSetting;
