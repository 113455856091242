import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryEmployeeAttendance = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        capacity_id: ['select'],
        type: ['select'],
        branch_id: ['select'],
        floor_id: ['select'],
        department: ['select'],
        capacity: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEE_ATTENDANCE_CAPACITY,
  };
};

const buildQueryEmployees = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        department: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};


export const fetchEmployeeAttendanceList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployeeAttendance({ tenantId });
  return executeQuery(request);
};

export const fetchEmployeeList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployees({ tenantId });
  return executeQuery(request);
};
