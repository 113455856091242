import {
  fetchDataOutlookStartAfterStartAndEndAfterEnd,
  fetchDataOutlookStartAfterStartAndEndBeforerEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterEnd,
  fetchDataOutlookStartBeforeStartAndEndAfterStart,
  fetchRoomAvailabilityByFloorIdList,
} from './query-request';

const getRoomAvailabilityByFloorIdList = async ({ floorIdList }) => {
  let rooms = await fetchRoomAvailabilityByFloorIdList({ floorIdList });
  return rooms || [];
};

const getMeetingRoomOutlookDataByFloorIdListAndDateTimeRange = async ({
  floorIdList,
  startDateTime,
  endDateTime,
}) => {
  // case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
  const res1 = await fetchDataOutlookStartBeforeStartAndEndAfterStart({
    floorIdList,
    startDateTime,
    endDateTime,
  });
  // case 2: fetch data with start is before startDateTime and end is after endDateTime
  const res2 = await fetchDataOutlookStartBeforeStartAndEndAfterEnd({
    floorIdList,
    startDateTime,
    endDateTime,
  });
  // case 3: fetch data with start is after startDateTime and end is before endDateTime
  const res3 = await fetchDataOutlookStartAfterStartAndEndBeforerEnd({
    floorIdList,
    startDateTime,
    endDateTime,
  });
  // case 4: fetch data with start is after startDateTime and end is after endDateTime
  const res4 = await fetchDataOutlookStartAfterStartAndEndAfterEnd({
    floorIdList,
    startDateTime,
    endDateTime,
  });
  return [...res1, ...res2, ...res3, ...res4];
};

export {
  getMeetingRoomOutlookDataByFloorIdListAndDateTimeRange,
  getRoomAvailabilityByFloorIdList
};

