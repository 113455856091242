import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from '@/helpers/request';

const buildQueryTableData = (tenantId, fromDate, toDate) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        category: ['select'],
        client_description: ['select'],
        client_id: ['select'],
        client_mac: ['select'],
        description: ['select'],
        device_name: ['select'],
        device_serial: ['select'],
        network_identifer: ['select'],
        network_name: ['select'],
        occurred_at: ['select'],
        product_type: ['select'],
        ssid_number: ['select'],
        tenant_id: ['select'],
        type: ['select'],
        log_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      occurred_at: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      occurred_at: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORK_EVENTS,
  };
};




export const fetchMerakiNetworkEvents = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildQueryTableData(tenantId, fromDate, toDate);
  return executeQuery(request);
};












//////////// NEW HERE
const buildQueryAreasByFloorId = ({ floorId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        area_id: ['select'],
        area_name: ['select'],
        capacity_limit: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_x1: ['select'],
        position_y: ['select'],
        position_y1: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      area_id: 'asc',
    },
    table_name: QUERY_TABLES.AREAS,
  };
};

const buildQueryBranchesByTenantId = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildQueryFloorsByBranchIdList = ({ branchIdList }) => {
  const randomId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const buildQueryDevicePositionsByFloorId = ({ floorId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_position_id: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        room_name: ['select'],
        area_id: ['select'],
        area_name: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_POSITIONS,
  };
};

const buildQueryMerakiDevicesByTenantIdAndModel = ({ tenantId, model }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        model: ['select'],
        network_identifer: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
      model: {
        like: [`${model}%`]
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      device_name: 'asc'
    },
    table_name: QUERY_TABLES.MERAKI_DEVICES,
  };
};

const buildQueryButlrDevicesByTenantId = ({ tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        room_id: ['select'],
        room_name: ['select'],
        apikey_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      room_name: 'asc'
    },
    table_name: QUERY_TABLES.BUTLR_ROOMS,
  };
};

const buildQueryVerkadaDevicesByFloorIdAndDeviceType = ({ floorId, deviceType }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        door_type: ['select'],
        floor_id: ['select'],
        model: ['select'],
        org_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
      device_type: [deviceType],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      device_name: 'asc'
    },
    table_name: QUERY_TABLES.VERKADA_DEVICES,
  };
};

const buildQueryWebexDevicesByTenantId = ({ tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        workspace_id: ['select'],
        name: ['select'],
        capacity: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      name: 'asc'
    },
    table_name: QUERY_TABLES.WEBEX_WORKSPACES,
  };
};

export const fetchAreasByFloorId = async ({ floorId }) => {
  const request = buildQueryAreasByFloorId({ floorId });
  return executeQuery(request);
};

export const fetchBranchesByTenantId = async ({ tenantId }) => {
  const request = buildQueryBranchesByTenantId({ tenantId });
  return executeQuery(request);
};

export const fetchFloorsByBranchIdList = async ({ branchIdList }) => {
  const request = buildQueryFloorsByBranchIdList({ branchIdList });
  return executeQuery(request);
};

export const fetchDevicePositionsByFloorId = async ({ floorId }) => {
  const request = buildQueryDevicePositionsByFloorId({ floorId });
  return executeQuery(request);
};

export const fetchMerakiDevicesByTenantIdAndModel = async ({ tenantId, model }) => {
  const request = buildQueryMerakiDevicesByTenantIdAndModel({ tenantId, model });
  return executeQuery(request);
};

export const fetchButlrDevicesByTenantId = async ({ tenantId }) => {
  const request = buildQueryButlrDevicesByTenantId({ tenantId });
  return executeQuery(request);
};

export const fetchVerkadaDevicesByFloorIdAndDeviceType = async ({ floorId, deviceType }) => {
  const request = buildQueryVerkadaDevicesByFloorIdAndDeviceType({ floorId, deviceType });
  return executeQuery(request);
};

export const fetchWebexDevicesByTenantId = async ({ tenantId }) => {
  const request = buildQueryWebexDevicesByTenantId({ tenantId });
  return executeQuery(request);
};