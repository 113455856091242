/**
 *
 * DeviceStatusOverview
 *
 */

import DeviceStatusOverview from './DeviceStatusOverview.jsx';
import './DeviceStatusOverview.less';

export default DeviceStatusOverview;
