import FpToaster from 'forepaas/toaster'
import FpTranslate from 'forepaas/translate'
import Tooltip from 'rc-tooltip'
import React from 'react'
import FpAuthentication from '../FpAuthentication'

import FpMfaAuthenticator from './Authenticator.jsx'
import FpMfaMail from './Mail.jsx'
import FpMfaSms from './SMS.jsx'

class FpMfa extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      current: null,
      code: ''
    }
  }

  get mfaModes() {
    return Object.keys(this.props.preferences.mfa).filter(key => {
      return key !== 'enforce' && this.props.preferences.mfa[key]
    })
  }

  get token () {
    return this.props.loginInfo.session.token
  }

  async validateMfa (event) {
    if (event) event.preventDefault()
    if (!this.state.code) return
    try {
      const session = await FpAuthentication.validateMfa(this.state.current, this.token, {
        otp: this.state.code
      })
      const newLogin = {
        ...this.props.loginInfo,
        session
      }
      this.props.afterLoginSuccess(newLogin)
    } catch (err) {
      let data = err.response?.data
      FpToaster.error(data.message)
    }
  }

  icons(mfa) {
    let icons = {
      email: 'fa fa-paper-plane',
      authenticator: 'fa fa-qrcode',
      sms: 'fa fa-commenting'
    }
    return icons[mfa]
  }

  selectMfa(mfa) {
    if(this.isSmsNoPhone(mfa)) return
    this.setState({
      current: mfa,
      code: ''
    })
  }

  isSmsNoPhone(mfa) {
    if (mfa === 'sms' && !this.props.phone) return true
    return false
  }

  className(mfa) {
    let classes = ['mfa']
    if(this.state.current === mfa) classes.push('active')
    return classes.join(' ')
  }

  get loginStyle() {
    return {
      color: 'white',
      backgroundColor: this.props.color,
      opacity: !this.state.code ? 0.5 : 1,
      cursor: !this.state.code ? 'not-allowed' : 'pointer'
    }
  }

  style(mfa) {
    if(this.isSmsNoPhone(mfa)) {
      return {
        'cursor':'not-allowed',
        'opacity': 0.5
      }
    }
    return {}
  }

  renderMfaMode(mfa) {
    return (
      <div
        key={mfa}
        onClick={()=>this.selectMfa(mfa)}
        style={this.style(mfa)}
        className={this.className(mfa)}
      >
        <i className={this.icons(mfa)}></i>
        <span>{FpTranslate('authentication.mfa.'+mfa)}</span>
      </div>
    )
  }

  render () {
    return (
      <div className="standard-login fp-mfa">
        <div className="login-screen">


          <div className="description">
            <div>{FpTranslate('authentication.mfa.description')}</div>
            <div>{FpTranslate('authentication.mfa.description2')}</div>
          </div>



          <div className="mfa-select">
            {this.mfaModes.map(mfa => {
              if(this.isSmsNoPhone(mfa)) {
                return <Tooltip
                  key={mfa}
                  placement="top"
                  overlay={FpTranslate('authentication.mfa.no-phone')}
                >
                  {this.renderMfaMode(mfa)}
                </Tooltip>
              }
              return this.renderMfaMode(mfa)
            })
            }
          </div>

          {this.state.current === 'sms' &&
            <FpMfaSms
              login={this.props.login}
              password={this.props.password}
              code={this.state.code}
              color={this.props.color}
              token={this.token}
              onChange={(code)=>this.setState({code})}
            />
          }
          {this.state.current === 'email' &&
            <FpMfaMail
              login={this.props.login}
              password={this.props.password}
              code={this.state.code}
              color={this.props.color}
              token={this.token}
              onChange={(code)=>this.setState({code})}
            />
          }
          {this.state.current === 'authenticator' &&
            <FpMfaAuthenticator
              login={this.props.login}
              password={this.props.password}
              code={this.state.code}
              color={this.props.color}
              token={this.token}
              onChange={(code)=>this.setState({code})}
            />
          }

          <div className='login-actions-container'>
            <div className='login-action-container send-container'>
              <button
                onClick={this.validateMfa.bind(this)}
                className='btn-login btn btn-primary'
                style={ this.loginStyle }
                type='submit'>
                  {FpTranslate('signin')}
              </button>
            </div>
            <div className='login-action-container return-container'>
              <button
                className='btn return-button'
                type='button'
                onClick={this.props.close}>
                  {FpTranslate('Return')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default FpMfa
