/**
 *
 * PeopleCountsAnalysis
 *
 */

import PeopleCountsAnalysis from './PeopleCountsAnalysis.jsx';
import './PeopleCountsAnalysis.less';

export default PeopleCountsAnalysis;
