import { USER_PRIVILEGE, USER_PRIVILEGE_KEY } from '@/helpers/constants';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const NAVIGATION_TYPE = {
  ADMIN: '/employee-locations',
  USER: '/employee-locations',
  SIGNAGE: '/signage-components',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNavigate: '',
    };
  }

  componentDidMount() {
    const userPrivilege = this.props.sessionStore[USER_PRIVILEGE_KEY];
    if (!userPrivilege) {
      return;
    }
    this.updatePageNavigate({ userPrivilege });
  }

  componentDidUpdate(prevProps) {
    const userPrivilege = this.props.sessionStore[USER_PRIVILEGE_KEY];
    if (!userPrivilege) {
      return;
    }
    this.updatePageNavigate({ userPrivilege });
  }

  updatePageNavigate = ({ userPrivilege }) => {
    if (!userPrivilege) {
      return;
    }
    if (
      userPrivilege === USER_PRIVILEGE.ADMIN ||
      userPrivilege === USER_PRIVILEGE.SUPERVISOR
    ) {
      this.setState({ pageNavigate: NAVIGATION_TYPE.ADMIN });
    } else if (userPrivilege === USER_PRIVILEGE.SIGNAGE) {
      this.setState({ pageNavigate: NAVIGATION_TYPE.SIGNAGE });
    } else {
      this.setState({ pageNavigate: NAVIGATION_TYPE.USER });
    }
  };

  render() {
    const { pageNavigate } = this.state;
    if (!pageNavigate) {
      return <></>;
    }
    if (pageNavigate === NAVIGATION_TYPE.ADMIN) {
      return <Redirect to={NAVIGATION_TYPE.ADMIN} exact={true} />;
    } else if (pageNavigate === NAVIGATION_TYPE.USER) {
      return <Redirect to={NAVIGATION_TYPE.USER} exact={true} />;
    } else if (pageNavigate === NAVIGATION_TYPE.SIGNAGE) {
      return <Redirect to={NAVIGATION_TYPE.SIGNAGE} exact={true} />;
    }
  }
}

RedirectPage.propTypes = {};

export default RedirectPage;
