/**
 *
 * MerakiEventlog
 *
 */

import MerakiEventlog from './MerakiEventlog.jsx';
import './MerakiEventlog.less';

export default MerakiEventlog;
