import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { COMMON_TEXT } from 'src/helpers/common-text';
import PropTypes from 'prop-types';

const LoadingSpinner = props => {
  const { loadingText } = props;

  return (
    <div
      className="spinner-container"
      style={{ padding: '1rem', backgroundColor: 'white', borderRadius: '5px' }}
    >
      <ProgressSpinner
        style={{ width: '80px', height: '80px' }}
        strokeWidth="3"
        fill="transparent"
        animationDuration="1.5s"
      />
      <div style={{ marginTop: '0.5rem' }}>
        <i>{loadingText ?? COMMON_TEXT.LOADING_DATA}</i>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  loadingText: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  loadingText: COMMON_TEXT.LOADING_DATA,
};
export default LoadingSpinner;
