import EmployeeLocationListLeft from '@/components/communication/EmployeeLocations/EmployeeLocationList/EmployeeLocationListLeft/EmployeeLocationListLeft';
import EmployeeLocationListRight from '@/components/communication/EmployeeLocations/EmployeeLocationList/EmployeeLocationListRight/EmployeeLocationListRight';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocationList extends Component {
  render() {
    const {
      isLoading,
      departmentList,
      empLocGroupedByTeam,
      selectKeyTeam,
      departmentActiveIndex,
      otherDepartmentActiveIndex,
      departmentSearchText,
      teamSearchText,
      employeeSearchText,
      branchName,
      filteredEmpLocGroupedByDeptAndTeam,
      totalEmployeeMap,
      updateSearchTextChange,
      updateSelectKeyTeam,
      updateActiveIndex,
    } = this.props;

    return (
      <div className="employee-location-list-container">
        <div className="component-container">
          <EmployeeLocationListLeft
            isLoading={isLoading}
            departmentList={departmentList}
            departmentActiveIndex={departmentActiveIndex}
            otherDepartmentActiveIndex={otherDepartmentActiveIndex}
            selectKeyTeam={selectKeyTeam}
            filteredEmpLocGroupedByDeptAndTeam={
              filteredEmpLocGroupedByDeptAndTeam
            }
            departmentSearchText={departmentSearchText}
            teamSearchText={teamSearchText}
            branchName={branchName}
            totalEmployeeMap={totalEmployeeMap}
            updateSearchTextChange={updateSearchTextChange}
            updateSelectKeyTeam={updateSelectKeyTeam}
            updateActiveIndex={updateActiveIndex}
          />
        </div>
        <div className="component-container">
          <EmployeeLocationListRight
            isLoading={isLoading}
            employeeSearchText={employeeSearchText}
            empLocGroupedByTeam={empLocGroupedByTeam}
            selectKeyTeam={selectKeyTeam}
            updateSearchTextChange={updateSearchTextChange}
          />
        </div>
      </div>
    );
  }
}

EmployeeLocationList.propTypes = {
  isLoading: PropTypes.bool,
  departmentList: PropTypes.array,
  empLocGroupedByTeam: PropTypes.array,
  selectKeyTeam: PropTypes.string,
  departmentActiveIndex: PropTypes.array,
  otherDepartmentActiveIndex: PropTypes.array,
  employeeSearchText: PropTypes.string,
  branchName: PropTypes.string,
  filteredEmpLocGroupedByDeptAndTeam: PropTypes.object,
  totalEmployeeMap: PropTypes.object,

  updateSearchTextChange: PropTypes.func,
  updateSelectKeyTeam: PropTypes.func,
  updateActiveIndex: PropTypes.func,
};

export default EmployeeLocationList;
