import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

@connect(state => ({
  local: state.local,
}))

/**
 * Renders the current logged username with picture
 */
class Logo extends Component {
  state = {};

  render() {
    if (isMobile) {
      return <></>;
    }
    return (
      <div className="logo-container">
        <div className="logo-content">
          <p className="logo-name">
            <img src="assets/img/cloom_logo.png" alt="Logo" className="logo" />
          </p>
        </div>
      </div>
    );
  }
}

Logo.propTypes = {
  local: PropTypes.object,
};

export default Logo;
