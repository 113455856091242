import { COMMON_TEXT } from '@/helpers/common-text';
import { generateRandomString } from '@/helpers/utility';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';

export const MeetingRoomUsageMarkerRender = props => {
  const { item } = props;
  const { data } = item;
  const {
    room_availability,
    current_reservation,
    next_reservation,
    device_name,
    timestamp,
  } = data;
  const isAvailable = room_availability === 1;
  const VACANT_COLOR = '#008000';
  const OCCUPIED_COLOR = '#FF0000';

  const targetId = generateRandomString(12);

  const markerOnClicked = ({ data }) => {
    if (props.markerOnClicked) {
      props.markerOnClicked({ data });
    }
  };

  return (
    <div
      className={`custom-marker custom-marker-tooltip-target-${targetId}`}
      style={{
        backgroundColor: isAvailable ? VACANT_COLOR : OCCUPIED_COLOR,
        width: 30,
        height: 30,
        cursor: 'pointer',
      }}
      onClick={() => markerOnClicked({ data })}
    >
      <Tooltip
        className="custom-marker-tooltip"
        target={`.custom-marker-tooltip-target-${targetId}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.ROOM_NAME}</div>
            <div className="text-value">
              <b>{device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.STATUS}</div>
            <div className="text-value">
              {isAvailable ? (
                <b style={{ color: VACANT_COLOR }}>{COMMON_TEXT.VACANT}</b>
              ) : (
                <b style={{ color: OCCUPIED_COLOR }}>{COMMON_TEXT.OCCUPIED}</b>
              )}
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.CURRENT_RESERVATION}</div>
            <div className="text-value">
              <b>{current_reservation}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.NEXT_RESERVATION}</div>
            <div className="text-value">
              <b>{next_reservation}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{timestamp ?? '-'}</b>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
