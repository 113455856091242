/**
 *
 * NetworkUsage
 *
 */

import NetworkUsage from './NetworkUsage.jsx';
import './NetworkUsage.less';

export default NetworkUsage;
