import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UnderConstructionPage from 'src/components/CustomComponent/UnderConstructionView/UnderConstructionView';
import { COMMON_TEXT } from 'src/helpers/common-text';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="config-container custom-config-container summary-page">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.TK_OVERVIEW}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end"></div>
        </div>
        <div className="config-content">
          <UnderConstructionPage />
        </div>
      </div>
    );
  }
}

Summary.propTypes = {};

export default withRouter(Summary);
