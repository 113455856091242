/**
 *
 * EmployeeTerminalSetting
 *
 */

import EmployeeTerminalSetting from './EmployeeTerminalSetting.jsx';
import './EmployeeTerminalSetting.less';

export default EmployeeTerminalSetting;
