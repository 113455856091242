import FpToaster from 'forepaas/toaster'
import FpTranslate from 'forepaas/translate'
import _get from 'lodash/get'
import React from 'react'
import FpAuthentication from '../FpAuthentication'

class FpMfaAuthenticator extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className="mfa-authenticator">
        <div className="text-center" style={{'marginTop': 20}} >
          <label className="authenticator-label">{FpTranslate('authentication.mfa.authenticator.code_label')}</label>
          <input
            type="text"
            className="text-center"
            value={this.props.code}
            onChange={(event)=>this.props.onChange(event.target.value)}
            placeholder={FpTranslate('authentication.mfa.code')}
          />
        </div>
      </div>

    )
  }
}
export default FpMfaAuthenticator
