import {
  fetchMeetingRoomMartTableDataByFloorIdListAndDate,
  fetchRoomAvailabilityByFloorIdList,
} from './query-request';

const getRoomAvailabilityByFloorIdList = async ({ floorIdList }) => {
  let rooms = await fetchRoomAvailabilityByFloorIdList({ floorIdList });
  return rooms || [];
};

const getMeetingRoomMartTableDataByFloorIdListAndDate = async ({
  floorIdList,
  selectedDate,
}) => {
  let data = await fetchMeetingRoomMartTableDataByFloorIdListAndDate({ floorIdList, selectedDate });
  return data || [];
};

export {
  getMeetingRoomMartTableDataByFloorIdListAndDate,
  getRoomAvailabilityByFloorIdList
};
