import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { Form, Formik } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomFormikErrorNotification from 'src/components/CustomComponent/CustomFormikErrorNotification';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import * as Yup from 'yup';
import {
  fetchBranchListByTenent,
  fetchFloorListByBranchIdList,
  fetchVerkadaDevicesByFloorIdList,
} from './query-request';

Yup.addMethod(Yup.array, 'verkadaUnique', function (field, message) {
  return this.test('verkadaUnique', message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map(row => row.data[field]?.toLowerCase()))
    );

    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row.data[field]?.toLowerCase() !== uniqueData[i]
    );
    return this.createError({
      path: `${this.path}.${index}.data.${field}`,
      message,
    });
  });
});

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class VerkadaDeviceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      isUpdating: false,
      branchList: [],
      floorList: [],
      selectedFloor: null,
      verkadaDevicesList: [],
      isDeviceLoading: false,
    };
    this.deviceListRef = React.createRef();
    this.toastRef = React.createRef();
    this.deviceTypeOptions = [
      { name: 'カメラ', value: 'camera' },
      { name: 'ドア', value: 'door' },
      { name: 'センサー', value: 'sensor' },
    ];
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    this.setState({ isLoading: true, isDeviceLoading: true });

    // fetch data here
    const branchList = await fetchBranchListByTenent(this.state.tenant);
    if (branchList && branchList.length > 0) {
      this.setState({ branchList });
      const branchIdList = this.state.branchList.map(
        branch => branch.branch_id
      );
      const floorList = await fetchFloorListByBranchIdList(branchIdList);
      if (floorList && floorList.length > 0) {
        const sortedFloorListByBranchOrder = floorList.sort(
          (a, b) =>
            branchIdList.indexOf(a.branch_id) -
            branchIdList.indexOf(b.branch_id)
        );
        this.setState({
          floorList: sortedFloorListByBranchOrder.map(floor => {
            return { isValidateError: false, ...floor };
          }),
          selectedFloor: floorList[0],
        });
      }
    }

    const floorIdList = this.state.floorList.map(floor => floor.floor_id);
    const devicesList = await fetchVerkadaDevicesByFloorIdList(floorIdList);
    const newDeviceList = devicesList.map(device => {
      return {
        c_status: 'normal',
        initialData: cloneDeep(device),
        data: device,
      };
    });

    this.setState({
      verkadaDevicesList: newDeviceList,
      isDeviceLoading: false,
    });

    await new Promise(resolve => setTimeout(resolve, 2000));
    this.setState({
      isLoading: false,
    });
  };

  getBranchName = branchId => {
    if (branchId) {
      const branch = this.state.branchList.filter(
        branch => branch.branch_id === branchId
      );
      return branch[0].branch_name + ' - ';
    }
  };

  getFloorName = floorId => {
    return this.state.floorList.filter(floor => floor.floor_id === floorId)[0]
      .floor_name;
  };

  calculateFloorError = floorIdList => {
    const updateFloor = this.state.floorList.map(floor => {
      const hasErrorFloor = floorIdList.filter(
        floor_id => floor_id === floor.floor_id
      );
      if (hasErrorFloor.length > 0) {
        floor.isValidateError = true;
      } else {
        floor.isValidateError = false;
      }
      return floor;
    });
    this.setState({
      floorList: updateFloor,
    });
  };

  onChangeSelectedFloorAction = selectedFloor => {
    this.setState({
      selectedFloor: selectedFloor,
    });
  };

  deleteConfirm = device => {
    if (device.c_status === 'add') {
      this.setState({
        verkadaDevicesList: this.state.verkadaDevicesList.filter(
          newDevice => newDevice.c_id !== device.c_id
        ),
      });
    } else {
      device.c_status = 'delete';
      this.setState({});
    }
  };

  deleteDevice = device => {
    if (device.data.device_identifer === '' && device.data.device_name === '') {
      this.deleteConfirm(device);
    } else {
      confirmDialog({
        message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
        header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
        icon: '',
        accept: () => this.deleteConfirm(device),
        reject: () => {},
        acceptClassName: 'p-button-danger',
        rejectClassName: 'p-button-secondary p-button-text',
        appendTo: 'self',
      });
    }
  };

  scrollToBottom = () => {
    const element = this.deviceListRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight + 60;
    }
  };

  addDevice = () => {
    const newDevice = {
      c_status: 'add',
      c_id: Math.floor(Math.random() * Date.now()),
      data: {
        device_identifer: '',
        device_name: '',
        device_type: 'camera',
        floor_id: this.state.selectedFloor.floor_id,
      },
    };
    this.setState(
      {
        verkadaDevicesList: [...this.state.verkadaDevicesList, newDevice],
      },
      () => {
        this.scrollToBottom();
      }
    );
  };

  updatedDevicesValues = (verkadaDevicesList, floor_id) => {
    const deletedDeviceFromStatusDelete = verkadaDevicesList
      .filter(device => device.c_status === 'delete')
      .map(device => {
        return {
          device_identifer: device.data.device_identifer,
          device_name: null,
        };
      });

    const deletedDeviceFromUpdate = verkadaDevicesList
      .filter(
        device =>
          device.c_status === 'normal' &&
          !isEqual(device.data, device.initialData) &&
          device.data.device_identifer !== device.initialData.device_identifer
      )
      .map(device => {
        return {
          device_identifer: device.initialData.device_identifer,
          device_name: null,
        };
      });

    const deletedDevice = [
      ...deletedDeviceFromStatusDelete,
      ...deletedDeviceFromUpdate,
    ];

    const updatedDevice = verkadaDevicesList
      .filter(
        device =>
          device.c_status === 'normal' &&
          !isEqual(device.data, device.initialData)
      )
      .map(device => {
        return {
          device_identifer: device.data.device_identifer,
          device_name: device.data.device_name,
          device_type: device.data.device_type,
        };
      });

    const addedDevice = verkadaDevicesList
      .filter(device => device.c_status === 'add')
      .map(device => {
        return {
          device_identifer: device.data.device_identifer,
          device_name: device.data.device_name,
          device_type: device.data.device_type,
        };
      });

    if (
      deletedDevice.length > 0 ||
      updatedDevice.length > 0 ||
      addedDevice.length > 0
    ) {
      const values = {
        floor_id,
        verkada_devices: [...updatedDevice, ...addedDevice, ...deletedDevice],
      };
      return values;
    } else {
      return false;
    }
  };

  getUpdateDataPayload = () => {
    const payloadValues = this.state.floorList.map(floor => {
      const devicesListByFloorId = this.state.verkadaDevicesList.filter(
        device => device.data.floor_id === floor.floor_id
      );
      if (devicesListByFloorId.length === 0) {
        return null;
      }
      const updatedValues = this.updatedDevicesValues(
        devicesListByFloorId,
        floor.floor_id
      );
      if (!updatedValues) {
        return null;
      }
      return updatedValues;
    });

    if (payloadValues.filter(values => values).length > 0) {
      const payload = {
        values: payloadValues.filter(values => values),
      };
      return payload;
    } else {
      return null;
    }
  };

  updateVerkadaDeviceInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.VERKADA_DEVICES,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateVerkadaDeviceInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderDeviceInputList = (device, index, touched, errors) => {
    const isFormFieldInvalid = (fieldName, index, touched, errors) => {
      if (
        typeof errors['verkadaDevicesList'] == 'object' &&
        errors['verkadaDevicesList'][index]
      ) {
        if (fieldName === 'device_identifer') {
          return touched['verkadaDevicesList'][index].data.device_identifer &&
            errors['verkadaDevicesList'][index].data.device_identifer
            ? `p-invalid`
            : '';
        } else {
          return touched['verkadaDevicesList'][index].data.device_name &&
            errors['verkadaDevicesList'][index].data.device_name
            ? `p-invalid`
            : '';
        }
      } else {
        return '';
      }
    };

    const getFormErrorMessage = (name, index, errors, touched) => {
      return isFormFieldInvalid(name, index, touched, errors) ? (
        <div className="validation-error-message">
          {name === 'device_identifer'
            ? errors['verkadaDevicesList'][index].data.device_identifer
            : errors['verkadaDevicesList'][index].data.device_name}
        </div>
      ) : (
        <></>
      );
    };

    return (
      <div className="device-list">
        <div className="id-container">
          <InputText
            value={device.data.device_identifer}
            name="device_identifer"
            className={isFormFieldInvalid(
              `device_identifer`,
              index,
              touched,
              errors
            )}
            onChange={e => {
              device.data.device_identifer = e.target.value;
              this.setState({});
            }}
          />
          {getFormErrorMessage(`device_identifer`, index, errors, touched)}
        </div>
        <div className="name-container">
          <InputText
            value={device.data.device_name}
            name="device_name"
            className={isFormFieldInvalid(
              'device_name',
              index,
              touched,
              errors
            )}
            onChange={e => {
              device.data.device_name = e.target.value;
              this.setState({});
            }}
          />
          {getFormErrorMessage(`device_name`, index, errors, touched)}
        </div>
        <div className="type-container">
          <Dropdown
            value={device.data.device_type}
            options={this.deviceTypeOptions}
            optionLabel="name"
            onChange={e => {
              device.data.device_type = e.value;
              this.setState({});
            }}
          ></Dropdown>
        </div>
        <div className="delete-container">
          <Button
            type="button"
            icon="pi pi-trash"
            text
            rounded
            severity="danger"
            aria-label="Delete"
            size="small"
            onClick={() => this.deleteDevice(device)}
          />
        </div>
      </div>
    );
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      isDeviceLoading,
      floorList,
      selectedFloor,
      verkadaDevicesList,
    } = this.state;

    const deviceSchema = Yup.object().shape({
      data: Yup.object().shape({
        device_identifer: Yup.string()
          .nullable()
          .required(COMMON_TEXT.REQUIRED),
        device_name: Yup.string().nullable().required(COMMON_TEXT.REQUIRED),
      }),
    });

    const VerkadaDeviceSettingSchema = Yup.object().shape({
      verkadaDevicesList: Yup.array()
        .of(deviceSchema)
        .verkadaUnique('device_identifer', 'Device Identifer is duplicated'),
    });

    const handleSubmit = () => {
      this.calculateFloorError([]);
      const updatePayLoad = this.getUpdateDataPayload();
      if (updatePayLoad) {
        this.updateData({ updatePayLoad });
      }
    };

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={{
              verkadaDevicesList,
            }}
            validationSchema={VerkadaDeviceSettingSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, touched, errors }) => (
              <Form>
                <CustomFormikErrorNotification
                  errors={errors}
                  values={values}
                  getBranchName={this.getBranchName}
                  getFloorName={this.getFloorName}
                  calculateFloorError={this.calculateFloorError}
                />
                <div className="panel">
                  <div className="floor-list-container">
                    <div className="title-text">拠点 - フロア</div>
                    <div className="floor-list">
                      {floorList.length > 0 &&
                        floorList.map((floor, index) => (
                          <div
                            key={index}
                            className={
                              floor.isValidateError
                                ? 'floor-item height-light-style'
                                : 'floor-item'
                            }
                          >
                            <RadioButton
                              inputId={floor.floor_id}
                              name="floor"
                              value={floor}
                              checked={
                                selectedFloor?.floor_id === floor.floor_id
                              }
                              onChange={e =>
                                this.onChangeSelectedFloorAction(e.value)
                              }
                            ></RadioButton>
                            <label htmlFor={floor.floor_id} className="ml-2">
                              {this.getBranchName(floor.branch_id)}
                              {floor.floor_name}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="verkada-device-container">
                    <div className="title-container">
                      <div className="id-container">
                        <div className="title-text">デバイスID</div>
                      </div>
                      <div className="name-container">
                        <div className="title-text">デバイス名</div>
                      </div>
                      <div className="type-container">
                        <div className="title-text">デバイスタイプ</div>
                      </div>
                      <div className="delete-container"></div>
                    </div>
                    <div
                      className="device-list-container"
                      ref={this.deviceListRef}
                    >
                      {isDeviceLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <>
                          {values.verkadaDevicesList.filter(
                            device =>
                              device.data.floor_id === selectedFloor.floor_id
                          ).length > 0 ? (
                            <>
                              {values.verkadaDevicesList
                                .filter(
                                  item =>
                                    item.data.floor_id ===
                                    selectedFloor.floor_id
                                )
                                .map((device, index) => (
                                  <div key={index}>
                                    <>
                                      {device.c_status !== 'delete'
                                        ? this.renderDeviceInputList(
                                            device,
                                            index,
                                            touched,
                                            errors
                                          )
                                        : null}
                                    </>
                                  </div>
                                ))}
                            </>
                          ) : (
                            <Message
                              security="info"
                              text={COMMON_TEXT.NO_DEVICE_AVAILABLE}
                              className="w-full mt-6"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <Button
                    type="submit"
                    severity="info"
                    label="保存"
                    className="submit-button has-shadow"
                    loading={isUpdating}
                  />
                  <Button
                    type="button"
                    label="追加"
                    severity="success"
                    className="has-shadow"
                    onClick={this.addDevice}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container verkada-device-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.VERKADA_DEVICE_SETTING}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

VerkadaDeviceSetting.propTypes = {};

export default VerkadaDeviceSetting;
