/**
 *
 * DeviceStatusOverviewNow
 *
 */

import DeviceStatusOverviewNow from './DeviceStatusOverviewNow.jsx';
import './DeviceStatusOverviewNow.less';

export default DeviceStatusOverviewNow;
