import { DEVICE_CATEGORY, SETTING_FLOW_ID } from '@/helpers/constants';
import { transformPositionToMarkerCoordinate } from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { cloneDeep } from 'lodash';
import {
    fetchAreasByFloorId,
    fetchBranchesByTenantId,
    fetchButlrDevicesByTenantId,
    fetchDevicePositionsByFloorId,
    fetchFloorsByBranchIdList,
    fetchMerakiDevicesByTenantIdAndModel,
    fetchVerkadaDevicesByFloorIdAndDeviceType,
    fetchWebexDevicesByTenantId
} from './query-request';

const getAreasByFloorId = async ({ floorId }) => {
    let areas = await fetchAreasByFloorId({ floorId });
    return areas || [];
};

const getBranchesByTenantId = async ({ tenantId }) => {
    let branches = await fetchBranchesByTenantId({ tenantId });
    return branches || [];
};

const getFloorsByBranchIdList = async ({ branchIdList }) => {
    let floors = await fetchFloorsByBranchIdList({ branchIdList });
    return floors || [];
};

const getDevicePositionsByFloorId = async ({ floorId }) => {
    let devicePositions = await fetchDevicePositionsByFloorId({ floorId });
    return devicePositions || [];
};

const getMerakiDevicesByTenantIdAndModel = async ({ tenantId, model }) => {
    let merakiDevices = await fetchMerakiDevicesByTenantIdAndModel({ tenantId, model });
    return merakiDevices || [];
};

const getButlrDevicesByTenantId = async ({ tenantId }) => {
    let butlrDevices = await fetchButlrDevicesByTenantId({ tenantId });
    return butlrDevices || [];
};

const getVerkadaDevicesByFloorIdAndDeviceType = async ({ floorId, deviceType }) => {
    let verkadaDevices = await fetchVerkadaDevicesByFloorIdAndDeviceType({ floorId, deviceType });
    return verkadaDevices || [];
};

const getWebexDevicesByTenantId = async ({ tenantId }) => {
    let devices = await fetchWebexDevicesByTenantId({ tenantId });
    devices = devices || [];
    return devices.sort((a, b) => a.name?.localeCompare(b.name));
};

const addDevicePositionToDevices = ({ devices, deviceCategory, devicePositions }) => {
    let deviceIdentifierKeyField = 'device_identifer';
    let deviceNameKeyField = 'device_name';
    switch (deviceCategory) {
        case DEVICE_CATEGORY.MERAKI:
            deviceIdentifierKeyField = 'device_identifer';
            deviceNameKeyField = 'device_name';
            break;
        case DEVICE_CATEGORY.VERKADA:
            deviceIdentifierKeyField = 'device_identifer';
            deviceNameKeyField = 'device_name';
            break;
        case DEVICE_CATEGORY.BUTLR:
            deviceIdentifierKeyField = 'room_id';
            deviceNameKeyField = 'room_name';
            break;
        case DEVICE_CATEGORY.WEBEX:
            deviceIdentifierKeyField = 'workspace_id';
            deviceNameKeyField = 'name';
            break;
        default:
            break;
    }

    return devices.map(device => {
        let devicePosition = devicePositions.filter(
            devicePos => devicePos.device_identifer === device[deviceIdentifierKeyField]
        );
        let position = { position_x: null, position_y: null };
        let hasPosition = false;
        if (devicePosition && devicePosition.length > 0) {
            position = cloneDeep(devicePosition[0]);
            hasPosition = true;
        }
        return {
            hasPosition: hasPosition,
            deviceCategory,
            ...position,
            device_name: device[deviceNameKeyField],
            device_identifer: device[deviceIdentifierKeyField],
            ...device,
        };
    });
};

const setDevicePositionToMap = ({ deviceList }) => {
    const cloneDevices = cloneDeep(deviceList);
    const markers = [];
    cloneDevices.forEach(device => {
        const { hasPosition, position_x, position_y } = device;
        if (hasPosition) {
            const { top, left } = transformPositionToMarkerCoordinate({
                x: position_x,
                y: position_y,
            });
            markers.push({
                top,
                left,
                isDelete: false,
                device,
            });
        }
    });
    return markers;
};

const updateAreaInfo = async ({ updatePayLoad }) => {
    const authToken = new AuthToken();
    try {
        const updateRes = await authToken.updateConfiguration({
            flowId: SETTING_FLOW_ID.AREAS,
            payload: updatePayLoad,
        });

        const { success, execution_id } = updateRes;
        if (!success) {
            throw Error('UPDATE SETTING ERROR');
        }
        return { isSuccess: true, executionId: execution_id };
    } catch (error) {
        console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
};

export {
    addDevicePositionToDevices, getAreasByFloorId,
    getBranchesByTenantId, getButlrDevicesByTenantId,
    getDevicePositionsByFloorId, getFloorsByBranchIdList, getMerakiDevicesByTenantIdAndModel, getVerkadaDevicesByFloorIdAndDeviceType, getWebexDevicesByTenantId, setDevicePositionToMap,
    updateAreaInfo
};

