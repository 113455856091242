import { COMMON_TEXT } from '@/helpers/common-text';
import { generateRandomString } from '@/helpers/utility';
import { format } from 'date-fns';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';

export const CustomSensorMarker = props => {
  const { item, keyItem } = props;
  const { carbon_dioxide, humidity, temperature, noise_level, device_name, timestamp } = item;
  let value = 0;
  const sensorColor = item[`${keyItem}_marker_color`];
  // reduce alpha of sensorColor
  if (keyItem === 'carbon_dioxide') {
    value = carbon_dioxide;
  } else if (keyItem === 'humidity') {
    value = humidity;
  } else if (keyItem === 'temperature') {
    value = temperature;
  } else if (keyItem === 'noise_level') {
    value = noise_level;
  } else {
    value = '-';
  }
  if (typeof value === 'number') {
    value = value.toFixed(1);
    if (keyItem === 'carbon_dioxide') {
      // set integer value for CO2
      value = parseInt(value, 10);
    }
  }

  const targetId = generateRandomString(12);

  return (
    <div
      className={`custom-marker custom-marker-tooltip-target-${targetId}`}
      style={{
        backgroundColor: sensorColor,
        width: 50,
        height: 50,
      }}
    >
      <Tooltip
        className="custom-marker-tooltip"
        target={`.custom-marker-tooltip-target-${targetId}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEVICE_NAME}</div>
            <div className="text-value">
              <b>{device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{format(new Date(timestamp), 'MM月dd日 - HH:mm')}</b>
            </div>
          </div>
        </div>
      </Tooltip>
      <div
        className="text-count"
        style={{
          fontSize: '1.15rem',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
        }}
      >
        {value}
      </div>
    </div>
  );
};
