import { USER_PRIVILEGE_KEY } from '@/helpers/constants';
import FpClientAuthorityManager from '@/forepaas-extensions/client-authority-manager';
import FpSdk from 'forepaas/sdk';
import { set as memoryActionSet } from 'forepaas/store/memory/action';
import { set as sessionActionSet } from 'forepaas/store/session/action';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

@connect(state => ({
  local: state.local,
}))

/**
 * Renders the current logged username with picture
 */
class UsernameMobile extends Component {
  state = {};

  componentDidMount() {
    if (isMobile) {
      const header = document.querySelector('.header');
      const page = document.querySelector('.page');
      const sidebar = document.querySelector('.sidebar');
      if (header) {
        header.classList.add('header-mobile');
        header.classList.toggle('header--sidebar-hidden');
      }
      if (page) {
        page.classList.add('page-mobile');
        page.classList.toggle('page--sidebar-hidden');
      }
      if (sidebar) {
        sidebar.classList.add('sidebar-mobile');
        sidebar.classList.toggle('sidebar--hidden');
      }
      const hideshowsidebar = document.querySelector('.hideshowsidebar');
      if (hideshowsidebar) {
        hideshowsidebar.classList.toggle('hideshowsidebar-sidebar-hidden');
      }
    }
  }

  get username() {
    const { local } = this.props;
    if (local && local['client-authority-manager-session']) {
      return `${local['client-authority-manager-session'].lastname} ${local['client-authority-manager-session'].firstname}`;
    }
    return 'Not connected';
  }

  clearUserPrivilege = () => {
    FpSdk.modules.store.dispatch(memoryActionSet(USER_PRIVILEGE_KEY, ''));
    FpSdk.modules.store.dispatch(sessionActionSet(USER_PRIVILEGE_KEY, ''));
  };

  logout = e => {
    this.clearUserPrivilege();
    FpClientAuthorityManager.FpAuthentication.logout();
  };

  toggleSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    if (sidebar) {
      sidebar.classList.toggle('sidebar--hidden');
    }
    const pageWrapper = document.querySelector('.page');
    if (pageWrapper) {
      pageWrapper.classList.toggle('page--sidebar-hidden');
    }
    const hideshowsidebar = document.querySelector('.hideshowsidebar');
    if (hideshowsidebar) {
      hideshowsidebar.classList.toggle('hideshowsidebar-sidebar-hidden');
    }
    const header = document.querySelector('.header');
    if (header) {
      header.classList.toggle('header--sidebar-hidden');
    }
  };

  /**
   * render
   * @return {ReactElement} markup
   */
  render() {
    if (isDesktop) {
      return <></>;
    }
    return (
      <div className={`username username-mobile`}>
        <div className="username-content">
          <div className="username-info">
            <p className="username-name">{this.username}</p>
          </div>
          <div className="toggle-button-container">
            <Button
              text
              severity="info"
              icon="pi pi-bars"
              onClick={this.toggleSidebar}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

UsernameMobile.propTypes = {
  local: PropTypes.object,
};

export default UsernameMobile;
