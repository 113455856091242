import { fetchMeetingRoomMartUnusedDataByBranchId } from './query-request';

const getMeetingRoomMartUnusedDataWeeklyByBranchId = async ({ branchId, limit = 0 }) => {
  const data = await fetchMeetingRoomMartUnusedDataByBranchId({ branchId, limit, type: 'weekly' });
  return data || [];
};

const getMeetingRoomMartUnusedDataMonthlyByBranchId = async ({ branchId, limit = 0 }) => {
  const data = await fetchMeetingRoomMartUnusedDataByBranchId({ branchId, limit, type: 'monthly' });
  return data || [];
};

export {
  getMeetingRoomMartUnusedDataWeeklyByBranchId,
  getMeetingRoomMartUnusedDataMonthlyByBranchId,
};
