/**
 *
 * Configuration
 *
 */

import Configuration from './Configuration.jsx';
import './Configuration.less';

export default Configuration;
