import {
    DEVICE_CATEGORY,
    SETTING_FLOW_ID
} from '@/helpers/constants';
import {
    transformPositionToMarkerCoordinate,
} from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { cloneDeep } from 'lodash';
import {
    fetchButlrDevicesByTenantId,
    fetchDevicePositionByFloorId,
    fetchMerakiDevicesByTenantId,
    fetchTenantInfo,
    fetchVerkadaDevicesByFloorId,
    fetchWebexDevicesByTenantId,
} from './query-request';

const MERAKI_MEETING_ROOM_DEVICE_TYPES = ['MT30', 'MT20'];

const getDevicePositionsByFloor = async ({ floorId }) => {
    let devicePositions = await fetchDevicePositionByFloorId({ floorId });
    devicePositions = devicePositions || [];
    return devicePositions;
};

const getTenantInfo = async ({ tenantId }) => {
    let tenantInfo = await fetchTenantInfo({ tenantId });
    tenantInfo = tenantInfo?.[0] || {};
    return tenantInfo;
};

const getWebexDevices = async ({ tenantId }) => {
    let devices = await fetchWebexDevicesByTenantId({ tenantId });
    devices = devices || [];
    return devices.sort((a, b) => a.name?.localeCompare(b.name));
};

const getButlrDevices = async ({ tenantId }) => {
    let devices = await fetchButlrDevicesByTenantId({ tenantId });
    devices = devices || [];
    return devices.sort((a, b) => a.room_name?.localeCompare(b.room_name));
};

const getMerakiDevices = async ({ tenantId }) => {
    let devices = await fetchMerakiDevicesByTenantId({ tenantId });
    devices = devices || [];
    return devices.sort((a, b) => a.device_name?.localeCompare(b.device_name));
};

const getVerkadaDevices = async ({ floorId }) => {
    let devices = await fetchVerkadaDevicesByFloorId({ floorId });
    devices = devices || [];
    return devices.sort((a, b) => a.device_name?.localeCompare(b.device_name));
};

const addDevicePositionToDevices = ({ devices, deviceCategory, devicePositions }) => {
    let deviceIdentifierKeyField = 'device_identifer';
    let deviceNameKeyField = 'device_name';
    let canHaveMeetingRoom = false;
    switch (deviceCategory) {
        case DEVICE_CATEGORY.MERAKI:
            deviceIdentifierKeyField = 'device_identifer';
            deviceNameKeyField = 'device_name';
            canHaveMeetingRoom = false;
            break;
        case DEVICE_CATEGORY.VERKADA:
            deviceIdentifierKeyField = 'device_identifer';
            deviceNameKeyField = 'device_name';
            canHaveMeetingRoom = false;
            break;
        case DEVICE_CATEGORY.BUTLR:
            deviceIdentifierKeyField = 'room_id';
            deviceNameKeyField = 'room_name';
            canHaveMeetingRoom = true;
            break;
        case DEVICE_CATEGORY.WEBEX:
            deviceIdentifierKeyField = 'workspace_id';
            deviceNameKeyField = 'name';
            canHaveMeetingRoom = false;
            break;
        default:
            break;
    }

    return devices.map(device => {
        const { model } = device;
        let devicePosition = devicePositions.filter(
            deviceposition =>
                deviceposition.device_identifer === device[deviceIdentifierKeyField]
        );
        let position = { position_x: null, position_y: null };
        let hasPosition = false;
        if (devicePosition && devicePosition.length > 0) {
            position = cloneDeep(devicePosition[0]);
            hasPosition = true;
        }
        let isMeetingRoom = canHaveMeetingRoom;
        if (deviceCategory === DEVICE_CATEGORY.MERAKI && MERAKI_MEETING_ROOM_DEVICE_TYPES.includes(model)) {
            isMeetingRoom = true;
        }
        return {
            hasPosition: hasPosition,
            deviceCategory,
            position,
            canHaveMeetingRoom: isMeetingRoom,
            device_name: device[deviceNameKeyField],
            device_identifer: device[deviceIdentifierKeyField],
            ...device,
        };
    });
};

const setDevicePositionToMap = ({ deviceList }) => {
    const cloneDevices = cloneDeep(deviceList);
    const positionList = [];
    cloneDevices.forEach(device => {
        const { hasPosition, position } = device;
        if (hasPosition) {
            const { top, left } = transformPositionToMarkerCoordinate({
                x: position.position_x,
                y: position.position_y,
            });
            positionList.push({
                top,
                left,
                isDelete: false,
                device,
            });
        }
    });
    return positionList;
};

const updateDeviceLocationInfo = async ({ updatePayLoad }) => {
    const authToken = new AuthToken();
    try {
        const updateRes = await authToken.updateConfiguration({
            flowId: SETTING_FLOW_ID.DEVICE_POSITIONS,
            payload: updatePayLoad,
        });

        const { success, execution_id } = updateRes;
        if (!success) {
            throw Error('UPDATE SETTING ERROR');
        }
        return { isSuccess: true, executionId: execution_id };
    } catch (error) {
        console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
};

export {
    addDevicePositionToDevices, getButlrDevices, getDevicePositionsByFloor, getMerakiDevices, getTenantInfo, getVerkadaDevices, getWebexDevices, setDevicePositionToMap,
    updateDeviceLocationInfo
};

