import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getCurrentFullDateFormattedInTimeZone } from '@/helpers/utility';

const buildQueryGetSettingUpdates = ({ executionIdList }) => {
  return {
    data: {
      fields: {
        error_message: ['select'],
        execution_id: ['select'],
        status: ['select'],
        tenant_id: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      execution_id: executionIdList,
      timestamp: {
        lte: [getCurrentFullDateFormattedInTimeZone()],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.SETTINGS_UPDATES,
  };
};

export const fetchSettingUpdateStatus = async payload => {
  const { executionIdList } = payload;
  const request = buildQueryGetSettingUpdates({ executionIdList });
  return executeQuery(request);
};
