import EmployeeCSVDownload from '@/components/organization/Configuration/EmployeeSetting/EmployeeCSVDownload';
import EmployeeCSVUpload from '@/components/organization/Configuration/EmployeeSetting/EmployeeCSVUpload';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class EmployeeCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  updateCsvData = data => {
    this.props.updateCsvData(data);
  };

  render() {
    const { tenantId } = this.props;
    return (
      <>
        <EmployeeCSVUpload
          tenantId={tenantId}
          updateCsvData={this.updateCsvData}
        ></EmployeeCSVUpload>
        <EmployeeCSVDownload tenantId={tenantId}></EmployeeCSVDownload>
      </>
    );
  }
}

EmployeeCSV.propTypes = {
  updateCsvData: PropTypes.func,
};

export default EmployeeCSV;
