import {
  extractTimeFromDateString,
  generateRandomString,
} from '@/helpers/utility';
import { Tooltip } from 'primereact/tooltip';
import React, { PureComponent } from 'react';

export class CustomAxisTick extends PureComponent {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { x, y, payload, rotate, textAnchor } = this.props;
    const { isHovered } = this.state;
    const targetId = generateRandomString(8);
    const { value } = extractTimeFromDateString(payload.value);

    return (
      <g
        transform={`translate(${x},${y})`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={`custom-axis-tick-target-${targetId}`}
      >
        <text
          x={0}
          y={0}
          dx={0}
          dy={8}
          fontSize={'0.85rem'}
          fontWeight={isHovered ? 'bold' : 'normal'}
          textAnchor={textAnchor}
          fill={isHovered ? '#B31616' : '#666'}
          transform={`rotate(${rotate})`}
        >
          {value}
        </text>
        <Tooltip
          className="child-div-tooltip"
          target={`.custom-axis-tick-target-${targetId}`}
          baseZIndex={2000}
          autoHide={false}
          position="top"
          event="hover"
        >
          {value}
        </Tooltip>
      </g>
    );
  }
}
