/**
 *
 * WirelessSignal
 *
 */

import WirelessSignal from './WirelessSignal.jsx';
import './WirelessSignal.less';

export default WirelessSignal;
