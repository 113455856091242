import './wdyr';

import FpSdk from 'forepaas/sdk';
import AppTemplate from 'forepaas/sdk/templates/default.jsx';
import React from 'react';
import { render } from 'react-dom';
import authentication from 'src/authentication';
import components from 'src/components';
import templates from 'src/templates';

// Prime React
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/tailwind-light/theme.css';

import 'font-awesome/less/font-awesome.less';
import 'react-toastify/dist/ReactToastify.min.css';
import 'src/helpers';
import 'src/styles/styles.less';

import './styles/index.less';

// Import css files

// for date fns
import { setDefaultOptions } from 'date-fns/esm';
import { ja as dateLocaleJa } from 'date-fns/locale';

import ja from '@/helpers/primereactLocales/ja';
import { addLocale, locale } from 'primereact/api';
addLocale('ja', ja);
locale('ja');

setDefaultOptions({ locale: dateLocaleJa });

FpSdk.start()
  .then(() => {
    authentication.init();
    templates.init();
    return components.init();
  })
  .then(() => {
    render(<AppTemplate />, document.getElementById('root'));
  });
