import { COMMON_TEXT } from '@/helpers/common-text';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import './EmployeeLocationListLeft.less';
import { isEmpty } from 'lodash';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeLocationListLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],

      isLoading: false,
      departmentList: [],
    };
  }

  renderGroup = ({ group }) => {
    if (!group || group.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }
    const { branch } = this.state;
    const {
      filteredEmpLocGroupedByDeptAndTeam,
      departmentActiveIndex,
      otherDepartmentActiveIndex,
      branchName,
    } = this.props;

    let newGroupList = [...group];

    if (newGroupList.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }

    return (
      <div className="group">
        {newGroupList.map((group, index) => {
          const departmentList =
            filteredEmpLocGroupedByDeptAndTeam[group] ?? {};
          const isDepartmentEmpty = isEmpty(Object.keys(departmentList));
          if (group !== String(branch) && group === 'NO_GROUP') {
            return (
              <div key={`group-header-${index}`}>
                <div className="group-container">
                  <span className="vertical-align-middle">{`${COMMON_TEXT.OTHER_GROUP}`}</span>
                  <span className="no-data-label">{`${
                    isDepartmentEmpty ? `(${COMMON_TEXT.NO_DATA})` : ''
                  }`}</span>
                </div>
                {this.renderDepartmentGroup({
                  departmentList,
                  group,
                  activeIndex: otherDepartmentActiveIndex,
                  isOther: true,
                })}
              </div>
            );
          }
          return (
            <div key={`group-other-header-${index}`}>
              <div className="group-container">
                <span className="vertical-align-middle">{`${
                  branchName ?? '-'
                }`}</span>
                <span className="no-data-label">{`${
                  isDepartmentEmpty ? `(${COMMON_TEXT.NO_DATA})` : ''
                }`}</span>
              </div>
              {this.renderDepartmentGroup({
                departmentList,
                group,
                activeIndex: departmentActiveIndex,
                isOther: false,
              })}
            </div>
          );
        })}
      </div>
    );
  };

  renderDepartmentGroup = ({ departmentList, group, activeIndex, isOther }) => {
    if (!departmentList || departmentList.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }
    const newDepartmentList = Object.keys(departmentList)
      .filter(item => item !== 'NO_DEPARTMENT')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });

    if (newDepartmentList.includes('NO_DEPARTMENT')) {
      newDepartmentList.push('NO_DEPARTMENT');
    }
    const { filteredEmpLocGroupedByDeptAndTeam, totalEmployeeMap } = this.props;

    let isNoDepartment = false;

    if (
      newDepartmentList.length === 1 &&
      newDepartmentList['NO_DEPARTMENT'] !== undefined
    ) {
      isNoDepartment = true;
    }

    return (
      <>
        <div
          className={`department-container ${
            isNoDepartment ? 'cursor-accordion-icon toggle-icon' : ''
          }`}
          style={{ width: '100%' }}
        >
          <Accordion
            multiple
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            activeIndex={activeIndex}
            onTabChange={e => {
              const newActiveIndex = e.index;
              this.updateActiveIndex({ isOther, index: newActiveIndex });
            }}
          >
            {newDepartmentList.map((departmentName, index) => {
              const teamGroupByDept =
                filteredEmpLocGroupedByDeptAndTeam[group][departmentName] ?? {};

              // calculate employee count for each department
              const employeeCount =
                totalEmployeeMap[group]?.[departmentName]?.['count'] ?? 0;

              return employeeCount > 0 ? (
                <AccordionTab
                  key={`department-header-${index}`}
                  header={
                    <div className="header-container">
                      <div className="title-container">
                        <span className="vertical-align-middle text-title ">{`${
                          departmentName === 'NO_DEPARTMENT'
                            ? '部署なし'
                            : departmentName
                        }`}</span>
                        <span className="count-title ">{`${employeeCount}`}</span>
                      </div>
                    </div>
                  }
                >
                  {this.renderTeamGroup({
                    department: departmentName,
                    empLocGroupedByTeam: teamGroupByDept,
                    group,
                  })}
                </AccordionTab>
              ) : (
                isNoDepartment && (
                  <AccordionTab
                    key={`department-header-${index}`}
                    header={
                      <div className="header-container">
                        <div className="title-container">
                          <span className="vertical-align-middle">{`${
                            departmentName === 'NO_DEPARTMENT'
                              ? '部署なし'
                              : departmentName
                          }`}</span>
                        </div>
                      </div>
                    }
                  >
                    {this.renderTeamGroup({
                      department: departmentName,
                      empLocGroupedByTeam: teamGroupByDept,
                      group,
                    })}
                  </AccordionTab>
                )
              );
            })}
          </Accordion>
        </div>
      </>
    );
  };

  renderTeamGroup = ({ department, empLocGroupedByTeam, group }) => {
    let teamList = Object.keys(empLocGroupedByTeam);
    const newTeamList = teamList
      .filter(item => item !== 'NO_TEAM')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    if (teamList.includes('NO_TEAM')) {
      newTeamList.push('NO_TEAM');
    }
    const { selectKeyTeam, totalEmployeeMap } = this.props;

    const handleClickTeam = (department, entry, empLocGroupedByTeam) => {
      const key = `${group}###${department}###${entry}`;
      this.updateSelectKeyTeam({
        selectKeyTeam: key,
        empLocGroupedByTeam: empLocGroupedByTeam[entry],
        employeeSearchText: '',
      });

      if (window.innerWidth <= 811) {
        // 811 is @mobile-size in _variables.less
        const targetDiv = document.querySelector('#listmapselectbutton');
        if (targetDiv) {
          targetDiv.scrollIntoView(
            { behavior: 'smooth', block: 'center' },
            300
          );
        }
      }
    };

    return (
      <div className="team-container" style={{ width: '100%' }}>
        {newTeamList.map((entry, index) => {
          const teamKey = `${group}###${department}###${entry}`;
          const employeeListCount =
            totalEmployeeMap[group]?.[department]?.[entry] ?? 0;
          const isSelected = selectKeyTeam === teamKey;
          return (
            <div
              className="team-group-container"
              key={`team-header-${index}`}
              onClick={() =>
                handleClickTeam(department, entry, empLocGroupedByTeam)
              }
            >
              <div
                className="header-container"
                onClick={() =>
                  handleClickTeam(department, entry, empLocGroupedByTeam)
                }
              >
                <div className={'title-container'}>
                  <span
                    className={`vertical-align-middle department-name text-title ${
                      isSelected ? 'is-selected' : ''
                    }`}
                  >{`${entry === 'NO_TEAM' ? 'チームなし' : entry}`}</span>
                  <span className="count-title">{`${employeeListCount}`}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  updateSelectKeyTeam = ({
    selectKeyTeam,
    empLocGroupedByTeam,
    employeeSearchText,
  }) => {
    this.props.updateSelectKeyTeam({
      selectKeyTeam: selectKeyTeam,
      empLocGroupedByTeam: empLocGroupedByTeam,
      employeeSearchText: employeeSearchText,
    });
  };

  updateActiveIndex = ({ isOther, index }) => {
    this.props.updateActiveIndex({
      isOther: isOther,
      index: index,
    });
  };

  componentDidMount() {
    const { tenant, branch } = this.state;
    const { departmentList, isLoading, empLocGroupedByTeam } = this.props;

    this.setState({
      tenant,
      branch,
      departmentList,
      isLoading,
      empLocGroupedByTeam,
    });
  }

  componentDidUpdate(prevProps) {
    const { querystring, departmentList, isLoading, empLocGroupedByTeam } =
      this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const branch = querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevBranch =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    const isBranchChanged = branch !== prevBranch;
    if (isTenantChanged || isBranchChanged) {
      this.setState({
        tenant,
        branch,
        isLoading,
        departmentList,
        empLocGroupedByTeam,
      });
    }
  }

  render() {
    const { departmentList, departmentSearchText, teamSearchText } = this.props;

    return (
      <div className="left-container employee-location-list-left-container">
        <div className="search-container">
          <IconField iconPosition="right" className="input-container">
            {departmentSearchText && (
              <InputIcon
                className="pi pi-times icon-clear"
                onClick={() =>
                  this.props.updateSearchTextChange({
                    value: '',
                    type: 'department',
                  })
                }
              />
            )}
            <InputText
              className="department-search"
              value={departmentSearchText}
              placeholder="部署"
              onChange={e =>
                this.props.updateSearchTextChange({
                  value: e.target.value,
                  type: 'department',
                })
              }
            />
          </IconField>
          <IconField iconPosition="right" className="input-container">
            {teamSearchText && (
              <InputIcon
                className="pi pi-times icon-clear"
                onClick={() =>
                  this.props.updateSearchTextChange({
                    value: '',
                    type: 'team',
                  })
                }
              />
            )}
            <InputText
              className="team-search"
              value={teamSearchText}
              placeholder="チーム"
              onChange={e =>
                this.props.updateSearchTextChange({
                  value: e.target.value,
                  type: 'team',
                })
              }
            />
          </IconField>
        </div>
        {this.renderGroup({
          group: departmentList,
        })}
      </div>
    );
  }
}

EmployeeLocationListLeft.propTypes = {
  isLoading: PropTypes.bool,
  departmentList: PropTypes.array,
  departmentSearchText: PropTypes.string,
  teamSearchText: PropTypes.string,
  departmentActiveIndex: PropTypes.array,
  otherDepartmentActiveIndex: PropTypes.array,
  selectKeyTeam: PropTypes.string,
  branchName: PropTypes.string,
  totalEmployeeMap: PropTypes.object,
  filteredEmpLocGroupedByDeptAndTeam: PropTypes.object,

  updateSearchTextChange: PropTypes.func,
  updateSelectKeyTeam: PropTypes.func,
  updateActiveIndex: PropTypes.func,
};

export default EmployeeLocationListLeft;
