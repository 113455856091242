/**
 *
 * VerkadaEventlog
 *
 */

import VerkadaEventlog from './VerkadaEventlog.jsx';
import './VerkadaEventlog.less';

export default VerkadaEventlog;
