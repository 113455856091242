/**
 *
 * EmployeeLocations
 *
 */

import EmployeeLocations from './EmployeeLocations.jsx';
import './EmployeeLocations.less';

export default EmployeeLocations;
