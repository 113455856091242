import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { COMMON_TEXT } from 'src/helpers/common-text';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.deviceInfoOrderedMap = {};
    this.menuItems = [
      {
        title: COMMON_TEXT.EMPLOYEE_LOCATIONS,
        image: 'assets/images/dashboard/employee_location.png',
        link: '/employee-locations',
      },
      {
        title: COMMON_TEXT.MEETING_ROOM_OUTLOOK_USAGE,
        image: 'assets/images/dashboard/meeting_room_usage.png',
        link: '/meeting-room-outlook-usage',
      },
      {
        title: COMMON_TEXT.PEOPLE_COUNTS,
        image: 'assets/images/dashboard/people_counts.png',
        link: '/people-counts',
      },
    ];
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="dashboard-container">
        <div className="dashboard-welcome-title-container">
          <div className="text-left">
            <h2>ようこそ</h2>
            <h5>表示する内容をメニューから選択してください</h5>
          </div>
        </div>
        <div className="dashboard-content">
          <div className="menu-container">
            {this.menuItems.map((item, index) => (
              <div className="menu-item" key={`menu-item-${index}`}>
                <div className="menu-item__title">
                  <Link to={item.link}>{item.title}</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
