import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CARBON_RGBA_COLOR,
  EXECUTION_ID_TIMEOUT,
  HUMIDITY_RGBA_COLOR,
  NOISE_RGBA_COLOR,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
  TEMPERATURE_RGBA_COLOR,
} from '@/helpers/constants';
import { getConfigurationUrlWithParam } from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep, isEmpty, toNumber, upperCase, upperFirst } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchBranchListByTenant,
  fetchFloorListByBranchIdList,
  fetchSensorDataThreshold,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AirQualitySetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isFloorListLoading: false,
      isSensorDataLoading: false,
      inputErrorFields: new Set(),
      confirmPayloadInfo: [],
      selectedSensorData: {},
      isUpdating: false,
      branchList: [],
      floorList: [],
      selectedFloor: null,
      isUpdateDialogVisible: false,
      updateDataPayload: { values: [] },
    };
    this.tempHumiOptions = [
      {
        label: 'Poor',
        colorTemp: TEMPERATURE_RGBA_COLOR.DEEP_BLUE,
        colorHumi: HUMIDITY_RGBA_COLOR.RED,
        tempatureField: 'temperature_poor_low',
        humidityField: 'humidity_poor_low',
        position: 'left',
      },
      {
        label: 'Bad',
        colorTemp: TEMPERATURE_RGBA_COLOR.BLUE,
        colorHumi: HUMIDITY_RGBA_COLOR.ORANGE,
        tempatureField: 'temperature_bad_low',
        humidityField: 'humidity_bad_low',
        position: 'left',
      },
      {
        label: 'Fair',
        colorTemp: TEMPERATURE_RGBA_COLOR.LIGHT_BLUE,
        colorHumi: HUMIDITY_RGBA_COLOR.YELLOW,
        tempatureField: 'temperature_fair_low',
        humidityField: 'humidity_fair_low',
        position: 'left',
      },
      {
        label: '',
        colorTemp: TEMPERATURE_RGBA_COLOR.GREEN,
        colorHumi: HUMIDITY_RGBA_COLOR.GREEN,
        noInput: true,
        position: 'left',
      },
      {
        label: 'Fair',
        colorTemp: TEMPERATURE_RGBA_COLOR.YELLOW,
        colorHumi: HUMIDITY_RGBA_COLOR.YELLOW,
        tempatureField: 'temperature_fair_high',
        humidityField: 'humidity_fair_high',
        position: 'right',
      },
      {
        label: 'Bad',
        colorTemp: TEMPERATURE_RGBA_COLOR.ORANGE,
        colorHumi: HUMIDITY_RGBA_COLOR.ORANGE,
        tempatureField: 'temperature_bad_high',
        humidityField: 'humidity_bad_high',
        position: 'right',
      },
      {
        label: 'Poor',
        colorTemp: TEMPERATURE_RGBA_COLOR.RED,
        colorHumi: HUMIDITY_RGBA_COLOR.RED,
        tempatureField: 'temperature_poor_high',
        humidityField: 'humidity_poor_high',
        position: 'right',
      },
    ];
    this.carbonNoiseOptions = [
      {
        label: '',
        colorCarbon: CARBON_RGBA_COLOR.GREEN,
        colorNoise: NOISE_RGBA_COLOR.GREEN,
        noInput: true,
        position: 'right',
      },
      {
        label: 'Fair',
        colorCarbon: CARBON_RGBA_COLOR.YELLOW,
        colorNoise: NOISE_RGBA_COLOR.YELLOW,
        carbonField: 'carbon_dioxide_fair_high',
        noiseField: 'noise_level_fair_high',
        position: 'right',
      },
      {
        label: 'Bad',
        colorCarbon: CARBON_RGBA_COLOR.ORANGE,
        colorNoise: NOISE_RGBA_COLOR.ORANGE,
        carbonField: 'carbon_dioxide_bad_high',
        noiseField: 'noise_level_bad_high',
        position: 'right',
      },
      {
        label: 'Poor',
        colorCarbon: CARBON_RGBA_COLOR.RED,
        colorNoise: NOISE_RGBA_COLOR.RED,
        carbonField: 'carbon_dioxide_poor_high',
        noiseField: 'noise_level_poor_high',
        position: 'right',
      },
    ];
    this.keyFieldList = [
      'poor_low',
      'bad_low',
      'fair_low',
      'fair_high',
      'bad_high',
      'poor_high',
    ];
    this.toastRef = React.createRef();
    this.deviceStateMap = {};
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.setState({ deviceList: [] });
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isFloorListLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isFloorListLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    this.setState({
      isFloorListLoading: true,
    });
    const { tenant } = this.state;

    let branchList = await fetchBranchListByTenant(tenant);
    branchList = branchList ?? [];
    this.setState({ branchList });
    const branchIdList = branchList.map(branch => branch.branch_id);
    let floorList = await fetchFloorListByBranchIdList(branchIdList);
    floorList = floorList ?? [];
    const sortedFloorListByBranchOrder = floorList.sort(
      (a, b) =>
        branchIdList.indexOf(a.branch_id) - branchIdList.indexOf(b.branch_id)
    );
    this.setState(
      {
        floorList: sortedFloorListByBranchOrder,
        selectedFloor: sortedFloorListByBranchOrder[0],
        isFloorListLoading: false,
      },
      () => {
        this.getSensorDataThreshold();
      }
    );
  };

  getBranchName = branchId => {
    if (branchId) {
      const branch = this.state.branchList.filter(
        branch => branch.branch_id === branchId
      );
      return branch[0].branch_name + ' - ';
    }
  };

  getSensorDataThreshold = async () => {
    const { selectedFloor } = this.state;
    this.setState({ isSensorDataLoading: true });
    const floorId = selectedFloor?.floor_id;
    const deviceStateByFloorId = this.retrieveSavedDeviceStateByFloorId({
      floorId,
    });
    let sensorThresholds = {};
    if (deviceStateByFloorId) {
      sensorThresholds = cloneDeep(deviceStateByFloorId.sensorData);
    } else {
      sensorThresholds = await fetchSensorDataThreshold({
        floorId: floorId,
      });
      sensorThresholds = sensorThresholds?.[0] || {};
      sensorThresholds['initData'] = cloneDeep(sensorThresholds);
    }
    this.setState({
      selectedSensorData: sensorThresholds,
      isSensorDataLoading: false,
    });
  };

  onChangeSelectedFloorAction = selectedFloor => {
    const { selectedFloor: prevFloor, selectedSensorData } = this.state;
    const { floor_id: prevFloorId } = prevFloor;
    this.updateFloorDeviceState({
      floorId: prevFloorId,
      selectedSensorData,
    });
    this.setState({ selectedFloor }, () => {
      this.getSensorDataThreshold();
    });
  };

  retrieveSavedDeviceStateByFloorId = ({ floorId }) => {
    return this.deviceStateMap[floorId] || null;
  };

  updateFloorDeviceState = ({ floorId, selectedSensorData }) => {
    floorId = +floorId;
    this.deviceStateMap[floorId] = {
      ...this.deviceStateMap[floorId],
      sensorData: selectedSensorData,
    };
  };

  convertKeyFieldToLabel = keyField => {
    const keyFieldText = keyField.split('_');
    if (keyFieldText.length === 3) {
      const [category, status, level] = keyFieldText;
      return {
        type: category,
        status,
        level,
      };
    } else if (keyFieldText.length === 4) {
      const [category, category2, status, level] = keyFieldText;
      return {
        type: category + '_' + category2,
        status,
        level,
      };
    }
    return {};
  };

  updateButtonOnClicked = () => {
    const { selectedFloor, floorList, selectedSensorData } = this.state;

    this.updateFloorDeviceState({
      floorId: selectedFloor?.floor_id,
      selectedSensorData,
    });
    const updatePayload = {
      values: [],
    };
    let confirmDisplayPayload = [];
    for (const floorId in this.deviceStateMap) {
      const findFloor = floorList.find(floor => +floor.floor_id === +floorId);
      let floorName = '';
      if (findFloor) {
        floorName = `${this.getBranchName(findFloor.branch_id)} ${
          findFloor.floor_name
        }`;
      }
      const deviceState = this.deviceStateMap[+floorId];
      const { sensorData } = deviceState;

      const confirmPayloadInfo = this.getPayloadConfirmationInfo({
        selectedSensorData: sensorData,
      });
      confirmPayloadInfo
        .map(item => {
          return {
            ...item,
            floorName,
          };
        })
        .forEach(item => {
          confirmDisplayPayload.push(item);
        });

      const thresholdPayload = cloneDeep(sensorData);
      delete thresholdPayload.initData;
      updatePayload.values.push({
        floor_id: +floorId,
        thresholds: {
          ...thresholdPayload,
        },
      });
    }
    if (confirmDisplayPayload.length > 0) {
      this.setState({
        isUpdateDialogVisible: true,
        updateDataPayload: updatePayload,
        confirmPayloadInfo: confirmDisplayPayload,
      });
    } else {
      this.toastRef.current.show({
        severity: 'info',
        summary: '情報',
        detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
        life: 3000,
        closable: true,
      });
    }
  };

  getPayloadConfirmationInfo = ({ selectedSensorData }) => {
    const { initData } = selectedSensorData;
    const payloadInfo = [];
    for (const key in selectedSensorData) {
      if (key !== 'initData') {
        if (selectedSensorData[key] !== initData[key]) {
          payloadInfo.push({
            keyField: key,
            oldValue: initData[key],
            newValue: selectedSensorData[key],
          });
        }
      }
    }
    return payloadInfo;
  };

  updateAirQualitySetting = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.AIR_QUALITY,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateAirQualitySetting({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  validateSensorData = ({ sensorData, field }) => {
    const fieldText = field.split('_');
    let keyField = '';
    if (fieldText.length === 3) {
      const [category, ,] = fieldText;
      keyField = category;
    } else if (fieldText.length === 4) {
      const [category, category2, ,] = fieldText;
      keyField = `${category}_${category2}`;
    }
    const keyList = this.keyFieldList.map(key => keyField + '_' + key);
    let values = [];
    for (let i = 0; i < keyList.length; i++) {
      const key = keyList[i];
      values.push(sensorData[key]);
    }
    // validate if values is ordered
    let inputErrorFields = [];
    for (let i = 0; i < values.length - 1; i++) {
      if (values[i] > values[i + 1]) {
        inputErrorFields.push(field);
        break;
      }
    }
    this.setState({ inputErrorFields: new Set(inputErrorFields) });
  };

  updateInputValue = (newValue, option, type) => {
    const { selectedSensorData } = this.state;
    switch (type) {
      case 'tempature':
        selectedSensorData[option.tempatureField] = newValue;
        break;
      case 'humidity':
        selectedSensorData[option.humidityField] = newValue;
        break;
      case 'carbon':
        selectedSensorData[option.carbonField] = newValue;
        break;
      case 'noise':
        selectedSensorData[option.noiseField] = newValue;
        break;
      default:
        break;
    }
    this.setState({ selectedSensorData }, () => {
      this.validateSensorData({
        sensorData: selectedSensorData,
        field: option[type + 'Field'],
      });
    });
  };

  renderSensorSetting = () => {
    const {
      isFloorListLoading,
      isSensorDataLoading,
      selectedSensorData,
      inputErrorFields,
    } = this.state;

    const inputNumberOnChanged = (e, option, type) => {
      this.updateInputValue(e.value, option, type);
    };

    const inputNumberOnInput = (e, option, type) => {
      let value = e.target?.value;
      if (isEmpty(value)) {
        const errorField = `${option[type + 'Field']}`;
        const newErrorFields = new Set();
        newErrorFields.add(errorField);
        this.setState({ inputErrorFields: newErrorFields });
        return;
      }
      value = value.replace(/[^0-9.]/g, ''); //
      const newValue = toNumber(value);
      this.updateInputValue(newValue, option, type);
    };

    const invalidInputClassName = field => {
      return inputErrorFields.has(field) ? 'p-invalid' : '';
    };
    return (
      <div className={`sensor-setting-container`}>
        <div className="title-text">{COMMON_TEXT.THRESHOLD_SETTING}</div>
        {isFloorListLoading || isSensorDataLoading ? (
          <div className="ml-auto mr-auto">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="sensor-setting-list">
              <div className="sensor-setting-item">
                <div className="sensor-setting-label">
                  {`${COMMON_TEXT.TEMPERATURE} (℃)`}
                </div>
                <div className="sensor-setting-content">
                  {this.tempHumiOptions.map((option, index) => (
                    <div
                      className="sensor-setting-content-item"
                      key={`temp-item-${index}`}
                    >
                      <div className={`label label-${option.position}`}>
                        <span>{option.label}</span>
                        {inputErrorFields.has(option.tempatureField) && (
                          <span className="error-label">
                            {COMMON_TEXT.PLEASE_INPUT_VALID_NUMBER}
                          </span>
                        )}
                      </div>
                      <div
                        className={`value-input value-input-${option.position}`}
                      >
                        {!option.noInput && (
                          <InputNumber
                            maxFractionDigits={1}
                            value={selectedSensorData[option.tempatureField]}
                            inputClassName={invalidInputClassName(
                              option.tempatureField
                            )}
                            mode="decimal"
                            allowEmpty={true}
                            onChange={e =>
                              inputNumberOnChanged(
                                e,
                                option,
                                'tempature',
                                selectedSensorData
                              )
                            }
                            onInput={e =>
                              inputNumberOnInput(
                                e,
                                option,
                                'tempature',
                                selectedSensorData
                              )
                            }
                          ></InputNumber>
                        )}
                      </div>
                      <div
                        className={`color-line`}
                        style={{
                          backgroundColor: option.colorTemp,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sensor-setting-item">
                <div className="sensor-setting-label">
                  {`${COMMON_TEXT.HUMIDITY} (%)`}
                </div>
                <div className="sensor-setting-content">
                  {this.tempHumiOptions.map((option, index) => (
                    <div
                      className="sensor-setting-content-item"
                      key={`humi-item-${index}`}
                    >
                      <div className={`label label-${option.position}`}>
                        <span>{option.label}</span>
                        {inputErrorFields.has(option.humidityField) && (
                          <span className="error-label">{`有効な数値を入力してください`}</span>
                        )}
                      </div>
                      <div
                        className={`value-input value-input-${option.position}`}
                      >
                        {!option.noInput && (
                          <InputNumber
                            min={0}
                            allowEmpty={true}
                            maxFractionDigits={1}
                            value={selectedSensorData[option.humidityField]}
                            inputClassName={invalidInputClassName(
                              option.humidityField
                            )}
                            onChange={e =>
                              inputNumberOnChanged(
                                e,
                                option,
                                'humidity',
                                selectedSensorData
                              )
                            }
                            onInput={e =>
                              inputNumberOnInput(
                                e,
                                option,
                                'humidity',
                                selectedSensorData
                              )
                            }
                          ></InputNumber>
                        )}
                      </div>
                      <div
                        className={`color-line`}
                        style={{
                          backgroundColor: option.colorHumi,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sensor-setting-item">
                <div className="sensor-setting-label">
                  {`${COMMON_TEXT.CARBON_DIOXIDE} (${COMMON_TEXT.CARBON_DIOXIDE_UNIT})`}
                </div>
                <div className="sensor-setting-content">
                  {this.carbonNoiseOptions.map((option, index) => (
                    <div
                      className="sensor-setting-content-item"
                      key={`carbon-item-${index}`}
                    >
                      <div className={`label label-${option.position}`}>
                        <span>{option.label}</span>
                        {inputErrorFields.has(option.carbonField) && (
                          <span className="error-label">{`有効な数値を入力してください`}</span>
                        )}
                      </div>
                      <div
                        className={`value-input value-input-${option.position}`}
                      >
                        {!option.noInput && (
                          <InputNumber
                            maxFractionDigits={0}
                            min={0}
                            allowEmpty={true}
                            inputClassName={invalidInputClassName(
                              option.carbonField
                            )}
                            value={selectedSensorData[option.carbonField]}
                            onChange={e =>
                              inputNumberOnChanged(
                                e,
                                option,
                                'carbon',
                                selectedSensorData
                              )
                            }
                            onInput={e =>
                              inputNumberOnInput(
                                e,
                                option,
                                'carbon',
                                selectedSensorData
                              )
                            }
                          ></InputNumber>
                        )}
                      </div>
                      <div
                        className={`color-line`}
                        style={{
                          backgroundColor: option.colorCarbon,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sensor-setting-item">
                <div className="sensor-setting-label">
                  {`${COMMON_TEXT.NOISE_LEVEL} (dB)`}
                </div>
                <div className="sensor-setting-content">
                  {this.carbonNoiseOptions.map((option, index) => (
                    <div
                      className="sensor-setting-content-item"
                      key={`noise-item-${index}`}
                    >
                      <div className={`label label-${option.position}`}>
                        <span>{option.label}</span>
                        {inputErrorFields.has(option.noiseField) && (
                          <span className="error-label">{`有効な数値を入力してください`}</span>
                        )}
                      </div>
                      <div
                        className={`value-input value-input-${option.position}`}
                      >
                        {!option.noInput && (
                          <InputNumber
                            maxFractionDigits={1}
                            min={0}
                            value={selectedSensorData[option.noiseField]}
                            inputClassName={invalidInputClassName(
                              option.noiseField
                            )}
                            onChange={e =>
                              inputNumberOnChanged(
                                e,
                                option,
                                'noise',
                                selectedSensorData
                              )
                            }
                            onInput={e =>
                              inputNumberOnInput(
                                e,
                                option,
                                'noise',
                                selectedSensorData
                              )
                            }
                            allowEmpty={true}
                          ></InputNumber>
                        )}
                      </div>
                      <div
                        className={`color-line`}
                        style={{
                          backgroundColor: option.colorNoise,
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  renderSettingContent() {
    const {
      isFloorListLoading,
      isUpdating,
      floorList,
      selectedFloor,
      inputErrorFields,
    } = this.state;

    const hasError = inputErrorFields.size > 0;

    return (
      <>
        <div className="setting-content">
          <div className="panel">
            <div className="floor-list-container">
              <div className="title-text">{`${COMMON_TEXT.BRANCH} - ${COMMON_TEXT.FLOOR}`}</div>
              <div className="floor-list">
                {isFloorListLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {floorList.length > 0 &&
                      floorList.map((floor, index) => (
                        <div
                          key={`floor-item-index-${index}`}
                          className={`floor-item ${
                            selectedFloor?.floor_id === floor.floor_id &&
                            'checked'
                          }`}
                        >
                          <RadioButton
                            inputId={floor.floor_id}
                            name="floor"
                            value={floor}
                            checked={selectedFloor?.floor_id === floor.floor_id}
                            onChange={e =>
                              this.onChangeSelectedFloorAction(e.value)
                            }
                          ></RadioButton>
                          <label htmlFor={floor.floor_id} className="ml-2">
                            {this.getBranchName(floor.branch_id)}
                            {floor.floor_name}
                          </label>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
            {this.renderSensorSetting()}
          </div>
          <div className="button-container">
            <Button
              type="submit"
              severity="info"
              label="保存"
              disabled={hasError}
              loading={isUpdating}
              className="submit-button has-shadow"
              onClick={this.updateButtonOnClicked}
            />
          </div>
        </div>
      </>
    );
  }

  renderUpdatePayloadTable = () => {
    const { confirmPayloadInfo } = this.state;
    const convertKeyFieldToText = keyField => {
      const keyFieldText = keyField.split('_');
      if (keyFieldText.length === 3) {
        const [category, status, level] = keyFieldText;
        return `${COMMON_TEXT[`${upperCase(category)}`]} (${
          COMMON_TEXT[`${upperCase(level)}`]
        }) ${upperFirst(status)}`;
      } else if (keyFieldText.length === 4) {
        const [category, category2, status, level] = keyFieldText;
        let categoryText = upperCase(category + '_' + category2);
        categoryText = categoryText.replace(' ', '_');
        return `${COMMON_TEXT[`${categoryText}`]} (${
          COMMON_TEXT[`${upperCase(level)}`]
        }) ${upperFirst(status)}`;
      }
      return keyField;
    };

    const keyFieldBodyTemplate = rowData => {
      return <div>{convertKeyFieldToText(rowData.keyField)}</div>;
    };

    return (
      <div className="payload-info-container">
        <DataTable
          value={confirmPayloadInfo}
          rowGroupMode="rowspan"
          groupRowsBy="floorName"
          sortOrder={0}
          scrollable
          scrollHeight="400px"
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="floorName"
            header={COMMON_TEXT.FLOOR}
            style={{}}
          ></Column>
          <Column
            field="keyField"
            header={COMMON_TEXT.TYPE}
            style={{}}
            body={keyFieldBodyTemplate}
          ></Column>
          <Column
            field="oldValue"
            header={COMMON_TEXT.OLD_VALUE}
            style={{}}
          ></Column>
          <Column
            field="newValue"
            header={COMMON_TEXT.NEW_VALUE}
            style={{ fontWeight: 'bold' }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  render() {
    const { isUpdateDialogVisible, updateDataPayload, isUpdating } = this.state;
    const updateDataDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => this.setState({ isUpdateDialogVisible: false })}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button has-shadow"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => this.updateData({ updatePayLoad: updateDataPayload })}
          />
        </React.Fragment>
      );
    };
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <Dialog
          visible={isUpdateDialogVisible}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.DEVICE_LOCATION_CHANGE_HEADER}
          modal
          className="p-fluid"
          footer={updateDataDialogFooter}
          onHide={() => this.setState({ isUpdateDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="confirmation-content">
            {this.renderUpdatePayloadTable()}
          </div>
        </Dialog>
        <div className="config-container custom-config-container air-quality-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.AIR_QUALITY_SETTING}
              </div>
            </div>
            <div className="grid-nogutter align-items-center justify-content-end grid">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">{this.renderSettingContent()}</div>
        </div>
      </>
    );
  }
}

AirQualitySetting.propTypes = {};

export default AirQualitySetting;
