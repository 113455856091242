import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import * as Yup from 'yup';
import { fetchOrganizationList } from './query-request';

Yup.addMethod(Yup.array, 'orgIdentiferUnique', function (field, message) {
  return this.test('orgIdentiferUnique', message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map(row => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class OrganizationSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      orgList: [],
    };
    this.originalOrgList = [];
    this.toastRef = React.createRef();
    this.orgProductTypeOptions = [
      { label: 'Meraki', value: 'meraki' },
      { label: 'Verkada', value: 'verkada' },
    ];
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    let organizationList = await fetchOrganizationList({ tenantId: tenant });
    organizationList = organizationList || [];
    const orgs = organizationList.map(item => {
      return {
        orgId: item.org_id,
        orgIdentifer: item.org_identifer,
        orgName: item.org_name,
        product: item.product,
        tenantId: item.tenant_id,
      };
    });
    this.originalOrgList = cloneDeep(orgs);
    this.setState({
      orgList: cloneDeep(orgs),
      isLoading: false,
    });
  };

  findItemChanges({ updatedArray, originalArray }) {
    // 1. Identify new items
    const newItems = updatedArray.filter(item => item.orgId === null);

    // 2. Identify deleted items
    // item is deleted if it's in originalArray but not in updatedArray
    let deletedItems = originalArray.filter(
      item =>
        !updatedArray.find(itemA => itemA.orgId && itemA.orgId === item.orgId)
    );
    for (let item of deletedItems) {
      item.orgIdentifer = null;
    }

    // 3. Identify updated items, updated items should not be in newItems or deletedItems
    const updatedItems = updatedArray.filter(itemA => {
      const originalItem = originalArray.find(
        itemO => itemO.orgId === itemA.orgId
      );

      if (deletedItems.find(itemD => itemD.orgId === itemA.orgId)) {
        return false;
      }

      if (!originalItem) {
        return false; // This means it's a new item or something went wrong
      }

      // Check if any attribute is different
      return (
        itemA.orgName !== originalItem.orgName ||
        itemA.orgIdentifer !== originalItem.orgIdentifer ||
        itemA.product !== originalItem.product
      );
    });

    return {
      newItems: newItems,
      deletedItems: deletedItems,
      updatedItems: updatedItems,
    };
  }

  getUpdateDataPayload = ({ newItems, deletedItems, updatedItems }) => {
    let payload = {
      values: [],
    };
    for (let item of newItems) {
      payload.values.push({
        id: null,
        identifer: item.orgIdentifer,
        name: item.orgName,
        product: item.product,
      });
    }

    for (let item of deletedItems) {
      payload.values.push({
        id: item.orgId,
        identifer: null,
      });
    }

    for (let item of updatedItems) {
      payload.values.push({
        id: item.orgId,
        identifer: item.orgIdentifer,
        name: item.orgName,
        product: item.product,
      });
    }

    return payload;
  };

  updateOrganizationInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.ORGANIZATIONS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateOrganizationInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderSettingContent() {
    const { isLoading, isUpdating, orgList, tenant } = this.state;

    const addNewRow = ({ arrayHelpers }) => {
      arrayHelpers.push({
        orgId: null,
        orgIdentifer: '',
        orgName: '',
        product: 'meraki',
        tenantId: tenant,
      });
    };

    const deleteAccepted = ({ arrayHelpers, index }) => {
      arrayHelpers.remove(index);
    };

    const deleteButtonOnClicked = (e, { arrayHelpers, item, index }) => {
      const { orgId } = item;
      if (orgId) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () => deleteAccepted({ arrayHelpers, index }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index });
      }
    };

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        const { newItems, deletedItems, updatedItems } = this.findItemChanges({
          updatedArray: values.orgs,
          originalArray: this.originalOrgList,
        });
        const updatePayLoad = this.getUpdateDataPayload({
          newItems,
          deletedItems,
          updatedItems,
        });
        const { values: payloadValues } = updatePayLoad;
        if (payloadValues.length === 0) {
          return;
        }
        this.updateData({ updatePayLoad });
        // alert(JSON.stringify(updatePayLoad, null, 2));
      } else {
      }
    };

    const isFormFieldInvalid = ({ name, touched, errors }) => {
      const error = getIn(errors, name);
      const touch = getIn(touched, name);
      return touch && error ? 'p-invalid' : '';
    };

    const getFormErrorMessage = ({ name, touched, errors }) => {
      return isFormFieldInvalid({ name, touched, errors }) ? (
        <span className="validation-error-message">{getIn(errors, name)}</span>
      ) : (
        <></>
      );
    };

    const formSchema = Yup.object().shape({
      orgs: Yup.array()
        .orgIdentiferUnique('orgIdentifer', 'orgIdentifer is duplicated')
        .of(
          Yup.object().shape({
            orgIdentifer: Yup.string().required(COMMON_TEXT.REQUIRED),
            orgName: Yup.string().required(COMMON_TEXT.REQUIRED),
            product: Yup.string().required(COMMON_TEXT.REQUIRED),
          })
        ),
    });

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={{
              orgs: orgList,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, touched, errors, resetForm }) => (
              <Form>
                <FieldArray
                  name="orgs"
                  render={arrayHelpers => (
                    <>
                      <div className="main-row-container is-header">
                        <div className="id-field">ID</div>
                        <div className="name-field">名前</div>
                        <div className="type-field">プロダクト</div>
                        <div className="action-field"></div>
                      </div>
                      {values.orgs.map((org, index) => (
                        <div className="main-row-container" key={index}>
                          <div className="input-text-field-container id-field">
                            <InputText
                              id={`orgs[${index}].orgIdentifer`}
                              value={org.orgIdentifer}
                              onChange={handleChange}
                              className={`${isFormFieldInvalid({
                                name: `orgs[${index}].orgIdentifer`,
                                touched,
                                errors,
                              })}`}
                            />
                            {getFormErrorMessage({
                              name: `orgs[${index}].orgIdentifer`,
                              touched,
                              errors,
                            })}
                          </div>
                          <div className="input-text-field-container name-field">
                            <InputText
                              id={`orgs[${index}].orgName`}
                              value={org.orgName}
                              onChange={handleChange}
                              className={`${isFormFieldInvalid({
                                name: `orgs[${index}].orgName`,
                                touched,
                                errors,
                              })}`}
                            />
                            {getFormErrorMessage({
                              name: `orgs[${index}].orgName`,
                              touched,
                              errors,
                            })}
                          </div>
                          <div className="input-text-field-container type-field">
                            <Dropdown
                              id={`orgs[${index}].product`}
                              value={org.product}
                              options={this.orgProductTypeOptions}
                              onChange={handleChange}
                              className={`${isFormFieldInvalid({
                                name: `orgs[${index}].product`,
                                touched,
                                errors,
                              })}`}
                            ></Dropdown>
                            {getFormErrorMessage({
                              name: `orgs[${index}].product`,
                              touched,
                              errors,
                            })}
                          </div>
                          <div className="input-text-field-container action-field">
                            <Button
                              type="button"
                              icon="pi pi-trash"
                              text
                              severity="danger"
                              className="icon-only-delete-button"
                              onClick={e =>
                                deleteButtonOnClicked(e, {
                                  arrayHelpers,
                                  item: org,
                                  index,
                                })
                              }
                            />
                          </div>
                        </div>
                      ))}
                      <div className="main-row-container is-submit-button-container">
                        <Button
                          severity="info"
                          type="submit"
                          label="保存"
                          className="submit-button has-shadow"
                          loading={isUpdating}
                        />
                        <Button
                          severity="success"
                          type="button"
                          label="追加"
                          className="add-new-button has-shadow"
                          onClick={() => addNewRow({ arrayHelpers })}
                        />
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container organization-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.ORGANIZATION_SETTING}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

OrganizationSetting.propTypes = {};

export default OrganizationSetting;
