import { QUERY_TABLES, WIRELESS_CHANNEL_UTILIZATION_TYPE } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const MAX_RECORDS = 100_000;
const buildRequestPerformanceScore = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        score: ['select'],
        device_name: ['select'],
        grade: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.PERFORMANCES_MART_LINE,
  };
};

const buildRequestQueryChannelUtilization = ({ floorId, fromDateTime, toDateTime, deviceIdList, channelType }) => {
  let tableName = '';
  if (channelType === WIRELESS_CHANNEL_UTILIZATION_TYPE.CHANNEL_24GHZ) {
    tableName = QUERY_TABLES.CHANNEL_UTILIZATIONS_24_MART_LINE;
  } else if (channelType === WIRELESS_CHANNEL_UTILIZATION_TYPE.CHANNEL_5GHZ) {
    tableName = QUERY_TABLES.CHANNEL_UTILIZATIONS_5_MART_LINE;
  }
  return {
    data: {
      fields: {
        device_name: ['select'],
        utilization_total: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: tableName,
  };
};

const buildRequestQueryNetworkClientCount = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        device_name: ['select'],
        counts: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.CLIENT_COUNTS_MART_LINE,
  };
};

const buildRequestQueryNetworkUsage = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        device_name: ['select'],
        average_kbps: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.DATA_RATE_HISTORIES_MART_LINE,
  };
};

const buildRequestQueryWirelessLatency = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        device_name: ['select'],
        background: ['select'],
        besteffort: ['select'],
        video: ['select'],
        voice: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: deviceIdList,
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.LATENCY_STATS_MART_LINE,
  };
};

const buildRequestQueryMeetingRoomOutlookTable = ({ floorIdList, fromDateTime, toDateTime }) => {
  return {
    data: {
      fields: {
        date: ['select'],
        room_name: ['select'],
        reserved: ['select'],
        reservation_counts: ['select'],
        reserved_and_unused: ['select'],
        occupied_rate: ['select'],
        unused_reservation: ['select'],
        unused_rate: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: {
        in: [...floorIdList],
      },
      date: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      room_name: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_TABLE,
  };
};

const buildRequestQuerySensorHistory = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        device_name: ['select'],
        timestamp: ['select'],
        carbon_dioxide: ['select'],
        humidity: ['select'],
        temperature: ['select'],
        noise_level: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.SENSOR_DATA_MART_LINE,
  };
};

const buildRequestQueryPeopleCountAnalysis = ({ floorId, fromDateTime, toDateTime, areaIdList }) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        area_name: ['select'],
        counts: ['select'],
        capacity_rate: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      area_id: areaIdList,
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.PEOPLE_COUNTS_AREA_MART_LINE,
  };
};

const buildRequestWirelessSignal = ({ floorId, fromDateTime, toDateTime, deviceIdList }) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        device_name: ['select'],
        snr: ['select'],
        grade: ['select'],
      },
      skip: 0,
      limit: MAX_RECORDS,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDateTime],
        lte: [toDateTime],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.SIGNAL_QUALITY_HISTORIES_MART_HEATMAP,
  };
};

export const fetchDataPerformanceScore = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestPerformanceScore(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataChannelUtilization = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList, channelType } = payload;
  const request = buildRequestQueryChannelUtilization(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList,
      channelType
    }
  );
  return executeQuery(request);
};

export const fetchDataNetworkClientCount = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestQueryNetworkClientCount(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataNetworkUsage = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestQueryNetworkUsage(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataWirelessLatency = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestQueryWirelessLatency(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataMeetingRoomOutlookTable = async payload => {
  const { floorIdList, fromDateTime, toDateTime } = payload;
  const request = buildRequestQueryMeetingRoomOutlookTable(
    {
      floorIdList,
      fromDateTime,
      toDateTime,
    }
  );
  return executeQuery(request);
};

export const fetchDataSensorHistory = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestQuerySensorHistory(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataPeopleCountAnalysis = async payload => {
  const { floorId, fromDateTime, toDateTime, areaIdList } = payload;
  const request = buildRequestQueryPeopleCountAnalysis(
    {
      floorId,
      fromDateTime,
      toDateTime,
      areaIdList
    }
  );
  return executeQuery(request);
};

export const fetchDataWirelessSignal = async payload => {
  const { floorId, fromDateTime, toDateTime, deviceIdList } = payload;
  const request = buildRequestWirelessSignal(
    {
      floorId,
      fromDateTime,
      toDateTime,
      deviceIdList
    }
  );
  return executeQuery(request);
};
