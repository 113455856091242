/**
 *
 * NetworkPerformanceScore
 *
 */

import NetworkPerformanceScore from './NetworkPerformanceScore.jsx';
import './NetworkPerformanceScore.less';

export default NetworkPerformanceScore;
