import {
  fetchMeetingRoomMartDataByFloorIdListAndDeviceAndDateRange,
  fetchMeetingRoomMartDataByFloorIdList,
} from './query-request';

const getMeetingRoomMartDataWeeklyByFloorIdList = async ({ floorIdList, limit = 0 }) => {
  const data = await fetchMeetingRoomMartDataByFloorIdList({ floorIdList, limit, type: 'weekly' });
  return data || [];
};

const getMeetingRoomMartDataMonthlyByFloorIdList = async ({ floorIdList, limit = 0 }) => {
  const data = await fetchMeetingRoomMartDataByFloorIdList({ floorIdList, limit, type: 'monthly' });
  return data || [];
};

const getMeetingRoomMartDataByFloorIdListAndDeviceAndDateRange = async ({ floorIdList, deviceIdList, fromDate, toDate }) => {
  const data = await fetchMeetingRoomMartDataByFloorIdListAndDeviceAndDateRange({ floorIdList, deviceIdList, fromDate, toDate });
  return data || [];
};

export {
  getMeetingRoomMartDataWeeklyByFloorIdList,
  getMeetingRoomMartDataMonthlyByFloorIdList,
  getMeetingRoomMartDataByFloorIdListAndDeviceAndDateRange
};
