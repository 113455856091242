import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

@connect(state => ({
  local: state.local,
}))

/**
 * Renders the current logged username with picture
 */
class FooterComponent extends Component {
  state = {};

  render() {
    return (
      <div className="footer-component-container">
        <div className="footer-content">
          <span
            id="appVersion"
            className="p-1"
            data-pr-tooltip={`Updated at: ${process.env.REACT_APP_BUILD_TIME}`}
          >{`Version: ${process.env.REACT_APP_VERSION}`}</span>
        </div>
        <Tooltip target="#appVersion" />
      </div>
    );
  }
}

FooterComponent.propTypes = {
  local: PropTypes.object,
};

export default FooterComponent;
