import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildQueryTableData = (filterPayload) => {
  return {
    data: {
      fields: {
        category: ['select'],
        client_description: ['select'],
        client_id: ['select'],
        client_mac: ['select'],
        description: ['select'],
        device_name: ['select'],
        device_serial: ['select'],
        network_identifer: ['select'],
        network_name: ['select'],
        occurred_at: ['select'],
        product_type: ['select'],
        ssid_number: ['select'],
        tenant_id: ['select'],
        type: ['select'],
        log_id: ['select'],
      },
      skip: 0,
      limit: 1000,
    },
    filter: filterPayload,
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      occurred_at: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORK_EVENTS,
  };
};

export const fetchMerakiNetworkEvents = async payload => {
  const request = buildQueryTableData(payload);
  return executeQuery(request);
};
