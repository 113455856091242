import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';

const buildQueryEmployees = ({ tenantId }) => {
  return {
    data: {
      fields: {
        department: ['select'],
        employee_id: ['select'],
        employee_identifer: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        team: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryEmployeeDevices = ({ employeeIdList }) => {
  return {
    data: {
      fields: {
        device_type: ['select'],
        employee_device_mac: ['select'],
        employee_device_name: ['select'],
        employee_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      employee_id: employeeIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_device_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEE_DEVICES,
  };
};

export const fetchEmployeeListByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployees({ tenantId });
  return executeQuery(request);
};

export const fetchEmployeeDeviceListByEmployeeIdList = async payload => {
  const { employeeIdList } = payload;
  const request = buildQueryEmployeeDevices({ employeeIdList });
  return executeQuery(request);
};
