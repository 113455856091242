import { formatNumberWithCommas } from '@/helpers/utility';
import React, { PureComponent } from 'react';

export class CustomYAxisTick extends PureComponent {
  render() {
    const { x, y, payload, yAxisUnit, dy, textAnchor } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={dy ?? 0}
          fontSize={'1rem'}
          textAnchor={textAnchor ?? 'end'}
          fill="#666"
        >
          {`${formatNumberWithCommas(payload.value)}${yAxisUnit ?? ''}`}
        </text>
      </g>
    );
  }
}
