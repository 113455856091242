import NetworkTitle from '@/components/CustomComponent/NetworkTitle';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  HEATMAP_SNR_COLORS,
  MEMORY_STORE,
  PERFORMANCE_COMPONENT,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { compareAsc, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCSVDownload from 'src/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import HeatMap from 'src/components/CustomComponent/HeatMap';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import {
  checkListChanges,
  transformPositionToMarkerCoordinate,
} from 'src/helpers/utility';
import { CustomMarker } from 'src/helpers/wireless-signal-marker';
import { fetchDataFloorMap, fetchDataHeatMap } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class WirelessSignal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      heatMapData: [],
      xAxisHeatMapData: [],
      yAxisHeatMapData: [],
      floorMapData: [],
      isLoadingHeatMap: false,
      isLoadingFloorMap: false,
      selectedSignalType: 0,
      floorMapError: false,
      floorMapUrl: '',
      csvDownload: false,
    };
    this.signalOptions = [
      { name: '2.4 Ghz', value: 0 },
      { name: '5.0 Ghz', value: 1 },
    ];
  }

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({ floorMapUrl: floor?.floor_map, floorMapError: false });
      },
      () => {
        this.setState({ floorMapUrl: '', floorMapError: false });
      }
    );
    return floor ? floor.floor_map : '';
  };

  componentDidMount() {
    const { floor } = this.state;
    if (floor) {
      this.getFloorMapUrl(floor);
      this.getDataFloorMap();
    }

    const { selectedDate } = this.props;
    if (floor && selectedDate) {
      this.getDataHeatMap();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      querystring,
      deviceInfoOrderedList: deviceList,
      selectedDate,
    } = this.props;
    const {
      querystring: prevQuerystring,
      selectedDate: prevSelectedDate,
      deviceInfoOrderedList: prevDeviceList,
    } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const isFloorChanged = floor !== prevFloor;
    if (isFloorChanged) {
      this.setState({ floor }, () => {
        this.getFloorMapUrl(floor);
      });
    }
    const isDateChanged = selectedDate !== prevSelectedDate;
    const { elementsChanged, orderChanged } = checkListChanges(
      deviceList,
      prevDeviceList,
      'deviceId'
    );
    if (elementsChanged || isDateChanged) {
      this.setState({ floor }, () => {
        this.getDataHeatMap();
        this.getDataFloorMap();
      });
    } else if (orderChanged) {
      this.triggerUpdateDataSorting();
    }
  }

  triggerUpdateDataSorting = () => {
    const { heatMapData } = this.state;
    const { deviceInfoOrderedList } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    const {
      heatMapData: sortedHeatMapData,
      xAxis,
      yAxis,
    } = this.sortDataHeatMap({
      heatMapData,
      orderedDeviceIdList: deviceIdList,
    });

    const newState = {
      heatMapData: sortedHeatMapData,
      xAxisHeatMapData: xAxis,
      yAxisHeatMapData: yAxis,
    };
    this.setState(newState);
  };

  computeXAxisHeatMap = heatMapData => {
    const xAxis = [];
    const xAxisLabelSet = new Set(heatMapData.map(item => item.x));
    const xAxisLabelArray = Array.from(xAxisLabelSet).map(label => ({
      label,
      datetime: new Date(label.split(' ')[0] + 'T' + label.split(' ')[1]),
    }));
    if (xAxisLabelArray.length === 0) {
      return xAxis;
    }

    // Sort the array by datetime
    xAxisLabelArray.sort((a, b) => a.datetime - b.datetime);

    for (let i = 0; i < xAxisLabelArray.length; i++) {
      const labels = xAxisLabelArray[i].label.split(' ');
      const labelTime = labels[1].substring(0, 5);
      const categoryDate = labels[0];
      const datetime = xAxisLabelArray[i].datetime;

      let categoryLabel = '';
      if (datetime.getHours() % 3 === 0 && datetime.getMinutes() === 0) {
        categoryLabel = labelTime;
      }

      xAxis.push({
        category: xAxisLabelArray[i].label,
        id: `xAxisLabel-${i}`,
        categoryLabel: categoryLabel,
        categoryDate: categoryDate,
      });
    }
    return xAxis;
  };

  computeYAxisHeatMap = heatMapData => {
    const yAxis = [];
    const yAxisLabelSet = new Set();
    const yAxisLabelArray = [];
    for (const data of heatMapData) {
      if (!yAxisLabelSet.has(data['device_identifer'])) {
        yAxisLabelSet.add(data['device_identifer']);
        yAxisLabelArray.push({
          deviceName: data.y,
          deviceId: data['device_identifer'],
        });
      }
    }
    for (let i = 0; i < yAxisLabelArray.length; i++) {
      yAxis.push({
        deviceId: yAxisLabelArray[i]['deviceId'],
        category: yAxisLabelArray[i]['deviceName'],
        id: `yAxisLabel-${i}`,
      });
    }
    return yAxis.sort((a, b) => b.category.localeCompare(a.category));
  };

  // This function is used to fill the missing data in the heat map
  fillHeatMapData(xAxis, yAxis, heatMapData) {
    // Convert heatMapData into a map for fast lookup
    let dataMap = new Map(
      heatMapData.map(item => [`${item.x}|${item.y}`, item])
    );

    xAxis.forEach(xItem => {
      yAxis.forEach(yItem => {
        let key = `${xItem.category}|${yItem.category}`;

        if (!dataMap.has(key)) {
          heatMapData.push({
            device_identifer: yItem.deviceId,
            x: xItem.category,
            y: yItem.category,
            columnSettings: {
              fill: '#ffffff', // Use default fill color or specify as needed
            },
            value: -1,
          });
        }
      });
    });

    // Return the updated heat map data
    return heatMapData;
  }

  getDataFloorMap = async () => {
    const { floor } = this.state;
    this.setState({ isLoadingFloorMap: true });
    let dataFloorMap = await fetchDataFloorMap({
      floorId: floor,
    });

    if (!dataFloorMap) {
      dataFloorMap = [];
    }

    let updatedFloorMapData = dataFloorMap.map(item => ({
      position: {
        x: parseFloat(item?.position_x),
        y: parseFloat(item?.position_y),
      },
      color: HEATMAP_SNR_COLORS[item?.grade?.toUpperCase()] ?? '#ffffff',
      data: item ?? null,
    }));

    this.setState({
      floorMapData: updatedFloorMapData,
      isLoadingFloorMap: false,
    });
  };

  sortDataHeatMap({ heatMapData, orderedDeviceIdList }) {
    const xAxis = this.computeXAxisHeatMap(heatMapData);
    let yAxis = this.computeYAxisHeatMap(heatMapData);

    heatMapData = this.fillHeatMapData(xAxis, yAxis, heatMapData);
    const orderMapping = orderedDeviceIdList.reduce((acc, deviceId, index) => {
      acc[deviceId] = index;
      return acc;
    }, {});
    yAxis = yAxis.sort((a, b) => {
      const indexA = orderMapping[b['deviceId']] ?? -1;
      const indexB = orderMapping[a['deviceId']] ?? -1;
      // If both indices are defined, sort normally.
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB || b.category.localeCompare(a.category);
      }

      // If one index is undefined, move it to the end.
      if (indexA === undefined && indexB !== undefined) {
        return 1;
      }
      if (indexA !== undefined && indexB === undefined) {
        return -1;
      }

      // If both indices are undefined, leave them as they are.
      return 0;
    });

    return { heatMapData, xAxis, yAxis };
  }

  getDataHeatMap = async () => {
    let { floor, heatMapData: currentHeatMapData } = this.state;
    const {
      deviceInfoOrderedList,
      selectedDate,
      addedDeviceIdList,
      isResetDeviceIdList,
    } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    if (!addedDeviceIdList || addedDeviceIdList.length === 0 || !floor) {
      return;
    }
    if (isResetDeviceIdList) {
      currentHeatMapData = [];
    }
    this.setState({ isLoadingHeatMap: true });
    let dataHeatMap = await fetchDataHeatMap({
      floorId: floor,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
      deviceIdList: addedDeviceIdList,
    });

    if (!dataHeatMap) {
      dataHeatMap = [];
    }

    let updatedHeatMapData = dataHeatMap
      .map(item => ({
        device_identifer: item.device_identifer,
        x: item.timestamp,
        y: item.device_name,
        columnSettings: {
          fill: HEATMAP_SNR_COLORS[item?.grade?.toUpperCase()] ?? '#ffffff',
        },
        value: item.snr,
      }))
      .sort((a, b) => compareAsc(new Date(a.x), new Date(b.x)));

    currentHeatMapData = currentHeatMapData.concat(updatedHeatMapData);

    let { heatMapData, xAxis, yAxis } = this.sortDataHeatMap({
      heatMapData: currentHeatMapData,
      orderedDeviceIdList: deviceIdList,
    });

    this.setState(
      {
        heatMapData,
        xAxisHeatMapData: xAxis,
        yAxisHeatMapData: yAxis,
        isLoadingHeatMap: false,
      },
      () => {}
    );
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  getTimeFromDate = timestamp => {
    const date = new Date(timestamp * 1000);
    const hours = date.getHours();
    return hours;
  };

  renderHeatMap(heatMapData, xAxis, yAxis) {
    const { isLoadingHeatMap } = this.state;
    let filteredYAxis = [];
    let filteredData = [];
    if (heatMapData && heatMapData.length > 0) {
      const { selectedDeviceIdLineList } = this.props;
      filteredYAxis = yAxis.filter(item =>
        selectedDeviceIdLineList.some(
          deviceId =>
            deviceId?.toLowerCase() === item['deviceId']?.toLowerCase()
        )
      );
      filteredData = heatMapData.filter(item =>
        selectedDeviceIdLineList.some(
          deviceId =>
            deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
        )
      );
    }
    return (
      <HeatMap
        horizontalData={xAxis}
        verticalData={filteredYAxis}
        data={filteredData}
        name="heatmapwirelesssignal"
        tooltipLabelList={['デバイス名', '日時', 'SNR']}
        headerTitle={`最新のチャネルとSNR`}
        isLoading={isLoadingHeatMap}
      />
    );
  }

  selectedSignalTypeOnChanged = e => {
    this.setState({ selectedSignalType: e.value });
  };

  renderSelectSignalType() {
    const { selectedSignalType } = this.state;
    return (
      <SelectButton
        value={selectedSignalType}
        optionLabel="name"
        onChange={this.selectedSignalTypeOnChanged}
        options={this.signalOptions}
        className="select-single-type-style"
        allowEmpty={false}
      />
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          severity={csvDownload ? "secondary" : "info"}
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button has-shadow' : 'submit-button csv-download has-shadow'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const {
      heatMapData,
      xAxisHeatMapData,
      yAxisHeatMapData,
      floorMapData,
      isLoadingFloorMap,
      floorMapError,
      floorMapUrl,
      selectedSignalType,
      csvDownload,
      floor,
    } = this.state;
    const { selectedDeviceIdLineList, selectedDate } = this.props;
    let floorMapDataMarkers = floorMapData.map(item => {
      const marker = transformPositionToMarkerCoordinate(item.position);
      return {
        ...marker,
        item: item,
        type: selectedSignalType === 0 ? 'channel_24' : 'channel_5',
      };
    });

    return (
      <div className="network-container wireless-signal">
        <div className="network-title-container">
          <div className="text-left">
            <NetworkTitle
              type={PERFORMANCE_COMPONENT.WIRELESS_SIGNAL}
            ></NetworkTitle>
          </div>
          <div className="grid grid-nogutter align-items-center md-justify-content-end">
            {csvDownload ? null : this.renderSelectSignalType()}
            <div style={{ paddingLeft: '1rem' }}>
              {this.renderDownloadButton()}
            </div>
          </div>
        </div>
        {csvDownload ? (
          <CustomCSVDownload
            fromDate={selectedDate}
            toDate={selectedDate}
            type={CSV_DOWNLOAD_COMPONENT.WIRELESS_SIGNAL}
            deviceIdList={[...selectedDeviceIdLineList]}
            floorId={floor}
          ></CustomCSVDownload>
        ) : (
          <div className="network-content heatmap-floormap-container">
            {this.renderMessageError() || (
              <>
                <div className="floormap-container">
                  {isLoadingFloorMap ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <div className="data-container-floormap">
                        <ImageMarker
                          extraClass="custom-image-marker"
                          bufferLeft={0}
                          bufferTop={0}
                          src={floorMapUrl}
                          markerComponent={CustomMarker}
                          markers={
                            floorMapError ? [] : floorMapDataMarkers || []
                          }
                          alt=""
                        />
                      </div>
                    </>
                  )}
                </div>
                <>
                  {floorMapError && (
                    <Message
                      severity="error"
                      className="mt-3 mb-3"
                      text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                    />
                  )}
                </>
                <div className="heatmap-container">
                  {this.renderHeatMap(
                    heatMapData,
                    xAxisHeatMapData,
                    yAxisHeatMapData
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

WirelessSignal.propTypes = {};
export default WirelessSignal;
