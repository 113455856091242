import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';

// case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
const buildQuerySecureConnectStartBeforeStartAndEndAfterStart = ({
  employeeId,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        start_time: ['select'],
        end_time: ['select'],
        ip_address: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: {
        eq: [employeeId],
      },
      start_time: {
        lte: [fromDate],
      },
      end_time: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.SECURECONNECT_REMOTE_ACCESS_EVENTS,
  };
};

// case 2: fetch data with start is before startDateTime and end is after endDateTime
const buildQuerySecureConnectStartBeforeStartAndEndAfterEnd = ({
  employeeId,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        start_time: ['select'],
        end_time: ['select'],
        ip_address: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: {
        eq: [employeeId],
      },
      start_time: {
        lte: [fromDate],
      },
      end_time: {
        gte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.SECURECONNECT_REMOTE_ACCESS_EVENTS,
  };
};

// case 3: fetch data with start is after startDateTime and end is before endDateTime
const buildQuerySecureConnectStartAfterStartAndEndBeforerEnd = ({
  employeeId,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        start_time: ['select'],
        end_time: ['select'],
        ip_address: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: {
        eq: [employeeId],
      },
      start_time: {
        gte: [fromDate],
      },
      end_time: {
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.SECURECONNECT_REMOTE_ACCESS_EVENTS,
  };
};

// case 4: fetch data with start is after fromDate and is before EndTime and end is after toDate
const buildQuerySecureConnectStartAfterStartAndEndAfterEnd = ({
  employeeId,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        start_time: ['select'],
        end_time: ['select'],
        ip_address: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      employee_id: {
        eq: [employeeId],
      },
      start_time: {
        gte: [fromDate],
        lte: [toDate],
      },
      end_time: {
        gte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.SECURECONNECT_REMOTE_ACCESS_EVENTS,
  };
};

const buildQueryEmployees = tenantId => {
  return {
    data: {
      fields: {
        department: ['select'],
        employee_id: ['select'],
        employee_identifer: ['select'],
        employee_image: ['select'],
        employee_name: ['select'],
        team: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_id: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryVerkadaAccessLogs = ({
  employeeIdentifier,
  fromDate,
  toDate,
}) => {
  return {
    data: {
      fields: {
        door_name: ['select'],
        door_type: ['select'],
        timestamp: ['select'],
        user_id: ['select'], // this is employee_identifier
      },
      skip: 0,
    },
    filter: {
      user_id: [employeeIdentifier],
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      door_type: ['IN', 'OUT'],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'asc',
    },
    table_name: QUERY_TABLES.VERKADA_ACCESSLOGS,
  };
};

const buildQueryMerakiAccessLogs = ({ employeeId, fromDate, toDate }) => {
  return {
    data: {
      fields: {
        meraki_device_name: ['select'],
        device_type: ['select'],
        device_name: ['select'],
        employee_id: ['select'],
        start_time: ['select'],
        end_time: ['select'],
      },
      skip: 0,
    },
    filter: {
      employee_id: [employeeId],
      start_time: {
        gte: [fromDate],
      },
      end_time: {
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      start_time: 'asc',
    },
    table_name: QUERY_TABLES.MERAKI_ACCESSLOGS,
  };
};

export const fetchEmployees = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployees(tenantId);
  return executeQuery(request);
};

export const fetchVerkadaAccessLogs = async payload => {
  const { tenantId, employeeIdentifier, fromDate, toDate } = payload;
  const request = buildQueryVerkadaAccessLogs({
    tenantId,
    employeeIdentifier,
    fromDate,
    toDate,
  });
  return executeQuery(request);
};

export const fetchMerakiAccessLogs = async payload => {
  const { tenantId, employeeId, fromDate, toDate } = payload;
  const request = buildQueryMerakiAccessLogs({
    tenantId,
    employeeId,
    fromDate,
    toDate,
  });
  return executeQuery(request);
};

export const fetchSecureConnectStartBeforeStartAndEndAfterStart =
  async payload => {
    const { employeeId, fromDate, toDate } = payload;
    const request = buildQuerySecureConnectStartBeforeStartAndEndAfterStart({
      employeeId,
      fromDate,
      toDate,
    });
    return executeQuery(request);
  };

export const fetchSecureConnectStartBeforeStartAndEndAfterEnd =
  async payload => {
    const { employeeId, fromDate, toDate } = payload;
    const request = buildQuerySecureConnectStartBeforeStartAndEndAfterEnd({
      employeeId,
      fromDate,
      toDate,
    });
    return executeQuery(request);
  };

export const fetchSecureConnectStartAfterStartAndEndBeforerEnd =
  async payload => {
    const { employeeId, fromDate, toDate } = payload;
    const request = buildQuerySecureConnectStartAfterStartAndEndBeforerEnd({
      employeeId,
      fromDate,
      toDate,
    });
    return executeQuery(request);
  };

export const fetchSecureConnectStartAfterStartAndEndAfterEnd =
  async payload => {
    const { employeeId, fromDate, toDate } = payload;
    const request = buildQuerySecureConnectStartAfterStartAndEndAfterEnd({
      employeeId,
      fromDate,
      toDate,
    });
    return executeQuery(request);
  };
