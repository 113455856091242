/**
 *
 * AreaSetting
 *
 */

import AreaSetting from './AreaSetting.jsx';
import './AreaSetting.less';

export default AreaSetting;
