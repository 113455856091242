import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ConfigUpdateStatus from '@/components/organization/Configuration/ConfigUpdateStatus';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  COMPONENT_CHILD_ID_GROUP,
  COMPONENT_ID_GROUP,
  EXECUTION_ID_TIMEOUT,
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
  USER_ID_KEY,
} from '@/helpers/constants';
import { generateRandomString } from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { cloneDeep, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import SignagePlay from './SignagePlay';
import { fetchSignageComponents } from './queryRequest';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class SignageComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isUpdating: false,
      isLoading: false,
      isActionUpdating: false,
      isSignagePlayDialogVisible: false,
      selectedPlaySetItems: [],
      signageComponentsList: [],
    };
    this.toastRef = React.createRef();
    this.authToken = new AuthToken();
    this.componentTypeOptions = [
      {
        label: COMMON_TEXT.MEETING_ROOM_USAGE,
        component_id: COMPONENT_ID_GROUP.ROOM_AVAILABILITIES,
        component_child_id: null,
      },
      {
        label: COMMON_TEXT.PEOPLE_COUNTS,
        component_id: COMPONENT_ID_GROUP.PEOPLE_COUNTS,
        component_child_id: null,
      },
      {
        label: `${COMMON_TEXT.AIR_QUALITY} - ${COMMON_TEXT.TEMPERATURE}`,
        component_id: COMPONENT_ID_GROUP.ENVIRONMENTS,
        component_child_id: COMPONENT_CHILD_ID_GROUP.TEMPERATURE,
      },
      {
        label: `${COMMON_TEXT.AIR_QUALITY} - ${COMMON_TEXT.HUMIDITY}`,
        component_id: COMPONENT_ID_GROUP.ENVIRONMENTS,
        component_child_id: COMPONENT_CHILD_ID_GROUP.HUMIDITY,
      },
      {
        label: `${COMMON_TEXT.AIR_QUALITY} - ${COMMON_TEXT.CARBON_DIOXIDE}`,
        component_id: COMPONENT_ID_GROUP.ENVIRONMENTS,
        component_child_id: COMPONENT_CHILD_ID_GROUP.CARBON_DIOXIDE,
      },
      {
        label: `${COMMON_TEXT.AIR_QUALITY} - ${COMMON_TEXT.NOISE_LEVEL}`,
        component_id: COMPONENT_ID_GROUP.ENVIRONMENTS,
        component_child_id: COMPONENT_CHILD_ID_GROUP.NOISE_LEVEL,
      },
    ];
    this.intervalTypeOptions = [
      { label: '15秒', value: 15 },
      { label: '30秒', value: 30 },
      { label: '45秒', value: 45 },
      { label: '60秒', value: 60 },
      { label: '75秒', value: 75 },
      { label: '90秒', value: 90 },
    ];
    this.branches = this.props?.sessionStore[MEMORY_STORE.BRANCHES] || [];
    this.floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    this.signageButtonList = [
      {
        label: `${COMMON_TEXT.SET}1`,
        value: 1,
      },
      {
        label: `${COMMON_TEXT.SET}2`,
        value: 2,
      },
      {
        label: `${COMMON_TEXT.SET}3`,
        value: 3,
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getSignageComponentData();
      });
    }
    const { sessionStore } = this.props;
    const sessionExecutionList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (sessionExecutionList && sessionExecutionList.length > 0) {
      this.setState({ isActionUpdating: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant: tenant }, () => {
        this.getSignageComponentData();
      });
    }
  }

  componentWillUnmount() {}

  getSignageComponentData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    const userId = sessionStorage.getItem(USER_ID_KEY);
    let signageComponentsList = await fetchSignageComponents({
      tenantId: tenant,
      userId: userId,
    });
    signageComponentsList = signageComponentsList || [];
    signageComponentsList.forEach(item => {
      item.rowId = generateRandomString(10);
      item.initData = cloneDeep(item);
    });
    signageComponentsList.sort((a, b) => a.display_order - b.display_order);

    this.setState({
      signageComponentsList,
      isLoading: false,
    });
  };

  updateSetComponents = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.SIGNAGE_COMPONENTS,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('update signage components error');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('update signage components error', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    // eslint-disable-next-line
    const { isSuccess, executionId } = await this.updateSetComponents({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false, isActionUpdating: true });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  getUpdateDataPayload = ({ updateDataList }) => {
    let payload = {
      values: [],
    };
    const cloneUpdateDataList = cloneDeep(updateDataList);
    if (cloneUpdateDataList && cloneUpdateDataList.length > 0) {
      for (const dataItem of cloneUpdateDataList) {
        if (dataItem.is_deleted) {
          payload.values.push({
            signage_components_id: dataItem.signage_components_id,
            component_id: null,
          });
        } else {
          const cloneDataItem = { ...dataItem };
          delete cloneDataItem.rowId;
          payload.values.push({
            ...cloneDataItem,
          });
        }
      }
    }
    return payload;
  };

  renderListAction = () => {
    const { signageComponentsList, isUpdating } = this.state;

    const handleSubmit = (values, { errors }) => {
      if (errors) {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `There is an error. Please check.`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      } else {
        const updateDataList = [];
        const { signageComponentsList } = this.state;
        for (let item of signageComponentsList) {
          const { initData } = item;
          if (!initData) {
            // new item
            updateDataList.push({
              ...item,
            });
          } else {
            // compare with initData
            const compareData = {
              ...item,
            };
            delete compareData.initData;
            delete compareData.rowId;
            if (!isEqual(initData, compareData)) {
              updateDataList.push({
                ...compareData,
              });
            }
          }
        }

        let hasChange = false;
        if (updateDataList.length > 0) {
          hasChange = true;
        }
        if (!hasChange) {
          this.toastRef.current.show({
            severity: 'info',
            summary: '情報',
            detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
            life: 3000,
            closable: true,
          });
        } else {
          // call api to update data
          const updatePayload = this.getUpdateDataPayload({ updateDataList });
          this.updateData({ updatePayLoad: updatePayload });
        }
      }
    };

    const addNewRow = ({ arrayHelpers }) => {
      if (!arrayHelpers.form.values['selectedActionList']) {
        arrayHelpers.form.values['selectedActionList'] = [];
      }
      const branchId = this.branches?.[0]?.branch_id;
      const floorId = this.floors?.filter(
        floor => floor.branch_id === branchId
      )?.[0].floor_id;
      arrayHelpers.form.values['selectedActionList'].push({
        signage_components_id: null,
        branch_id: branchId,
        floor_id: floorId,
        component_id: COMPONENT_ID_GROUP.ROOM_AVAILABILITIES,
        component_child_id: null,
        interval: this.intervalTypeOptions[1].value,
        user_id: +sessionStorage.getItem(USER_ID_KEY),
        display_order:
          arrayHelpers.form.values['selectedActionList'].length + 1,
        rowId: generateRandomString(10),
      });
      this.setState({
        signageComponentsList: arrayHelpers.form.values['selectedActionList'],
      });
    };

    const formSchema = Yup.object().shape({
      selectedActionList: Yup.array().of(
        Yup.object().shape({
          component_id: Yup.number().required(COMMON_TEXT.REQUIRED),
          branch_id: Yup.number().required(COMMON_TEXT.REQUIRED),
          floor_id: Yup.number().required(COMMON_TEXT.REQUIRED),
        })
      ),
    });

    const computeScrollHeight = () => {
      const scrollHeight = signageComponentsList?.length * 100;
      return `calc(min(100vh - 320px, ${scrollHeight}px))`;
    };
    const scrollHeightValue = computeScrollHeight();

    return (
      <Formik
        key={`formik-1`}
        initialValues={{
          selectedActionList: signageComponentsList,
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, touched, errors, resetForm }) => (
          <Form>
            <FieldArray
              render={arrayHelpers => (
                <>
                  <ScrollPanel
                    style={{
                      width: '100%',
                      height: `${scrollHeightValue}`,
                      minHeight: '0px',
                    }}
                  >
                    {values?.selectedActionList
                      ?.filter(item => item.is_deleted !== true)
                      .map((actionItem, index) => (
                        <div key={`action-item-${index}`}>
                          {this.renderItemAction(
                            actionItem,
                            index,
                            arrayHelpers,
                            {
                              handleChange,
                              touched,
                              errors,
                            }
                          )}
                        </div>
                      ))}
                  </ScrollPanel>
                  <div className="main-row-container is-submit-button-container">
                    <Button
                      severity="info"
                      type="submit"
                      label="保存"
                      className="submit-button has-shadow"
                      loading={isUpdating}
                    />
                    <Button
                      severity="success"
                      type="button"
                      label={COMMON_TEXT.ADD_NEW}
                      className="add-new-button has-shadow"
                      onClick={() => addNewRow({ arrayHelpers })}
                    />
                  </div>
                </>
              )}
            />
          </Form>
        )}
      </Formik>
    );
  };

  renderItemAction = (
    actionItem,
    index,
    arrayHelpers,
    { handleChange, touched, errors }
  ) => {
    const { branch_id, component_id, component_child_id, floor_id, interval } =
      actionItem;

    const deleteButtonOnClicked = (e, { arrayHelpers, item, index }) => {
      const { component_id } = item;
      if (component_id) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () =>
            deleteAccepted({ arrayHelpers, index, actionItem: item }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index, actionItem: item });
      }
    };

    const deleteAccepted = ({ arrayHelpers, index, actionItem }) => {
      const { rowId } = actionItem;
      const itemIndex = arrayHelpers.form.values.selectedActionList.findIndex(
        item => item.rowId === rowId
      );
      if (itemIndex !== -1) {
        const { signageComponentsList } = this.state;
        const item = arrayHelpers.form.values.selectedActionList[itemIndex];
        if (item.signage_components_id) {
          item.is_deleted = true;
          arrayHelpers.form.values.selectedActionList[itemIndex] = item;
        } else {
          arrayHelpers.form.values.selectedActionList.splice(itemIndex, 1);
        }
        this.setState({ signageComponentsList });
      }
    };

    const fieldValueOnChanged = (e, { arrayHelpers, index, actionItem }) => {
      const { name, value } = e.target;
      const { rowId } = actionItem;
      const itemIndex = arrayHelpers.form.values.selectedActionList.findIndex(
        item => item.rowId === rowId
      );
      if (name === 'component_id') {
        const { component_id, component_child_id } = value;
        arrayHelpers.form.values.selectedActionList[itemIndex]['component_id'] =
          component_id;
        arrayHelpers.form.values.selectedActionList[itemIndex][
          'component_child_id'
        ] = component_child_id;
      } else {
        arrayHelpers.form.values.selectedActionList[itemIndex][name] = value;
      }
      const { signageComponentsList } = this.state;
      this.setState({ signageComponentsList });
    };

    const isFormFieldInvalid = ({ name, touched, errors }) => {
      const error = getIn(errors, name);
      const touch = getIn(touched, name);
      const isInvalid = touch && error;
      return isInvalid ? 'p-invalid' : '';
    };

    const getFormErrorMessage = ({ name, touched, errors }) => {
      return isFormFieldInvalid({ name, touched, errors }) ? (
        <span className="validation-error-message">{getIn(errors, name)}</span>
      ) : (
        <></>
      );
    };

    const branchOptions = this.branches.map(branch => ({
      label: branch.branch_name,
      value: branch.branch_id,
    }));

    const floorOptions = this.floors
      .filter(floor => floor.branch_id === branch_id)
      .map(floor => ({
        label: floor.floor_name,
        value: floor.floor_id,
      }));

    const componentOptions = this.componentTypeOptions.map(option => ({
      label: option.label,
      value: {
        component_id: option.component_id,
        component_child_id: option.component_child_id,
      },
    }));

    return (
      <div className="action-item">
        <div className="main-row-container is-header">
          <div className="numerical-order"></div>
          <div className="branch-field">{COMMON_TEXT.BRANCH}</div>
          <div className="floor-field">{COMMON_TEXT.FLOOR}</div>
          <div className="component-field">{COMMON_TEXT.COMPONENT}</div>
          <div className="interval-field">{COMMON_TEXT.DISPLAY_TIME}</div>
          <div className="action-field"></div>
        </div>

        <div className="main-row-container" key={`main-row-container-${index}`}>
          <div className="numerical-order">{index + 1}</div>
          <div className="input-text-field-container branch-field">
            <Dropdown
              id={`selectedActionList[${index}].branch_id`}
              name="branch_id"
              value={branch_id}
              options={branchOptions}
              onChange={e =>
                fieldValueOnChanged(e, { arrayHelpers, index, actionItem })
              }
            ></Dropdown>
          </div>
          <div className="input-text-field-container floor-field">
            <Dropdown
              id={`selectedActionList[${index}].floor_id`}
              name="floor_id"
              value={floor_id}
              options={floorOptions}
              onChange={e =>
                fieldValueOnChanged(e, { arrayHelpers, index, actionItem })
              }
            ></Dropdown>
          </div>
          <div className="input-text-field-container component-field">
            <Dropdown
              id={`selectedActionList[${index}].component_id`}
              name="component_id"
              value={{
                component_id: component_id,
                component_child_id: component_child_id,
              }}
              onChange={e =>
                fieldValueOnChanged(e, { arrayHelpers, index, actionItem })
              }
              options={componentOptions}
              className={`${isFormFieldInvalid({
                name: `selectedActionList[${index}].component_id`,
                touched,
                errors,
              })}`}
            ></Dropdown>
            {getFormErrorMessage({
              name: `selectedActionList[${index}].component_id`,
              touched,
              errors,
            })}
          </div>
          <div className="input-text-field-container interval-field">
            <Dropdown
              id={`selectedActionList[${index}].interval`}
              name="interval"
              value={interval}
              options={this.intervalTypeOptions}
              onChange={e =>
                fieldValueOnChanged(e, { arrayHelpers, index, actionItem })
              }
            ></Dropdown>
          </div>
          <div className="input-text-field-container action-field">
            <Button
              type="button"
              icon="pi pi-trash"
              text
              severity="danger"
              className="icon-only-delete-button"
              onClick={e =>
                deleteButtonOnClicked(e, {
                  arrayHelpers,
                  item: actionItem,
                  index,
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  updateConfigStatus = ({ isActionUpdating }) => {
    this.setState({ isActionUpdating });
  };

  render() {
    const {
      isUpdating,
      isLoading,
      isActionUpdating,
      isSignagePlayDialogVisible,
      selectedPlaySetItems,
      signageComponentsList,
    } = this.state;
    const playItems = selectedPlaySetItems
      .filter(item => item.is_deleted !== true)
      .map(item => {
        return {
          ...item,
        };
      });
    for (const playItem of playItems) {
      delete playItem.initData;
    }

    const canPlaySignageView = signageComponentsList.length > 0;

    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <Dialog
          showHeader={false}
          maximizable
          maximized
          closeOnEscape={true}
          className="signage-play-dialog"
          header=""
          visible={isSignagePlayDialogVisible}
          onHide={() => this.setState({ isSignagePlayDialogVisible: false })}
        >
          <SignagePlay items={playItems} />
        </Dialog>
        <div className="config-container custom-config-container signage-components-container">
          <>
            {isLoading || isUpdating ? (
              <LoadingSpinner
                loadingText={isUpdating ? COMMON_TEXT.UPDATING_DATA : null}
              />
            ) : (
              <>
                {isActionUpdating ? (
                  <ConfigUpdateStatus
                    updateConfigStatus={this.updateConfigStatus}
                  ></ConfigUpdateStatus>
                ) : (
                  <>
                    <div className="config-title-signage">
                      <div className="title-text">
                        {COMMON_TEXT.SIGNAGE_MODE}
                      </div>
                    </div>
                    <div className="setting-container">
                      <div className="add-signage-container">
                        <Button
                          type="button"
                          severity="info"
                          label={COMMON_TEXT.START_SIGNAGE_MODE}
                          className="has-shadow"
                          disabled={!canPlaySignageView}
                          onClick={e =>
                            this.setState({
                              isSignagePlayDialogVisible: true,
                              selectedPlaySetItems:
                                [...signageComponentsList] ?? [],
                            })
                          }
                        />
                        <span>{COMMON_TEXT.PRESS_ESC_TO_EXIT}</span>
                      </div>
                      <div className="list-action-container">
                        {this.renderListAction()}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </div>
      </>
    );
  }
}

SignageComponents.propTypes = {};
SignageComponents.whyDidYouRender = true;
export default SignageComponents;
