import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import {
  fetchAllEmployees,
  fetchAllEmployeesDeviceByEmployeeIdList,
} from '@/components/organization/Configuration/EmployeeSetting/query-request';
import { COMMON_TEXT } from '@/helpers/common-text';
import { EMPLOYEE_CSV_COLUMN } from '@/helpers/constants';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';

class EmployeeCSVDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadCsv: false,
      isFetchingData: false,
      isDialogVisible: false,
      csvData: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  csvDownloadButtonOnClicked = () => {
    this.setState({
      isDialogVisible: true,
    });
  };

  fetchAllEmployeeInfo = async () => {
    const { tenantId } = this.props;
    this.setState({ isFetchingData: true });
    let emplList = await fetchAllEmployees({ tenantId });
    emplList = emplList || [];
    const emplIdList = emplList.map(employee => employee.employee_id);
    if (emplIdList && emplIdList.length > 0) {
      // fetch all employee devices meraki and verkada
      const emplDevices = await fetchAllEmployeesDeviceByEmployeeIdList({
        employeeIdList: emplIdList,
      });
      const devicesMap = {};
      if (emplDevices && emplDevices.length > 0) {
        emplDevices.forEach(employeeDevice => {
          const { employee_id, device_type } = employeeDevice;
          if (!devicesMap[+employee_id]) {
            devicesMap[+employee_id] = {
              employee_device_pc: '',
              employee_device_mobile: '',
            };
          }
          if (device_type === 'pc') {
            devicesMap[+employee_id].employee_device_pc = employeeDevice;
          } else if (device_type === 'phone') {
            devicesMap[+employee_id].employee_device_mobile = employeeDevice;
          }
        });
      }
      // mapp employee device to employee
      emplList.forEach(employee => {
        const { employee_id } = employee;
        const pcDevice = devicesMap[+employee_id]?.['employee_device_pc'];
        const mobileDevice =
          devicesMap[+employee_id]?.['employee_device_mobile'];
        const { employee_device_mac: pcMac, employee_device_name: pcName } =
          pcDevice ?? {};
        const {
          employee_device_mac: mobileMac,
          employee_device_name: mobileName,
        } = mobileDevice ?? {};
        employee['employee_device_pc_mac'] = pcMac ?? '';
        employee['employee_device_pc_name'] = pcName ?? '';
        employee['employee_device_mobile_mac'] = mobileMac ?? '';
        employee['employee_device_mobile_name'] = mobileName ?? '';
      });
    }

    const csvData = this.transformToCsvData({ data: emplList });
    this.setState({
      csvData,
      isFetchingData: false,
    });
  };

  transformToCsvData = ({ data }) => {
    let csvRows = [];
    const headerData = {
      employee_name: EMPLOYEE_CSV_COLUMN.NAME,
      employee_name_reading: EMPLOYEE_CSV_COLUMN.NAME_READING,
      employee_number: EMPLOYEE_CSV_COLUMN.EMPLOYEE_NUMBER,
      phone_number: EMPLOYEE_CSV_COLUMN.PHONE_NUMBER,
      phone_number2: EMPLOYEE_CSV_COLUMN.PHONE_NUMBER2,
      email: EMPLOYEE_CSV_COLUMN.EMAIL,
      chat_tools: EMPLOYEE_CSV_COLUMN.CHAT_TOOL,
      department: EMPLOYEE_CSV_COLUMN.DEPARTMENT,
      team: EMPLOYEE_CSV_COLUMN.TEAM,
      branch_name: EMPLOYEE_CSV_COLUMN.BRANCH,
      floor_name: EMPLOYEE_CSV_COLUMN.FLOOR,
      employee_identifer: EMPLOYEE_CSV_COLUMN.VERKADA_ID,
      employee_device_pc_mac: EMPLOYEE_CSV_COLUMN.PC_MAC_ADDRESS,
      employee_device_pc_name: EMPLOYEE_CSV_COLUMN.PC_HOST_NAME,
      employee_device_mobile_mac: EMPLOYEE_CSV_COLUMN.MOBILE_MAC_ADDRESS,
      employee_device_mobile_name: EMPLOYEE_CSV_COLUMN.MOBILE_HOST_NAME,
      employee_id: EMPLOYEE_CSV_COLUMN.ID,
    };
    const headersKey = Object.keys(headerData);

    // Create CSV Header
    const headerValues = Object.values(headerData);

    csvRows.push(headerValues.join(','));
    // Create CSV Data
    for (const row of data) {
      const values = headersKey.map(e => {
        return row[e];
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  dialogOnShow = () => {
    this.fetchAllEmployeeInfo();
  };

  dialogOnHide = () => {
    this.setState({
      isDialogVisible: false,
    });
  };

  renderDialogBodyContent = () => {
    const csvDownload = ({ data }) => {
      const downloadlink = document.createElement('a');
      downloadlink.textContent = 'download';
      const filename = `employees_${format(new Date(), 'yyyyMMddHHmm')}`;
      downloadlink.download = filename + '.csv';
      downloadlink.href =
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data);
      downloadlink.click();
    };

    const { isFetchingData, csvData } = this.state;
    return (
      <>
        {isFetchingData ? (
          <div className="flex align-items-center justify-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <>
              {(!csvData || csvData.length) === 0 && (
                <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>
              )}
            </>
            <>
              {csvData && csvData.length > 0 && (
                <>
                  <div>
                    <h6>{`データが準備できました、ダウンロードしてください`}</h6>
                  </div>
                  <div className="mt-4 flex align-items-center justify-content-center">
                    <Button
                      label={COMMON_TEXT.DOWNLOAD}
                      severity="info"
                      className="download-button has-shadow"
                      icon="pi pi-download"
                      onClick={e => csvDownload({ data: csvData })}
                    />
                  </div>
                </>
              )}
            </>
          </>
        )}
      </>
    );
  };

  render() {
    const { isDownloadCsv, isDialogVisible } = this.state;
    const DialogBodyContent = this.renderDialogBodyContent;
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <>
          <Dialog
            header="CSVダウンロード"
            visible={isDialogVisible}
            style={{ width: '50vw' }}
            onHide={() => this.dialogOnHide()}
            onShow={() => {
              this.dialogOnShow();
            }}
          >
            <DialogBodyContent />
          </Dialog>
        </>
        <>
          <Button
            severity="secondary"
            type="button"
            label="CSVダウンロード"
            loading={isDownloadCsv}
            className="csv-button csv-download has-shadow"
            onClick={() => {
              this.csvDownloadButtonOnClicked();
            }}
          />
        </>
      </>
    );
  }
}

EmployeeCSVDownload.propTypes = {};

export default EmployeeCSVDownload;
