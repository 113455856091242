import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from '@/helpers/request';

const buildQueryTableData = (tenantId, fromDate, toDate) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        category: ['select'],
        client_description: ['select'],
        client_id: ['select'],
        client_mac: ['select'],
        description: ['select'],
        device_name: ['select'],
        device_serial: ['select'],
        network_identifer: ['select'],
        network_name: ['select'],
        occurred_at: ['select'],
        product_type: ['select'],
        ssid_number: ['select'],
        tenant_id: ['select'],
        type: ['select'],
        log_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      occurred_at: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      occurred_at: 'desc',
    },
    table_name: QUERY_TABLES.MERAKI_NETWORK_EVENTS,
  };
};

const buildRequestQueryBranch = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

const buildRequestQueryFloor = branchIdList => {
  const randomId = getRandomNumber900000to999999();
  branchIdList = branchIdList || [];
  branchIdList.push(randomId);
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
        floor_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      floor_order: 'asc',
    },
    table_name: QUERY_TABLES.FLOORS,
  };
};

const getQueryVerkadaDevicesByType = ({ floorId, deviceType }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        device_type: ['select'],
        door_type: ['select'],
        floor_id: ['select'],
        model: ['select'],
        org_id: ['select'],
        zones: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
      device_type: [deviceType],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      device_name: 'asc',
    },
    table_name: QUERY_TABLES.VERKADA_DEVICES,
  };
};

const buildRequestMerakiNetworks = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        network_identifer: ['select'],
        branch_id: ['select'],
        network_name: ['select'],
        org_identifer: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.MERAKI_NETWORKS,
  };
};

const buildRequestMerakiDevices = ({ networkIdentiferList, merakiModel, tenantId }) => {
  const randomId = getRandomNumber900000to999999();
  networkIdentiferList = networkIdentiferList || [];
  networkIdentiferList.push(randomId);
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        model: ['select'],
        network_identifer: ['select'],
        zone_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      network_identifer: networkIdentiferList,
      model: {
        like: [`${merakiModel}%`]
      },
      tenant_id: [tenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      device_name: 'asc',
    },
    table_name: QUERY_TABLES.MERAKI_DEVICES,
  };
};

const buildRequestDevicePosition = floorId => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_position_id: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        room_name: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_POSITIONS,
  };
};

const buildRequestMerakiZones = ({ tenantId, merakiIdList }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        zone_id: ['select'],
        label: ['select'],
        device_identifer: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      device_identifer: merakiIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.MERAKI_CAMERA_ZONES,
  };
};

export const fetchMerakiNetworkEvents = async payload => {
  const { tenantId, fromDate, toDate } = payload;
  const request = buildQueryTableData(tenantId, fromDate, toDate);
  return executeQuery(request);
};

export const fetchBranchListByTenant = async tenentId => {
  const request = buildRequestQueryBranch(tenentId);
  return executeQuery(request);
};

export const fetchFloorListByBranchIdList = async branchIdList => {
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};

export const fetchVerkadaDevicesByType = async ({ floorId, deviceType }) => {
  const request = getQueryVerkadaDevicesByType({ floorId, deviceType });
  return executeQuery(request);
};

export const fetchMerakiNetworks = async payload => {
  const { tenantId } = payload;
  const request = buildRequestMerakiNetworks({ tenantId });
  return executeQuery(request);
};

export const fetchMerakiDevicesByNetworkIdentiferListAndModel =
  async ({ networkIdentiferList, merakiModel, tenantId }) => {
    const request = buildRequestMerakiDevices({ networkIdentiferList, merakiModel, tenantId });
    return executeQuery(request);
  };

export const fetchDevicePositionByFloorId = async floorId => {
  const request = buildRequestDevicePosition(floorId);
  return executeQuery(request);
};

export const fetchMerakiZonesByTenant = async payload => {
  const { tenantId, merakiIdList } = payload;
  const request = buildRequestMerakiZones({ tenantId, merakiIdList });
  return executeQuery(request);
};
