/**
 *
 * WirelessLatency
 *
 */

import WirelessLatency from './WirelessLatency.jsx';
import './WirelessLatency.less';

export default WirelessLatency;
