import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  convertArrayToObject,
  generateRandomString,
  sortObjectKeys,
} from '@/helpers/utility';
import { format, isSameDay } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class DeviceStatusOverviewHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTableHistory: this.props.dataTableHistory ?? [],
      isLoadingHistory: this.props.isLoadingHistory ?? false,
      startDate: this.props.startDate ?? new Date(),
      endDate: this.props.endDate ?? new Date(),
      selectedDevice: {},
    };
    this.overlayRef = React.createRef();
    this.dataHistoryTableRef = React.createRef();
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      product: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    };

    this.dataHistoryColumns = [
      {
        field: 'status',
        header: '状態',
        align: 'center',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem', width: '60px' },
        bodyStyle: { fontSize: '0.85rem', width: '60px' },
      },
      {
        field: 'product',
        header: 'プロダクト',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem', width: '100px' },
        bodyStyle: { fontSize: '0.85rem', width: '100px' },
        hasFilter: true,
      },
      {
        field: 'device_type',
        header: '種類',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem', width: '80px' },
        bodyStyle: { fontSize: '0.85rem', width: '80px' },
        hasFilter: true,
      },
      {
        field: 'device_name',
        header: '名前',
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem', width: '150px' },
        bodyStyle: { fontSize: '0.85rem', width: '150px' },
        hasFilter: true,
      },
      {
        field: 'last_error_timestamp',
        header: '最終エラー日時',
        align: 'center',
        headerClassName: 'text-left',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem', width: '170px' },
        bodyStyle: { fontSize: '0.85rem', width: '170px' },
      },
      {
        field: 'status_tooltip',
        header: 'ステータス',
        align: 'center',
        headerClassName: 'text-left',
        bodyClassName: 'text-left status-column',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
      },
    ];
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { dataTableHistory, isLoadingHistory, startDate, endDate } =
      this.props;
    const {
      dataTableHistory: prevDataTableHistory,
      isLoadingHistory: prevIsLoadingHistory,
      startDate: prevStartDate,
      endDate: prevEndDate,
    } = prevProps;

    const isDataTableHistoryChanged = dataTableHistory !== prevDataTableHistory;
    const isLoadingHistoryChanged = isLoadingHistory !== prevIsLoadingHistory;
    const isStartDateChanged = startDate !== prevStartDate;
    const isEndDateChanged = endDate !== prevEndDate;
    if (
      isDataTableHistoryChanged ||
      isLoadingHistoryChanged ||
      isStartDateChanged ||
      isEndDateChanged
    ) {
      this.setState({
        dataTableHistory,
        isLoadingHistory,
        startDate,
        endDate,
      });
    }
  }

  renderOverlayPanelContent() {
    const { selectedDevice } = this.state;
    const { status_tooltip, device_name } = selectedDevice;
    let statusTooltip = status_tooltip ?? [];

    const getTimeLabel = ({ item }) => {
      const startTime = item.start_time ?? null;
      const endTime = item.end_time ?? null;
      let timeLabel = '';
      if (startTime && endTime) {
        const startDateTime = new Date(startTime);
        const endDateTime = new Date(endTime);
        if (isSameDay(startDateTime, endDateTime)) {
          timeLabel = `${format(
            startDateTime,
            'yyyy年MM月dd日 - HH:mm'
          )} ~ ${format(endDateTime, 'HH:mm')}`;
        } else {
          timeLabel = `${format(
            startDateTime,
            'yyyy年MM月dd日 - HH:mm'
          )} ~ ${format(endDateTime, 'yyyy年MM月dd日 - HH:mm')}`;
        }
      } else {
        if (startTime) {
          const startDateTime = new Date(startTime);
          timeLabel = `${format(startDateTime, 'yyyy年MM月dd日 - HH:mm')} ~ `;
        }

        if (endTime) {
          const endDateTime = new Date(endTime);
          timeLabel = `${timeLabel}${format(
            endDateTime,
            'yyyy年MM月dd日 - HH:mm'
          )}`;
        }

        if (!startTime && !endTime) {
          timeLabel = '';
        }
      }
      return timeLabel;
    };

    return (
      <>
        <h6>{device_name}</h6>
        {statusTooltip.map((item, index) => {
          const timeLabel = getTimeLabel({ item });
          return (
            <div className="row-item" key={`row-item-idx-${index}`}>
              <div className="time-item">{`${timeLabel}`}</div>
              <div className={`status-item status-${item.status}`}>{`${
                item.status ?? ''
              }`}</div>
            </div>
          );
        })}
      </>
    );
  }

  renderDataTableHistory({ tableData }) {
    const { isLoadingHistory } = this.props;

    const statusBodyTemplate = (dataItem, col) => {
      const value = dataItem[col.field];
      return (
        <>
          <span className="p-column-title">{col.header}</span>
          <div className={`status-icon status-${value}`}></div>
        </>
      );
    };
    const deviceNameOnClicked = (e, data) => {
      this.setState({ selectedDevice: data });
      this.overlayRef.current.toggle(e);
    };

    const deviceNameBodyTemplate = (dataItem, col) => {
      const value = dataItem[col.field];
      return (
        <>
          <span className="p-column-title">{col.header}</span>
          <Button
            label={value}
            severity="secondary"
            text
            className="device-name"
            onClick={e => deviceNameOnClicked(e, dataItem)}
          ></Button>
        </>
      );
    };

    let groupedData = {};

    for (let key in tableData) {
      if (tableData.hasOwnProperty(key)) {
        const data = tableData[key];
        const deviceName = key;

        data.forEach(item => {
          if (!groupedData[deviceName]) {
            groupedData[deviceName] = {
              ...item,
              device_name: deviceName,
              status: item.status,
              status_tooltip: [
                {
                  start_time: item.start_time,
                  end_time: item.end_time,
                  status: item.status,
                  device_name: deviceName,
                },
              ],
              last_error_timestamp:
                item.status === 'offline' ? item.end_time : null,
            };
          } else {
            groupedData[deviceName].status_tooltip.push({
              start_time: item.start_time,
              end_time: item.end_time,
              status: item.status,
              device_name: deviceName,
            });
            if (
              item.status === 'offline' &&
              (groupedData[deviceName].last_error_timestamp === null ||
                new Date(item.end_time) >
                  new Date(groupedData[deviceName].last_error_timestamp))
            ) {
              groupedData[deviceName].last_error_timestamp = item.end_time;
            }
            if (
              item.end_time === null ||
              new Date(item.end_time) >
                new Date(groupedData[deviceName].end_time)
            ) {
              groupedData[deviceName].status = item.status;
            }
          }
        });
      }
    }

    let dataHistoryAfterGroup = Object.values(groupedData);

    return (
      <>
        <DataTable
          ref={this.dataHistoryTableRef}
          className="outlook-data-table"
          value={dataHistoryAfterGroup}
          tableStyle={{ width: '100%' }}
          groupRowsBy="device_name"
          size="small"
          stripedRows
          scrollable
          scrollHeight="calc(100vh - 300px)"
          showGridlines
          loading={isLoadingHistory}
          filterDisplay="row"
          filters={this.filters}
          emptyMessage={() => {
            return (
              <div className="flex justify-content-center align-items-center">
                <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
              </div>
            );
          }}
        >
          {this.dataHistoryColumns.map((col, i) => {
            return (
              <Column
                dataType={col.dataType ?? 'string'}
                key={col.field}
                field={col.field}
                header={col.header}
                align={col.align}
                alignHeader={col.align}
                headerClassName={col.headerClassName}
                bodyClassName={col.bodyClassName}
                headerStyle={col.headerStyle}
                bodyStyle={col.bodyStyle}
                filter={col.hasFilter}
                filterMatchMode={'contains'}
                body={dataItem => {
                  switch (col.field) {
                    case 'status':
                      return statusBodyTemplate(dataItem, col);
                    case 'device_name':
                      return deviceNameBodyTemplate(dataItem, col);
                    case 'last_error_timestamp':
                      return (
                        <>
                          <span className="p-column-title">{col.header}</span>
                          <span>
                            {dataItem[col.field] != null
                              ? format(
                                  new Date(dataItem[col.field]),
                                  'yyyy年MM月dd日 - HH:mm'
                                )
                              : null}
                          </span>
                        </>
                      );
                    case 'status_tooltip':
                      return this.renderTooltip(dataItem[col.field]);
                    default:
                      return (
                        <>
                          <span className="p-column-title">{col.header}</span>
                          <span>{dataItem[col.field]}</span>
                        </>
                      );
                  }
                }}
              />
            );
          })}
        </DataTable>
      </>
    );
  }

  calculateOffset({ start_time, end_time, earliestStartTime, latestEndTime }) {
    let startTime = new Date(start_time);
    if (startTime < earliestStartTime) {
      startTime = earliestStartTime;
    }
    let endTime = end_time ? new Date(end_time) : new Date(this.state.endDate);
    if (endTime > latestEndTime) {
      endTime = latestEndTime;
    }
    // Calculate the duration of the current item
    const currentDuration = endTime - startTime;

    // Calculate totalDuration as the time span from earliestStartTime to latestEndTime
    const totalDuration = latestEndTime - earliestStartTime;

    const width = (currentDuration / totalDuration) * 100;

    return {
      width: width,
    };
  }

  renderTooltipContent(tooltipData) {
    const { start_time, end_time, status, device_name } = tooltipData;
    const startTime = format(new Date(start_time), 'MM月dd日 - HH:mm');

    const endTime = end_time
      ? format(new Date(end_time), 'MM月dd日 - HH:mm')
      : '';

    return (
      <div className="tooltip-content device-status-overview-history">
        <div className="tooltip-content-item timestamp">
          <b>{`${startTime} ~ ${endTime}`}</b>
        </div>
        <div className="tooltip-content-item">
          <div style={{ whiteSpace: 'nowrap' }}>{COMMON_TEXT.DEVICE_NAME}</div>
          <b>{`${device_name}`}</b>
        </div>
        <div className="tooltip-content-item">
          <div style={{ whiteSpace: 'nowrap' }}>{COMMON_TEXT.STATUS}</div>
          <b className={`status status-${status}`}>{`${status}`}</b>
        </div>
      </div>
    );
  }

  renderTooltip(statusTooltip) {
    const { startDate, endDate } = this.state;
    const getDurationAndEarliestStart = statusTooltip => {
      let earliestStartTime = new Date(statusTooltip[0].start_time);
      let latestEndTime = new Date(statusTooltip[0].end_time);

      statusTooltip.forEach(item => {
        const startTime = new Date(item.start_time);
        const endTime = item.end_time
          ? new Date(item.end_time)
          : new Date(endDate);

        if (startTime < earliestStartTime) {
          earliestStartTime = startTime;
        }
        if (startTime < startDate) {
          earliestStartTime = startDate;
        }

        if (endTime > latestEndTime) {
          latestEndTime = endTime;
        }
      });

      return { earliestStartTime, latestEndTime };
    };

    const { earliestStartTime, latestEndTime } =
      getDurationAndEarliestStart(statusTooltip);

    const { width: widthBeforeStart } = this.calculateOffset({
      start_time: startDate,
      end_time: earliestStartTime,
      earliestStartTime: startDate,
      latestEndTime: endDate,
    });

    const { width: widthAfterEnd } = this.calculateOffset({
      start_time: latestEndTime,
      end_time: endDate,
      earliestStartTime: startDate,
      latestEndTime: endDate,
    });

    return (
      <div className="status-container">
        <div
          className="child-div status-no-data"
          style={{
            width: `${widthBeforeStart}%`,
          }}
        ></div>
        {statusTooltip.map(item => {
          const { start_time, end_time, status } = item;
          const { width } = this.calculateOffset({
            start_time: start_time,
            end_time: end_time,
            earliestStartTime: startDate,
            latestEndTime: endDate,
          });
          const targetId = generateRandomString(8);

          const childDiv = (
            <Fragment key={`child-div-${targetId}`}>
              <Tooltip
                className="child-div-tooltip"
                target={`.child-div-tooltip-target-${targetId}`}
                baseZIndex={2000}
                autoHide={false}
                position="top"
                event="hover"
              >
                {this.renderTooltipContent(item)}
              </Tooltip>
              <div
                className={`child-div child-div-tooltip-target-${targetId} status-${status}`}
                style={{
                  width: `${width}%`,
                }}
              ></div>
            </Fragment>
          );

          return childDiv;
        })}
        <div
          className="child-div status-no-data"
          style={{
            width: `${widthAfterEnd}%`,
          }}
        ></div>
      </div>
    );
  }

  render() {
    const { isLoadingHistory, dataTableHistory } = this.props;

    const dataByDeviceName = sortObjectKeys(
      convertArrayToObject(dataTableHistory, 'device_name')
    );

    return (
      <div className="device-status-overview-history main-container">
        {isLoadingHistory ? (
          <LoadingSpinner />
        ) : (
          <>
            <OverlayPanel
              className="overlay-panel-device-history"
              ref={this.overlayRef}
              showCloseIcon
              closeOnEscape
              dismissable={true}
            >
              <div className="overlay-panel-content-container">
                {this.renderOverlayPanelContent()}
              </div>
            </OverlayPanel>
            <div className="table-container history">
              {this.renderDataTableHistory({
                tableData: dataByDeviceName,
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

DeviceStatusOverviewHistory.propTypes = {};

export default DeviceStatusOverviewHistory;
