import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import VerkadaEventlog from './VerkadaEventlog';

@connect(state => ({
  querystring: state.querystring,
}))
class VerkadaNetworkEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
    };
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant });
    }
  }

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  render() {
    return (
      <div className="config-container custom-config-container verkada-network-events">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">
              {COMMON_TEXT.VERKADA_NETWORK_EVENTS}
            </div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end"></div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <VerkadaEventlog />
            </>
          )}
        </div>
      </div>
    );
  }
}

VerkadaNetworkEvents.propTypes = {};

export default VerkadaNetworkEvents;
