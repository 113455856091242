import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';

@connect(state => ({
  local: state.local,
}))

/**
 * Renders the current logged username with picture
 */
class LogoMobile extends Component {
  state = {};

  componentDidMount() {
    if (isDesktop) {
      const logoMobileContainer = document.querySelector(
        '.fp-container.logo-mobile'
      );
      if (logoMobileContainer) {
        logoMobileContainer.style.display = 'none';
      }
    }
  }

  render() {
    if (isDesktop) {
      return <></>;
    }
    return (
      <div className="logo-container">
        <div className="logo-content">
          <p className="logo-name">
            <img src="assets/img/cloom_logo.png" alt="Logo" className="logo" />
          </p>
        </div>
      </div>
    );
  }
}

LogoMobile.propTypes = {
  local: PropTypes.object,
};

export default LogoMobile;
