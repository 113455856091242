import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class BCPOverviewEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ?? [],
      dataTableEmployee: this.props.dataTableEmployee ?? [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: this.props.isLoading ?? false,

      textSearchEmployeeName: '',
      textSearchDepartment: '',
      textSearchTeam: '',
      textSearchDeviceType: '',
    };

    // Data get from API
    this.dataEmployee = [];

    this.dataTableEmployeeRef = React.createRef();
    this.filters = {
      employee_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      department: { value: null, matchMode: FilterMatchMode.CONTAINS },
      team: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };

    this.deviceTypeOptions = [
      { label: '全て', value: '' },
      { label: 'スマホ/PC', value: 'phone_pc' },
      { label: 'スマホ', value: 'phone' },
      { label: 'PC', value: 'pc' },
    ];

    this.dataColumns = [
      {
        field: 'employee_name',
        header: COMMON_TEXT.NAME,
        align: 'left',
        headerClassName: 'text-center',
        bodyClassName: 'text-align-left',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '180px',
        },
        bodyStyle: { fontSize: '0.85rem', width: '200px' },
        hasFilter: true,
      },
      {
        field: 'department',
        header: COMMON_TEXT.DEPARTMENT,
        align: 'left',
        headerClassName: 'text-center',
        bodyClassName: 'text-align-left',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '220px',
        },
        bodyStyle: { fontSize: '0.85rem', width: '200px' },
        hasFilter: true,
      },
      {
        field: 'team',
        header: COMMON_TEXT.TEAM,
        align: 'left',
        headerClassName: 'text-center',
        bodyClassName: 'text-align-left',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '200px',
        },
        bodyStyle: { fontSize: '0.85rem', width: '200px' },
        hasFilter: true,
      },
      {
        field: 'device_type',
        header: '端末',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: {
          fontSize: '1rem',
          fontWeight: 'bold',
          height: '40px',
          width: '150px',
        },
        bodyStyle: { fontSize: '0.85rem', width: '150px' },
        hasFilter: true,
      },
    ];
  }

  componentDidMount() {
    const { tenant } = this.state;
    const { data, dataTableEmployee, isLoading } = this.props;

    this.setState({
      tenant,
      data,
      dataTableEmployee,
      isLoading,
      textSearchEmployeeName: '',
      textSearchTeam: '',
      textSearchDepartment: '',
      textSearchDeviceType: '',
    });
    this.dataEmployee = dataTableEmployee;
    this.props.updateFilterDataEmployee({
      dataFilterEmployee: dataTableEmployee,
    });
  }

  componentDidUpdate(prevProps) {
    const { data, dataTableEmployee, isLoading } = this.props;
    const {
      data: prevData,
      dataTableEmployee: prevDataTable,
      isLoading: prevIsLoading,
    } = prevProps;

    const isDataChanged = data !== prevData;
    const isDataTableChanged = dataTableEmployee !== prevDataTable;
    const isLoadingChanged = isLoading !== prevIsLoading;

    if (isDataChanged || isDataTableChanged || isLoadingChanged) {
      this.setState({
        data,
        dataTableEmployee,
        isLoading,
        textSearchEmployeeName: '',
        textSearchTeam: '',
        textSearchDepartment: '',
        textSearchDeviceType: '',
      });
      this.props.updateFilterDataEmployee({
        dataFilterEmployee: dataTableEmployee,
      });
      this.dataEmployee = dataTableEmployee;
    }
  }

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  updateSearchTextChange = ({ value, field }) => {
    const searchText = value;

    let {
      textSearchEmployeeName: currentTextSearchEmployeeName,
      textSearchDepartment: currentTextSearchDepartment,
      textSearchTeam: currentTextSearchTeam,
      textSearchDeviceType: currentTextSearchDeviceType,
    } = this.state;

    let filteredEmployeeByText = cloneDeep(this.dataEmployee);

    switch (field) {
      case 'employee_name':
        currentTextSearchEmployeeName = searchText;
        this.setState({
          textSearchEmployeeName: searchText,
        });
        break;
      case 'department':
        currentTextSearchDepartment = searchText;
        this.setState({
          textSearchDepartment: searchText,
        });
        break;
      case 'device_type':
        currentTextSearchDeviceType = searchText;
        this.setState({
          textSearchDeviceType: searchText,
        });
        break;
      default:
        currentTextSearchTeam = searchText;
        this.setState({
          textSearchTeam: searchText,
        });
        break;
    }

    if (currentTextSearchEmployeeName.length > 0) {
      // filter employee by textSearchEmployeeName
      filteredEmployeeByText = filteredEmployeeByText.filter(
        floor =>
          floor.employee_name != null &&
          floor.employee_name
            .toLocaleLowerCase()
            .includes(currentTextSearchEmployeeName.toLocaleLowerCase())
      );
    }

    if (currentTextSearchDepartment.length > 0) {
      // filter employee by textSearchDepartment
      filteredEmployeeByText = filteredEmployeeByText.filter(
        floor =>
          floor.department != null &&
          floor.department
            .toLocaleLowerCase()
            .includes(currentTextSearchDepartment.toLocaleLowerCase())
      );
    }

    if (currentTextSearchTeam.length > 0) {
      // filter employee by textSearchTeam
      filteredEmployeeByText = filteredEmployeeByText.filter(
        floor =>
          floor.team != null &&
          floor.team
            .toLocaleLowerCase()
            .includes(currentTextSearchTeam.toLocaleLowerCase())
      );
    }

    if (currentTextSearchDeviceType.length > 0) {
      // filter employee by currentTextSearchDeviceType
      filteredEmployeeByText = filteredEmployeeByText.filter(
        floor =>
          floor.device_type != null &&
          floor.device_type
            .toLocaleLowerCase()
            .includes(currentTextSearchDeviceType.toLocaleLowerCase())
      );
    }

    let newDataList = filteredEmployeeByText.sort((a, b) => {
      const aName = a['employee_name'] ?? '';
      const bName = b['employee_name'] ?? '';
      return aName.localeCompare(bName, 'ja');
    });

    this.setState({
      dataTableEmployee: newDataList,
    });
    this.props.updateFilterDataEmployee({
      dataFilterEmployee: newDataList,
    });
  };

  renderFilterSearch = options => {
    const {
      textSearchEmployeeName,
      textSearchDepartment,
      textSearchTeam,
      textSearchDeviceType,
    } = this.state;
    let textValue = '';
    switch (options.field) {
      case 'employee_name':
        textValue = textSearchEmployeeName;
        break;
      case 'department':
        textValue = textSearchDepartment;
        break;
      default:
        textValue = textSearchTeam;
        break;
    }

    if (options.field === 'device_type') {
      return (
        <Dropdown
          value={textSearchDeviceType}
          options={this.deviceTypeOptions}
          onChange={e =>
            this.updateSearchTextChange({
              value: e.value,
              field: options.field,
            })
          }
        />
      );
    }

    return (
      <div
        className="flex justify-content-between"
        style={{ alignItems: 'center' }}
      >
        <InputText
          value={textValue}
          onChange={e =>
            this.updateSearchTextChange({
              value: e.target.value,
              field: options.field,
            })
          }
        />
      </div>
    );
  };

  renderDataTable({ tableData }) {
    const { isLoading } = this.state;
    const { selectedFloor } = this.props;

    tableData = tableData.sort((a, b) => {
      if (a.department < b.department) return -1;
      if (a.department > b.department) return 1;
      if (a.team < b.team) return -1;
      if (a.team > b.team) return 1;
      return 0;
    });

    return (
      <>
        <DataTable
          ref={this.dataTableEmployeeRef}
          className="outlook-data-table"
          value={tableData}
          tableStyle={{ width: '100%' }}
          size="small"
          stripedRows
          scrollable
          scrollHeight={`calc(100vh - 220px)`}
          showGridlines
          loading={isLoading}
          filterDisplay="row"
          filters={this.filters}
          emptyMessage={() => {
            if (selectedFloor == null) {
              return (
                <div className="flex justify-content-center align-items-center">
                  <Message text={COMMON_TEXT.EMPLOYEE_BCP_SELECT} />
                </div>
              );
            }
            return (
              <div className="flex justify-content-center align-items-center">
                <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
              </div>
            );
          }}
        >
          {this.dataColumns.map(col => {
            return (
              <Column
                dataType={col.dataType ?? 'string'}
                key={col.field}
                field={col.field}
                header={col.header}
                align={col.align}
                alignHeader={col.align}
                headerClassName={col.headerClassName}
                bodyClassName={col.bodyClassName}
                headerStyle={col.headerStyle}
                bodyStyle={col.bodyStyle}
                filter={col.hasFilter}
                filterMatchMode={'contains'}
                filterElement={options => this.renderFilterSearch(options)}
                body={dataItem => {
                  switch (col.field) {
                    case 'device_type':
                      let text = 'スマホ/PC';
                      if (dataItem[col.field] === 'pc') {
                        text = 'PC';
                      } else if (dataItem[col.field] === 'phone') {
                        text = 'スマホ';
                      }
                      return text;
                    default:
                      return dataItem[col.field];
                  }
                }}
              />
            );
          })}
        </DataTable>
      </>
    );
  }

  render() {
    const { isLoading, dataTableEmployee } = this.state;

    return (
      <div className="bcp-overview-employee overview-employee-container">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="table-container">
              {this.renderDataTable({
                tableData: dataTableEmployee,
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

BCPOverviewEmployee.propTypes = {
  selectedFloor: PropTypes.object,
  dataTableEmployee: PropTypes.array,

  updateFilterDataEmployee: PropTypes.func,
};

export default BCPOverviewEmployee;
