import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE, MEMORY_STORE } from '@/helpers/constants';
import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import BCPOverviewFloor from './BCPOverviewFloor';
import BCPOverviewEmployee from './BCPOverviewEmployee';
import {
  fetchDataBCPFloorByTenantId,
  fetchDataEmployeeByFloorId,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class BCPOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],

      // Data table
      dataTableFloor: [],
      dataTableSelectedEmployee: [],
      dataFilterEmployee: [],
      selectedFloor: null,
      isLoading: false,
    };

    // Data get from API
    this.dataEmployee = [];
    this.copyToast = React.createRef();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.fetchDataByTenantId();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.fetchDataByTenantId();
      });
    }
  }

  fetchDataByTenantId = async () => {
    const { tenant } = this.state;
    if (!tenant) {
      return;
    }
    this.setState({ isLoading: true });

    // Fetch bcp_floor by tenant id
    let dataBCPFloorList = await fetchDataBCPFloorByTenantId({
      tenantId: tenant,
    });
    if (!dataBCPFloorList) {
      dataBCPFloorList = [];
    }

    // Get data branches in session store
    let dataBranchList = this.props.sessionStore?.[MEMORY_STORE.BRANCHES] ?? [];
    if (!dataBranchList) {
      dataBranchList = [];
    }

    // Get data employees in session store
    let dataFloorListByBranchIdList =
      this.props.sessionStore?.[MEMORY_STORE.FLOORS] ?? [];
    if (!dataFloorListByBranchIdList) {
      dataFloorListByBranchIdList = [];
    }

    // Mapping branch_name
    dataBCPFloorList.map(item => {
      const find = dataBranchList.find(e => e.branch_id === item.branch_id);
      if (find) {
        item.branch_name = find.branch_name;
        return true;
      }
      return false;
    });

    // Mapping floor_name
    dataBCPFloorList.map(item => {
      const find = dataFloorListByBranchIdList.find(
        e => e.branch_id === item.branch_id && e.floor_id === item.floor_id
      );
      if (find) {
        item.floor_name = find.floor_name;
        return true;
      }
      return false;
    });

    let newDataFloorList = dataBCPFloorList.sort((a, b) => {
      const aName = a['branch_name'] ?? '';
      const bName = b['branch_name'] ?? '';
      return aName.localeCompare(bName, 'ja');
    });

    // Filter floor id list
    const floorIdList = dataBCPFloorList.map(floor => +floor['floor_id']);

    // Fetch data employee by floor id list
    let dataEmployeeList = await fetchDataEmployeeByFloorId({
      floorIds: floorIdList,
    });
    if (!dataEmployeeList) {
      dataEmployeeList = [];
    }

    const mergedData = [];
    let idMap = {};

    const newData = dataEmployeeList;
    newData.forEach(item => {
      const { employee_id, device_type } = item;

      // If id already exists in idMap
      if (idMap[employee_id]) {
        // Check if type is different
        if (idMap[employee_id].device_type !== device_type) {
          idMap[employee_id].device_type = 'phone_pc'; // Merge type value to "phone_pc"
        }
      } else {
        // If id does not exist, add new one to idMap
        idMap[employee_id] = item;
      }
    });

    // Convert idMap to result array
    for (const key in idMap) {
      mergedData.push(idMap[key]);
    }

    this.dataEmployee = mergedData;

    this.setState({
      data: newDataFloorList,
      dataTableFloor: newDataFloorList,
      dataTableSelectedEmployee: [],
      isLoading: false,
      selectedFloor: null,
    });
  };

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  refreshData() {
    this.fetchDataByTenantId();
  }

  renderRefreshButton() {
    const { isLoading } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => this.refreshData()}
      />
    );
  }

  showErrorMessage = () => {
    this.copyToast.current.show({
      severity: 'error',
      detail: COMMON_TEXT.EMPLOYEE_BCP_SELECT,
      life: 2000,
      content: props => (
        <div className="">
          <span>{props.message.detail}</span>
        </div>
      ),
    });
  };

  csvDownload = () => {
    const { selectedFloor, dataFilterEmployee } = this.state;
    if (!selectedFloor) {
      return this.showErrorMessage();
    }
    const csvData = this.transformToCsvData({
      data: dataFilterEmployee,
    });
    const downloadLink = document.createElement('a');
    downloadLink.textContent = 'download';
    const filename = `BCP${COMMON_TEXT.COMPANY_EMPLOYEE_SETTING}_${format(
      new Date(),
      'yyyyMMddHHmm'
    )}`;
    downloadLink.download = filename + '.csv';
    downloadLink.href =
      'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvData);
    downloadLink.click();
  };

  renderDownloadButton() {
    const { isLoading } = this.state;
    return (
      <>
        <Button
          severity="info"
          type="button"
          label={COMMON_TEXT.DOWNLOAD}
          className="submit-button csv-download has-shadow"
          loading={isLoading}
          onClick={e => this.csvDownload()}
        />
      </>
    );
  }

  transformToCsvData = ({ data }) => {
    let csvRows = [];
    const headerData = {
      employee_name: COMMON_TEXT.NAME,
      department: COMMON_TEXT.DEPARTMENT,
      team: COMMON_TEXT.TEAM,
      device_type: '端末',
    };
    const headersKey = Object.keys(headerData);

    // Create CSV Header
    const headerValues = Object.values(headerData);

    csvRows.push(headerValues.join(','));

    if (!data || data.length === 0) {
      return csvRows.join('\n');
    }

    // Create CSV Data
    for (const row of data) {
      const values = headersKey.map(e => {
        if (row[e] === 'pc') {
          return 'PC';
        }
        if (row[e] === 'phone') {
          return 'スマホ';
        }
        if (row[e] === 'phone_pc') {
          return 'スマホ/PC';
        }
        return row[e];
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  updateSelectedFloor = ({ floor }) => {
    const dataEmployee = cloneDeep(this.dataEmployee);
    if (!dataEmployee || dataEmployee.length === 0) {
      this.setState({
        selectedFloor: floor,
        dataTableSelectedEmployee: [],
      });
      return;
    }
    const filterEmployeeByFloor = dataEmployee
      .filter(emp => emp.floor_id === floor.floor_id)
      .sort((a, b) => {
        const aName = a['employee_name'] ?? '';
        const bName = b['employee_name'] ?? '';
        return aName.localeCompare(bName, 'ja');
      });

    this.setState({
      selectedFloor: floor,
      dataTableSelectedEmployee: filterEmployeeByFloor,
    });
  };

  updateFilterDataEmployee = ({ dataFilterEmployee }) => {
    this.setState({
      dataFilterEmployee,
    });
  };

  render() {
    const {
      data,
      dataTableFloor,
      dataTableSelectedEmployee,
      selectedFloor,
      isLoading,
    } = this.state;

    return (
      <div>
        <Toast ref={this.copyToast} position="top-right" />
        <div className="config-container custom-config-container bcp-overview">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.BCP}</div>
            </div>
            <div className="config-custom-container">
              <div className="btn-download">{this.renderDownloadButton()}</div>
              <div className="">{this.renderRefreshButton()}</div>
            </div>
          </div>
          <div className="config-content">
            {this.renderMessageError() || (
              <div className="main-container">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <BCPOverviewFloor
                      dataTableFloor={dataTableFloor}
                      data={data}
                      isLoading={isLoading}
                      updateSelectedFloor={this.updateSelectedFloor}
                    ></BCPOverviewFloor>
                    <BCPOverviewEmployee
                      dataTableEmployee={dataTableSelectedEmployee}
                      data={data}
                      isLoading={isLoading}
                      selectedFloor={selectedFloor}
                      updateFilterDataEmployee={this.updateFilterDataEmployee}
                    ></BCPOverviewEmployee>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BCPOverview.propTypes = {};

export default BCPOverview;
