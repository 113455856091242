/**
 *
 * AreaMarker
 *
 */

import AreaMarker from './AreaMarker.jsx';
import './AreaMarker.less';

export default AreaMarker;
