import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { COMMON_TEXT } from '@/helpers/common-text';
import { fetchEmployees, fetchVerkadaLogCsv } from './query-request';
import { format, isBefore, sub, add } from 'date-fns';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { OrderList } from 'primereact/orderlist';

class CsvExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant: null,
      employeeList: [],
      filterEmployeeList: [],
      selectedEmployeeList: [],
      startDate: new Date(),
      endDate: new Date(),
      selectedOption: 'clock-in-out-time',
      isSelectAllEmployee: false,
      isDownloadingCsv: false,
      isShowDialog: false,
      listColumn: [
        { name: '日付', code: 'date', checked: true },
        { name: '区分', code: 'day_type', checked: true },
        { name: '社員名', code: 'employee_name', checked: true },
        { name: '社員番号', code: 'employee_number', checked: true },
        { name: '出勤', code: 'first', checked: true },
        { name: '退勤', code: 'last', checked: true },
        { name: '曜日', code: 'weekday', checked: true },
      ],
      listTargetColumn: [],
    };
    this.outputOption = [{ label: '出勤退勤時間', value: 'clock-in-out-time' }];
  }

  componentDidMount() {
    const { tenant } = this.props;
    if (tenant) {
      this.getEmployeeList({ tenantId: tenant });
    }
    this.setState({
      listTargetColumn: this.state.listColumn,
    });
  }

  componentDidUpdate(prevProps) {
    const { tenant } = this.props;
    const { tenant: prevTenant } = prevProps;
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {});
      this.getEmployeeList({ tenantId: tenant });
    }
  }

  getEmployeeList = async ({ tenantId }) => {
    const employeeRes = await fetchEmployees({
      tenantId,
    });
    this.setState({ employeeList: employeeRes });
  };

  renderCsvContent = () => {
    const {
      employeeList,
      selectedOption,
      filterEmployeeList,
      selectedEmployeeList,
      startDate,
      endDate,
      isDownloadingCsv,
      isShowDialog,
      isSelectAllEmployee,
      listColumn,
      listTargetColumn,
    } = this.state;

    const search = event => {
      let _filteredEmployees;
      if (!event.query.trim().length) {
        _filteredEmployees = [...employeeList];
      } else {
        _filteredEmployees = employeeList.filter(employee => {
          return employee.employee_name.includes(event.query);
        });
      }
      this.setState({
        filterEmployeeList: _filteredEmployees,
      });
    };

    const addSelectedEmployee = e => {
      let isAlreadySelectedEmployee = selectedEmployeeList.filter(
        employee => employee.employee_id === e.value.employee_id
      );
      if (isAlreadySelectedEmployee.length <= 0) {
        this.setState({
          selectedEmployeeList: [...selectedEmployeeList, e.value],
        });
      }
    };

    const deleteSelectedEmployee = selectedEmployee => {
      this.setState({
        selectedEmployeeList: selectedEmployeeList.filter(
          employee => employee.employee_id !== selectedEmployee.employee_id
        ),
      });
    };

    const csvMaker = data => {
      let csvRows = [];
      const headerData = {
        date: '日付',
        weekday: '曜日',
        day_type: '区分',
        employee_number: '社員番号',
        employee_name: '社員名',
        first: '出勤',
        last: '退勤',
      };
      // Filter headers based on target column
      const headers = listTargetColumn.map(t => t.code);
      // Create CSV Header
      const headerValues = headers.map(e => {
        return headerData[e];
      });
      csvRows.push(headerValues.join(','));
      // Create CSV Data
      for (const row of data) {
        const values = headers.map(e => {
          if (e === 'date') {
            return format(new Date(row[e]), 'yyyy/MM/dd');
          }
          if (e === 'first' || e === 'last') {
            let date;
            if (row[e].includes('-') || row[e].includes('/')) {
              // If the string includes '-', it's a full date-time string
              date = new Date(row[e]);
              const hours = date.getHours().toString().padStart(2, '0');
              const minutes = date.getMinutes().toString().padStart(2, '0');
              return `${hours}:${minutes}`;
            } else {
              // Otherwise, it's a time string
              const [hours, minutes] = row[e].split(':');
              return `${hours}:${minutes}`;
            }
          }
          return row[e];
        });
        csvRows.push(values.join(','));
      }
      return csvRows.join('\n');
    };

    const csvDownload = data => {
      const downloadLink = document.createElement('a');
      downloadLink.textContent = 'download';
      const filename = this.outputOption.find(
        option => option.value === selectedOption
      ).label;
      downloadLink.download = filename + '.csv';
      downloadLink.href =
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data);
      downloadLink.click();
    };

    const CsvDownloadFormSchema = Yup.object().shape({
      employee: Yup.array().min(1, COMMON_TEXT.REQUIRED),
      startDate: Yup.string().nullable().required(COMMON_TEXT.REQUIRED),
      endDate: Yup.string().nullable().required(COMMON_TEXT.REQUIRED),
    });

    const getVerkadaLogCsv = async ({ employeeIdList, selectedDateList }) => {
      let csvRes = await fetchVerkadaLogCsv({
        employeeIdList,
        selectedDateList,
      });
      csvRes = csvRes ?? [];
      return csvRes;
    };

    const handleSubmit = async (values, { resetForm }) => {
      this.setState({
        isDownloadingCsv: true,
      });
      const { employee, startDate, endDate } = values;
      // get list of date in format yyyy-MM-dd from startDate to endDate using date-fns
      const dateList = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dateList.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate = add(currentDate, { days: 1 });
      }
      const employeeIdList = Array.from(
        new Set(employee.map(employee => employee.employee_id + ''))
      );

      const csvRes = await getVerkadaLogCsv({
        employeeIdList,
        selectedDateList: dateList,
      });

      if (csvRes.length > 0) {
        const csvdata = csvMaker(csvRes);

        csvDownload(csvdata);
      } else {
        this.setState({
          isShowDialog: true,
        });
      }

      this.setState({
        isDownloadingCsv: false,
      });
    };

    const isFormFieldInvalid = (fieldName, touched, errors, styles) => {
      return touched[fieldName] && errors[fieldName]
        ? `p-invalid ${styles}`
        : styles;
    };

    const getFormErrorMessage = (name, errors, touched) => {
      return isFormFieldInvalid(name, touched, errors) ? (
        <div className="validation-error-message">{errors[name]}</div>
      ) : (
        <></>
      );
    };

    const calculateStartDate = e => {
      this.setState({ startDate: e.value });
      const dayAfterThirtyFromStartDate = add(e.value, { days: 30 });
      if (isBefore(dayAfterThirtyFromStartDate, this.state.endDate)) {
        this.setState({ endDate: dayAfterThirtyFromStartDate });
      }
    };

    const calculateEndDate = e => {
      this.setState({ endDate: e.value });
      const dayBeforeThirtyFromEndDate = sub(e.value, { days: 30 });
      if (isBefore(this.state.startDate, dayBeforeThirtyFromEndDate)) {
        this.setState({ startDate: dayBeforeThirtyFromEndDate });
      }
    };

    const selectAllEmployeeOption = e => {
      if (e.checked) {
        this.setState({
          selectedEmployeeList: this.state.employeeList,
          isSelectAllEmployee: e.checked,
        });
      } else {
        this.setState({
          selectedEmployeeList: [],
          isSelectAllEmployee: e.checked,
        });
      }
    };
    const handleCheckboxChange = (e, data) => {
      let index = listColumn.findIndex(item => item.code === data);
      listColumn[index].checked = e.checked;

      let checkList = listColumn.filter(item => item.checked === true);
      let newListColumn = [...listColumn];
      newListColumn.sort((a, b) => b.checked - a.checked);
      this.setState({
        listTargetColumn: checkList,
        newListColumn: newListColumn,
      });
    };

    const onChangeOrderList = newListColumn => {
      newListColumn.sort((a, b) => b.checked - a.checked);
      this.setState({
        listColumn: newListColumn,
        listTargetColumn: newListColumn.filter(item => item.checked === true),
      });
    };

    const itemTemplate = item => {
      return (
        <div className="select-column">
          <div className="left-item">
            <Checkbox
              onChange={e => handleCheckboxChange(e, item.code)}
              checked={item.checked}
              className="checkbox"
            ></Checkbox>
            <div key={item.code} className="p-clearfix">
              {item.name}
            </div>
          </div>
          <i
            className="pi pi-bars"
            style={{ fontSize: '1rem', paddingLeft: '4px' }}
          ></i>
        </div>
      );
    };

    return (
      <Formik
        initialValues={{ employee: selectedEmployeeList, startDate, endDate }}
        validationSchema={CsvDownloadFormSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, touched, errors }) => (
          <Form>
            <div className="flex mb-4">
              <div className="input-container">
                <div className="label">出力するログ</div>
                <Dropdown
                  value={selectedOption}
                  options={this.outputOption}
                  className="drop-down-textfield-style"
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="input-container">
                <div className="label">開始日</div>
                <Calendar
                  value={values.startDate}
                  name="startDate"
                  onChange={calculateStartDate}
                  className={isFormFieldInvalid(
                    'startDate',
                    touched,
                    errors,
                    'calendar-style'
                  )}
                  locale="ja"
                  dateFormat="yy年mm月dd日"
                  maxDate={new Date()}
                  readOnlyInput
                  showIcon
                />
                {getFormErrorMessage('startDate', errors, touched)}
              </div>
              <div className="input-container">
                <div className="label">終了日</div>
                <Calendar
                  value={values.endDate}
                  name="endDate"
                  onChange={calculateEndDate}
                  className={isFormFieldInvalid(
                    'endDate',
                    touched,
                    errors,
                    'calendar-style'
                  )}
                  locale="ja"
                  dateFormat="yy年mm月dd日"
                  maxDate={new Date()}
                  readOnlyInput
                  showIcon
                />
                {getFormErrorMessage('endDate', errors, touched)}
              </div>
              <div className="input-container mt-4" style={{ width: '100%' }}>
                <div className="btn-container">
                  <Button
                    type="submit"
                    severity="info"
                    label="ダウンロード"
                    className="btn-style"
                    loading={isDownloadingCsv}
                  />
                </div>
              </div>
            </div>
            <div className="flex mb-4">
              <div className="input-container">
                <div className="label select-all-employee-div">
                  <div>社員名</div>
                  <div>
                    <Checkbox
                      onChange={e => selectAllEmployeeOption(e)}
                      checked={isSelectAllEmployee}
                      className="custom-checkbox"
                    ></Checkbox>
                    <label htmlFor="selectallemployee" className="ml-2">
                      全員を出力
                    </label>
                  </div>
                </div>
                <AutoComplete
                  field="employee_name"
                  name="employee"
                  suggestions={filterEmployeeList}
                  completeMethod={search}
                  onSelect={addSelectedEmployee}
                  disabled={isSelectAllEmployee}
                  className={isFormFieldInvalid(
                    'employee',
                    touched,
                    errors,
                    'auto-complete-style'
                  )}
                  delay={800}
                  dropdownIcon="pi pi-search"
                  dropdown
                />
                {getFormErrorMessage('employee', errors, touched)}
                {!isSelectAllEmployee ? (
                  <div className="selected-employee-container mb-4">
                    {selectedEmployeeList.map((employee, index) => {
                      return (
                        <div className="employee-list" key={index}>
                          <div className="employee-name">
                            {employee.employee_name}
                          </div>
                          <Button
                            type="button"
                            icon="pi pi-trash"
                            text
                            severity="danger"
                            className="icon-only-delete-button"
                            onClick={() => deleteSelectedEmployee(employee)}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <OrderList
                dataKey="code"
                value={listColumn}
                onChange={e => onChangeOrderList(e.value)}
                itemTemplate={itemTemplate}
                dragdrop
                header="出力する列"
              ></OrderList>
            </div>
            <Dialog
              header="No Log Data"
              visible={isShowDialog}
              style={{ width: '320px' }}
              onHide={() => this.setState({ isShowDialog: false })}
              footer={
                <Button
                  label="OK"
                  severity="info"
                  onClick={() => this.setState({ isShowDialog: false })}
                ></Button>
              }
            >
              <Message
                className="w-full"
                severity="warn"
                text="There is no log data"
              ></Message>
            </Dialog>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    return (
      <>
        <div className="csv-export">
          <Accordion>
            <AccordionTab header={COMMON_TEXT.CSV_EXPORT}>
              {this.renderCsvContent()}
            </AccordionTab>
          </Accordion>
        </div>
      </>
    );
  }
}

CsvExport.propTypes = {};

export default CsvExport;
