/**
 *
 * MeetingRoomUsage
 *
 */

import MeetingRoomUsage from './MeetingRoomUsage.jsx';
import './MeetingRoomUsage.less';

export default MeetingRoomUsage;
