import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryEmployeesLite = tenantId => {
  // query for display table
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        department: ['select'],
        employee_id: ['select'],
        employee_name: ['select'],
        team: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryEmployees = tenantId => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        employee_id: ['select'],
        employee_name: ['select'],
        employee_identifer: ['select'],
        chat_tools: ['select'],
        team: ['select'],
        employee_image: ['select'],
        tenant_id: ['select'],
        employee_number: ['select'],
        department: ['select'],
        phone_number: ['select'],
        email: ['select'],
        url_teams: ['select'],
        branch_id: ['select'],
        branch_name: ['select'],
        floor_name: ['select'],
        floor_id: ['select'],
        employee_name_reading: ['select'],
        phone_number2: ['select']
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_id: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryEmployee = ({ employeeId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        employee_id: ['select'],
        employee_name: ['select'],
        employee_identifer: ['select'],
        team: ['select'],
        chat_tools: ['select'],
        employee_image: ['select'],
        tenant_id: ['select'],
        employee_number: ['select'],
        department: ['select'],
        phone_number: ['select'],
        email: ['select'],
        url_teams: ['select'],
        branch_id: ['select'],
        branch_name: ['select'],
        floor_name: ['select'],
        floor_id: ['select'],
        employee_name_reading: ['select'],
        phone_number2: ['select']
      },
      skip: 0,
    },
    filter: {
      employee_id: [employeeId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryEmployeeDevices = ({ employeeId }) => {
  const randomId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        device_type: ['select'],
        employee_device_mac: ['select'],
        employee_device_name: ['select'],
        employee_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      employee_id: [employeeId, randomId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_device_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEE_DEVICES,
  };
};

const buildQueryEmployeeDevicesByEmployeeIdList = ({ employeeIdList }) => {
  const randomId = getRandomNumber900000to999999();
  employeeIdList = employeeIdList || [];
  employeeIdList.push(randomId);
  return {
    data: {
      fields: {
        device_type: ['select'],
        employee_device_mac: ['select'],
        employee_device_name: ['select'],
        employee_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      employee_id: employeeIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_device_name: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEE_DEVICES,
  };
};

export const fetchEmployeeDeviceListByEmployeeId = async payload => {
  const { employeeId } = payload;
  const request = buildQueryEmployeeDevices({ employeeId });
  return executeQuery(request);
};

export const fetchEmployees = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployeesLite(tenantId);
  return executeQuery(request);
};

export const fetchAllEmployees = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployees(tenantId);
  return executeQuery(request);
};

export const fetchAllEmployeesDeviceByEmployeeIdList = async payload => {
  const { employeeIdList } = payload;
  const request = buildQueryEmployeeDevicesByEmployeeIdList({ employeeIdList });
  return executeQuery(request);
};

export const fetchEmployeeByEmployeeId = async payload => {
  const { employeeId } = payload;
  const request = buildQueryEmployee({ employeeId });
  return executeQuery(request);
};
