import { COMMON_TEXT } from '@/helpers/common-text';
import { format } from 'date-fns';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect } from 'react';
// import { useSelector } from 'react-redux';

const EmployeeDetailMarkerRender = React.memo(({ item }) => {
  const { employee_name, department, team, timestamp, employee_image_url, employee_device_name, employee_id } = item;
  // const querystring = useSelector(state => state.querystring);
  // const sessionStore = useSelector(state => state.session);

  const handleUnmount = useCallback(() => {
    // write your code here
    // this is equivalent to componentWillUnmount
  }, []);

  useEffect(() => {
    // write your code here
    // this is equivalent to componentDidMount
    return handleUnmount;
  }, [handleUnmount]);

  return (
    <div
      className={`custom-marker marker-employee-info-target-${employee_id}`}
      style={{ backgroundColor: 'white', width: 40, height: 40 }}
    >
      <div className="face-image">
        <img
          src={`${employee_image_url}`}
          alt="employee"
          width="40"
          height="40"
        />
      </div>
      <Tooltip
        className="custom-marker-tooltip"
        target={`.marker-employee-info-target-${employee_id}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.EMPLOYEE_NAME}</div>
            <div className="text-value">
              <b>{employee_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEPARTMENT}</div>
            <div className="text-value">
              <b>{department}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.TEAM}</div>
            <div className="text-value">
              <b>{team}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEVICE_NAME}</div>
            <div className="text-value">
              <b>{employee_device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{format(new Date(timestamp), 'MM月dd日 - HH:mm')}</b>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
});

export default EmployeeDetailMarkerRender;