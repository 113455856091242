/**
 *
 * ConfigUpdateStatus
 *
 */

import ConfigUpdateStatus from './ConfigUpdateStatus.jsx';
import './ConfigUpdateStatus.less';

export default ConfigUpdateStatus;
