/**
 *
 * FloorSetting
 *
 */

import FloorSetting from './FloorSetting.jsx';
import './FloorSetting.less';

export default FloorSetting;
