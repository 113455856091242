/**
 *
 * OrganizationSetting
 *
 */

import OrganizationSetting from './OrganizationSetting.jsx';
import './OrganizationSetting.less';

export default OrganizationSetting;
