/**
 *
 * AirQuality
 *
 */

import AirQuality from './AirQuality.jsx';
import './AirQuality.less';

export default AirQuality;
