/**
 *
 * DeviceStatusOverviewHistory
 *
 */

import DeviceStatusOverviewHistory from './DeviceStatusOverviewHistory.jsx';
import './DeviceStatusOverviewHistory.less';

export default DeviceStatusOverviewHistory;
