import { COMMON_TEXT } from '@/helpers/common-text';
import { API_UPDATE_STATUS, SESSION_STORAGE_KEY } from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSettingUpdateStatus } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class ConfigUpdateStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingUpdateStatus: false,
    };
    this.toastRef = React.createRef();
  }

  componentDidMount() {
    this.fetchSettingUpdateResult({ isFirst: true });
  }

  componentDidUpdate(prevProps) {}

  saveExecutionToSessionStorage = ({ executionList }) => {
    this.props.dispatch(
      sessionStoreActionSet(
        SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
        executionList
      )
    );
  };

  showSuccessToast = () => {
    this.toastRef.current.show({
      severity: 'success',
      summary: '成功',
      detail: '設定が変更されました',
      life: 3000,
      closable: true,
      icon: 'pi pi-exclamation-circle',
    });
  };

  showInfoToast = ({ infoMessage }) => {
    this.toastRef.current.show({
      severity: 'info',
      summary: COMMON_TEXT.INFO,
      detail: infoMessage,
      life: 3000,
      closable: true,
      icon: 'pi pi-exclamation-circle',
    });
  };

  showErrorToast = ({ errorMessage }) => {
    this.toastRef.current.show({
      severity: 'error',
      summary: 'エラー',
      detail: `変更に失敗しました. ${errorMessage}`,
      life: 3000,
      closable: true,
      icon: 'pi pi-exclamation-circle',
    });
  };

  fetchSettingUpdateResult = async ({ isFirst = false }) => {
    const { sessionStore } = this.props;
    const sessionExecutionList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (!sessionExecutionList || sessionExecutionList.length === 0) {
      this.updateConfigStatus({ isActionUpdating: false });
      return;
    }
    const sessionExecutionIdList = sessionExecutionList.map(item => item.id);
    this.setState({ isFetchingUpdateStatus: true });
    let updateSettingStatus = await fetchSettingUpdateStatus({
      executionIdList: sessionExecutionIdList,
    });
    updateSettingStatus = updateSettingStatus || [];

    if (sessionExecutionIdList.length > 0 && updateSettingStatus.length === 0) {
      // Find expired execution_id
      const expiredExecutionList = [];
      const notExpiredExecutionList = [];
      sessionExecutionList.forEach(item => {
        const { expiredAt } = item;
        if (expiredAt && expiredAt < Date.now()) {
          expiredExecutionList.push(item);
        } else {
          notExpiredExecutionList.push(item);
        }
      });
      if (
        expiredExecutionList.length > 0 &&
        notExpiredExecutionList.length === 0
      ) {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          this.saveExecutionToSessionStorage({
            executionList: notExpiredExecutionList,
          });
          this.showErrorToast({ errorMessage: COMMON_TEXT.REQUEST_TIME_OUT });
          this.updateConfigStatus({ isActionUpdating: false });
        });
      } else if (
        expiredExecutionList.length > 0 &&
        notExpiredExecutionList.length > 0
      ) {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          this.saveExecutionToSessionStorage({
            executionList: notExpiredExecutionList,
          });
          this.showErrorToast({ errorMessage: COMMON_TEXT.REQUEST_TIME_OUT });
          this.updateConfigStatus({ isActionUpdating: true });
        });
      } else if (expiredExecutionList.length === 0) {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          if (!isFirst) {
            this.showInfoToast({ infoMessage: COMMON_TEXT.NO_UPDATE });
          }
        });
      }
      return;
    }

    // construct status map for each execution_id, values are status list [SUCCESS, FAILED, PROCESSING]
    const statusMap = {};
    for (let item of updateSettingStatus) {
      const { execution_id, status } = item;
      if (!statusMap[execution_id]) {
        statusMap[execution_id] = [];
      }
      statusMap[execution_id].push(status);
    }
    // check if all status is success
    const isAllExecutionIdSuccess = Object.keys(statusMap).every(item =>
      statusMap[item].includes(API_UPDATE_STATUS.SUCCESS)
    );
    // check if there is any failed
    const hasExecutionIdFailed = Object.keys(statusMap).some(item =>
      statusMap[item].includes(API_UPDATE_STATUS.FAILED)
    );
    if (isAllExecutionIdSuccess) {
      this.setState({ isFetchingUpdateStatus: false }, () => {
        this.showSuccessToast();
        this.saveExecutionToSessionStorage({ executionList: [] });
        this.updateConfigStatus({ isActionUpdating: false });
      });
    } else if (hasExecutionIdFailed) {
      // Remove expired execution_id and success or failed execution_id
      const inProcessExecutionList = sessionExecutionList.filter(item => {
        const { id: executionId, expiredAt } = item;
        const isExpired = expiredAt && expiredAt > Date.now();
        // Filter out expired execution_id
        if (isExpired) {
          return false;
        }
        const statusList = statusMap[executionId];
        // Filter out success or failed execution_id
        if (
          statusList.includes(API_UPDATE_STATUS.SUCCESS) ||
          statusList.includes(API_UPDATE_STATUS.FAILED)
        ) {
          return false;
        }
        return true;
      });
      // get error message
      const errorMessages = updateSettingStatus.filter(item => {
        const { status } = item;
        return status === API_UPDATE_STATUS.FAILED;
      });
      let errorMessage = '';
      if (errorMessages && errorMessages.length > 0) {
        // merge all error message
        errorMessage = errorMessages.map(item => item.error_message).join(', ');
      }
      this.setState({ isFetchingUpdateStatus: false }, () => {
        this.showErrorToast({ errorMessage });
        this.saveExecutionToSessionStorage({
          executionList: inProcessExecutionList,
        });
        this.updateConfigStatus({ isActionUpdating: false });
      });
    } else {
      // Remove expired execution_id and success or failed execution_id
      const notExpiredExecutionList = sessionExecutionList.filter(item => {
        const { expiredAt } = item;
        const isExpired = expiredAt && expiredAt > Date.now();
        return !isExpired;
      });
      const isAllExpired = sessionExecutionList.every(item => {
        const { expiredAt } = item;
        return expiredAt && expiredAt < Date.now();
      });
      if (notExpiredExecutionList.length === 0) {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          this.saveExecutionToSessionStorage({
            executionList: [],
          });
          this.updateConfigStatus({ isActionUpdating: false });
        });
      } else if (isAllExpired) {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          this.showErrorToast({ errorMessage: COMMON_TEXT.REQUEST_TIME_OUT });
          this.saveExecutionToSessionStorage({
            executionList: [],
          });
          this.updateConfigStatus({ isActionUpdating: false });
        });
      } else {
        this.setState({ isFetchingUpdateStatus: false }, () => {
          this.showErrorToast({ errorMessage: COMMON_TEXT.REQUEST_TIME_OUT });
          this.saveExecutionToSessionStorage({
            executionList: notExpiredExecutionList,
          });
        });
      }
    }
  };

  updateConfigStatus = ({ isActionUpdating }) => {
    if (this.props.updateConfigStatus) {
      setTimeout(() => {
        this.props.updateConfigStatus({ isActionUpdating: isActionUpdating });
      }, 1000);
    }
  };

  renderRefreshButton() {
    const { isFetchingUpdateStatus } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        loading={isFetchingUpdateStatus}
        onClick={() => this.fetchSettingUpdateResult({})}
      />
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <div className="config-update-page">
          <div className="message-container">
            <Message
              severity="info"
              content={
                <div className="flex align-items-center">
                  <i className="pi pi-exclamation-circle"></i>
                  <div className="ml-3 p-1 text-left">
                    <div
                      style={{ whiteSpace: 'break-spaces', lineHeight: '1.5' }}
                    >
                      {`設定を変更しています\nしばらく待ってから更新をしてください`}
                    </div>
                  </div>
                </div>
              }
            ></Message>
          </div>
          <div className="refresh-button-container">
            {this.renderRefreshButton()}
          </div>
        </div>
      </>
    );
  }
}

ConfigUpdateStatus.propTypes = {
  updateConfigStatus: PropTypes.func,
};

export default ConfigUpdateStatus;
