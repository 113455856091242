import {
  COMPONENT_ID_GROUP,
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SIDEBAR_CATEGORIES,
  SIDEBAR_CATEGORY,
  USER_PRIVILEGE,
  USER_PRIVILEGE_KEY,
} from '@/helpers/constants';
import FpClientAuthorityManager from '@/forepaas-extensions/client-authority-manager';
import FpSdk from 'forepaas/sdk';
import { set as memoryActionSet, set } from 'forepaas/store/memory/action';
import { set as sessionActionSet } from 'forepaas/store/session/action';
import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { router } from '../../../helpers/router';

@connect(state => ({
  querystring: state.querystring,
  memory: state.memory,
  sessionStore: state.session,
}))
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.renderLink = this.renderLink.bind(this);
    this.categories = [...SIDEBAR_CATEGORIES];
  }

  setComponentValue = ({ type, icon, url, text, label, isNav, tooltip }) => {
    const val = {
      type: type ?? null,
      icon: icon ?? null,
      url: url ?? null,
      text: text ?? null,
      label: label ?? null,
      isNav: isNav ?? null,
      tooltip: tooltip ?? null,
    };

    return val;
  };

  componentDidMount() {
    const { ROUTERS } = MEMORY_STORE;

    const configRoute = {};
    router.map(item => (configRoute[item.url.replace('/', '')] = item.hideTop));
    FpSdk.modules.store.dispatch(set(ROUTERS, configRoute));
  }

  /**
   * Renders the link
   */
  renderLink({ icon, text }) {
    return (
      <div className="sidebar-container">
        <div className="sidebar-icon"> {icon}</div>
        <div>{text && <span>{text}</span>}</div>
      </div>
    );
  }

  renderNav = ({ key, text, icon, url, className }) => {
    const link = this.renderLink({ text: text, icon: icon, url: url });

    const navLinkOnClicked = (e, url) => {
      if (isMobile) {
        // Toggle sidebar
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
          sidebar.classList.toggle('sidebar--hidden');
        }
        const pageWrapper = document.querySelector('.page');
        if (pageWrapper) {
          pageWrapper.classList.toggle('page--sidebar-hidden');
        }
        const hideshowsidebar = document.querySelector('.hideshowsidebar');
        if (hideshowsidebar) {
          hideshowsidebar.classList.toggle('hideshowsidebar-sidebar-hidden');
        }
        const header = document.querySelector('.header');
        if (header) {
          header.classList.toggle('header--sidebar-hidden');
        }
      }
    };

    return (
      <NavLink
        key={key}
        exact={true}
        className={`fp-link ${className ?? ''}`}
        activeClassName="active"
        style={this.props.style}
        to={url}
        onClick={e => {
          navLinkOnClicked(e, url);
        }}
      >
        {link}
      </NavLink>
    );
  };

  renderCategoryItems({ categoryRouterList }) {
    return categoryRouterList.map((item, idx) =>
      this.renderNav({
        key: `${idx}-${item.text}`,
        text: item.text,
        icon: item.icon,
        url: item.url,
        className: item.className,
      })
    );
  }

  filterRouterByPrivilege = ({ router }) => {
    const componentIdSet = new Set();
    router.map(item => componentIdSet.add(item.componentIdGroup));
    let userPrivilege = this.props.sessionStore[USER_PRIVILEGE_KEY];
    userPrivilege = userPrivilege ?? null;
    let userAllowComponents = [];
    if (userPrivilege === USER_PRIVILEGE.STANDARD) {
      userAllowComponents = [
        COMPONENT_ID_GROUP.EMPLOYEE_LOCATIONS,
        COMPONENT_ID_GROUP.ROOM_AVAILABILITIES,
        COMPONENT_ID_GROUP.PEOPLE_COUNTS,
        COMPONENT_ID_GROUP.PERFORMANCES,
        COMPONENT_ID_GROUP.ENVIRONMENTS,
        COMPONENT_ID_GROUP.DEVICE_STATUS,
        COMPONENT_ID_GROUP.EMPLOYEE_ATTENDANCE,
      ];
    } else if (userPrivilege === USER_PRIVILEGE.SIGNAGE) {
      userAllowComponents = [COMPONENT_ID_GROUP.SIGNAGE_COMPONENTS];
    } else if (
      userPrivilege === USER_PRIVILEGE.SUPERVISOR ||
      userPrivilege === USER_PRIVILEGE.ADMIN
    ) {
      userAllowComponents = Array.from(componentIdSet);
    } else {
      userAllowComponents = [];
    }
    const selectedTenantId =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const tenants = this.props?.sessionStore?.[MEMORY_STORE.TENANTS];
    const selectedTenant = tenants?.find(
      item => +item.tenant_id === +selectedTenantId
    );
    if (!selectedTenant) {
      return [];
    }
    let tenantComps = [];
    try {
      tenantComps = JSON.parse(selectedTenant?.components) ?? [];
    } catch (error) {
      console.error(error);
    }
    let allowTenantComponents = tenantComps;
    let allowComponents = [];
    for (let i = 0; i < userAllowComponents.length; i++) {
      if (allowTenantComponents.includes(userAllowComponents[i])) {
        allowComponents.push(userAllowComponents[i]);
      }
    }
    if (
      userPrivilege === USER_PRIVILEGE.SUPERVISOR ||
      userPrivilege === USER_PRIVILEGE.ADMIN
    ) {
      allowComponents.push(999); // setting component
      allowComponents = allowComponents.filter(item => item !== 13);
    }
    let allowRouters = router.filter(routerItem =>
      allowComponents.includes(routerItem.componentIdGroup)
    );
    return allowRouters;
  };

  renderRouter = () => {
    if (router.length === 0) {
      return <div></div>;
    }
    const allowRouters = this.filterRouterByPrivilege({ router });
    const categoryList = allowRouters.map(item => item.category);
    return this.categories.map((item, idx) => {
      if (isMobile && item.value !== SIDEBAR_CATEGORY.COMMUNICATION) {
        return <Fragment key={`empty-${idx}`}></Fragment>;
      }
      const isValid = categoryList.includes(item.value);
      return (
        isValid && (
          <Fragment key={idx}>
            <div className={`sidebar-category-container`}>
              <div className="sidebar-category">{item.name}</div>
              <hr></hr>
              {this.renderCategoryItems({
                categoryRouterList: allowRouters.filter(
                  routerItem => routerItem.category === item.value
                ),
              })}
            </div>
          </Fragment>
        )
      );
    });
  };

  clearUserPrivilege = () => {
    FpSdk.modules.store.dispatch(memoryActionSet(USER_PRIVILEGE_KEY, ''));
    FpSdk.modules.store.dispatch(sessionActionSet(USER_PRIVILEGE_KEY, ''));
  };

  logout = e => {
    this.clearUserPrivilege();
    FpClientAuthorityManager.FpAuthentication.logout();
  };

  render() {
    return (
      <>
        <div>{this.renderRouter()}</div>
        {isMobile && (
          <div className="toggle-pc-mobile-container">
            <div className="username-logout mt-2" onClick={this.logout}>
              ログアウト
            </div>
          </div>
        )}
      </>
    );
  }
}

SideBar.propTypes = {};
export default SideBar;
