import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import {
  fetchEmployeeEvents,
  fetchEmployeeInfo,
  fetchEmployeeLocationByEmployeeId,
} from '@/components/communication/EmployeeLocations/query-request';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  MEMORY_STORE,
  QUERY_STRING_STORE,
  CHAT_TOOL_PRESENCE_COLOR,
} from '@/helpers/constants';
import EmployeeDetailMarkerRender from '@/helpers/employee-detail-marker';
import { transformPositionToMarkerCoordinate } from '@/helpers/utility';
import FpDataStore from '@/services/FpDatastore';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import './EmployeeInfo.less';

const fpDataStore = new FpDataStore();

const EmployeeInfo = props => {
  const copyToast = useRef(null);
  const querystring = useSelector(state => state.querystring);
  const sessionStore = useSelector(state => state.session);

  const [selectedFloorId, setSelectedFloorId] = useState(null);
  const [selectedTenantId, setSelectedTenantId] = useState(null);

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isMobileIndex, setIsMobileIndex] = useState(0);

  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [employeeLocations, setEmployeeLocations] = useState(null);
  const [employeeEvents, setEmployeeEvents] = useState(null);

  const getEmployeeInfo = useCallback(async ({ employeeId, tenantId }) => {
    setIsLoadingInfo(true);
    let employeeInfo = await fetchEmployeeInfo({
      employeeId,
      tenantId,
    });
    employeeInfo =
      employeeInfo && employeeInfo.length > 0 ? employeeInfo[0] : null;
    if (!employeeInfo) {
      setIsLoadingInfo(false);
      return;
    }
    let employeeImageUrl = 'assets/images/default_employee_avatar.jpg';
    const { employee_image } = employeeInfo;
    if (employee_image) {
      employeeImageUrl =
        fpDataStore.getObjectDownloadUrlWithBucket(employee_image);
    }
    employeeInfo['employee_image_url'] = employeeImageUrl;
    setEmployeeInfo(employeeInfo);
    setIsLoadingInfo(false);
  }, []);

  const getEmployeeLocation = useCallback(
    async ({ employeeId, tenantId }) => {
      setIsLoadingLocation(true);
      let employeeLocations = await fetchEmployeeLocationByEmployeeId({
        employeeId,
        tenantId,
      });
      const { deviceType } = props;
      employeeLocations = employeeLocations ?? [];
      if (!deviceType || deviceType === 'pc') {
        setIsMobileIndex(1);
      } else {
        setIsMobileIndex(0);
      }
      setEmployeeLocations(employeeLocations);
      setIsLoadingLocation(false);
    },
    [props]
  );

  const getEmployeeEvent = useCallback(async ({ employeeId, tenantId }) => {
    setIsLoadingEvents(true);
    const currentDate = new Date();
    const fromDate = format(currentDate, 'yyyy-MM-dd 00:00:00');
    const toDate = format(currentDate, 'yyyy-MM-dd 23:59:59');
    let employeeEvents = await fetchEmployeeEvents({
      employeeId,
      fromDate,
      toDate,
      tenantId,
    });
    employeeEvents = employeeEvents || [];
    employeeEvents.sort((a, b) => {
      if (a['allday'] && !b['allday']) return -1;
      if (!a['allday'] && b['allday']) return 1;
      return a['start_time'].localeCompare(b['start_time']);
    });
    employeeEvents.forEach(event => {
      event['start_time'] =
        format(new Date(event['start_time']), 'HH:mm') ?? '-';
      event['end_time'] = format(new Date(event['end_time']), 'HH:mm') ?? '-';
    });
    setEmployeeEvents(employeeEvents);
    setIsLoadingEvents(false);
  }, []);

  const copyTextToClipboard = async text => {
    await navigator.clipboard.writeText(text ?? '');
    copyToast.current.show({
      severity: 'success',
      detail: COMMON_TEXT.INFO_COPIED_TO_CLIPBOARD,
      life: 2000,
      content: props => (
        <div className="">
          <span>{props.message.detail}</span>
        </div>
      ),
    });
  };

  const componentUnmount = useCallback(() => {}, []);

  useEffect(() => {
    return componentUnmount;
  }, [componentUnmount]);

  useEffect(() => {
    setSelectedFloorId(querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0]);
    setSelectedTenantId(
      querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0]
    );
  }, [querystring]);

  useEffect(() => {
    const { employeeId } = props;
    if (!employeeInfo && employeeId && selectedTenantId) {
      getEmployeeInfo({
        employeeId,
        tenantId: selectedTenantId,
      });
    }
  }, [selectedTenantId, getEmployeeInfo, props, employeeInfo]);

  useEffect(() => {
    const { employeeId } = props;
    if (!employeeInfo && employeeId && selectedTenantId) {
      getEmployeeLocation({
        employeeId,
        tenantId: selectedTenantId,
      });
    }
  }, [selectedTenantId, getEmployeeLocation, props, employeeInfo]);

  useEffect(() => {
    const { employeeId } = props;
    if (employeeEvents === null && employeeId && selectedTenantId) {
      getEmployeeEvent({
        employeeId,
        tenantId: selectedTenantId,
      });
    }
  }, [selectedTenantId, getEmployeeEvent, props, employeeEvents]);

  const EmployeeBasicInfo = () => {
    if (isLoadingInfo) {
      return (
        <div className="info-container">
          <LoadingSpinner />
        </div>
      );
    }
    if (!employeeInfo) {
      return null;
    }
    const {
      employee_name,
      employee_name_reading,
      employee_image_url,
      url_teams: teamURL,
      current_floor_id: emplCurrentFloorId,
      employee_number,
      department,
      team,
      phone_number,
      phone_number2,
      email,
      chat_tool_presence,
      chat_tool_presence_color,
    } = employeeInfo;

    const employeePresence = +selectedFloorId === +emplCurrentFloorId;

    const basicInfoList = [
      { title: '社員番号', value: employee_number },
      { title: '部署', value: department },
      { title: 'チーム', value: team },
      {
        title: 'TEL1',
        value: phone_number,
        hasCopyButton: phone_number ? true : false,
        isTel: true,
      },
      {
        title: 'TEL2',
        value: phone_number2,
        hasCopyButton: phone_number2 ? true : false,
        isTel: true,
      },
      {
        title: 'Email',
        value: email,
        hasCopyButton: email ? true : false,
        isEmail: true,
      },
    ];

    return (
      <Fragment>
        <div className="name-container">
          <div className="employee-image">
            <img src={employee_image_url} alt="employee" />
          </div>
          <div className="employee-name">
            <span>{employee_name}</span>
            <span className="employee-name-reading">
              {employee_name_reading}
            </span>
          </div>
          <div className="employee-presence-chat-container">
            <div className="employee-presence-status">
              {employeePresence === null ? (
                <span>{`該当なし`}</span>
              ) : employeePresence === true ? (
                <span className="presence">{`在室`}</span>
              ) : (
                <span className="not-presence">{`不在`}</span>
              )}
            </div>
            {chat_tool_presence != null && chat_tool_presence_color != null && (
              <div className='employee-chattool-container'>
                <div
                  className={`presence-status-color`}
                  style={{
                    backgroundColor:
                      CHAT_TOOL_PRESENCE_COLOR[
                        chat_tool_presence_color.toUpperCase()
                      ],
                  }}
                ></div>
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {chat_tool_presence}
                </span>
              </div>
            )}
          </div>
          <div className="team-url">
            {teamURL && (
              <Fragment>
                <Tooltip
                  position="left"
                  className="custom-marker-tooltip"
                  target=".team-button"
                  showDelay={0}
                  hideDelay={0}
                >
                  <span>{teamURL}</span>
                </Tooltip>
                <a
                  href={teamURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-button team-button"
                >
                  <img
                    alt="microsofteam"
                    src="assets/img/microsoft-team.png"
                    className=""
                  ></img>
                </a>
              </Fragment>
            )}
          </div>
        </div>
        <div className="basic-info info-container">
          {basicInfoList.map((item, index) => {
            return (
              <div className="row-info" key={`row-index-${index}`}>
                <div className="left-title">{item.title}</div>
                <div
                  className={`right-value ${item.isEmail ? 'email-value' : ''}`}
                >
                  {item.isEmail && (
                    <a href={`mailto: ${item.value}`}>{item.value}</a>
                  )}
                  {item.isTel && (
                    <a href={`tel: ${item.value}`}>{item.value}</a>
                  )}
                  {!item.isEmail && !item.isTel && <span>{item.value}</span>}
                </div>
                <div className="copy-button-container">
                  {item.hasCopyButton && (
                    <Button
                      className="copy-button"
                      icon="pi pi-copy"
                      text
                      severity="secondary"
                      aria-label="copy-button"
                      onClick={() => copyTextToClipboard(item.value)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };

  const EmployeeDetailLocation = () => {
    if (isLoadingLocation) {
      return <LoadingSpinner />;
    }
    if (!employeeLocations || employeeLocations.length === 0 || !employeeInfo) {
      return <div className="no-location">{COMMON_TEXT.NO_LOCATION_DATA}</div>;
    }

    const LocationMap = ({ data }) => {
      if (!data || data.length === 0) {
        return (
          <div className="no-location">{COMMON_TEXT.NO_LOCATION_DATA}</div>
        );
      }
      const employeeInfo = data[0];
      let markerList = [];
      const {
        position_x,
        position_y,
        floor_name,
        branch_name,
        timestamp,
        floor_id,
        employee_image,
      } = employeeInfo;
      let employeeImageUrl = 'assets/images/default_employee_avatar.jpg';
      if (employee_image) {
        employeeImageUrl =
          fpDataStore.getObjectDownloadUrlWithBucket(employee_image);
      }
      employeeInfo['employee_image_url'] = employeeImageUrl;
      if (position_x && position_y) {
        const marker = transformPositionToMarkerCoordinate({
          x: position_x,
          y: position_y,
        });
        markerList = [
          {
            ...marker,
            item: employeeInfo || {},
          },
        ];
      }
      const floors = sessionStore[MEMORY_STORE.FLOORS] || [];
      const floor = floors.find(floor => +floor.floor_id === +floor_id);
      const floorMapUrl = floor?.floor_map;
      return (
        <Fragment>
          <div className="location-map">
            <div className="image-container">
              <ImageMarker
                extraClass="custom-image-marker"
                bufferLeft={0}
                bufferTop={0}
                src={floorMapUrl}
                markerComponent={EmployeeDetailMarkerRender}
                markers={markerList}
                alt=""
              />
              <>
                {!floorMapUrl && (
                  <Message
                    severity="error"
                    text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                  />
                )}
              </>
            </div>
          </div>
          <div className="row-info">
            <div className="left-title">{`拠点`}</div>
            <div className="right-value">
              <span>{`${branch_name}`}</span>
            </div>
          </div>
          <div className="row-info">
            <div className="left-title">{`フロア`}</div>
            <div className="right-value">
              <span>{floor_name}</span>
            </div>
          </div>
          <div className="row-info">
            <div className="left-title">{`更新日時`}</div>
            <div className="right-value">
              <span>
                {timestamp && format(new Date(timestamp), 'MM月dd日 - HH:mm')}
              </span>
            </div>
          </div>
        </Fragment>
      );
    };

    let mobileDeviceMarkers = employeeLocations.filter(
      item => item.device_type === 'phone'
    );
    let pcDeviceMarkers = employeeLocations.filter(
      item => item.device_type === 'pc'
    );

    return (
      <Fragment>
        <TabView
          activeIndex={isMobileIndex}
          onTabChange={e => setIsMobileIndex(e.index)}
        >
          <TabPanel
            header={COMMON_TEXT.MOBILE_DEVICE_TYPE}
            visible={mobileDeviceMarkers.length > 0}
          >
            <LocationMap
              key={`mobileLocationMap`}
              data={mobileDeviceMarkers}
            ></LocationMap>
          </TabPanel>
          <TabPanel
            header={COMMON_TEXT.PC_DEVICE_TYPE}
            visible={pcDeviceMarkers.length > 0}
          >
            <LocationMap
              key={`pcLocationMap`}
              data={pcDeviceMarkers}
            ></LocationMap>
          </TabPanel>
        </TabView>
      </Fragment>
    );
  };

  const EmployeeDetailEvents = () => {
    if (isLoadingEvents) {
      return <LoadingSpinner />;
    }
    if (!employeeEvents || employeeEvents.length === 0) {
      return <div className="no-events">{COMMON_TEXT.EMPLOYEE_NO_EVENTS}</div>;
    }
    return (
      <Fragment>
        <Tooltip
          className="custom-marker-tooltip"
          target=".event-value"
          showDelay={0}
          hideDelay={0}
        ></Tooltip>
        {employeeEvents.map((event, index) => (
          <div className="row-info" key={`employee-event-${index}`}>
            <div className="left-title">
              {event['allday'] ? (
                <span>{'終日'}</span>
              ) : (
                <span>{`${event['start_time']} - ${event['end_time']}`}</span>
              )}
            </div>
            <div
              className="event-value right-value"
              data-pr-tooltip={`${event['start_time']} - ${event['end_time']} \n ${event['subject']} \n ${event['location']}`}
              data-pr-position="left"
            >
              <div className="subject-value">
                <span>{event['subject']}</span>
              </div>
              <div className="location-value">
                <span>{event['location']}</span>
              </div>
            </div>
          </div>
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Toast ref={copyToast} position="top-right" />
      <div className="employee-info-container">
        <EmployeeBasicInfo></EmployeeBasicInfo>
        <div className="location-info info-container">
          <EmployeeDetailLocation></EmployeeDetailLocation>
        </div>
        <div className="events-info info-container">
          <EmployeeDetailEvents></EmployeeDetailEvents>
        </div>
      </div>
    </Fragment>
  );
};

EmployeeInfo.propTypes = {
  employeeId: PropTypes.number,
  deviceType: PropTypes.string,
};

export default EmployeeInfo;
