import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildRequestQueryBarChart = ({ floorId, deviceIdList, selectedDate }) => {
  return {
    data: {
      fields: {
        device_name: ['select'],
        device_identifer: ['select'],
        counts: ['select'],
        date: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      floor_id: [floorId],
      device_identifer: deviceIdList,
      date: [selectedDate],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      counts: 'desc',
    },
    table_name: QUERY_TABLES.CLIENT_COUNTS_MART_BAR_V2,
  };
};

const buildRequestQueryLineChart = (
  floorId,
  fromDate,
  toDate,
  deviceIdList
) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        tenant_id: ['select'],
        floor_id: ['select'],
        counts: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'desc',
    },
    table_name: QUERY_TABLES.CLIENT_COUNTS_MART_LINE,
  };
};

export const fetchDataBarChart = async payload => {
  const { floorId, deviceIdList, selectedDate } = payload;
  const request = buildRequestQueryBarChart({
    floorId,
    deviceIdList,
    selectedDate,
  });
  return executeQuery(request);
};

export const fetchDataLineChart = async payload => {
  const { floorId, fromDate, toDate, deviceIdList } = payload;
  const request = buildRequestQueryLineChart(
    floorId,
    fromDate,
    toDate,
    deviceIdList
  );
  return executeQuery(request);
};
