
class FpQueries {
  constructor (requests) {
    throw new Error('FpQueries is not implemented')
  }

  compute () {
  }
}

export default FpQueries
