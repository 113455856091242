import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getCurrentFullDateFormattedInTimeZone, getRandomNumber900000to999999 } from '@/helpers/utility';

const buildRequestQuery = floorId => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        floor_id: ['select'],
        position_x: ['select'],
        position_y: ['select'],
        room_availability: ['select'],
        tenant_id: ['select'],
        timestamp: ['select'],
        organizer: ['select'],
        current_reservation: ['select'],
        next_reservation: ['select'],
        employee_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        lte: [getCurrentFullDateFormattedInTimeZone()],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ROOM_AVAILABILITIES_MART,
  };
};

const buildRequestDevicePosition = ({ floor, deviceId }) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        room_name: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floor],
      device_identifer: [deviceId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.DEVICE_POSITIONS,
  };
};

const buildQueryTenant = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
        schedule_tool: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

export const fetchRoomName = async payload => {
  const { floor, deviceId } = payload;
  const request = buildRequestDevicePosition({ floor, deviceId });
  return executeQuery(request);
};

export const fetchData = async payload => {
  const { floorId } = payload;
  const request = buildRequestQuery(floorId);
  return executeQuery(request);
};

export const fetchTenantById = async payload => {
  const { tenantId } = payload;
  const request = buildQueryTenant({ tenantId });
  return executeQuery(request);
};


// case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
const buildQueryOutlookStartBeforeStartAndEndAfterStart = ({ floorId, deviceId, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: {
        eq: [deviceId],
      },
      reserved_start: {
        lte: [startDateTime],
      },
      reserved_end: {
        gte: [startDateTime],
        lte: [endDateTime],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartBeforeStartAndEndAfterStart = async payload => {
  const { floorId, deviceId, startDateTime, endDateTime } = payload;
  const request = buildQueryOutlookStartBeforeStartAndEndAfterStart({ floorId, deviceId, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 2: fetch data with start is before startDateTime and end is after endDateTime
const buildQueryOutlookStartBeforeStartAndEndAfterEnd = ({ floorId, deviceId, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: {
        eq: [deviceId],
      },
      reserved_start: {
        lte: [startDateTime],
      },
      reserved_end: {
        gte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartBeforeStartAndEndAfterEnd = async payload => {
  const { floorId, deviceId, startDateTime, endDateTime } = payload;
  const request = buildQueryOutlookStartBeforeStartAndEndAfterEnd({ floorId, deviceId, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 3: fetch data with start is after startDateTime and end is before endDateTime
const buildQueryOutlookStartAfterStartAndEndBeforeEnd = ({ floorId, deviceId, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: {
        eq: [deviceId],
      },
      reserved_start: {
        gte: [startDateTime],
      },
      reserved_end: {
        lte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartAfterStartAndEndBeforerEnd = async payload => {
  const { floorId, deviceId, startDateTime, endDateTime } = payload;
  const request = buildQueryOutlookStartAfterStartAndEndBeforeEnd({ floorId, deviceId, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 4: fetch data with start is after startDateTime and end is after endDateTime
const buildQueryOutlookStartAfterStartAndEndAfterEnd = ({ floorId, deviceId, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      device_identifer: {
        eq: [deviceId],
      },
      reserved_start: {
        gte: [startDateTime],
      },
      reserved_end: {
        gte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartAfterStartAndEndAfterEnd = async payload => {
  const { floorId, deviceId, startDateTime, endDateTime } = payload;
  const request = buildQueryOutlookStartAfterStartAndEndAfterEnd({ floorId, deviceId, startDateTime, endDateTime }
  );
  return executeQuery(request);
};