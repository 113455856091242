import React from 'react';
import { COMMON_TEXT } from '@/helpers/common-text';
import { Tooltip } from 'primereact/tooltip';
import { generateRandomString } from '@/helpers/utility';
import { format } from 'date-fns';

export const DeviceStatusMarkerImageRender = props => {
  const { item } = props;
  const { data, iconImage } = item;
  const { status } = data;
  const usingDefaultIcon = iconImage === 'default';
  const deviceOnlineStatus = {
    ONLINE: '#008000',
    OFFLINE: '#FF0000',
    DORMANT: '#D3D3D3',
    ALERTING: '#FFD700',
    UNKNOWN: '#000000',
  };
  let deviceStatus = status;
  if (deviceStatus) {
    deviceStatus = deviceStatus.toUpperCase();
  } else {
    deviceStatus = 'UNKNOWN';
  }
  const bgColor = deviceOnlineStatus[deviceStatus];
  const targetId = generateRandomString(12);
  return (
    <div
      className={`custom-marker device-status-marker ${
        !usingDefaultIcon ? 'device-status-marker-icon' : ''
      } custom-marker-tooltip-target-${targetId}`}
      style={{
        backgroundColor: usingDefaultIcon ? bgColor : 'transparent',
        width: usingDefaultIcon ? 30 : 40,
        height: usingDefaultIcon ? 30 : 40,
      }}
    >
      {usingDefaultIcon ? (
        <></>
      ) : (
        <>
          <div className="face-image">
            <img src={`${iconImage}`} alt="device" width="40" height="40" />
          </div>
        </>
      )}
      <Tooltip
        className="custom-marker-tooltip"
        target={`.custom-marker-tooltip-target-${targetId}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEVICE_NAME}</div>
            <div className="text-value">
              <b>{data.device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.STATUS}</div>
            <div className="text-value">
              <b>{data.status}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{format(new Date(data.timestamp), 'MM月dd日 - HH:mm')}</b>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
