import CustomBarChart from '@/components/CustomComponent/CustomBarChart';
import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import NetworkTitle from '@/components/CustomComponent/NetworkTitle';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CHART_TYPE,
  CSV_DOWNLOAD_COMPONENT,
  PERFORMANCE_COMPONENT,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import {
  checkListChanges,
  sortObjectOrArrayByDeviceIdList,
  timestampTickList,
} from '@/helpers/utility';
import { compareAsc, format } from 'date-fns';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDataBarChart, fetchDataLineChart } from './query-request';

@connect(state => ({
  querystring: state.querystring,
}))
class WirelessLatency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartBackgroundData: [],
      barChartVideoData: [],
      barChartBesteffortData: [],
      barChartVoiceData: [],
      lineChartBackgroundData: [],
      lineChartVideoData: [],
      lineChartBesteffortData: [],
      lineChartVoiceData: [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoadingBarChart: false,
      isLoadingLineChart: false,
      csvDownload: false,
    };
    this.deviceInfoOrderedMap = {};
  }

  componentDidMount() {
    const { deviceInfoOrderedList: deviceList } = this.props;
    this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
      acc[item.deviceId] = item;
      return acc;
    }, {});
    this.getAllData();
  }

  componentDidUpdate(prevProps) {
    const { deviceInfoOrderedList: deviceList, selectedDate } = this.props;
    const {
      selectedDate: prevSelectedDate,
      deviceInfoOrderedList: prevDeviceList,
    } = prevProps;
    const isDateChanged = selectedDate !== prevSelectedDate;
    const { elementsChanged, orderChanged } = checkListChanges(
      deviceList,
      prevDeviceList,
      'deviceId'
    );
    const deviceIdList = deviceList.map(item => item.deviceId);
    const floor =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    if (elementsChanged || isDateChanged) {
      const { deviceInfoOrderedList: deviceList } = this.props;
      this.deviceInfoOrderedMap = deviceList.reduce((acc, item) => {
        acc[item.deviceId] = item;
        return acc;
      }, {});
      this.setState({ floor }, () => {
        this.getAllData();
      });
    } else if (orderChanged) {
      const {
        barChartBackgroundData,
        barChartBesteffortData,
        barChartVideoData,
        barChartVoiceData,
        lineChartBackgroundData,
        lineChartBesteffortData,
        lineChartVideoData,
        lineChartVoiceData,
      } = this.state;
      const {
        sortedBarChartBackgroundData,
        sortedBarChartBesteffortData,
        sortedBarChartVideoData,
        sortedBarChartVoiceData,
        sortedLineChartBackgroundData,
        sortedLineChartBesteffortData,
        sortedLineChartVideoData,
        sortedLineChartVoiceData,
      } = this.triggerUpdateAllDataSorting({
        barChartBackgroundData,
        barChartBesteffortData,
        barChartVideoData,
        barChartVoiceData,
        lineChartBackgroundData,
        lineChartBesteffortData,
        lineChartVideoData,
        lineChartVoiceData,
        deviceIdList,
      });
      this.setState({
        isLoadingBarChart: false,
        barChartBackgroundData: sortedBarChartBackgroundData,
        barChartBesteffortData: sortedBarChartBesteffortData,
        barChartVideoData: sortedBarChartVideoData,
        barChartVoiceData: sortedBarChartVoiceData,
        lineChartBackgroundData: sortedLineChartBackgroundData,
        lineChartBesteffortData: sortedLineChartBesteffortData,
        lineChartVideoData: sortedLineChartVideoData,
        lineChartVoiceData: sortedLineChartVoiceData,
      });
    }
  }

  capitalizeFirstLetter = type => {
    return `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  };

  triggerUpdateAllDataSorting = ({
    barChartBackgroundData,
    barChartBesteffortData,
    barChartVideoData,
    barChartVoiceData,
    lineChartBackgroundData,
    lineChartBesteffortData,
    lineChartVideoData,
    lineChartVoiceData,
    deviceIdList,
  }) => {
    const dataSets = {
      barChartBackgroundData,
      barChartBesteffortData,
      barChartVideoData,
      barChartVoiceData,
      lineChartBackgroundData,
      lineChartBesteffortData,
      lineChartVideoData,
      lineChartVoiceData,
    };

    let sortKey = 'device_identifer';

    let sortedDataSets = {};

    for (const [dataName, dataList] of Object.entries(dataSets)) {
      if (dataName.startsWith('lineChart')) {
        sortKey = 'deviceId';
      }

      const { sortedDataList } = sortObjectOrArrayByDeviceIdList({
        deviceIdList,
        dataObject: dataList,
        sortKey,
      });

      sortedDataSets[`sorted${this.capitalizeFirstLetter(dataName)}`] =
        sortedDataList;
    }

    return sortedDataSets;
  };

  getAllData = async () => {
    let {
      floor,
      barChartBackgroundData: currentBarChartBackgroundData,
      barChartBesteffortData: currentBarChartBesteffortData,
      barChartVideoData: currentBarChartVideoData,
      barChartVoiceData: currentBarChartVoiceData,
      lineChartBackgroundData: currentLineChartBackgroundData,
      lineChartBesteffortData: currentLineChartBesteffortData,
      lineChartVideoData: currentLineChartVideoData,
      lineChartVoiceData: currentLineChartVoiceData,
    } = this.state;
    const {
      deviceInfoOrderedList,
      selectedDate,
      addedDeviceIdList,
      isResetDeviceIdList,
    } = this.props;
    const deviceIdList = deviceInfoOrderedList.map(item => item.deviceId);
    if (!deviceIdList || deviceIdList.length === 0) {
      return;
    }
    if (!addedDeviceIdList || addedDeviceIdList.length === 0) {
      return;
    }
    if (isResetDeviceIdList) {
      currentBarChartBackgroundData = [];
      currentBarChartBesteffortData = [];
      currentBarChartVideoData = [];
      currentBarChartVoiceData = [];
      currentLineChartBackgroundData = [];
      currentLineChartBesteffortData = [];
      currentLineChartVideoData = [];
      currentLineChartVoiceData = [];
    }
    this.setState({
      isLoadingBarChart: true,
      isLoadingLineChart: true,
    });
    const {
      barChartBackgroundData,
      barChartBesteffortData,
      barChartVideoData,
      barChartVoiceData,
    } = await this.getBarChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });
    currentBarChartBackgroundData = currentBarChartBackgroundData.concat(
      barChartBackgroundData
    );
    currentBarChartBesteffortData = currentBarChartBesteffortData.concat(
      barChartBesteffortData
    );
    currentBarChartVideoData =
      currentBarChartVideoData.concat(barChartVideoData);
    currentBarChartVoiceData =
      currentBarChartVoiceData.concat(barChartVoiceData);

    const {
      sortedBarChartBackgroundData,
      sortedBarChartBesteffortData,
      sortedBarChartVideoData,
      sortedBarChartVoiceData,
    } = this.triggerUpdateAllDataSorting({
      barChartBackgroundData: currentBarChartBackgroundData,
      barChartBesteffortData: currentBarChartBesteffortData,
      barChartVideoData: currentBarChartVideoData,
      barChartVoiceData: currentBarChartVoiceData,
      deviceIdList,
    });

    this.setState({
      isLoadingBarChart: false,
      barChartBackgroundData: sortedBarChartBackgroundData,
      barChartBesteffortData: sortedBarChartBesteffortData,
      barChartVideoData: sortedBarChartVideoData,
      barChartVoiceData: sortedBarChartVoiceData,
    });

    const {
      lineChartBackgroundData,
      lineChartBesteffortData,
      lineChartVideoData,
      lineChartVoiceData,
    } = await this.getLineChartData({
      floor,
      deviceIdList: addedDeviceIdList,
      selectedDate,
    });
    currentLineChartBackgroundData = currentLineChartBackgroundData.concat(
      lineChartBackgroundData
    );
    currentLineChartBesteffortData = currentLineChartBesteffortData.concat(
      lineChartBesteffortData
    );
    currentLineChartVideoData =
      currentLineChartVideoData.concat(lineChartVideoData);
    currentLineChartVoiceData =
      currentLineChartVoiceData.concat(lineChartVoiceData);

    const {
      sortedLineChartBackgroundData,
      sortedLineChartBesteffortData,
      sortedLineChartVideoData,
      sortedLineChartVoiceData,
    } = this.triggerUpdateAllDataSorting({
      lineChartBackgroundData: currentLineChartBackgroundData,
      lineChartBesteffortData: currentLineChartBesteffortData,
      lineChartVideoData: currentLineChartVideoData,
      lineChartVoiceData: currentLineChartVoiceData,
      deviceIdList,
    });
    this.setState({
      isLoadingLineChart: false,
      lineChartBackgroundData: sortedLineChartBackgroundData,
      lineChartBesteffortData: sortedLineChartBesteffortData,
      lineChartVideoData: sortedLineChartVideoData,
      lineChartVoiceData: sortedLineChartVoiceData,
    });
  };

  getBarChartData = async ({ floor, deviceIdList, selectedDate }) => {
    let barChartDataResponse = await fetchDataBarChart({
      floorId: floor,
      deviceIdList,
      selectedDate: format(selectedDate, 'yyyy-MM-dd'),
    });
    let barChartBackgroundData = [];
    let barChartBesteffortData = [];
    let barChartVideoData = [];
    let barChartVoiceData = [];

    if (barChartDataResponse && barChartDataResponse.length > 0) {
      const data = barChartDataResponse.map(item => ({
        device_identifer: item?.device_identifer,
        device_name: item?.device_name,
        background: parseFloat(item?.background),
        besteffort: parseFloat(item?.besteffort),
        video: parseFloat(item?.video),
        voice: parseFloat(item?.voice),
      }));

      barChartBackgroundData = data.slice().map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]['fillColor'],
      }));
      barChartBesteffortData = data.slice().map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]['fillColor'],
      }));
      barChartVideoData = data.slice().map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]['fillColor'],
      }));
      barChartVoiceData = data.slice().map(item => ({
        ...item,
        fillColor:
          this.deviceInfoOrderedMap[item.device_identifer]['fillColor'],
      }));
    }
    return {
      barChartBackgroundData,
      barChartBesteffortData,
      barChartVideoData,
      barChartVoiceData,
    };
  };

  getLineChartData = async ({ floor, deviceIdList, selectedDate }) => {
    const dataLineChartRes = await fetchDataLineChart({
      floorId: floor,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
      deviceIdList: deviceIdList,
    });
    let result = {};
    const defaultTimestampTickList = timestampTickList();

    for (const chartType of Object.values(CHART_TYPE)) {
      const transformedChartType = this.capitalizeFirstLetter(chartType);
      const stateProp = `lineChart${transformedChartType}Data`;
      if (dataLineChartRes?.length > 0) {
        result[stateProp] = deviceIdList.map(deviceId => {
          const deviceName =
            this.deviceInfoOrderedMap[deviceId]?.['deviceName'];
          const fillColor = this.deviceInfoOrderedMap[deviceId]?.['fillColor'];
          const timestampSet = new Set(
            dataLineChartRes.map(item =>
              format(new Date(item.timestamp), 'HH:mm')
            )
          );
          let timestampArray = Array.from(timestampSet).sort((a, b) => a > b);
          const maxTimestamp = timestampArray[timestampArray.length - 1];
          const missingTimestampList = defaultTimestampTickList.filter(
            item => !timestampSet.has(item) && item <= maxTimestamp
          );
          const dataByDeviceId = dataLineChartRes.filter(
            item => item.device_identifer === deviceId
          );
          const data = dataByDeviceId
            .map(item => ({
              timestamp: item.timestamp,
              [chartType]: item[chartType] ?? null,
            }))
            .concat(
              missingTimestampList.map(item => ({
                timestamp: format(selectedDate, `yyyy-MM-dd ${item}:00`),
                [chartType]: null,
              })) ?? []
            )
            .sort((a, b) =>
              compareAsc(new Date(a.timestamp), new Date(b.timestamp))
            );
          return {
            name: deviceName,
            deviceId,
            fillColor,
            data,
          };
        });
      } else {
        result[stateProp] = [];
      }
    }
    return result;
  };

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderBarChart = (barChartData, chartType) => {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoadingBarChart } = this.state;
    const filteredData = barChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId =>
          deviceId?.toLowerCase() === item['device_identifer']?.toLowerCase()
      )
    );

    return (
      <CustomBarChart
        data={filteredData}
        xAxisDataKey={'device_name'}
        yAxisDataKey={chartType}
        yAxisLabel={'ミリ秒'}
        isLoading={isLoadingBarChart}
      />
    );
  };

  getThresholdValue = ({ chartType }) => {
    const { lineChartThreshold } = this.props;
    let threshold = null;
    let thresholdDirection = null;
    if (lineChartThreshold) {
      const { latency_be, latency_bg, latency_video, latency_voice } =
        lineChartThreshold;
      if (chartType === CHART_TYPE.BACKGROUND) {
        threshold = latency_bg?.['threshold'] ?? null;
        thresholdDirection = latency_bg?.['direction'] ?? 'upper';
      } else if (chartType === CHART_TYPE.BEST_EFFORT) {
        threshold = latency_be?.['threshold'] ?? null;
        thresholdDirection = latency_be?.['direction'] ?? 'upper';
      } else if (chartType === CHART_TYPE.VIDEO) {
        threshold = latency_video?.['threshold'] ?? null;
        thresholdDirection = latency_video?.['direction'] ?? 'upper';
      } else if (chartType === CHART_TYPE.VOICE) {
        threshold = latency_voice?.['threshold'] ?? null;
        thresholdDirection = latency_voice?.['direction'] ?? 'upper';
      }
    }
    return { threshold, thresholdDirection };
  };

  renderLineChart = (lineChartData, chartType) => {
    const { selectedDeviceIdLineList } = this.props;
    const { isLoadingLineChart } = this.state;
    const filteredDisplayItems = lineChartData.filter(item =>
      selectedDeviceIdLineList.some(
        deviceId => deviceId?.toLowerCase() === item.deviceId?.toLowerCase()
      )
    );

    const { threshold, thresholdDirection } = this.getThresholdValue({
      chartType,
    });

    return (
      <CustomLineChart
        data={filteredDisplayItems}
        xAxisDataKey={'timestamp'}
        yAxisDataKey={chartType}
        yAxisMaxValue={500}
        yAxisLabel={'ミリ秒'}
        threshold={threshold}
        thresholdDirection={thresholdDirection}
        isLoading={isLoadingLineChart}
      />
    );
  };

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          severity={csvDownload ? "secondary" : "info"}
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button has-shadow' : 'submit-button csv-download has-shadow'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const {
      barChartBackgroundData,
      barChartVideoData,
      barChartBesteffortData,
      barChartVoiceData,
      lineChartBackgroundData,
      lineChartVideoData,
      lineChartBesteffortData,
      lineChartVoiceData,
      csvDownload,
      floor,
    } = this.state;
    const { selectedDeviceIdLineList, selectedDate } = this.props;

    const { threshold: thresholdBackground } = this.getThresholdValue({
      chartType: CHART_TYPE.BACKGROUND,
    });
    const { threshold: thresholdBestEffort } = this.getThresholdValue({
      chartType: CHART_TYPE.BEST_EFFORT,
    });
    const { threshold: thresholdVoice } = this.getThresholdValue({
      chartType: CHART_TYPE.VOICE,
    });
    const { threshold: thresholdVideo } = this.getThresholdValue({
      chartType: CHART_TYPE.VIDEO,
    });

    return (
      <div className="network-container wireless-latency">
        <div className="network-title-container">
          <div className="text-left">
            <NetworkTitle
              thresholds={[
                { type: CHART_TYPE.BACKGROUND, value: thresholdBackground },
                { type: CHART_TYPE.BEST_EFFORT, value: thresholdBestEffort },
                { type: CHART_TYPE.VOICE, value: thresholdVoice },
                { type: CHART_TYPE.VIDEO, value: thresholdVideo },
              ]}
              type={PERFORMANCE_COMPONENT.WIRELESS_LATENCY}
            ></NetworkTitle>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            <div>{this.renderDownloadButton()}</div>
          </div>
        </div>
        {csvDownload ? (
          <CustomCSVDownload
            fromDate={selectedDate}
            toDate={selectedDate}
            type={CSV_DOWNLOAD_COMPONENT.WIRELESS_LATENCY}
            deviceIdList={[...selectedDeviceIdLineList]}
            floorId={floor}
          ></CustomCSVDownload>
        ) : (
          <div className="network-content">
            {this.renderMessageError() || (
              <>
                <div className="data-container">
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.BACKGROUND} - {COMMON_TEXT.AVERAGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart(
                        barChartBackgroundData,
                        CHART_TYPE.BACKGROUND
                      )}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.BACKGROUND} - {COMMON_TEXT.CHANGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart(
                        lineChartBackgroundData,
                        CHART_TYPE.BACKGROUND
                      )}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.BEST_EFFORT} - {COMMON_TEXT.AVERAGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart(
                        barChartBesteffortData,
                        CHART_TYPE.BEST_EFFORT
                      )}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.BEST_EFFORT} - {COMMON_TEXT.CHANGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart(
                        lineChartBesteffortData,
                        CHART_TYPE.BEST_EFFORT
                      )}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.VIDEO} - {COMMON_TEXT.AVERAGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart(barChartVideoData, CHART_TYPE.VIDEO)}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.VIDEO} - {COMMON_TEXT.CHANGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart(
                        lineChartVideoData,
                        CHART_TYPE.VIDEO
                      )}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.VOICE} - {COMMON_TEXT.AVERAGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderBarChart(barChartVoiceData, CHART_TYPE.VOICE)}
                    </div>
                  </div>
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.VOICE} - {COMMON_TEXT.CHANGE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart(
                        lineChartVoiceData,
                        CHART_TYPE.VOICE
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

WirelessLatency.propTypes = {};

export default WirelessLatency;
