/**
 *
 * EmployeeSetting
 *
 */

import EmployeeSetting from './EmployeeSetting.jsx';
import './EmployeeSetting.less';

export default EmployeeSetting;
