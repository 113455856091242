class Order {
  constructor (order) {
    Object.assign(this, order)
  }
  merge (order) {
    Object.assign(this, order)
  }
}

export default Order
