import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  MEMORY_STORE,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { formatPercentage } from '@/helpers/utility';
import { format } from 'date-fns';
import { size } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getMeetingRoomMartTableDataByFloorIdListAndDate,
  getRoomAvailabilityByFloorIdList,
} from './service';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class MeetingRoomOutlookTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      floors: [],
      isLoadingTable: false,
      csvDownload: false,
    };
    this.pendingRequest = null;
    this.dataColumns = [
      {
        field: 'room_name',
        header: COMMON_TEXT.ROOM_NAME,
        align: 'left',
        headerClassName: 'room-name',
        bodyClassName: 'room-name',
      },
      {
        field: 'reserved',
        header: COMMON_TEXT.RESERVED,
        align: 'right',
        headerClassName: 'gray-title',
        bodyClassName: '',
      },
      {
        field: 'reservation_counts',
        header: COMMON_TEXT.RESERVATION_COUNTS,
        align: 'right',
        headerClassName: 'gray-title',
        bodyClassName: '',
      },
      {
        field: 'reserved_and_unused',
        header: COMMON_TEXT.RESERVED_AND_UNUSED,
        align: 'right',
        headerClassName: 'red-title',
        bodyClassName: '',
      },
      {
        field: 'occupied_rate',
        header: COMMON_TEXT.OCCUPIED_9_18,
        align: 'right',
        headerClassName: 'red-title',
        bodyClassName: 'has-percentage',
      },
      {
        field: 'unused_reservation',
        header: COMMON_TEXT.UNUSED_RESERVATION,
        align: 'right',
        headerClassName: 'yellow-title',
        bodyClassName: '',
      },
      {
        field: 'unused_rate',
        header: COMMON_TEXT.EMPTY_BOOKING_RATE,
        align: 'right',
        headerClassName: 'yellow-title',
        bodyClassName: 'has-percentage',
      },
    ];
  }

  componentDidMount() {
    const { selectedDate } = this.props;
    const { branch } = this.state;
    if (selectedDate && branch) {
      const floorIdList = this.getUpdatedFloorIdList({ branchId: branch });
      this.setState({ floors: floorIdList }, () =>
        this.getMeetingRoomOutlookData()
      );
    }
  }

  componentDidUpdate(prevProps) {
    const branch =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const prevBranch =
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const { selectedDate } = this.props;
    const { selectedDate: prevSelectedDate } = prevProps;
    const isDateChanged = selectedDate !== prevSelectedDate;
    const isBranchChanged = branch !== prevBranch;
    if (isBranchChanged || isDateChanged) {
      const floorIdList = this.getUpdatedFloorIdList({ branchId: branch });
      this.setState({ branch, floors: floorIdList }, () =>
        this.getMeetingRoomOutlookData()
      );
    }
  }

  getUpdatedFloorIdList = ({ branchId }) => {
    const allFloors = this.props.sessionStore?.[MEMORY_STORE.FLOORS] ?? [];
    const floors = allFloors.filter(floor => +floor.branch_id > 0 && +floor.branch_id === +branchId);
    return floors.map(floor => floor.floor_id);
  };

  getMeetingRoomOutlookData = async () => {
    this.getTableData();
  };

  getTableData = async () => {
    const { floors } = this.state;
    const { selectedDate } = this.props;
    if (!selectedDate || !size(floors)) {
      return;
    }
    this.setState({ isLoadingTable: true });
    let rooms = await getRoomAvailabilityByFloorIdList({
      floorIdList: floors,
    });
    rooms = rooms || [];
    const roomInfoMap = rooms.reduce((acc, item) => {
      acc[item.device_identifer] = item.device_name;
      return acc;
    }, {});
    let res = await getMeetingRoomMartTableDataByFloorIdListAndDate({
      floorIdList: floors,
      selectedDate: format(selectedDate, 'yyyy-MM-dd'),
    });

    res = res || [];

    let tableData = [];
    res.forEach(item => {
      const {
        room_name,
        reserved,
        reserved_and_unused,
        occupied_rate,
        unused_reservation,
        reservation_counts,
        unused_rate,
        device_identifer,
      } = item;
      tableData.push({
        room_id: device_identifer,
        room_name: room_name,
        reserved_and_unused: reserved_and_unused + '分',
        occupied_rate: occupied_rate,
        reserved: reserved + '分',
        reservation_counts: reservation_counts,
        unused_reservation: unused_reservation + '分',
        unused_rate: unused_rate,
      });
    });
    // check if roomInfoMap has room_id that is not in tableData
    Object.keys(roomInfoMap).forEach(roomId => {
      const isRoomExist = tableData.some(item => item.room_id === roomId);
      if (!isRoomExist) {
        tableData.push({
          room_id: roomId,
          room_name: roomInfoMap[roomId],
          reserved_and_unused: '0分',
          occupied_rate: 0,
          reserved: '0分',
          reservation_counts: 0,
          unused_reservation: '0分',
          unused_rate: 0,
        });
      }
    });

    tableData = tableData.sort((a, b) => {
      return a.room_name.localeCompare(b.room_name);
    });

    const newState = {
      data: tableData,
      isLoadingTable: false,
    };
    this.setState(newState);
  };

  renderMessageError = () => {
    const { branch } = this.state;
    if (!branch) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.BRANCH_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderDataTable({ data }) {
    const percentBodyTemplate = (dataItem, field) => {
      const value = dataItem[field];
      return (
        <>
          <div className="d-flex justify-content-end">
            <span>{formatPercentage(value)}</span>
          </div>
          <div
            className="percentage-bg"
            style={{ right: `${100 - value * 100}%` }}
          ></div>
        </>
      );
    };

    return (
      <DataTable
        className="outlook-data-table"
        value={data}
        tableStyle={{ minWidth: '50rem' }}
        size="medium"
        stripedRows
        rowHover
        scrollable
        scrollHeight="440px"
        showGridlines
        loading={this.state.isLoadingTable}
      >
        {this.dataColumns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable
            align={col.align}
            alignHeader={col.align}
            headerClassName={col.headerClassName}
            bodyClassName={col.bodyClassName}
            body={
              col.field === 'occupied_rate' || col.field === 'unused_rate'
                ? dataItem => percentBodyTemplate(dataItem, col.field)
                : null
            }
          />
        ))}
      </DataTable>
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          severity={csvDownload ? "secondary" : "info"}
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button has-shadow' : 'submit-button csv-download has-shadow'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const { data, branch, csvDownload } = this.state;
    const { selectedDate } = this.props;

    return (
      <div className="network-container meeting-room-outlook-table">
        <div className="network-title-container">
          <div className="text-left">
            <div>{COMMON_TEXT.MEETING_ROOM_OUTLOOK_USAGE}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            <div style={{ paddingLeft: '1rem' }}>
              {this.renderDownloadButton()}
            </div>
          </div>
        </div>
        {csvDownload ? (
          <CustomCSVDownload
            fromDate={selectedDate}
            toDate={selectedDate}
            type={CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE}
            branchId={branch}
          ></CustomCSVDownload>
        ) : (
          <div className="network-content">
            {this.renderMessageError() || (
              <>
                <div className="table-container">
                  <>{this.renderDataTable({ data })}</>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

MeetingRoomOutlookTable.propTypes = {};

export default MeetingRoomOutlookTable;
