/**
 *
 * DynamicParameterSidebar
 *
 */

import DynamicParameterSidebar from './DynamicParameterSidebar.jsx';
import './DynamicParameterSidebar.less';

export default DynamicParameterSidebar;
