/**
 *
 * SignageComponents
 *
 */

import SignageComponents from './SignageComponents.jsx';
import './SignageComponents.less';

export default SignageComponents;
