import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getCurrentFullDateFormattedInTimeZone } from '@/helpers/utility';

const buildQueryRoomAvailabilityByFloorIdList = ({ floorIdList }) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        floor_id: ['select'],
        room_availability: ['select'],
        timestamp: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [...floorIdList],
      timestamp: {
        lte: [getCurrentFullDateFormattedInTimeZone()],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ROOM_AVAILABILITIES_MART,
  };
};

export const fetchRoomAvailabilityByFloorIdList = async ({ floorIdList }) => {
  const request = buildQueryRoomAvailabilityByFloorIdList({ floorIdList });
  return executeQuery(request);
};

// Case 1: fetch data with start is before startDateTime and end is after startDateTime and is before endDateTime
const buildQueryOutlookStartBeforeStartAndEndAfterStart = ({ floorIdList, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        in: [...floorIdList],
      },
      reserved_start: {
        lte: [startDateTime],
      },
      reserved_end: {
        gte: [startDateTime],
        lte: [endDateTime],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};

export const fetchDataOutlookStartBeforeStartAndEndAfterStart = async ({ floorIdList, startDateTime, endDateTime }) => {
  const request = buildQueryOutlookStartBeforeStartAndEndAfterStart({ floorIdList, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 2: fetch data with start is before startDateTime and end is after endDateTime
const buildQueryOutlookStartBeforeStartAndEndAfterEnd = ({ floorIdList, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        in: [...floorIdList],
      },
      reserved_start: {
        lte: [startDateTime],
      },
      reserved_end: {
        gte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartBeforeStartAndEndAfterEnd = async ({ floorIdList, startDateTime, endDateTime }) => {
  const request = buildQueryOutlookStartBeforeStartAndEndAfterEnd({ floorIdList, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 3: fetch data with start is after startDateTime and end is before endDateTime
const buildQueryOutlookStartAfterStartAndEndBeforeEnd = ({ floorIdList, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        in: [...floorIdList],
      },
      reserved_start: {
        gte: [startDateTime],
      },
      reserved_end: {
        lte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartAfterStartAndEndBeforerEnd = async ({ floorIdList, startDateTime, endDateTime }) => {
  const request = buildQueryOutlookStartAfterStartAndEndBeforeEnd({ floorIdList, startDateTime, endDateTime }
  );
  return executeQuery(request);
};

// case 4: fetch data with start is after startDateTime and end is after endDateTime
const buildQueryOutlookStartAfterStartAndEndAfterEnd = ({ floorIdList, startDateTime, endDateTime }) => {
  return {
    data: {
      fields: {
        organizer: ['select'],
        subject: ['select'],
        reserved_start: ['select'],
        reserved_end: ['select'],
        reserved_minutes: ['select'],
        room_name: ['select'],
        device_identifer: ['select'],
        occupied_minutes: ['select'],
        occupied_start: ['select'],
        occupied_end: ['select'],
        unused_start1: ['select'],
        unused_end1: ['select'],
        unused_start2: ['select'],
        unused_end2: ['select'],
        unused_minutes1: ['select'],
        unused_minutes2: ['select'],
        event_id: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        in: [...floorIdList],
      },
      reserved_start: {
        gte: [startDateTime],
      },
      reserved_end: {
        gte: [endDateTime],
      }
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      reserved_start: 'desc',
    },
    table_name: QUERY_TABLES.MEETING_ROOM_MART_LINE,
  };
};
export const fetchDataOutlookStartAfterStartAndEndAfterEnd = async ({ floorIdList, startDateTime, endDateTime }) => {
  const request = buildQueryOutlookStartAfterStartAndEndAfterEnd({ floorIdList, startDateTime, endDateTime }
  );
  return executeQuery(request);
};