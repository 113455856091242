/**
 *
 * AttendanceRateSetting
 *
 */

import AttendanceRateSetting from './AttendanceRateSetting.jsx';
import './AttendanceRateSetting.less';

export default AttendanceRateSetting;
