/**
 *
 * MeetingRoomOutlookBooking
 *
 */

import MeetingRoomOutlookBooking from './MeetingRoomOutlookBooking.jsx';
import './MeetingRoomOutlookBooking.less';

export default MeetingRoomOutlookBooking;
