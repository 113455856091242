import FpSdk from 'forepaas/sdk';

import DynamicParameterSidebar from './CustomComponent/DynamicParameterSidebar';
import FooterComponent from './CustomComponent/FooterComponent';
import HideShowSidebar from './CustomComponent/HideShowSidebar';
import Logo from './CustomComponent/Logo';
import LogoMobile from './CustomComponent/LogoMobile';
import RedirectPage from './CustomComponent/RedirectPage/RedirectPage';
import Toaster from './CustomComponent/Toaster';
import Username from './CustomComponent/Username';
import UsernameMobile from './CustomComponent/UsernameMobile';
import EmployeeAttendance from './communication/EmployeeAttendance';
import EmployeeLocations from './communication/EmployeeLocations';
import MeetingRoomUsage from './communication/MeetingRoomUsage';
import PeopleCounts from './communication/PeopleCounts';
import PeopleCountsAnalysis from './communication/PeopleCountsAnalysis';
import Dashboard from './dashboard/Dashboard';
import AirQuality from './facility_management/AirQuality';
import DeviceStatusOverview from './facility_management/DeviceStatusOverview';
import MeetingRoomOutlook from './facility_management/MeetingRoomOutlookUsage/MeetingRoomOutlook';
import MeetingRoomOutlookUsage from './facility_management/MeetingRoomOutlookUsage/MeetingRoomOutlookUsage';
import MerakiNetworkEvents from './facility_management/MerakiNetworkEvents';
import VerkadaNetworkEvents from './facility_management/VerkadaNetworkEvents';
import Network from './facility_management/performance/Network';
import NetworkClientCount from './facility_management/performance/NetworkClientCount';
import NetworkPerformanceScore from './facility_management/performance/NetworkPerformanceScore';
import NetworkUsage from './facility_management/performance/NetworkUsage';
import WirelessChannelUtilization from './facility_management/performance/WirelessChannelUtilization';
import WirelessLatency from './facility_management/performance/WirelessLatency';
import WirelessSignal from './facility_management/performance/WirelessSignal';
import BCPOverview from './facility_management/BCPOverview';
import EmployeeAccessLog from './labor_management/EmployeeAccessLog';
import Configuration from './organization/Configuration';
//
import SideBar from './CustomComponent/SideBar';
import Account from './organization/Account';
import AirQualitySetting from './organization/Configuration/AirQualitySetting';
import AreaSetting from './organization/Configuration/AreaSetting';
import CameraZoneSetting from './organization/Configuration/CameraZoneSetting';
import DeviceLocationSetting from './organization/Configuration/DeviceLocationSetting';
import EmployeeSetting from './organization/Configuration/EmployeeSetting';
import EmployeeTerminalSetting from './organization/Configuration/EmployeeTerminalSetting';
import FloorSetting from './organization/Configuration/FloorSetting';
import LocationSetting from './organization/Configuration/LocationSetting';
import OrganizationSetting from './organization/Configuration/OrganizationSetting';
import TenantSetting from './organization/Configuration/TenantSetting';
import VerkadaDeviceSetting from './organization/Configuration/VerkadaDeviceSetting';
import WorkingHourSetting from './organization/Configuration/WorkingHourSetting';
import AttendanceRateSetting from './organization/Configuration/AttendanceRateSetting';
import Summary from './organization/Summary';
import SignageComponents from './signage_components';

export default {
  components: {
    FooterComponent,
    HideShowSidebar,
    Logo,
    LogoMobile,
    RedirectPage,
    AirQuality,
    Dashboard,
    DeviceStatusOverview,
    PeopleCounts,
    PeopleCountsAnalysis,
    EmployeeAttendance,
    DynamicParameterSidebar,
    EmployeeAccessLog,
    EmployeeLocations,
    MeetingRoomOutlook,
    MeetingRoomOutlookUsage,
    MeetingRoomUsage,
    MerakiNetworkEvents,
    Network,
    NetworkClientCount,
    NetworkPerformanceScore,
    NetworkUsage,
    SideBar,
    Toaster,
    Username,
    UsernameMobile,
    VerkadaNetworkEvents,
    WirelessChannelUtilization,
    WirelessLatency,
    WirelessSignal,
    Configuration,
    //
    TenantSetting,
    LocationSetting,
    OrganizationSetting,
    DeviceLocationSetting,
    EmployeeSetting,
    AreaSetting,
    EmployeeTerminalSetting,
    VerkadaDeviceSetting,
    WorkingHourSetting,
    FloorSetting,
    CameraZoneSetting,
    AirQualitySetting,
    AttendanceRateSetting,
    //
    Summary,
    Account,
    SignageComponents,
    BCPOverview,
  },
  camelCaseToDash(myStr) {
    return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  },
  init() {
    for (const component in this.components) {
      FpSdk.modules[this.camelCaseToDash(component)] =
        this.components[component];
    }
  },
};
