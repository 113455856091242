import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CHART_NAME,
  CSV_DOWNLOAD_COMPONENT,
  LINE_CHART_COLOR_LIST,
} from '@/helpers/constants';
import { getRandomHexColor, isObjectEmpty } from '@/helpers/utility';
import { compareAsc, format, isSameDay } from 'date-fns';
import debounce from 'lodash/debounce';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSensorDataHistory } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AirQualityHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorData: [],
      isLoadingData: false,
      selectedDate: new Date(),
      selectedTimeRange: {
        fromTime: '00:00',
        toTime: '01:00',
      },
      selectedDevices: [],
      deviceOptions: [],
      csvDownload: false,
    };
    this.sensorTypeOptions = [
      {
        label: COMMON_TEXT.TEMPERATURE,
        value: 'temperature',
      },
      {
        label: COMMON_TEXT.HUMIDITY,
        value: 'humidity',
      },
      {
        label: COMMON_TEXT.CARBON_DIOXIDE,
        value: 'carbon_dioxide',
      },
      {
        label: COMMON_TEXT.NOISE_LEVEL,
        value: 'noise_level',
      },
    ];
    this.periodDropDownOptions = [
      { label: '24時間', value: 'last24' },
      { label: '1週間', value: 'lastweek' },
      { label: '1ヶ月', value: 'lastmonth' },
    ];
    this.timeRangeOptions = [
      { label: '01:00', value: '01:00' },
      { label: '02:00', value: '02:00' },
      { label: '03:00', value: '03:00' },
      { label: '04:00', value: '04:00' },
      { label: '05:00', value: '05:00' },
      { label: '06:00', value: '06:00' },
      { label: '07:00', value: '07:00' },
      { label: '08:00', value: '08:00' },
      { label: '09:00', value: '09:00' },
      { label: '10:00', value: '10:00' },
      { label: '11:00', value: '11:00' },
      { label: '12:00', value: '12:00' },
      { label: '13:00', value: '13:00' },
      { label: '14:00', value: '14:00' },
      { label: '15:00', value: '15:00' },
      { label: '16:00', value: '16:00' },
      { label: '17:00', value: '17:00' },
      { label: '18:00', value: '18:00' },
      { label: '19:00', value: '19:00' },
      { label: '20:00', value: '20:00' },
      { label: '21:00', value: '21:00' },
      { label: '22:00', value: '22:00' },
      { label: '23:00', value: '23:00' },
    ];
    this.fromTimeRangeOptions = [
      { label: '00:00', value: '00:00' },
      ...this.timeRangeOptions,
    ];
    this.toTimeRangeOptions = [
      ...this.timeRangeOptions,
      { label: '23:59', value: '23:59' },
    ];
  }

  getDefaultTimeRange = () => {
    const currentDate = new Date();
    let currentHour = currentDate.getHours();
    currentHour = currentHour < 10 ? `0${currentHour}` : `${currentHour}`;
    const fromTime = `00:00`;
    const toTime = `${currentHour}:00`;
    return { fromTime, toTime };
  };

  componentDidMount() {
    const { floor } = this.props;
    const { fromTime, toTime } = this.getDefaultTimeRange();
    this.setState(
      {
        selectedTimeRange: {
          fromTime,
          toTime,
        },
      },
      () => {
        if (floor) {
          this.getAllData();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { floor } = this.props;
    const { floor: prevFloor } = prevProps;
    const isFloorChanged = floor !== prevFloor;
    if (isFloorChanged) {
      this.getAllData();
    }
  }

  getAllData = async () => {
    const { floor } = this.props;
    const { selectedDate, selectedTimeRange } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    if (!floor || !selectedDate) {
      return;
    }
    this.setState({ isLoadingData: true });
    const {
      sensorData,
      deviceOptions: updatedDeviceOptions,
      selectedDevices: updatedSelectedDevices,
    } = await this.getSensorData({
      floor,
      selectedDate,
      fromTime,
      toTime,
    });
    this.setState({
      isLoadingData: false,
      sensorData,
      deviceOptions: updatedDeviceOptions,
      selectedDevices: updatedSelectedDevices,
    });
  };

  getSensorData = async ({ floor, selectedDate, fromTime, toTime }) => {
    const selectedDateString = format(selectedDate, 'yyyy-MM-dd');
    let sensorDataRes = await fetchSensorDataHistory({
      floorId: floor,
      fromDate: `${selectedDateString} ${fromTime}:00`,
      toDate: `${selectedDateString} ${toTime}:00`,
    });

    const deviceInfoMap = {};
    const allTimestampInHourSet = new Set();
    let deviceOptions = [];
    let selectedDevices = [];
    if (sensorDataRes && sensorDataRes.length > 0) {
      sensorDataRes.forEach((item, index) => {
        const {
          device_identifer,
          device_name,
          timestamp,
          temperature,
          humidity,
          carbon_dioxide,
          noise_level,
        } = item;
        if (!deviceInfoMap[device_identifer]) {
          deviceInfoMap[device_identifer] = {
            name: device_name,
            deviceId: device_identifer,
            data: [],
            fillColor: LINE_CHART_COLOR_LIST[index] ?? getRandomHexColor(),
          };
        }
        const timestampInHour = timestamp.split(' ')[1];
        deviceInfoMap[device_identifer]['data'].push({
          timestamp,
          timestampInHour,
          timestampObject: new Date(item.timestamp),
          temperature,
          humidity,
          carbon_dioxide,
          noise_level,
        });
        allTimestampInHourSet.add(timestampInHour);
      });
      const deviceIdList = Object.keys(deviceInfoMap);
      deviceOptions = deviceIdList
        .map(deviceId => ({
          name: deviceInfoMap[deviceId]['name'],
          code: deviceId,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      // set selected devices to first 5 devices of deviceOptions
      selectedDevices = deviceOptions.slice(0, 5);

      deviceIdList.forEach(deviceId => {
        const data = deviceInfoMap[deviceId]['data'];
        const timestampSet = new Set(
          data.map(item => format(item.timestampObject, 'HH:mm:ss'))
        );
        const missingTimestamps = [...allTimestampInHourSet].filter(
          x => !timestampSet.has(x)
        );

        missingTimestamps.forEach(missingTs => {
          data.push({
            timestamp: `${format(selectedDate, 'yyyy/MM/dd')} ${missingTs}`,
            timestampInHour: missingTs,
            timestampObject: new Date(
              `${format(selectedDate, 'yyyy/MM/dd')} ${missingTs}`
            ),
            temperature: null,
            humidity: null,
            carbon_dioxide: null,
            noise_level: null,
          });
        });

        data.sort((a, b) => compareAsc(a.timestampObject, b.timestampObject));
      });
    }
    return {
      sensorData: Object.values(deviceInfoMap),
      deviceOptions,
      selectedDevices,
    };
  };

  datePickerOnChanged = e => {
    const BOUNCE_IN_MILISECONDS = 300;
    const { selectedDate } = this.state;
    if (!isSameDay(e.value, selectedDate)) {
      const debouncedFunc = debounce(() => {
        this.getAllData();
      }, BOUNCE_IN_MILISECONDS);
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        debouncedFunc();
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }

    this.setState({ selectedDate: e.value });
  };

  renderDatePicker() {
    const { selectedDate, isLoadingData } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        disabled={isLoadingData}
        value={selectedDate}
        onChange={this.datePickerOnChanged}
        maxDate={maxDate}
      ></CustomCalendar>
    );
  }

  timeRangeOnChanged = (e, type) => {
    let newVal = e.value;
    const { selectedTimeRange } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    let updatedState = {};
    if (type === 'fromTime') {
      if (newVal >= toTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex + 1;
        newIndex =
          newIndex > this.timeRangeOptions.length - 1
            ? this.timeRangeOptions.length - 1
            : newIndex;
        const newToTime = this.toTimeRangeOptions[newIndex].value;
        updatedState = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: newToTime,
          },
        };
      } else {
        updatedState = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: this.state.selectedTimeRange.toTime,
          },
        };
      }
    } else if (type === 'toTime') {
      if (newVal <= fromTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex;
        newIndex = newIndex < 0 ? 0 : newIndex;
        const newFromTime = this.fromTimeRangeOptions[newIndex].value;
        updatedState = {
          selectedTimeRange: {
            fromTime: newFromTime,
            toTime: newVal,
          },
        };
      } else {
        updatedState = {
          selectedTimeRange: {
            fromTime: this.state.selectedTimeRange.fromTime,
            toTime: newVal,
          },
        };
      }
    }
    this.setState(
      updatedState,
      debounce(() => {
        this.getAllData();
      }, 500)
    );
  };

  renderTimeRangeDropdown() {
    const { selectedTimeRange } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    return (
      <div className="timerange-select-container">
        <CustomDropdown
          label="開始"
          value={fromTime}
          options={this.fromTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'fromTime')}
        ></CustomDropdown>
        <CustomDropdown
          label="終了"
          value={toTime}
          options={this.toTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'toTime')}
        ></CustomDropdown>
      </div>
    );
  }

  multiSelectDevicesOnChanged = e => {
    this.setState({ selectedDevices: e.value });
  };

  renderMultiSelectDevices = () => {
    const { selectedDevices, deviceOptions } = this.state;

    const itemTemplate = option => {
      return (
        <div className="multi-select-item-template" style={{backgroundColor: option.fillColor}}>
          {option.name}
        </div>
      );
    };
    
    const footerTemplate = () => {
      const length = selectedDevices ? selectedDevices.length : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };
    return (
      <MultiSelect
        value={selectedDevices}
        options={deviceOptions}
        disabled={deviceOptions.length === 0}
        onChange={this.multiSelectDevicesOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className="multiselect-style"
        display="chip"
      />
    );
  };

  renderMessageError = () => {
    const { floor } = this.props;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  getThresholdRange = ({ type, sensorDataThreshold }) => {
    if (!isObjectEmpty(sensorDataThreshold)) {
      let defaultThresholdArray = [];
      const poorLow = sensorDataThreshold[`${type}_poor_low`];
      const badLow = sensorDataThreshold[`${type}_bad_low`];
      const fairLow = sensorDataThreshold[`${type}_fair_low`];
      const fairHigh = sensorDataThreshold[`${type}_fair_high`];
      const badHigh = sensorDataThreshold[`${type}_bad_high`];
      const poorHigh = sensorDataThreshold[`${type}_poor_high`];
      if (type === 'temperature' || type === 'humidity') {
        defaultThresholdArray = [
          [Number.MIN_SAFE_INTEGER, poorLow, 'rgba(210, 20, 4, 0.5)'],
          [poorLow, badLow, 'rgba(255, 153, 68, 0.5)'],
          [badLow, fairLow, 'rgba(255, 225, 53, 0.5)'],
          [fairLow, fairHigh, 'rgba(0, 168, 107, 0.5)'],
          [fairHigh, badHigh, 'rgba(255, 225, 53, 0.5)'],
          [badHigh, poorHigh, 'rgba(255, 153, 68, 0.5)'],
          [poorHigh, Number.MAX_SAFE_INTEGER, 'rgba(210, 20, 4, 0.5)'],
        ];
      } else if (type === 'carbon_dioxide' || type === 'noise_level') {
        defaultThresholdArray = [
          [Number.MIN_SAFE_INTEGER, fairHigh, 'rgba(0, 168, 107, 0.5)'],
          [fairHigh, badHigh, 'rgba(255, 225, 53, 0.5)'],
          [badHigh, poorHigh, 'rgba(255, 153, 68, 0.5)'],
          [poorHigh, Number.MAX_SAFE_INTEGER, 'rgba(210, 20, 4, 0.5)'],
        ];
      }
      const thresholdRange = defaultThresholdArray.map(item => {
        return {
          fromValue: item[0],
          toValue: item[1],
          color: item[2],
        };
      });
      return thresholdRange;
    }
    return [];
  };

  renderLineChart({ data: lineChartData, type }) {
    const { isLoadingData, selectedDevices } = this.state;
    const { sensorDataThreshold } = this.props;
    lineChartData = lineChartData ?? [];
    const filteredDisplayItems = lineChartData.filter(item =>
      selectedDevices.some(
        deviceItem =>
          deviceItem?.code?.toLowerCase() === item.deviceId?.toLowerCase()
      )
    );
    const sortedDisplayItems = filteredDisplayItems.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const thresholdRange = this.getThresholdRange({
      type,
      sensorDataThreshold,
    });

    let yAxisLabel = '';
    let chartName = '';
    let tooltipLabel = '';
    switch (type) {
      case 'temperature':
        yAxisLabel = '℃';
        chartName = CHART_NAME.TEMPERATURE_LINE_CHART;
        tooltipLabel = COMMON_TEXT.TEMPERATURE;
        break;
      case 'humidity':
        yAxisLabel = '%';
        chartName = CHART_NAME.HUMIDITY_LINE_CHART;
        tooltipLabel = COMMON_TEXT.HUMIDITY;
        break;
      case 'carbon_dioxide':
        yAxisLabel = COMMON_TEXT.CARBON_DIOXIDE_UNIT;
        chartName = CHART_NAME.CARBON_DIOXIDE_LINE_CHART;
        tooltipLabel = COMMON_TEXT.CARBON_DIOXIDE;
        break;
      case 'noise_level':
        yAxisLabel = 'dB';
        chartName = CHART_NAME.NOISE_LEVEL_LINE_CHART;
        tooltipLabel = COMMON_TEXT.NOISE_LEVEL;
        break;
      default:
        break;
    }

    return (
      <CustomLineChart
        name={chartName}
        tooltipLabel={tooltipLabel}
        data={sortedDisplayItems}
        xAxisDataKey={'timestamp'}
        yAxisDataKey={type}
        yAxisLabel={yAxisLabel}
        isLoading={isLoadingData}
        thresholdRange={thresholdRange}
      />
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          severity={csvDownload ? "secondary" : "info"}
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button has-shadow' : 'submit-button csv-download has-shadow'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  render() {
    const {
      sensorData,
      csvDownload,
      selectedDevices,
      selectedTimeRange,
      selectedDate,
    } = this.state;
    const { floor } = this.props;

    let deviceIdList = selectedDevices.map(item => item.code);
    return (
      <div className="network-container air-quality-history">
        <div className="network-content">
          {this.renderMessageError() || (
            <>
              <div className="sensor-menu-container">
                {this.renderDatePicker()}
                {this.renderTimeRangeDropdown()}
              </div>
              <div className="sensor-menu-container mt-2">
                {this.renderMultiSelectDevices()}
              </div>
              <div
                style={{
                  paddingTop: '1rem',
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'flex-end',
                }}
              >
                {this.renderDownloadButton()}
              </div>
              {csvDownload ? (
                <CustomCSVDownload
                  fromDate={selectedDate}
                  toDate={selectedDate}
                  type={CSV_DOWNLOAD_COMPONENT.SENSOR_DATA_HISTORY}
                  deviceIdList={[...deviceIdList]}
                  selectedTimeRange={selectedTimeRange}
                  floorId={floor}
                ></CustomCSVDownload>
              ) : (
                <div className="data-container">
                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.TEMPERATURE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: sensorData,
                        type: 'temperature',
                      })}
                    </div>
                  </div>

                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">{COMMON_TEXT.HUMIDITY}</div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: sensorData,
                        type: 'humidity',
                      })}
                    </div>
                  </div>

                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.CARBON_DIOXIDE}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: sensorData,
                        type: 'carbon_dioxide',
                      })}
                    </div>
                  </div>

                  <div className="data-container__item">
                    <div className="title-chart-container">
                      <div className="title-chart">
                        {COMMON_TEXT.NOISE_LEVEL}
                      </div>
                      <div className="title-chart__right"></div>
                    </div>
                    <div className="chart-container">
                      {this.renderLineChart({
                        data: sensorData,
                        type: 'noise_level',
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

AirQualityHistory.propTypes = {};

export default AirQualityHistory;
