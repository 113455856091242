import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import './image-marker.less';
import { calculateMarkerPosition } from './utils';

const DEFAULT_BUFFER = 12;
const INITIAL_SCALE = 1.0;

const ImageAreaMarker = ({
  src,
  markers,
  areaMarkers: areaMarkersProp,
  onAddMarker,
  markerComponent: MarkerComponent,
  areaMarkerComponent: AreaMarkerComponent,
  bufferLeft = DEFAULT_BUFFER,
  bufferTop = DEFAULT_BUFFER,
  alt = 'Markers',
  extraClass = '',
  onAreaMarkersChange,
}) => {
  const imageRef = useRef(null);
  const imageMarkerContainerAreaMapRef = useRef(null);
  const imageMapRef = useRef(null);
  const [areaMarkers, setAreaMarkers] = React.useState([]);
  const [isDisableDragging, setIsDisableDragging] = useState(false);
  const [currentScaleValue, setCurrentScaleValue] = useState(INITIAL_SCALE);
  const [selectedAreaMarkerId, setSelectedAreaMarkerId] = useState(null);

  useEffect(() => {
    setAreaMarkers(cloneDeep(areaMarkersProp));
  }, [areaMarkersProp]);

  useEffect(() => {
    if (imageMarkerContainerAreaMapRef.current) {
      imageMarkerContainerAreaMapRef.current.addEventListener('click', () => {
        setSelectedAreaMarkerId(null);
      });
    }
  }, []);

  const updateAreaMarker = ({ area_id, newRectData }) => {
    const findAreaMarker = areaMarkers.find(
      areaMarker => areaMarker.area_id === area_id
    );
    if (findAreaMarker) {
      const newTopPercentValue = +newRectData.topPercent;
      const newLeftPercentValue = +newRectData.leftPercent;
      const newWidthPercentage = +newRectData.widthPercent;
      const newHeightPercentage = +newRectData.heightPercent;
      findAreaMarker['newRect'] = {
        top: newTopPercentValue,
        left: newLeftPercentValue,
        width: newWidthPercentage,
        height: newHeightPercentage,
      };
    } else {
      console.warn('AreaMarker not found');
    }
    setAreaMarkers([...areaMarkers]);
    onAreaMarkersChange({ areaList: areaMarkers });
  };

  const updateAreaMarkerStatus = ({ status }) => {
    if (status === 'dragging' || status === 'resizing') {
      setIsDisableDragging(true);
    } else {
      setIsDisableDragging(false);
    }
  };

  const updateSelectedAreaMarkerId = ({ area_id }) => {
    setSelectedAreaMarkerId(area_id);
  };

  const handleImageClick = event => {
    if (!imageRef.current || !onAddMarker) {
      return;
    }
    const imageDimentions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateMarkerPosition(
      event,
      imageDimentions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    onAddMarker({
      top,
      left,
    });
  };

  const getItemPosition = marker => {
    const { top, left } = marker;
    return {
      top: `${top}%`,
      left: `${left}%`,
    };
  };

  const getAreaPosition = areaMarker => {
    const { rect, newRect } = areaMarker;
    if (newRect) {
      return {
        top: `${newRect.top}%`,
        left: `${newRect.left}%`,
        width: `${newRect.width}%`,
        height: `${newRect.height}%`,
      };
    } else if (rect) {
      return {
        top: `${rect.top}%`,
        left: `${rect.left}%`,
        width: `${rect.width}%`,
        height: `${rect.height}%`,
      };
    }
    return {
      top: `${0}%`,
      left: `${0}%`,
      width: `${0}%`,
      height: `${0}%`,
    };
  };

  return (
    <TransformWrapper
      ref={imageMapRef}
      wheel={{ step: 0.1, disabled: true }}
      minScale={0.2}
      maxScale={2.0}
      initialScale={INITIAL_SCALE}
      panning={{ disabled: false }}
      disabled={isDisableDragging}
      onTransformed={(ref, event) => {
        setCurrentScaleValue(ref.state.scale);
      }}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="image-marker-tools p-buttonset">
            <Button
              icon="pi pi-plus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomIn()}
            />
            <Button
              icon="pi pi-arrows-alt ml-1 mr-1"
              size="small"
              outlined
              severity="info"
              onClick={() => resetTransform()}
            />
            <Button
              icon="pi pi-minus"
              size="small"
              outlined
              severity="info"
              onClick={() => zoomOut()}
            />
          </div>
          <TransformComponent
            wrapperStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            contentStyle={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
          >
            <div
              className="image-marker"
              id={'imageMarkerContainerAreaMap'}
              ref={imageMarkerContainerAreaMapRef}
            >
              <div>
                <img
                  src={src}
                  alt={alt}
                  onClick={handleImageClick}
                  className={'image-marker__image ' + extraClass}
                  ref={imageRef}
                />
              </div>
              {markers.map((marker, itemNumber) => (
                <div
                  className={`image-marker__marker ${
                    !MarkerComponent && 'image-marker__marker--default'
                  }`}
                  key={itemNumber}
                  style={{
                    ...getItemPosition(marker),
                    cursor: 'pointer',
                    zIndex: 902,
                  }}
                  data-testid="marker"
                >
                  {MarkerComponent ? (
                    <MarkerComponent {...marker} itemNumber={itemNumber} />
                  ) : (
                    itemNumber + 1
                  )}
                </div>
              ))}
              {areaMarkers.map((areaMarker, itemNumber) => (
                <div
                  className={`image-marker__marker area-marker__marker ${
                    !AreaMarkerComponent && 'image-marker__marker--default'
                  }`}
                  key={`area-marker-${itemNumber}`}
                  style={{
                    ...getAreaPosition(areaMarker),
                    cursor: 'pointer',
                    zIndex: 900,
                  }}
                  data-testid="marker"
                >
                  {AreaMarkerComponent ? (
                    <AreaMarkerComponent
                      {...areaMarker}
                      newRect={areaMarker.newRect}
                      // eslint-disable-next-line eqeqeq
                      isSelected={selectedAreaMarkerId == areaMarker.area_id}
                      itemNumber={itemNumber}
                      imageDimension={imageRef}
                      updateAreaMarker={updateAreaMarker}
                      updateAreaMarkerStatus={updateAreaMarkerStatus}
                      updateSelectedAreaMarkerId={updateSelectedAreaMarkerId}
                      scaleValue={currentScaleValue}
                    />
                  ) : (
                    itemNumber + 1
                  )}
                </div>
              ))}
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default ImageAreaMarker;
