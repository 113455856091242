/**
 *
 * DeviceLocationSetting
 *
 */

import DeviceLocationSetting from './DeviceLocationSetting.jsx';
import './DeviceLocationSetting.less';

export default DeviceLocationSetting;
