import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
  MEMORY_STORE,
} from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { FieldArray, Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import {
  fetchEmployeeList,
  fetchEmployeeAttendanceList,
} from './query-request';

const ATTENDANCE_RATE_TYPE = {
  BRANCH: 'branch',
  FLOOR: 'floor',
  DEPARTMENT: 'department',
};

const ATTENDANCE_RATE_STATUS = {
  DELETED: 'DELETED',
  MODIFIED: 'MODIFIED',
  NEW: 'NEW',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AttendanceRateSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      isUpdating: false,
      isUpdateDialogVisible: false,
      updateDataPayload: { values: [] },

      branchList: [],
      floorList: [],
      departmentList: [],

      branchOptions: [],
      floorOptions: [],
      departmentOptions: [],
    };
    this.toastRef = React.createRef();
    this.authToken = new AuthToken();
    this.employeeAttendanceList = [];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getEmployeeAttendanceByTenantId = async ({ tenantId }) => {
    const attendancesRes = await fetchEmployeeAttendanceList({ tenantId });
    return attendancesRes || [];
  };

  getBranchList = ({ tenantId }) => {
    const branchesRes = this.props.sessionStore?.[MEMORY_STORE.BRANCHES] ?? [];
    const branchOptions = [];

    branchesRes.forEach(item => {
      if (item.branch_id && item.branch_name && item.tenant_id === tenantId) {
        branchOptions.push({
          label: item.branch_name,
          value: item.branch_id,
        });
      }
    });

    return (
      branchOptions.sort((a, b) => {
        return a.label.localeCompare(b.label, 'ja');
      }) || []
    );
  };

  getFloorList = ({ branchOption }) => {
    const branchIds = branchOption.map(branch => branch.value);
    const floorsRes = this.props.sessionStore?.[MEMORY_STORE.FLOORS] ?? [];
    const floorOptions = [];
    floorsRes.forEach(item => {
      const haveBranchId = branchIds.some(id => id === item.branch_id);
      if (haveBranchId && item.branch_id && item.floor_id && item.floor_name) {
        let floorName = item.floor_name;
        const findBranch = branchOption.find(
          branch => item.branch_id === branch.value
        );

        if (findBranch && findBranch.label) {
          floorName = `${findBranch.label} - ${item.floor_name}`;
        }

        floorOptions.push({
          label: floorName,
          value: item.floor_id,
        });
      }
    });

    return (
      floorOptions.sort((a, b) => {
        return a.label.localeCompare(b.label, 'ja');
      }) || []
    );
  };

  getDepartmentListByTenantId = async ({ tenantId }) => {
    const employeesRes = await fetchEmployeeList({ tenantId });
    const departmentList = employeesRes
      .map(e => e.department)
      .filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

    const departmentOptions = departmentList.map(item => ({
      label: item,
      value: item,
    }));

    return (
      departmentOptions.sort((a, b) => {
        return a.label.localeCompare(b.label, 'ja');
      }) || []
    );
  };

  getData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    const attendanceList = await this.getEmployeeAttendanceByTenantId({
      tenantId: tenant,
    });
    const branchOptionList = this.getBranchList({ tenantId: tenant });
    const floorOptionList = this.getFloorList({
      branchOption: branchOptionList,
    });
    const departmentOptionList = await this.getDepartmentListByTenantId({
      tenantId: tenant,
    });

    const filterAttendanceList = attendanceList.filter(item => {
      switch (item.type) {
        // Handle remove branch_id don't have at branchOptionList
        case ATTENDANCE_RATE_TYPE.BRANCH:
          const findBranchId = branchOptionList.find(
            branch => branch.value === item.branch_id
          );
          if (findBranchId) {
            return true;
          }
          break;
        // Handle remove floor_id don't have at floorOptionList
        case ATTENDANCE_RATE_TYPE.FLOOR:
          const findFloorId = floorOptionList.find(
            floor => floor.value === item.floor_id
          );
          if (findFloorId) {
            return true;
          }
          break;
        // Handle remove department don't have at departmentOptionList
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          const findDepartment = departmentOptionList.find(
            department => department.value === item.department
          );
          if (findDepartment) {
            return true;
          }
          break;
        default:
          break;
      }
      return false;
    });

    const branchList = filterAttendanceList.filter(e => e.type === 'branch');
    const floorList = filterAttendanceList.filter(e => e.type === 'floor');
    const departmentList = filterAttendanceList.filter(
      e => e.type === 'department'
    );

    this.setState({
      branchList,
      floorList,
      departmentList,
      branchOptions: branchOptionList,
      floorOptions: floorOptionList,
      departmentOptions: departmentOptionList,
      isLoading: false,
    });
    this.employeeAttendanceList = cloneDeep(filterAttendanceList);
  };

  findItemChanges({ updatedArray, originalArray }) {
    // Finding newItems
    const newItems = updatedArray.filter(item => item.capacity_id === null);

    // Finding deletedItems
    const deletedItems = originalArray.filter(originalItem => {
      return !updatedArray.some(
        uItem => uItem.capacity_id === originalItem.capacity_id
      );
    });

    // Finding updatedItems
    const updatedItems = updatedArray.filter(uItem => {
      // If the item is in the deleted list, it can't be in the updated list
      if (deletedItems.some(dItem => dItem.capacity_id === uItem.capacity_id))
        return false;

      const originalItem = originalArray.find(
        oItem => oItem.capacity_id === uItem.capacity_id
      );

      if (!originalItem) return false; // if not found in originalArray, it can't be updated

      // Check for branch_id, floor_id or department changes
      return (
        originalItem.branch_id !== uItem.branch_id ||
        originalItem.floor_id !== uItem.floor_id ||
        originalItem.department !== uItem.department ||
        originalItem.capacity !== uItem.capacity
      );
    });

    return {
      newItems,
      deletedItems,
      updatedItems,
    };
  }

  handleCreateUpdateData = item => {
    let newObj = {
      id: item.capacity_id ?? null,
      type: item.type,
      capacity: item.capacity,
    };
    switch (item.type) {
      case ATTENDANCE_RATE_TYPE.BRANCH:
        newObj.branch_id = item.branch_id;
        break;
      case ATTENDANCE_RATE_TYPE.FLOOR:
        newObj.floor_id = item.floor_id;
        break;
      case ATTENDANCE_RATE_TYPE.DEPARTMENT:
        newObj.department = item.department;
        break;
      default:
        break;
    }
    return newObj;
  };

  handleRenderTable = ({
    createDeleteArray,
    updateArray,
    branchOptions,
    floorOptions,
  }) => {
    const tableValueBranches = [];
    const tableValueFloors = [];
    const tableValueDepartments = [];

    const handleFindName = ({ id, list }) => {
      const find = list.find(item => item.value === id);
      if (!find || !find.label) {
        return '';
      }
      return find.label;
    };

    createDeleteArray.forEach(item => {
      let newObj = {
        type: item.type,
        capacity: item.capacity,
      };
      switch (item.type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          const branchName = handleFindName({
            id: item.branch_id,
            list: branchOptions,
          });
          newObj.branch_name = branchName;
          newObj.status =
            item.capacity_id === null
              ? ATTENDANCE_RATE_STATUS.NEW
              : ATTENDANCE_RATE_STATUS.DELETED;
          tableValueBranches.push(newObj);
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          const floorName = handleFindName({
            id: item.floor_id,
            list: floorOptions,
          });
          newObj.floor_name = floorName;
          newObj.status =
            item.capacity_id === null
              ? ATTENDANCE_RATE_STATUS.NEW
              : ATTENDANCE_RATE_STATUS.DELETED;
          tableValueFloors.push(newObj);
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          newObj.department = item.department;
          newObj.status =
            item.capacity_id === null
              ? ATTENDANCE_RATE_STATUS.NEW
              : ATTENDANCE_RATE_STATUS.DELETED;
          tableValueDepartments.push(newObj);
          break;
        default:
          break;
      }
    });

    updateArray.forEach(itemUpdate => {
      let newObj = {
        type: itemUpdate.type,
        capacity: itemUpdate.capacity,
      };
      switch (itemUpdate.type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          const branchName = handleFindName({
            id: itemUpdate.branch_id,
            list: branchOptions,
          });
          newObj.branch_name = branchName;
          newObj.status = ATTENDANCE_RATE_STATUS.MODIFIED;
          tableValueBranches.push(newObj);
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          const floorName = handleFindName({
            id: itemUpdate.floor_id,
            list: floorOptions,
          });
          newObj.floor_name = floorName;
          newObj.status = ATTENDANCE_RATE_STATUS.MODIFIED;
          tableValueFloors.push(newObj);
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          newObj.department = itemUpdate.department;
          newObj.status = ATTENDANCE_RATE_STATUS.MODIFIED;
          tableValueDepartments.push(newObj);
          break;
        default:
          break;
      }
    });

    return {
      tableValueBranches,
      tableValueFloors,
      tableValueDepartments,
    };
  };

  getUpdateDataPayload = ({ newItems, deletedItems, updatedItems }) => {
    let payload = {
      values: [],
    };
    // Create
    for (let item of newItems) {
      const createObj = this.handleCreateUpdateData(item);
      payload.values.push(createObj);
    }

    // Delete
    for (let item of deletedItems) {
      payload.values.push({
        id: item.capacity_id,
        capacity: null,
      });
    }

    // Update
    for (let item of updatedItems) {
      const updateObj = this.handleCreateUpdateData(item);
      payload.values.push(updateObj);
    }
    return payload;
  };

  updateInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.ATTENDANCE_RATE,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE ATTENDANCE RATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE ATTENDANCE RATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    // eslint-disable-next-line
    const { isSuccess, executionId } = await this.updateInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    }

    // eslint-disable-next-line
    if (!isSuccess) {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  showMessage = ({ detail }) => {
    this.toastRef.current.show({
      severity: 'info',
      detail,
      life: 3000,
      closable: true,
    });
  };

  saveAttendanceRateSetting = ({ updateDataPayload }) => {
    if (updateDataPayload.values.length > 0) {
      this.updateData({ updatePayLoad: updateDataPayload });
    }
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      branchOptions,
      floorOptions,
      departmentOptions,
      branchList,
      floorList,
      departmentList,
    } = this.state;

    const handleSubmit = ({ errors }) => {
      if (!errors) {
        const { newItems, deletedItems, updatedItems } = this.findItemChanges({
          updatedArray: [...branchList, ...floorList, ...departmentList],
          originalArray: this.employeeAttendanceList,
        });

        if (!newItems.length && !updatedItems.length && !deletedItems.length) {
          this.showMessage({
            detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
          });
          return;
        }

        const updatePayLoad = this.getUpdateDataPayload({
          newItems,
          deletedItems,
          updatedItems,
        });

        this.setState({
          isUpdateDialogVisible: true,
          updateDataPayload: updatePayLoad,
        });
      }
    };

    const deleteAccepted = ({ arrayHelpers, index, type }) => {
      const newList = arrayHelpers;
      // delete index
      newList.splice(index, 1);
      switch (type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          this.setState({
            branchList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          this.setState({
            floorList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          this.setState({
            departmentList: newList,
          });
          break;
        default:
          break;
      }
    };

    const deleteButtonOnClicked = ({ arrayHelpers, item, index }) => {
      const { capacity_id } = item;

      if (capacity_id > 0) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () =>
            deleteAccepted({ arrayHelpers, index, type: item.type }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index, type: item.type });
      }
    };

    const handleRemoveDuplicate = ({ listId, options }) =>
      options.filter(item => !listId.has(item.value));

    const handleClickAdd = ({ arrayHelpers, type, options }) => {
      const newList = arrayHelpers;
      let uniqueList = [];

      switch (type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          const branchIdSet = new Set(arrayHelpers.map(item => item.branch_id));

          uniqueList = handleRemoveDuplicate({
            listId: branchIdSet,
            options,
          });
          if (!uniqueList.length || !uniqueList[0].value) {
            this.showMessage({
              detail: COMMON_TEXT.ADD_MULTIPLE_BRANCH_ERROR_MESSAGE,
            });
            return;
          }
          newList.push({
            capacity_id: null,
            type,
            branch_id: uniqueList[0].value,
            capacity: 0,
          });
          this.setState({
            branchList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          const floorIdSet = new Set(arrayHelpers.map(item => item.floor_id));
          uniqueList = handleRemoveDuplicate({
            listId: floorIdSet,
            options,
          });
          if (!uniqueList.length || !uniqueList[0].value) {
            this.showMessage({
              detail: COMMON_TEXT.ADD_MULTIPLE_FLOOR_ERROR_MESSAGE,
            });
            return;
          }
          newList.push({
            capacity_id: null,
            type,
            floor_id: uniqueList[0].value,
            capacity: 0,
          });
          this.setState({
            floorList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          const departmentIdSet = new Set(
            arrayHelpers.map(item => item.department)
          );
          uniqueList = handleRemoveDuplicate({
            listId: departmentIdSet,
            options,
          });
          if (!uniqueList.length || !uniqueList[0].value) {
            this.showMessage({
              detail: COMMON_TEXT.ADD_MULTIPLE_DEPARTMENT_ERROR_MESSAGE,
            });
            return;
          }
          newList.push({
            capacity_id: null,
            type,
            department: uniqueList[0].value,
            capacity: 0,
          });
          this.setState({
            departmentList: newList,
          });
          break;
        default:
          break;
      }
    };

    const handleChangeSelect = ({ arrayHelpers, value, type, index }) => {
      const newList = arrayHelpers;
      if (!newList[index]) {
        return;
      }
      switch (type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          newList[index].branch_id = value;
          this.setState({
            branchList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          newList[index].floor_id = value;
          this.setState({
            floorList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          newList[index].department = value;
          this.setState({
            departmentList: newList,
          });
          break;
        default:
          break;
      }
    };

    const handleChangeText = ({ arrayHelpers, value, type, index }) => {
      const newList = arrayHelpers;
      const newValue = value ? value : 0;
      if (!newList[index]) {
        return;
      }
      switch (type) {
        case ATTENDANCE_RATE_TYPE.BRANCH:
          newList[index].capacity = parseInt(newValue);
          this.setState({
            branchList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.FLOOR:
          newList[index].capacity = parseInt(newValue);
          this.setState({
            floorList: newList,
          });
          break;
        case ATTENDANCE_RATE_TYPE.DEPARTMENT:
          newList[index].capacity = parseInt(newValue);
          this.setState({
            departmentList: newList,
          });
          break;
        default:
          break;
      }
    };

    const renderMainContent = ({
      branchOptions,
      floorOptions,
      departmentOptions,
      branchList,
      floorList,
      departmentList,
    }) => {
      const branchIdSet = branchList.map(item => item.branch_id);
      const floorIdSet = floorList.map(item => item.floor_id);
      const departmentIdSet = departmentList.map(item => item.department);
      return (
        <div className="main-content">
          <div className={'main-row-container is-row'}>
            <div className="input-text-field-container is-group">
              {branchList.map((branch, index) => {
                const filteredBranch = new Set(
                  branchIdSet.filter(item => item !== branch.branch_id)
                );
                const newBranchOptions =
                  branchOptions
                    .filter(item => !filteredBranch.has(item.value))
                    .sort((a, b) => {
                      return a.label.localeCompare(b.label, 'ja');
                    }) || [];

                return (
                  <div
                    key={`branch-[${index}]`}
                    className="input-text-field-container is-column"
                  >
                    <div className="input-text-field-container select-branch-field">
                      <FieldArray
                        name={`branchList[${index}]`}
                        render={() => (
                          <Fragment key={`branch-device-item`}>
                            <Dropdown
                              className="is-select-group"
                              value={branch.branch_id}
                              options={newBranchOptions}
                              onChange={e =>
                                handleChangeSelect({
                                  arrayHelpers: branchList,
                                  value: e.target.value,
                                  type: branch.type,
                                  index,
                                })
                              }
                            ></Dropdown>
                          </Fragment>
                        )}
                      />
                    </div>
                    <div className="input-text-field-container input-branch-field">
                      <InputText
                        value={branch.capacity}
                        onChange={e =>
                          handleChangeText({
                            arrayHelpers: branchList,
                            value: e.target.value,
                            type: branch.type,
                            index,
                          })
                        }
                        keyfilter="int"
                      />
                    </div>
                    <div className="input-text-field-container action-field">
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        text
                        severity="danger"
                        className="icon-only-delete-button"
                        onClick={e =>
                          deleteButtonOnClicked({
                            arrayHelpers: branchList,
                            index,
                            item: branch,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div className="add-row-button-container">
                <Button
                  severity="secondary"
                  type="button"
                  label={COMMON_TEXT.ADD_NEW}
                  className="add-new-button has-shadow"
                  onClick={() =>
                    handleClickAdd({
                      arrayHelpers: branchList,
                      type: 'branch',
                      options: branchOptions,
                    })
                  }
                />
              </div>
            </div>
            <div className="input-text-field-container is-group">
              {floorList.map((floor, index) => {
                const filteredFloor = new Set(
                  floorIdSet.filter(item => item !== floor.floor_id)
                );
                const newFloorOptions =
                  floorOptions
                    .filter(item => !filteredFloor.has(item.value))
                    .sort((a, b) => {
                      return a.label.localeCompare(b.label, 'ja');
                    }) || [];
                return (
                  <div
                    key={`floor-[${index}]`}
                    className="input-text-field-container is-column"
                  >
                    <div className="input-text-field-container select-floor-field">
                      <Dropdown
                        className="is-select-group"
                        value={floor.floor_id}
                        options={newFloorOptions}
                        onChange={e =>
                          handleChangeSelect({
                            arrayHelpers: floorList,
                            value: e.target.value,
                            type: floor.type,
                            index,
                          })
                        }
                      ></Dropdown>
                    </div>
                    <div className="input-text-field-container input-floor-field">
                      <InputText
                        value={floor.capacity}
                        onChange={e =>
                          handleChangeText({
                            arrayHelpers: floorList,
                            value: e.target.value,
                            type: floor.type,
                            index,
                          })
                        }
                        keyfilter="int"
                      />
                    </div>
                    <div className="input-text-field-container action-field">
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        text
                        severity="danger"
                        className="icon-only-delete-button"
                        onClick={e =>
                          deleteButtonOnClicked({
                            arrayHelpers: floorList,
                            index,
                            item: floor,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div className="add-row-button-container">
                <Button
                  severity="secondary"
                  type="button"
                  label={COMMON_TEXT.ADD_NEW}
                  className="add-new-button has-shadow"
                  onClick={() =>
                    handleClickAdd({
                      arrayHelpers: floorList,
                      type: 'floor',
                      options: floorOptions,
                    })
                  }
                />
              </div>
            </div>
            <div className="input-text-field-container is-group">
              {departmentList.map((department, index) => {
                const filteredDepartment = new Set(
                  departmentIdSet.filter(item => item !== department.department)
                );
                const newDepartmentOptions =
                  departmentOptions
                    .filter(item => !filteredDepartment.has(item.value))
                    .sort((a, b) => {
                      return a.label.localeCompare(b.label, 'ja');
                    }) || [];
                return (
                  <div
                    key={`department-[${index}]`}
                    className="input-text-field-container is-column"
                  >
                    <div className="input-text-field-container select-department-field">
                      <Dropdown
                        className="is-select-department-group"
                        value={department.department}
                        options={newDepartmentOptions}
                        onChange={e =>
                          handleChangeSelect({
                            arrayHelpers: departmentList,
                            value: e.target.value,
                            type: department.type,
                            index,
                          })
                        }
                      ></Dropdown>
                    </div>
                    <div className="input-text-field-container input-department-field">
                      <InputText
                        value={department.capacity}
                        onChange={e =>
                          handleChangeText({
                            arrayHelpers: departmentList,
                            value: e.target.value,
                            type: department.type,
                            index,
                          })
                        }
                        keyfilter="int"
                      />
                    </div>
                    <div className="input-text-field-container action-field">
                      <Button
                        type="button"
                        icon="pi pi-trash"
                        text
                        severity="danger"
                        className="icon-only-delete-button"
                        onClick={e =>
                          deleteButtonOnClicked({
                            arrayHelpers: departmentList,
                            index,
                            item: department,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <div className="add-row-button-container">
                <Button
                  severity="secondary"
                  type="button"
                  label={COMMON_TEXT.ADD_NEW}
                  className="add-new-button has-shadow"
                  onClick={() =>
                    handleClickAdd({
                      arrayHelpers: departmentList,
                      type: 'department',
                      options: departmentOptions,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    };

    const cloneCapacityList = cloneDeep(this.employeeAttendanceList);

    return (
      <>
        {isLoading || isUpdating ? (
          <LoadingSpinner
            loadingText={isUpdating ? COMMON_TEXT.UPDATING_DATA : null}
          />
        ) : (
          <Formik
            initialValues={{
              capacities: cloneCapacityList,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                <div className="main-row-container is-header">
                  <div className="select-branch-field">
                    {COMMON_TEXT.BRANCH}
                  </div>
                  <div className="input-branch-field">
                    {COMMON_TEXT.CAPACITY}
                  </div>

                  <div className="select-floor-field">{COMMON_TEXT.FLOOR}</div>
                  <div className="input-floor-field">
                    {COMMON_TEXT.CAPACITY}
                  </div>

                  <div className="select-department-field">
                    {COMMON_TEXT.DEPARTMENT}
                  </div>
                  <div className="input-department-field">
                    {COMMON_TEXT.CAPACITY}
                  </div>
                </div>
                {renderMainContent({
                  branchOptions,
                  floorOptions,
                  departmentOptions,
                  branchList,
                  floorList,
                  departmentList,
                })}
                <div className="main-row-container is-submit-button-container">
                  <Button
                    severity="info"
                    type="submit"
                    label="保存"
                    className="submit-button has-shadow"
                    loading={isUpdating}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  renderUpdatePayloadTable = () => {
    const {
      branchList,
      floorList,
      departmentList,
      branchOptions,
      floorOptions,
    } = this.state;

    const { newItems, deletedItems, updatedItems } = this.findItemChanges({
      updatedArray: [...branchList, ...floorList, ...departmentList],
      originalArray: this.employeeAttendanceList,
    });
    const { tableValueBranches, tableValueFloors, tableValueDepartments } =
      this.handleRenderTable({
        createDeleteArray: [...newItems, ...deletedItems],
        updateArray: updatedItems,
        branchOptions,
        floorOptions,
      });

    const statusBodyTemplate = rowData => {
      return (
        <Tag
          value={getTextStatus(rowData.status)}
          severity={getSeverityStatus(rowData.status)}
        />
      );
    };

    const getTextStatus = status => {
      switch (status) {
        case ATTENDANCE_RATE_STATUS.DELETED:
          return COMMON_TEXT.STATUS_DELETED;

        case ATTENDANCE_RATE_STATUS.MODIFIED:
          return COMMON_TEXT.STATUS_MODIFIED;

        case ATTENDANCE_RATE_STATUS.NEW:
          return COMMON_TEXT.STATUS_NEW;

        default:
          return '';
      }
    };

    const getSeverityStatus = status => {
      switch (status) {
        case ATTENDANCE_RATE_STATUS.DELETED:
          return 'danger';

        case ATTENDANCE_RATE_STATUS.MODIFIED:
          return 'warning';

        case ATTENDANCE_RATE_STATUS.NEW:
          return 'info';

        default:
          return 'info';
      }
    };

    return (
      <div className="payload-info-container">
        <DataTable
          value={tableValueBranches}
          rowGroupMode="rowspan"
          groupRowsBy="branch_name"
          sortOrder={0}
          scrollable
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="branch_name"
            header={COMMON_TEXT.BRANCH}
            headerStyle={{ fontWeight: 'bold' }}
            style={{}}
          ></Column>
          <Column
            field="capacity"
            header={COMMON_TEXT.CAPACITY}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
        </DataTable>
        <DataTable
          value={tableValueFloors}
          rowGroupMode="rowspan"
          groupRowsBy="floor_name"
          sortOrder={0}
          scrollable
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="floor_name"
            header={COMMON_TEXT.FLOOR}
            headerStyle={{ fontWeight: 'bold' }}
            style={{}}
          ></Column>
          <Column
            field="capacity"
            header={COMMON_TEXT.CAPACITY}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
        </DataTable>
        <DataTable
          value={tableValueDepartments}
          rowGroupMode="rowspan"
          groupRowsBy="department"
          sortOrder={0}
          scrollable
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="department"
            header={COMMON_TEXT.DEVICE}
            headerStyle={{ fontWeight: 'bold' }}
            style={{}}
          ></Column>
          <Column
            field="capacity"
            header={COMMON_TEXT.CAPACITY}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
          <Column
            field="status"
            header={COMMON_TEXT.STATUS}
            body={statusBodyTemplate}
            headerStyle={{ fontWeight: 'bold' }}
            style={{ width: '20%' }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  render() {
    const { tenant, isUpdateDialogVisible, updateDataPayload, isUpdating } =
      this.state;

    const updateDataDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => this.setState({ isUpdateDialogVisible: false })}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button has-shadow"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={() =>
              this.saveAttendanceRateSetting({ updateDataPayload })
            }
          />
        </React.Fragment>
      );
    };
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <Dialog
          visible={isUpdateDialogVisible}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.EMPLOYEE_ATTENDANCE_RATE}
          modal
          className="p-fluid"
          footer={updateDataDialogFooter}
          onHide={() => this.setState({ isUpdateDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="confirmation-content">
            {this.renderUpdatePayloadTable()}
          </div>
        </Dialog>
        <div className="config-container custom-config-container attendance-rate-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.EMPLOYEE_ATTENDANCE_RATE}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">
              {tenant ? (
                this.renderSettingContent()
              ) : (
                <Message
                  severity="error"
                  text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AttendanceRateSetting;
