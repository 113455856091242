import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';

const buildRequestQuery = ({ tenantId }) => {
  return {
    data: {
      fields: {
        chart_name: ['select'],
        id: ['select'],
        tenant_id: ['select'],
        threshold: ['select'],
        direction: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: {
        eq: [tenantId],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.LINE_CHART_THRESHOLDS,
  };
};

export const fetchLineChartThresholds = async payload => {
  const { tenantId } = payload;
  const request = buildRequestQuery({ tenantId });
  return executeQuery(request);
};
