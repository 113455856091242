import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  MEMORY_STORE,
  PEOPLE_COUNT_ANALYSIS_TYPE,
  WIRELESS_CHANNEL_UTILIZATION_TYPE,
} from '@/helpers/constants';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { isEqual, size } from 'lodash';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomCalendar from '../CustomCalendar/CustomCalendar';
import './CustomCSVDownload.less';
import {
  getCsvDataMeetingRoomOutlookTable,
  getCsvDataNetworkClientCount,
  getCsvDataNetworkUsage,
  getCsvDataPeopleCountAnalysis,
  getCsvDataPerformanceScore,
  getCsvDataSensorHistory,
  getCsvDataWirelessChannel,
  getCsvDataWirelessLatency,
  getCsvDataWirelessSignal,
} from './service';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class CustomCSVDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      fromDate: new Date(),
      toDate: new Date(),
      deviceIdList: [],
      floorId: '',
      branchId: null,
      tenantId: '',
      isDownloading: false,
      selectedTimeRange: {
        fromTime: '00:00',
        toTime: '23:59',
      },
      selectedWirelessChannelOption:
        WIRELESS_CHANNEL_UTILIZATION_TYPE.CHANNEL_24GHZ,
      selectedPeopleCountAnalysisOption: PEOPLE_COUNT_ANALYSIS_TYPE.COUNTS,
    };
    this.timeRangeOptions = [
      { label: '01:00', value: '01:00' },
      { label: '02:00', value: '02:00' },
      { label: '03:00', value: '03:00' },
      { label: '04:00', value: '04:00' },
      { label: '05:00', value: '05:00' },
      { label: '06:00', value: '06:00' },
      { label: '07:00', value: '07:00' },
      { label: '08:00', value: '08:00' },
      { label: '09:00', value: '09:00' },
      { label: '10:00', value: '10:00' },
      { label: '11:00', value: '11:00' },
      { label: '12:00', value: '12:00' },
      { label: '13:00', value: '13:00' },
      { label: '14:00', value: '14:00' },
      { label: '15:00', value: '15:00' },
      { label: '16:00', value: '16:00' },
      { label: '17:00', value: '17:00' },
      { label: '18:00', value: '18:00' },
      { label: '19:00', value: '19:00' },
      { label: '20:00', value: '20:00' },
      { label: '21:00', value: '21:00' },
      { label: '22:00', value: '22:00' },
      { label: '23:00', value: '23:00' },
    ];
    this.fromTimeRangeOptions = [
      { label: '00:00', value: '00:00' },
      ...this.timeRangeOptions,
    ];
    this.toTimeRangeOptions = [
      ...this.timeRangeOptions,
      { label: '23:59', value: '23:59' },
    ];
    this.wirelessChannelOptions = [
      {
        label: '2.4GHz',
        value: WIRELESS_CHANNEL_UTILIZATION_TYPE.CHANNEL_24GHZ,
      },
      {
        label: '5GHz',
        value: WIRELESS_CHANNEL_UTILIZATION_TYPE.CHANNEL_5GHZ,
      },
    ];
    this.peopleCountAnalysisOptions = [
      {
        label: COMMON_TEXT.COUNTS,
        value: PEOPLE_COUNT_ANALYSIS_TYPE.COUNTS,
      },
      {
        label: COMMON_TEXT.CAPACITY_RATE,
        value: PEOPLE_COUNT_ANALYSIS_TYPE.CAPACITY_RATE,
      },
    ];
  }

  componentDidMount() {
    const {
      type,
      fromDate,
      toDate,
      deviceIdList,
      floorId,
      branchId,
      selectedOption,
      selectedTimeRange,
    } = this.props;
    this.setState({
      type,
      fromDate: fromDate ?? new Date(),
      toDate: toDate ?? new Date(),
      deviceIdList: deviceIdList ?? [],
      floorId: floorId ?? '',
      branchId: branchId ?? null
    });
    if (type === CSV_DOWNLOAD_COMPONENT.PEOPLE_COUNTS_ANALYSIS) {
      this.setState({
        selectedPeopleCountAnalysisOption: selectedOption,
        selectedTimeRange: { ...selectedTimeRange },
      });
    } else if (type === CSV_DOWNLOAD_COMPONENT.SENSOR_DATA_HISTORY) {
      this.setState({
        selectedTimeRange: { ...selectedTimeRange },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { deviceIdList, floorId, branchId } = this.props;
    const { deviceIdList: prevDeviceIdList, floorId: prevFloorId, branchId: prevBranchId } = prevProps;
    const isDeviceListChanged = !isEqual(deviceIdList, prevDeviceIdList);
    const isFloorChanged = floorId !== prevFloorId;
    const isBranchChagned = branchId !== null && prevBranchId !== null && branchId !== prevBranchId;
    if (isDeviceListChanged || isFloorChanged) {
      this.setState({
        deviceIdList: deviceIdList ?? [],
        floorId: floorId ?? '',
      });
    } else if (isBranchChagned) {
      this.setState({
        branchId: branchId
      });
    }
  }

  getUpdatedFloorIdList = ({ branchId }) => {
    const allFloors = this.props.sessionStore?.[MEMORY_STORE.FLOORS] ?? [];
    const floors = allFloors.filter(floor => +floor.branch_id > 0 && +floor.branch_id === +branchId);
    return floors.map(floor => floor.floor_id);
  };

  getCsvFileName = ({ type, channelType, fromDateTime, toDateTime }) => {
    let typeName = '';
    switch (type) {
      case CSV_DOWNLOAD_COMPONENT.NETWORK_PERFORMANCE_SCORE:
        typeName = COMMON_TEXT.NETWORK_PERFORMANCE_SCORE;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_SIGNAL:
        typeName = COMMON_TEXT.WIRELESS_SIGNAL;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.NETWORK_CLIENT_COUNT:
        typeName = COMMON_TEXT.NETWORK_CLIENT_COUNT;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.NETWORK_USAGE:
        typeName = COMMON_TEXT.NETWORK_USAGE;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_LATENCY:
        typeName = COMMON_TEXT.WIRELESS_LATENCY;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE:
        typeName = COMMON_TEXT.MEETING_ROOM_OUTLOOK_USAGE;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.SENSOR_DATA_HISTORY:
        typeName = COMMON_TEXT.AIR_QUALITY;
        channelType = '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_CHANNEL_UTILIZATION:
        typeName = COMMON_TEXT.WIRELESS_CHANNEL_UTILIZATION;
        channelType = '_' + channelType + '_';
        break;
      case CSV_DOWNLOAD_COMPONENT.PEOPLE_COUNTS_ANALYSIS:
        typeName = COMMON_TEXT.PEOPLE_COUNTS_ANALYSIS;
        channelType = '_';
        break;
      default:
        break;
    }
    const filename = `${typeName}${channelType}${format(
      new Date(fromDateTime),
      'yyyyMMddHHmm'
    )}_${format(new Date(toDateTime), 'yyyyMMddHHmm')}`;
    return filename;
  };

  startDownloadCsv = ({ data, filename }) => {
    const downloadLink = document.createElement('a');
    downloadLink.textContent = 'download';
    downloadLink.download = filename + '.csv';
    downloadLink.href =
      'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data);
    downloadLink.click();
  };

  handleDownload = async () => {
    const {
      type,
      fromDate,
      toDate,
      deviceIdList,
      floorId,
      branchId,
      selectedTimeRange,
      selectedWirelessChannelOption,
      selectedPeopleCountAnalysisOption,
    } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    this.setState({ isDownloading: true });
    let csvDataResult = [];
    const fromDateTimeStr = format(fromDate, `yyyy-MM-dd ${fromTime}:00`);
    const toDateTimeStr = format(toDate, `yyyy-MM-dd ${toTime}:59`);
    switch (type) {
      case CSV_DOWNLOAD_COMPONENT.NETWORK_PERFORMANCE_SCORE:
        csvDataResult = await getCsvDataPerformanceScore({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_SIGNAL:
        csvDataResult = await getCsvDataWirelessSignal({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.NETWORK_CLIENT_COUNT:
        csvDataResult = await getCsvDataNetworkClientCount({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.NETWORK_USAGE:
        csvDataResult = await getCsvDataNetworkUsage({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_LATENCY:
        csvDataResult = await getCsvDataWirelessLatency({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE:
        // Data from this is retrieved based on floorIdList
        // floorIdList is retrieved from branchId
        const floorIdList = this.getUpdatedFloorIdList({branchId: branchId})
        csvDataResult = await getCsvDataMeetingRoomOutlookTable({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          floorIdList: floorIdList,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.SENSOR_DATA_HISTORY:
        csvDataResult = await getCsvDataSensorHistory({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.WIRELESS_CHANNEL_UTILIZATION:
        csvDataResult = await getCsvDataWirelessChannel({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          deviceIdList,
          floorId,
          channelType: selectedWirelessChannelOption,
        });
        break;
      case CSV_DOWNLOAD_COMPONENT.PEOPLE_COUNTS_ANALYSIS:
        csvDataResult = await getCsvDataPeopleCountAnalysis({
          fromDateTime: fromDateTimeStr,
          toDateTime: toDateTimeStr,
          areaIdList: deviceIdList,
          floorId,
          type: selectedPeopleCountAnalysisOption,
        });
        break;
      default:
        break;
    }
    this.startDownloadCsv({
      data: csvDataResult.csvData,
      filename: this.getCsvFileName({
        type: type,
        channelType: selectedWirelessChannelOption,
        fromDateTime: fromDateTimeStr,
        toDateTime: toDateTimeStr,
      }),
    });
    this.setState({ isDownloading: false });
  };

  renderStartDatePicker() {
    const { fromDate, toDate, isDownloading } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        value={fromDate}
        disabled={isDownloading}
        onChange={e => {
          if (isAfter(e.value, toDate)) {
            this.setState({
              toDate: e.value,
              fromDate: e.value,
            });
          } else {
            this.setState({
              fromDate: e.value,
            });
          }
        }}
        maxDate={maxDate}
      />
    );
  }

  renderEndDatePicker() {
    const { fromDate, toDate, isDownloading } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        value={toDate}
        disabled={isDownloading}
        onChange={e => {
          if (isBefore(e.value, fromDate)) {
            this.setState({
              toDate: e.value,
              fromDate: e.value,
            });
          } else {
            this.setState({
              toDate: e.value,
            });
          }
        }}
        maxDate={maxDate}
      />
    );
  }

  renderTimeRangeStartDateDropdown() {
    const { selectedTimeRange, type, isDownloading } = this.state;
    if (type === CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE) {
      return <></>;
    }
    const { fromTime } = selectedTimeRange;
    return (
      <div className="timerange-select-container">
        <CustomDropdown
          disabled={isDownloading}
          label="開始時間"
          value={fromTime}
          options={this.fromTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'fromTime')}
        ></CustomDropdown>
      </div>
    );
  }

  renderTimeRangeEndDateDropdown() {
    const { selectedTimeRange, type, isDownloading } = this.state;
    if (type === CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE) {
      return <></>;
    }
    const { toTime } = selectedTimeRange;
    return (
      <div className="timerange-select-container">
        <CustomDropdown
          label="終了時間"
          disabled={isDownloading}
          value={toTime}
          options={this.toTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'toTime')}
        ></CustomDropdown>
      </div>
    );
  }

  timeRangeOnChanged = (e, type) => {
    let newVal = e.value;
    const { selectedTimeRange, fromDate, toDate } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    let newSelectedTimeRange = {};
    if (isSameDay(fromDate, toDate)) {
      if (type === 'fromTime') {
        if (newVal >= toTime) {
          const foundIndex = this.toTimeRangeOptions.findIndex(
            item => item.value === newVal
          );
          let newIndex = foundIndex + 1;
          newIndex =
            newIndex > this.timeRangeOptions.length - 1
              ? this.timeRangeOptions.length - 1
              : newIndex;
          const newToTime = this.toTimeRangeOptions[newIndex].value;
          newSelectedTimeRange = {
            fromTime: newVal,
            toTime: newToTime,
          };
        } else {
          newSelectedTimeRange = {
            fromTime: newVal,
            toTime: toTime,
          };
        }
      } else if (type === 'toTime') {
        if (newVal <= fromTime) {
          const foundIndex = this.toTimeRangeOptions.findIndex(
            item => item.value === newVal
          );
          let newIndex = foundIndex;
          newIndex = newIndex < 0 ? 0 : newIndex;
          const newFromTime = this.fromTimeRangeOptions[newIndex].value;
          newSelectedTimeRange = {
            fromTime: newFromTime,
            toTime: newVal,
          };
        } else {
          newSelectedTimeRange = {
            fromTime: fromTime,
            toTime: newVal,
          };
        }
      }
    } else {
      if (type === 'fromTime') {
        newSelectedTimeRange = {
          fromTime: newVal,
          toTime: toTime,
        };
      } else if (type === 'toTime') {
        newSelectedTimeRange = {
          fromTime: fromTime,
          toTime: newVal,
        };
      }
    }
    this.setState({
      selectedTimeRange: newSelectedTimeRange,
    });
  };

  renderDownloadButton() {
    const { isDownloading, deviceIdList, type } = this.state;
    let isEmptyDeviceIdList = false;
    if (type !== CSV_DOWNLOAD_COMPONENT.MEETING_ROOM_OUTLOOK_TABLE) {
      isEmptyDeviceIdList = size(deviceIdList) === 0;
    }
    return (
      <>
        <Button
          type="button"
          severity="info"
          label="ダウンロード"
          className="submit-button csv-download has-shadow"
          onClick={() => this.handleDownload()}
          loading={isDownloading}
          disabled={isEmptyDeviceIdList}
        />
        {isEmptyDeviceIdList && (
          <span className="no-device">{`CSVをダウンロードしたいデバイスを選択してください。`}</span>
        )}
      </>
    );
  }

  renderWirelessChannelDropdown() {
    const { selectedWirelessChannelOption, isDownloading } = this.state;

    const dropdownOnChanged = e => {
      this.setState({ selectedWirelessChannelOption: e.value });
    };

    return (
      <Dropdown
        disabled={isDownloading}
        value={selectedWirelessChannelOption}
        options={this.wirelessChannelOptions}
        onChange={e => dropdownOnChanged(e)}
        className="drop-down-textfield-style"
      />
    );
  }

  renderPeopleCountAnalysisDropdown() {
    const { selectedPeopleCountAnalysisOption, isDownloading } = this.state;

    const dropdownOnChanged = e => {
      this.setState({ selectedPeopleCountAnalysisOption: e.value });
    };

    return (
      <Dropdown
        disabled={isDownloading}
        value={selectedPeopleCountAnalysisOption}
        options={this.peopleCountAnalysisOptions}
        onChange={e => dropdownOnChanged(e)}
        className="drop-down-textfield-style"
      />
    );
  }

  render() {
    const { type } = this.state;
    return (
      <>
        <div className="dialog-body" key={`csv-download-type-${type}`}>
          {type === CSV_DOWNLOAD_COMPONENT.WIRELESS_CHANNEL_UTILIZATION && (
            <div className="date-time-section wireless-channel">
              <div className="label">{`出力するログ`}</div>
              <div className="inner-section">
                {this.renderWirelessChannelDropdown()}
              </div>
            </div>
          )}
          {type === CSV_DOWNLOAD_COMPONENT.PEOPLE_COUNTS_ANALYSIS && (
            <div className="date-time-section wireless-channel">
              <div className="label">{`種別`}</div>
              <div className="inner-section">
                {this.renderPeopleCountAnalysisDropdown()}
              </div>
            </div>
          )}
          <div className="date-time-section from-date">
            <div className="label from-date-label">{`開始日`}</div>
            <div className="inner-section">
              {this.renderStartDatePicker()}
              {this.renderTimeRangeStartDateDropdown()}
            </div>
          </div>
          <div className="date-time-section to-date">
            <div className="label from-date-label">{`終了日`}</div>
            <div className="inner-section">
              {this.renderEndDatePicker()}
              {this.renderTimeRangeEndDateDropdown()}
            </div>
          </div>
          <div className="dialog-button">{this.renderDownloadButton()}</div>
        </div>
        <p className="note">備考: CSVファイル行数上限は100,000 行です。</p>
      </>
    );
  }
}

export default CustomCSVDownload;
