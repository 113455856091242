export const SETTING_FLOW_ID = {
  TENANTS: '64d1e067dc5f7d224e68b4fd',
  APIKEYS: '64d1ce9d6c6f5e5b1299f6c0',
  ORGANIZATIONS: '64d452086c6f5e5b1299fc5b',
  BRANCHES: '64d46ff36c6f5e5b1299fc9f',
  FLOORS: '64d9721a6c6f5e5b129a07ed',
  VERKADA_DEVICES: '64d97bc96c6f5e5b129a0806',
  DEVICE_POSITIONS: '64d99149dc5f7d224e68c613',
  EMPLOYEES: '64dac619dc5f7d224e68c89f',
  BUSINESS_HOURS: '64dae5dedc5f7d224e68c8f6',
  AREAS: '65bca93763d57fd8549eb813',
  CAMERA_ZONE: '65cc23b53564ca9922278ada',
  AIR_QUALITY: '65dc0aed0bd8c52cc96fc177',
  SIGNAGE_COMPONENTS: '65cc8b0a3564ca9922278d5e',
  ATTENDANCE_RATE: '66b570e2216687aa03b038f3',
  AUTH_PROVIDERS: '66cd92d3216687aa03b0b326',
};
