import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { format } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { DeviceStatusMarkerImageRender } from 'src/helpers/device-status-marker';
import { transformPositionToMarkerCoordinate } from 'src/helpers/utility';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class DeviceStatusOverviewNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data ?? [],
      dataTable: this.props.dataTable ?? [],
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading: this.props.isLoading ?? false,
      floorMapUrl: this.props.floorMapUrl ?? '',
      floorMapError: this.props.floorMapError ?? false,
      selectedDeviceModelList: this.props.selectedDeviceModelList ?? [],
      deviceModelOptions: this.props.deviceModelOptions ?? [],
    };

    this.dataTableRef = React.createRef();
    this.filters = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      product: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      device_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    };
    this.dataColumns = [
      {
        field: 'status',
        header: '状態',
        align: 'center',
        headerClassName: 'text-center',
        bodyClassName: 'text-center',
        headerStyle: { fontSize: '0.85rem', width: '70px' },
        bodyStyle: { fontSize: '0.85rem', width: '70px' },
      },
      {
        field: 'product',
        header: 'プロダクト',
        align: 'center',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem', width: '100px' },
        bodyStyle: { fontSize: '0.85rem', width: '100px' },
        hasFilter: true,
      },
      {
        field: 'device_type',
        header: '機種',
        align: 'center',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem', width: '80px' },
        bodyStyle: { fontSize: '0.85rem', width: '80px' },
        hasFilter: true,
      },
      {
        field: 'device_name',
        header: '名前',
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem' },
        bodyStyle: { fontSize: '0.85rem' },
        hasFilter: true,
      },
      {
        field: 'timestamp',
        header: '更新日時',
        align: 'left',
        headerClassName: 'text-left',
        bodyClassName: 'text-left',
        headerStyle: { fontSize: '0.85rem', width: '180px' },
        bodyStyle: { fontSize: '0.85rem', width: '180px' },
      },
    ];
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const {
      data,
      dataTable,
      isLoading,
      floorMapUrl,
      floorMapError,
      selectedDeviceModelList,
      deviceModelOptions,
    } = this.props;
    const {
      data: prevData,
      dataTable: prevDataTable,
      isLoading: prevIsLoading,
      floorMapUrl: prevFloorMapUrl,
      floorMapError: prevFloorMapError,
      selectedDeviceModelList: prevSelectedDeviceModelList,
      deviceModelOptions: prevDeviceModelOptions,
    } = prevProps;

    const isDataChanged = data !== prevData;
    const isDataTableChanged = dataTable !== prevDataTable;
    const isLoadingChanged = isLoading !== prevIsLoading;
    const isFloorMapUrlChanged = floorMapUrl !== prevFloorMapUrl;
    const isFloorMapErrorChanged = floorMapError !== prevFloorMapError;
    const isSelectedDeviceModelListChanged =
      selectedDeviceModelList !== prevSelectedDeviceModelList;
    const isDeviceModelOptionsChanged =
      deviceModelOptions !== prevDeviceModelOptions;

    if (
      isDataChanged ||
      isDataTableChanged ||
      isLoadingChanged ||
      isFloorMapUrlChanged ||
      isFloorMapErrorChanged ||
      isSelectedDeviceModelListChanged ||
      isDeviceModelOptionsChanged
    ) {
      this.setState({
        data,
        dataTable,
        isLoading,
        floorMapUrl,
        floorMapError,
        selectedDeviceModelList,
        deviceModelOptions,
      });
    }
  }

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderDataTable({ tableData }) {
    const { isLoading } = this.state;

    const statusBodyTemplate = (dataItem, field) => {
      const value = dataItem[field];
      return <div className={`status-icon status-${value}`}></div>;
    };

    return (
      <>
        <DataTable
          ref={this.dataTableRef}
          className="outlook-data-table"
          value={tableData}
          tableStyle={{ width: '100%' }}
          size="small"
          stripedRows
          scrollable
          scrollHeight="630px"
          showGridlines
          loading={isLoading}
          filterDisplay="row"
          filters={this.filters}
          emptyMessage={() => {
            return (
              <div className="flex justify-content-center align-items-center">
                <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
              </div>
            );
          }}
        >
          {this.dataColumns.map((col, i) => {
            return (
              <Column
                dataType={col.dataType ?? 'string'}
                key={col.field}
                field={col.field}
                header={col.header}
                align={col.align}
                alignHeader={col.align}
                headerClassName={col.headerClassName}
                bodyClassName={col.bodyClassName}
                headerStyle={col.headerStyle}
                bodyStyle={col.bodyStyle}
                filter={col.hasFilter}
                filterMatchMode={'contains'}
                body={dataItem => {
                  switch (col.field) {
                    case 'status':
                      return statusBodyTemplate(dataItem, col.field);
                    case 'timestamp':
                      return dataItem[col.field] != null
                        ? format(
                            new Date(dataItem[col.field]),
                            'yyyy年MM月dd日 - HH:mm'
                          )
                        : null;

                    default:
                      return dataItem[col.field];
                  }
                }}
              />
            );
          })}
        </DataTable>
      </>
    );
  }

  render() {
    const {
      data: floorMapData,
      isLoading,
      floorMapUrl,
      floorMapError,
      selectedDeviceModelList,
      dataTable,
    } = this.state;

    let floorMapDataMarkers = floorMapData.map(item => {
      const marker = transformPositionToMarkerCoordinate(item.position);
      return {
        ...marker,
        item: item,
      };
    });

    const deviceModelSet = new Set(
      selectedDeviceModelList.map(item => item.code)
    );
    floorMapDataMarkers = floorMapDataMarkers.filter(item => {
      const deviceModel = item.item?.data?.device_type;
      return deviceModelSet.has(deviceModel);
    });
    return (
      <div className="device-status-overview-now main-container">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="image-container">
              <ImageMarker
                extraClass="custom-image-marker"
                bufferLeft={0}
                bufferTop={0}
                src={floorMapUrl}
                markerComponent={DeviceStatusMarkerImageRender}
                markers={floorMapError ? [] : floorMapDataMarkers || []}
                alt=""
              />
              {floorMapError && (
                <Message
                  severity="error"
                  text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                />
              )}
            </div>
            <div className="table-container">
              {this.renderDataTable({
                tableData: dataTable,
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

DeviceStatusOverviewNow.propTypes = {};

export default DeviceStatusOverviewNow;
