import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from 'src/helpers/request';

const buildQueryEmployees = tenantId => {
  return {
    data: {
      fields: {
        employee_id: ['select'],
        employee_identifer: ['select'],
        employee_name: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_id: 'asc',
    },
    table_name: QUERY_TABLES.EMPLOYEES,
  };
};

const buildQueryVerkadaLogCsv = ({ employeeIdList, selectedDateList }) => {
  return {
    data: {
      fields: {
        date: ['select'],
        day_type: ['select'],
        employee_id: ['select'],
        employee_name: ['select'],
        employee_number: ['select'],
        first: ['select'],
        last: ['select'],
        weekday: ['select'],
      },
      skip: 0,
    },
    filter: {
      date: selectedDateList,
      employee_id: employeeIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      employee_id: 'asc',
    },
    table_name: QUERY_TABLES.VERKADA_ACCESSLOGS_MART_ATTENDANCES,
  };
};

export const fetchEmployees = async payload => {
  const { tenantId } = payload;
  const request = buildQueryEmployees(tenantId);
  return executeQuery(request);
};

export const fetchVerkadaLogCsv = async payload => {
  const { employeeIdList, selectedDateList } = payload;
  const request = buildQueryVerkadaLogCsv({ employeeIdList, selectedDateList });
  return executeQuery(request);
};
