/**
 *
 * HideShowSidebar
 *
 */

import HideShowSidebar from './HideShowSidebar.jsx';
import './HideShowSidebar.less';

export default HideShowSidebar;
