class Total {
  constructor (total) {
    Object.assign(this, total)
  }
  merge (total) {
    Object.assign(this, total)
  }
}

export default Total
