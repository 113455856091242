import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import * as Yup from 'yup';
import {
  fetchEmployeeDeviceListByEmployeeIdList,
  fetchEmployeeListByTenantId,
} from './query-request';

Yup.addMethod(Yup.array, 'deviceTypeUnique', function (field, message) {
  return this.test('deviceTypeUnique', message, function (array) {
    // Flatten all deviceList items into a single list, for each item of new list, add employeeId and indices correctly
    const deviceListFlat = array.reduce((acc, item, employeeIndex) => {
      return [
        ...acc,
        ...item.deviceList.map((device, deviceIndex) => ({
          ...device,
          employeeId: array[employeeIndex].employeeId,
          employeeIndex,
          deviceIndex,
        })),
      ];
    }, []);

    // Helper object to track occurrences of deviceType and employeeId combinations
    const deviceTypeEmployeeIdMap = {};

    for (const device of deviceListFlat) {
      const key = `${device.deviceType}-${device.employeeId}`;
      if (deviceTypeEmployeeIdMap[key]) {
        // If the combination already exists, it's a duplicate
        return this.createError({
          path: `${this.path}[${device.employeeIndex}].deviceList[${device.deviceIndex}].${field}`,
          message:
            message ||
            `Duplicate device type '${device.deviceType}' for employee ID '${device.employeeId}' found.`,
        });
      }
      deviceTypeEmployeeIdMap[key] = true;
    }

    // If no duplicates found, return true
    return true;
  });
});

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeTerminalSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      dataList: [],
    };
    this.toastRef = React.createRef();
    this.deviceTypeOptions = [
      { label: 'PC', value: 'pc' },
      { label: '携帯', value: 'phone' },
    ];
    this.originalDataList = [];
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    const { tenant } = this.state;
    if (!tenant) {
      return;
    }
    this.setState({ isLoading: true });
    // fetch data here
    // set time out 1 seconds
    const employeeList = await fetchEmployeeListByTenantId({
      tenantId: tenant,
    });

    const employeeIdList = Array.from(
      new Set(employeeList.map(e => e.employee_id))
    );

    const employeeDeviceList = await fetchEmployeeDeviceListByEmployeeIdList({
      employeeIdList,
    });
    const employeeDeviceMap = employeeDeviceList.reduce((acc, item) => {
      const { employee_id } = item;
      if (!acc[employee_id]) {
        acc[employee_id] = [];
      }
      acc[employee_id].push(item);
      return acc;
    }, {});

    const mappingEmployeeList = employeeList.map(employee => {
      const { employee_id } = employee;
      const deviceList = employeeDeviceMap[employee_id] ?? [];
      return {
        employeeId: employee.employee_id,
        employeeName: employee.employee_name,
        employeeNumber: employee.employee_number ?? '-',
        deviceList: deviceList.map(device => ({
          deviceType: device.device_type,
          deviceName: device.employee_device_name,
          deviceMac: device.employee_device_mac,
        })),
      };
    });
    this.originalDataList = cloneDeep(mappingEmployeeList);
    this.setState({
      dataList: cloneDeep(mappingEmployeeList),
    });
    this.setState({ isLoading: false });
  };

  updateData = async () => {
    this.setState({ isUpdating: true });
    // call api to update data
    // set time out 1 seconds
    await new Promise(resolve => setTimeout(resolve, 3000));
    // random number only 0 or 1
    // 1. call API to generate token
    // 2. if create token error, show error message
    // 3. if create token success, call API to start the action,
    // then navigate to WAITING page (update value isUpdating to true for configuration page)
    const isSuccess = Math.floor(Math.random() * 2);
    if (!isSuccess) {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'success',
          summary: '成功',
          detail: '設定が変更されました',
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderSettingContent() {
    const { isLoading, isUpdating, dataList } = this.state;

    const addNewDevice = ({ arrayHelpers, deviceList }) => {
      let hasPc = false;
      let hasMobile = false;
      for (const device of deviceList) {
        const { deviceType } = device;
        if (deviceType === 'pc') {
          hasPc = true;
        } else if (deviceType === 'mobile') {
          hasMobile = true;
        }
      }

      if (!hasPc && !hasMobile) {
        arrayHelpers.push({
          deviceMac: '',
          deviceName: '',
          deviceType: 'pc',
        });
      } else if (!hasPc) {
        arrayHelpers.push({
          deviceMac: '',
          deviceName: '',
          deviceType: 'pc',
        });
      } else if (!hasMobile) {
        arrayHelpers.push({
          deviceMac: '',
          deviceName: '',
          deviceType: 'phone',
        });
      }
    };

    const deleteAccepted = ({ arrayHelpers, index }) => {
      arrayHelpers.remove(index);
    };

    const deleteButtonOnClicked = (e, { arrayHelpers, item, index }) => {
      const { employeeId } = item;
      if (!isEmpty(employeeId)) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () => deleteAccepted({ arrayHelpers, index }),
          reject: () => {},
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index });
      }
    };

    const csvUploadButtonOnClicked = () => {
      alert('csvUploadButtonOnClicked');
    };

    const csvDownloadButtonOnClicked = () => {
      alert('csvDownloadButtonOnClicked');
    };

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        // this.updateData();
        alert(JSON.stringify(values, null, 2));
      } else {
      }
    };

    const isFormFieldInvalid = ({ name, touched, errors }) => {
      const error = getIn(errors, name);
      const touch = getIn(touched, name);
      return touch && error ? 'p-invalid' : '';
    };

    const getFormErrorMessage = ({ name, touched, errors }) => {
      return isFormFieldInvalid({ name, touched, errors }) ? (
        <span className="validation-error-message">{getIn(errors, name)}</span>
      ) : (
        <></>
      );
    };

    const formSchema = Yup.object().shape({
      employees: Yup.array()
        .of(
          Yup.object().shape({
            deviceList: Yup.array().of(
              Yup.object().shape({
                deviceName: Yup.string().required('Device name is required'),
                deviceMac: Yup.string().required('Device MAC is required'),
              })
            ),
          })
        )
        .deviceTypeUnique('deviceType', 'Duplicated'),
    });

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={{
              employees: dataList.map(employee => ({
                employeeId: employee.employeeId,
                employeeName: employee.employeeName,
                employeeNumber: employee.employeeNumber,
                deviceList: employee.deviceList,
              })),
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              values,
              handleChange,
              handleBlur,
              touched,
              errors,
              resetForm,
            }) => (
              <Form>
                <FieldArray
                  name="employees"
                  render={arrayHelpers => (
                    <>
                      <div className="main-row-container is-header">
                        <div className="empl-name-field">社員名</div>
                        <div className="empl-number-field">社員番号</div>
                        <div className="empl-device-list-container">
                          <div className="empl-device-mac-field">
                            MACアドレス
                          </div>
                          <div className="empl-device-name-field">端末名</div>
                          <div className="empl-device-type-field">
                            端末タイプ
                          </div>
                          <div className="action-field"></div>
                        </div>
                      </div>
                      <div className="main-content">
                        {values.employees.map((employee, index) => (
                          <div className="main-row-container" key={index}>
                            <div className="input-text-field-container empl-name-field">
                              <div>{employee.employeeName}</div>
                            </div>
                            <div className="input-text-field-container empl-number-field">
                              <div>{employee.employeeNumber}</div>
                            </div>
                            <div className="empl-device-list-container">
                              <FieldArray
                                name={`employees[${index}].deviceList`}
                                render={deviceListHelpers => (
                                  <>
                                    {employee.deviceList.map(
                                      (deviceInfo, deviceIndex) => (
                                        <div
                                          className="empl-device-list-row"
                                          key={`device-item-${deviceIndex}`}
                                        >
                                          <div className="input-text-field-container empl-device-mac-field">
                                            <InputText
                                              id={`employees[${index}].deviceList[${deviceIndex}].deviceMac`}
                                              value={deviceInfo.deviceMac}
                                              onChange={handleChange}
                                              className={`${isFormFieldInvalid({
                                                name: `employees[${index}].deviceList[${deviceIndex}].deviceMac`,
                                                touched,
                                                errors,
                                              })}`}
                                            />
                                            {getFormErrorMessage({
                                              name: `employees[${index}].deviceList[${deviceIndex}].deviceMac`,
                                              touched,
                                              errors,
                                            })}
                                          </div>
                                          <div className="input-text-field-container empl-device-name-field">
                                            <InputText
                                              id={`employees[${index}].deviceList[${deviceIndex}].deviceName`}
                                              value={deviceInfo.deviceName}
                                              onChange={handleChange}
                                              className={`${isFormFieldInvalid({
                                                name: `employees[${index}].deviceList[${deviceIndex}].deviceName`,
                                                touched,
                                                errors,
                                              })}`}
                                            />
                                            {getFormErrorMessage({
                                              name: `employees[${index}].deviceList[${deviceIndex}].deviceName`,
                                              touched,
                                              errors,
                                            })}
                                          </div>
                                          <div className="input-text-field-container empl-device-type-field">
                                            <Dropdown
                                              id={`employees[${index}].deviceList[${deviceIndex}].deviceType`}
                                              value={deviceInfo.deviceType}
                                              options={this.deviceTypeOptions}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className={`${isFormFieldInvalid({
                                                name: `employees[${index}].deviceList[${deviceIndex}].deviceType`,
                                                touched,
                                                errors,
                                              })}`}
                                            ></Dropdown>
                                            {getFormErrorMessage({
                                              name: `employees[${index}].deviceList[${deviceIndex}].deviceType`,
                                              touched,
                                              errors,
                                            })}
                                          </div>
                                          <div className="input-text-field-container action-field">
                                            <Button
                                              type="button"
                                              icon="pi pi-trash"
                                              text
                                              severity="danger"
                                              className="icon-only-delete-button"
                                              onClick={e =>
                                                deleteButtonOnClicked(e, {
                                                  arrayHelpers:
                                                    deviceListHelpers,
                                                  item: deviceInfo,
                                                  index: deviceIndex,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="add-meraki-button-container">
                                      {employee.deviceList.length <= 1 && (
                                        <Button
                                          severity="secondary"
                                          type="button"
                                          label="追加"
                                          className="add-new-button"
                                          onClick={() =>
                                            addNewDevice({
                                              arrayHelpers: deviceListHelpers,
                                              deviceList: employee.deviceList,
                                            })
                                          }
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="main-row-container is-submit-button-container">
                        <Button
                          severity="info"
                          type="submit"
                          label="保存"
                          className="submit-button has-shadow"
                          loading={isUpdating}
                        />
                        <div className="csv-button-container">
                          <Button
                            severity="info"
                            type="button"
                            label="CSVアップロード"
                            className="csv-button csv-upload"
                            onClick={() => {
                              csvUploadButtonOnClicked();
                            }}
                          />
                          <Button
                            severity="secondary"
                            type="button"
                            label="CSVダウンロード"
                            className="csv-button csv-download"
                            onClick={() => {
                              csvDownloadButtonOnClicked();
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container employee-terminal-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.EMPLOYEE_TERMINAL_SETTING}
              </div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">{this.renderSettingContent()}</div>
          </div>
        </div>
      </>
    );
  }
}

EmployeeTerminalSetting.propTypes = {};

export default EmployeeTerminalSetting;
