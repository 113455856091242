import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  ATTENDANCE_RATE_LEGEND,
  LINE_CHART_COLOR_LIST,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { addDays, compareAsc, format, isBefore, isSameDay } from 'date-fns';
import { round } from 'lodash';
import debounce from 'lodash/debounce';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { generateRandomString, getRandomHexColor } from 'src/helpers/utility';
import {
  fetchEmployeeAttendanceRateByDayForBranch,
  fetchEmployeeAttendanceRateByDayForDepartment,
  fetchEmployeeAttendanceRateByDayForFloor,
  fetchEmployeeAttendanceRateByHourForBranch,
  fetchEmployeeAttendanceRateByHourForDepartment,
  fetchEmployeeAttendanceRateByHourForFloor,
  fetchLatestTimestampListByHourForBranch,
  fetchLatestTimestampListByHourForDepartment,
  fetchLatestTimestampListByHourForFloor,
} from './query-request';

const LOCATION_TYPE = {
  BRANCH: 'branch',
  FLOOR: 'floor',
  DEPARTMENT: 'department',
};

const HISTORY_TYPE = {
  DAY: 'day',
  HOUR: 'hour',
};

const DATE_PICKER_FIELD = {
  SELECTED_DATE: 'selectedDate',
  FROM_DATE: 'fromSelectedDate',
  TO_DATE: 'toSelectedDate',
};

const DEFAULT_FILTER_DATA = {
  // if type is HOUR => use selectedTimeRange
  // if type is DAY => use fromSelectedDate & toSelectedDate
  selectedHistoryType: HISTORY_TYPE.HOUR,
  [DATE_PICKER_FIELD.SELECTED_DATE]: new Date(),
  [DATE_PICKER_FIELD.FROM_DATE]: addDays(new Date(), -7),
  [DATE_PICKER_FIELD.TO_DATE]: addDays(new Date(), -1),
  selectedTimeRange: {
    fromTime: '00:00',
    toTime: '01:00',
  },
  locationOptions: [-1], // If contain -1, then it is initial
  selectedLocations: [],
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      branchData: {
        chartData: [],
        tableData: [],
        filterData: { ...DEFAULT_FILTER_DATA },
      },
      floorData: {
        chartData: [],
        tableData: [],
        filterData: { ...DEFAULT_FILTER_DATA },
      },
      departmentData: {
        chartData: [],
        tableData: [],
        filterData: { ...DEFAULT_FILTER_DATA },
      },
      isLoadingLineChart: false,
      isLoadingLocation: false,
      selectedLocationType: LOCATION_TYPE.BRANCH,
    };
    this.timeRangeOptions = [
      { label: '01:00', value: '01:00' },
      { label: '02:00', value: '02:00' },
      { label: '03:00', value: '03:00' },
      { label: '04:00', value: '04:00' },
      { label: '05:00', value: '05:00' },
      { label: '06:00', value: '06:00' },
      { label: '07:00', value: '07:00' },
      { label: '08:00', value: '08:00' },
      { label: '09:00', value: '09:00' },
      { label: '10:00', value: '10:00' },
      { label: '11:00', value: '11:00' },
      { label: '12:00', value: '12:00' },
      { label: '13:00', value: '13:00' },
      { label: '14:00', value: '14:00' },
      { label: '15:00', value: '15:00' },
      { label: '16:00', value: '16:00' },
      { label: '17:00', value: '17:00' },
      { label: '18:00', value: '18:00' },
      { label: '19:00', value: '19:00' },
      { label: '20:00', value: '20:00' },
      { label: '21:00', value: '21:00' },
      { label: '22:00', value: '22:00' },
      { label: '23:00', value: '23:00' },
    ];
    this.fromTimeRangeOptions = [
      { label: '00:00', value: '00:00' },
      ...this.timeRangeOptions,
    ];
    this.toTimeRangeOptions = [
      ...this.timeRangeOptions,
      { label: '23:59', value: '23:59' },
    ];
    this.locationTypeOptions = [
      {
        label: COMMON_TEXT.BRANCH,
        value: LOCATION_TYPE.BRANCH,
      },
      {
        label: COMMON_TEXT.FLOOR,
        value: LOCATION_TYPE.FLOOR,
      },
      {
        label: COMMON_TEXT.DEPARTMENT,
        value: LOCATION_TYPE.DEPARTMENT,
      },
    ];
    this.historyTypeOptions = [
      {
        label: COMMON_TEXT.BY_TIME,
        value: HISTORY_TYPE.HOUR,
      },
      {
        label: COMMON_TEXT.BY_DAY,
        value: HISTORY_TYPE.DAY,
      },
    ];
    this.tooltipRef = React.createRef();
  }

  getDefaultTimeRange = () => {
    const currentDate = new Date();
    let currentHour = currentDate.getHours();
    currentHour = currentHour < 10 ? `0${currentHour}` : `${currentHour}`;
    const fromTime = `00:00`;
    const toTime = `${currentHour}:00`;
    return { fromTime, toTime };
  };

  componentDidMount() {
    const { tenantId } = this.state;
    const { fromTime, toTime } = this.getDefaultTimeRange();
    const newFilterData = {
      selectedTimeRange: {
        fromTime,
        toTime,
      },
    };
    const branchData = this.updateDataByLocationType({
      locationType: LOCATION_TYPE.BRANCH,
      filterData: newFilterData,
    });
    const floorData = this.updateDataByLocationType({
      locationType: LOCATION_TYPE.FLOOR,
      filterData: newFilterData,
    });
    const departmentData = this.updateDataByLocationType({
      locationType: LOCATION_TYPE.DEPARTMENT,
      filterData: newFilterData,
    });
    this.setState({ ...branchData, ...floorData, ...departmentData }, () => {
      if (tenantId) {
        this.getCurrentAttendanceRateByLocation();
        this.getLineChartData();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenantId: tenant }, () => {
        this.getCurrentAttendanceRateByLocation();
        this.getLineChartData();
      });
    }
  }

  updateDataByLocationType({
    locationType,
    chartData = null,
    tableData = null,
    filterData = null,
  }) {
    let data = {};
    if (locationType === LOCATION_TYPE.BRANCH) {
      const { branchData } = this.state;
      data = { ...branchData };
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      const { floorData } = this.state;
      data = { ...floorData };
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      const { departmentData } = this.state;
      data = { ...departmentData };
    } else {
      return {};
    }
    if (chartData) {
      data = { ...data, chartData: chartData };
    }
    if (tableData) {
      data = { ...data, tableData: tableData };
    }
    if (filterData) {
      let newFilterData = data.filterData ?? {};
      Object.keys(filterData).forEach(key => {
        newFilterData = {
          ...newFilterData,
          [key]: filterData[key],
        };
      });
      data = { ...data, filterData: newFilterData };
    }
    if (locationType === LOCATION_TYPE.BRANCH) {
      return { branchData: data };
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      return { floorData: data };
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      return { departmentData: data };
    } else {
      return {};
    }
  }

  getTableDataByLocationType({ locationType }) {
    if (locationType === LOCATION_TYPE.BRANCH) {
      const { branchData } = this.state;
      return branchData['tableData'];
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      const { floorData } = this.state;
      return floorData['tableData'];
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      const { departmentData } = this.state;
      return departmentData['tableData'];
    } else {
      return [];
    }
  }

  getChartDataByLocationType({ locationType }) {
    if (locationType === LOCATION_TYPE.BRANCH) {
      const { branchData } = this.state;
      return branchData['chartData'];
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      const { floorData } = this.state;
      return floorData['chartData'];
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      const { departmentData } = this.state;
      return departmentData['chartData'];
    } else {
      return [];
    }
  }

  getFilterDataByLocationType({ locationType }) {
    if (locationType === LOCATION_TYPE.BRANCH) {
      const { branchData } = this.state;
      return branchData['filterData'];
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      const { floorData } = this.state;
      return floorData['filterData'];
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      const { departmentData } = this.state;
      return departmentData['filterData'];
    } else {
      return {};
    }
  }

  extractLocationInfo({ data, locationType }) {
    const locationIdSet = new Set();
    const idSet = new Set();
    data.forEach(obj => {
      const { branch_id, branch_name, floor_id, floor_name, department } = obj;
      if (locationType === LOCATION_TYPE.BRANCH) {
        if (!branch_id) {
          return;
        }
        if (!idSet.has(branch_id)) {
          idSet.add(branch_id);
          locationIdSet.add({ id: branch_id, name: branch_name });
        }
      } else if (locationType === LOCATION_TYPE.FLOOR) {
        if (!floor_id) {
          return;
        }
        if (!idSet.has(floor_id)) {
          idSet.add(floor_id);
          locationIdSet.add({ id: floor_id, name: floor_name });
        }
      } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
        if (!department) {
          return;
        }
        if (!idSet.has(department)) {
          idSet.add(department);
          locationIdSet.add({ id: department, name: department });
        }
      }
    });
    const arr = Array.from(locationIdSet);
    for (let i = 0; i < arr.length; i++) {
      const color = LINE_CHART_COLOR_LIST[i];
      arr[i]['fillColor'] = color ?? getRandomHexColor();
    }
    return arr.reduce((acc, obj) => {
      const { id, name, fillColor } = obj;
      acc[id] = { id, name, fillColor };
      return acc;
    }, {});
  }

  getCurrentAttendanceRateByLocation = async () => {
    const { tenantId, selectedLocationType: locationType } = this.state;
    if (!tenantId || !locationType) {
      return;
    }
    this.setState({ isLoadingLocation: true });
    let res = [];
    let resLocationList = [];
    if (locationType === LOCATION_TYPE.BRANCH) {
      const tsRes = await fetchLatestTimestampListByHourForBranch({
        tenantId,
      });
      const latestTimestampVal = tsRes?.[0]?.timestamp;
      res = await fetchEmployeeAttendanceRateByHourForBranch({
        tenantId,
        fromDate: latestTimestampVal,
        toDate: latestTimestampVal,
      });
      res = res || [];
      // find a collection of records that has same latest timestamp
      const latestTsItemList = res.reduce((acc, obj) => {
        const { branch_id, timestamp, branch_name } = obj;
        obj['name'] = branch_name;
        if (!acc[branch_id] || acc[branch_id] < timestamp) {
          acc[branch_id] = obj;
        }
        return acc;
      }, {});
      resLocationList = Object.values(latestTsItemList);
      resLocationList.sort((a, b) => a.branch_order - b.branch_order);
    } else if (locationType === LOCATION_TYPE.FLOOR) {
      const tsRes = await fetchLatestTimestampListByHourForFloor({
        tenantId,
      });
      const latestTimestampVal = tsRes?.[0]?.timestamp;
      res = await fetchEmployeeAttendanceRateByHourForFloor({
        tenantId,
        fromDate: latestTimestampVal,
        toDate: latestTimestampVal,
      });
      res = res || [];
      // find a collection of records that has same latest timestamp
      const latestTsItemList = res.reduce((acc, obj) => {
        const { floor_id, timestamp, floor_name } = obj;
        obj['name'] = floor_name;
        if (!acc[floor_id] || acc[floor_id] < timestamp) {
          acc[floor_id] = obj;
        }
        return acc;
      }, {});
      resLocationList = Object.values(latestTsItemList);
      resLocationList.sort((a, b) => a.floor_order - b.floor_order);
    } else if (locationType === LOCATION_TYPE.DEPARTMENT) {
      const tsRes = await fetchLatestTimestampListByHourForDepartment({
        tenantId,
      });
      const latestTimestampVal = tsRes?.[0]?.timestamp;
      res = await fetchEmployeeAttendanceRateByHourForDepartment({
        tenantId,
        fromDate: latestTimestampVal,
        toDate: latestTimestampVal,
      });
      res = res || [];
      // find a collection of records that has same latest timestamp
      const latestTsItemList = res.reduce((acc, obj) => {
        const { department, timestamp } = obj;
        obj['name'] = department;
        if (!acc[department] || acc[department] < timestamp) {
          acc[department] = obj;
        }
        return acc;
      }, {});
      resLocationList = Object.values(latestTsItemList);
      resLocationList.sort((a, b) => a.name.localeCompare(b.name));
    }
    // Update attendance_rate
    resLocationList = resLocationList.map(item => {
      item.attendance_rate = round(item.attendance_rate * 100, 2);
      return item;
    });

    const newData = this.updateDataByLocationType({
      locationType: locationType,
      tableData: resLocationList,
    });

    this.setState({
      ...newData,
      isLoadingLocation: false,
    });
  };

  fillMissingData = ({ data }) => {
    const timestampSet = new Set();
    const dateSet = new Set();
    for (const chartData of data) {
      const { data } = chartData;
      data.forEach(item => {
        const { date, timestamp } = item;
        if (date) {
          dateSet.add(date);
        } else if (timestamp) {
          timestampSet.add(timestamp);
        }
      });
    }
    for (const chartData of data) {
      const { data } = chartData;
      const timestampValues = data.map(item => item.timestamp);
      const dateValues = data.map(item => item.date);
      // filter value that is in timeValueSet but not in currentTimeValues
      const missingTimeValues = Array.from(timestampSet).filter(
        item => !timestampValues.includes(item)
      );
      const missingDateValues = Array.from(dateSet).filter(
        item => !dateValues.includes(item)
      );
      if (missingTimeValues.length > 0) {
        for (const missingTimeValue of missingTimeValues) {
          data.push({
            timestamp: missingTimeValue,
            date: '',
            attendance_rate: null,
          });
        }
      }
      if (missingDateValues.length > 0) {
        for (const missingDateValue of missingDateValues) {
          data.push({
            date: missingDateValue,
            timestamp: '',
            attendance_rate: null,
          });
        }
      }
      data.sort((a, b) => {
        const { date: dateA, timestamp: timestampA } = a;
        const { date: dateB, timestamp: timestampB } = b;
        if (dateA && dateB) {
          return compareAsc(new Date(dateA), new Date(dateB));
        } else if (timestampA && timestampB) {
          return compareAsc(new Date(timestampA), new Date(timestampB));
        }
        return 0;
      });
    }
    return data;
  };

  getLineChartData = async () => {
    const { tenantId, selectedLocationType } = this.state;
    if (!tenantId || !selectedLocationType) {
      return;
    }
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const {
      selectedHistoryType,
      selectedDate,
      fromSelectedDate,
      toSelectedDate,
      selectedTimeRange,
      locationOptions,
    } = filterData;

    this.setState({ isLoadingLineChart: true });
    let res = [];
    if (selectedHistoryType === HISTORY_TYPE.DAY) {
      const fromSelectedDateStr = format(fromSelectedDate, 'yyyy-MM-dd');
      const toSelectedDateStr = format(toSelectedDate, 'yyyy-MM-dd');
      if (selectedLocationType === LOCATION_TYPE.BRANCH) {
        res = await fetchEmployeeAttendanceRateByDayForBranch({
          tenantId,
          fromDate: fromSelectedDateStr,
          toDate: toSelectedDateStr,
        });
      } else if (selectedLocationType === LOCATION_TYPE.FLOOR) {
        res = await fetchEmployeeAttendanceRateByDayForFloor({
          tenantId,
          fromDate: fromSelectedDateStr,
          toDate: toSelectedDateStr,
        });
      } else if (selectedLocationType === LOCATION_TYPE.DEPARTMENT) {
        res = await fetchEmployeeAttendanceRateByDayForDepartment({
          tenantId,
          fromDate: fromSelectedDateStr,
          toDate: toSelectedDateStr,
        });
      }
    } else if (selectedHistoryType === HISTORY_TYPE.HOUR) {
      const { fromTime, toTime } = selectedTimeRange;
      const selectedDateString = format(selectedDate, 'yyyy-MM-dd');
      const fromDate = `${selectedDateString} ${fromTime}:00`;
      const toDate = `${selectedDateString} ${toTime}:00`;
      if (selectedLocationType === LOCATION_TYPE.BRANCH) {
        res = await fetchEmployeeAttendanceRateByHourForBranch({
          tenantId,
          fromDate,
          toDate,
        });
      } else if (selectedLocationType === LOCATION_TYPE.FLOOR) {
        res = await fetchEmployeeAttendanceRateByHourForFloor({
          tenantId,
          fromDate,
          toDate,
        });
      } else if (selectedLocationType === LOCATION_TYPE.DEPARTMENT) {
        res = await fetchEmployeeAttendanceRateByHourForDepartment({
          tenantId,
          fromDate,
          toDate,
        });
      }
    }
    res = res || [];
    const locationInfoMap = this.extractLocationInfo({
      data: res,
      locationType: selectedLocationType,
    });
    let newLineChartData = [];
    for (const key in locationInfoMap) {
      if (Object.hasOwnProperty.call(locationInfoMap, key)) {
        const locationId = locationInfoMap[key]['id'];
        const locationName = locationInfoMap[key]['name'];
        let filteredData = res
          .filter(item => {
            if (selectedLocationType === LOCATION_TYPE.BRANCH) {
              return item.branch_id === locationId;
            } else if (selectedLocationType === LOCATION_TYPE.FLOOR) {
              return item.floor_id === locationId;
            } else if (selectedLocationType === LOCATION_TYPE.DEPARTMENT) {
              return item.department === locationId;
            }
            return false;
          })
          .map(item => ({
            timestamp: item.timestamp ?? '',
            date: item.date ?? '',
            attendance_rate: round(item.attendance_rate * 100, 2),
          }));
        newLineChartData.push({
          name: locationName,
          locationId: locationId,
          data: filteredData,
          fillColor: locationInfoMap[key]['fillColor'],
        });
      }
    }
    newLineChartData = this.fillMissingData({ data: newLineChartData });

    // Update line multiselect options
    const newLineOptions = newLineChartData
      .map(item => ({
        name: item?.name?.toString().toUpperCase(),
        code: item?.locationId,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    let newFilterData = null;
    if (locationOptions.includes(-1)) {
      newFilterData = {
        ...filterData,
        locationOptions: newLineOptions,
        selectedLocations: newLineOptions,
      };
    }
    const newData = this.updateDataByLocationType({
      locationType: selectedLocationType,
      chartData: newLineChartData,
      filterData: newFilterData,
    });

    this.setState({
      ...newData,
      isLoadingLineChart: false,
    });
  };

  datePickerOnChanged = (e, field) => {
    const BOUNCE_IN_MILISECONDS = 300;
    const { selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { fromSelectedDate } = filterData;
    let isSelectedDateField = false;
    if (
      field === DATE_PICKER_FIELD.TO_DATE &&
      isBefore(e.value, fromSelectedDate)
    ) {
      isSelectedDateField = true;
    }
    if (!isSameDay(e.value, this.state[field])) {
      const debouncedFunc = debounce(() => {
        this.getLineChartData();
      }, BOUNCE_IN_MILISECONDS);
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        debouncedFunc();
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }

    let newFilterData = {};
    if (isSelectedDateField) {
      newFilterData = {
        [field]: e.value,
        [DATE_PICKER_FIELD.FROM_DATE]: e.value,
      };
    } else {
      newFilterData = {
        [field]: e.value,
      };
    }

    const newData = this.updateDataByLocationType({
      locationType: selectedLocationType,
      filterData: newFilterData,
    });

    this.setState({
      ...newData,
    });
  };

  renderDatePicker() {
    const { selectedLocationType, isLoadingLineChart } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedDate } = filterData;
    const maxDate = new Date();
    return (
      <CustomCalendar
        disabled={isLoadingLineChart}
        value={selectedDate}
        onChange={e =>
          this.datePickerOnChanged(e, DATE_PICKER_FIELD.SELECTED_DATE)
        }
        maxDate={maxDate}
      ></CustomCalendar>
    );
  }

  renderDateRangePicker() {
    const { selectedLocationType, isLoadingLineChart } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { fromSelectedDate, toSelectedDate } = filterData;
    const maxDate = new Date();
    return (
      <Fragment>
        <CustomCalendar
          className="mr-2"
          disabled={isLoadingLineChart}
          value={fromSelectedDate}
          onChange={e =>
            this.datePickerOnChanged(e, DATE_PICKER_FIELD.FROM_DATE)
          }
          maxDate={toSelectedDate}
          label={COMMON_TEXT.FROM_DATE}
        ></CustomCalendar>
        <CustomCalendar
          disabled={isLoadingLineChart}
          value={toSelectedDate}
          onChange={e => this.datePickerOnChanged(e, DATE_PICKER_FIELD.TO_DATE)}
          maxDate={maxDate}
          label={COMMON_TEXT.TO_DATE}
        ></CustomCalendar>
      </Fragment>
    );
  }

  timeRangeOnChanged = (e, type) => {
    let newVal = e.value;
    const { selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedTimeRange } = filterData;
    const { fromTime, toTime } = selectedTimeRange;
    let newFilterData = {};
    if (type === 'fromTime') {
      if (newVal >= toTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex + 1;
        newIndex =
          newIndex > this.timeRangeOptions.length - 1
            ? this.timeRangeOptions.length - 1
            : newIndex;
        const newToTime = this.toTimeRangeOptions[newIndex].value;
        newFilterData = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: newToTime,
          },
        };
      } else {
        newFilterData = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: toTime,
          },
        };
      }
    } else if (type === 'toTime') {
      if (newVal <= fromTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex;
        newIndex = newIndex < 0 ? 0 : newIndex;
        const newFromTime = this.fromTimeRangeOptions[newIndex].value;
        newFilterData = {
          selectedTimeRange: {
            fromTime: newFromTime,
            toTime: newVal,
          },
        };
      } else {
        newFilterData = {
          selectedTimeRange: {
            fromTime: fromTime,
            toTime: newVal,
          },
        };
      }
    }

    const newData = this.updateDataByLocationType({
      locationType: selectedLocationType,
      filterData: newFilterData,
    });
    this.setState(
      {
        ...newData,
      },
      debounce(() => {
        this.getLineChartData();
      }, 500)
    );
  };

  renderDatePickerContainer() {
    const { selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedHistoryType } = filterData;
    if (selectedHistoryType === HISTORY_TYPE.DAY) {
      return this.renderDateRangePicker();
    } else if (selectedHistoryType === HISTORY_TYPE.HOUR) {
      return (
        <>
          {this.renderDatePicker()}
          {this.renderTimeRangeDropdown()}
        </>
      );
    }
    return <></>;
  }

  renderTimeRangeDropdown() {
    const { selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedTimeRange } = filterData;
    const { fromTime, toTime } = selectedTimeRange;
    return (
      <div className="timerange-select-container">
        <CustomDropdown
          label="開始"
          value={fromTime}
          options={this.fromTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'fromTime')}
        ></CustomDropdown>
        <CustomDropdown
          label="終了"
          value={toTime}
          options={this.toTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'toTime')}
        ></CustomDropdown>
      </div>
    );
  }

  multiSelectLocationsOnChanged = e => {
    const { selectedLocationType } = this.state;
    const newFilterData = {
      selectedLocations: e.value,
    };
    const newData = this.updateDataByLocationType({
      locationType: selectedLocationType,
      filterData: newFilterData,
    });
    this.setState({
      ...newData,
    });
  };

  renderMultiSelectLocations = () => {
    const { isLoadingLocation, selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedLocations, locationOptions } = filterData;
    const currentLocationList = this.getTableDataByLocationType({
      locationType: selectedLocationType,
    });
    const itemTemplate = option => {
      return (
        <div
          className="multi-select-item-template"
          style={{ backgroundColor: option.fillColor }}
        >
          {option.name}
        </div>
      );
    };
    const footerTemplate = () => {
      const length = selectedLocations ? selectedLocations.length : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };

    const sortedLocationOptions = locationOptions.map(option => {
      const locOpt = currentLocationList.find(
        item =>
          item.branch_id === option.code ||
          item.floor_id === option.code ||
          item.department === option.code
      );
      const { branch_order, floor_order, name } = locOpt ?? {};
      if (branch_order) {
        return { ...option, order: branch_order };
      } else if (floor_order) {
        return { ...option, order: floor_order };
      } else {
        return { ...option, order: name };
      }
    });
    sortedLocationOptions.sort((a, b) => a.order - b.order);
    const sortedSelectedLocations = selectedLocations.sort((a, b) => {
      const aIndex = sortedLocationOptions.findIndex(
        item => item.code === a.code
      );
      const bIndex = sortedLocationOptions.findIndex(
        item => item.code === b.code
      );
      return aIndex - bIndex;
    });

    return (
      <MultiSelect
        dataKey={'code'}
        value={sortedSelectedLocations}
        options={sortedLocationOptions}
        disabled={sortedLocationOptions.length === 0 || isLoadingLocation}
        onChange={this.multiSelectLocationsOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className=""
        display="chip"
        style={{ minWidth: '300px', maxWidth: '85%' }}
      />
    );
  };

  renderMessageError = () => {
    const { tenantId } = this.state;
    if (!tenantId) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderLineChart({ chartData, filterData }) {
    const { isLoadingLineChart: isLoadingData } = this.state;
    const { selectedLocations, selectedHistoryType } = filterData;
    chartData = chartData ?? [];
    const filteredDisplayItems = chartData.filter(item =>
      selectedLocations.some(
        locationItem => locationItem?.code === item.locationId
      )
    );
    const sortedDisplayItems = filteredDisplayItems.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const xAxisDataKey =
      selectedHistoryType === HISTORY_TYPE.DAY ? 'date' : 'timestamp';
    return (
      <div style={{ position: 'relative' }}>
        <CustomLineChart
          data={sortedDisplayItems}
          xAxisDataKey={xAxisDataKey}
          yAxisDataKey={'attendance_rate'}
          tooltipLabel={COMMON_TEXT.ATTENDANCE_RATE}
          yAxisLabel={'%'}
          yAxisMaxValue={100}
          yAxisMinValue={0}
          containerHeight={420}
          isLoading={isLoadingData}
        />
      </div>
    );
  }

  renderRefreshButton() {
    const { isLoadingLineChart, isLoadingLocation, tenantId } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoadingLineChart || isLoadingLocation}
        disabled={!tenantId}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => {
          this.getCurrentAttendanceRateByLocation();
          this.getLineChartData();
        }}
      />
    );
  }

  locationTypeButtonOnChanged = e => {
    this.setState({ selectedLocationType: e.value }, () => {
      this.getCurrentAttendanceRateByLocation();
      this.getLineChartData();
    });
  };

  renderLocationTypeSelectButton = () => {
    const { selectedLocationType } = this.state;
    return (
      <div className="card flex justify-content-center">
        <SelectButton
          value={selectedLocationType}
          onChange={e => this.locationTypeButtonOnChanged(e)}
          options={this.locationTypeOptions}
          allowEmpty={false}
        />
      </div>
    );
  };

  historyTypeDropdownOnChange = e => {
    const { selectedLocationType } = this.state;
    const newFilterData = {
      selectedHistoryType: e.value,
    };
    const newData = this.updateDataByLocationType({
      locationType: selectedLocationType,
      filterData: newFilterData,
    });
    this.setState(
      {
        ...newData,
      },
      () => {
        this.getLineChartData();
      }
    );
  };

  renderHistoryTypeDropdown = () => {
    const { selectedLocationType } = this.state;
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    const { selectedHistoryType } = filterData;
    return (
      <div className="card flex justify-content-center">
        <CustomDropdown
          key={`history-type-dropdown-${generateRandomString(10)}`}
          label={COMMON_TEXT.TYPE}
          value={selectedHistoryType}
          options={this.historyTypeOptions}
          onChange={e => this.historyTypeDropdownOnChange(e)}
        ></CustomDropdown>
      </div>
    );
  };

  LocationListView = () => {
    const { isLoadingLocation, selectedLocationType } = this.state;
    const currentLocationList = this.getTableDataByLocationType({
      locationType: selectedLocationType,
    });

    const infoButtonOnClick = e => {
      if (this.tooltipRef.current) {
        this.tooltipRef.current.show(e);
      }
    };

    const LegendContent = () => {
      return (
        <div className="legend-container">
          <div className="legend-header">
            <span>{COMMON_TEXT.ATTENDANCE_RATE_THRESHOLD}</span>
          </div>
          <div className="legend-content">
            {ATTENDANCE_RATE_LEGEND.map((item, index) => {
              const { color, label, value } = item;
              return (
                <div className="item-row" key={`legend-item-row-${index}`}>
                  <div className="item-name-container">
                    <div
                      className="item-color"
                      style={{ backgroundColor: color }}
                    ></div>
                    <div className="item-name">
                      <span>{label}</span>
                    </div>
                  </div>
                  <div className="item-value">
                    <span>{value}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return (
      <div className="location-list-container">
        <div className="title-container">
          <span>{COMMON_TEXT.LATEST_ATTENDANCE_RATE}</span>
          <Tooltip
            ref={this.tooltipRef}
            target={`.custom-legend-button`}
            position="right"
            className="custom-legend-tooltip"
            event="hover"
          >
            <div className="data-container-legend">
              {<LegendContent></LegendContent>}
            </div>
          </Tooltip>
          <Button
            type="button"
            className={`custom-legend-button custom-legend-button}`}
            severity="secondary"
            text
            onClick={e => infoButtonOnClick(e)}
          >
            <i className="pi pi-info-circle"></i>
          </Button>
        </div>
        <div className="location-list">
          {isLoadingLocation ? (
            <div className="loading-container">
              <LoadingSpinner />
            </div>
          ) : currentLocationList.length === 0 ? (
            <>
              <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>
            </>
          ) : (
            currentLocationList.map((item, index) => {
              const {
                name,
                attendance_zone,
                attendance_rate,
                timestamp,
                current_counts,
                total_counts,
              } = item;
              return (
                <div
                  key={`loc-item-${generateRandomString(10)}`}
                  className={`location-item location-zone-${attendance_zone}`}
                >
                  <div className="left-container">
                    <div className="item-name">{name}</div>
                  </div>
                  <div className="right-container">
                    <div className="rate">
                      {`${attendance_rate}`}
                      <span className="percentage-symbol">{`%`}</span>
                    </div>
                    <div className="count">{`(${current_counts}/${total_counts})`}</div>
                  </div>
                  <div className="timestamp">
                    {format(new Date(timestamp), 'MM月dd日 - HH:mm')}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  ChartView = () => {
    const { selectedLocationType, isLoadingLineChart } = this.state;
    const chartData = this.getChartDataByLocationType({
      locationType: selectedLocationType,
    });
    const filterData = this.getFilterDataByLocationType({
      locationType: selectedLocationType,
    });
    return (
      <div className="chart-view-container">
        <div className="title-container">{COMMON_TEXT.LOG}</div>
        <div className="chart-view">
          <div className="menu-container menu-container-1">
            {this.renderHistoryTypeDropdown()}
            {this.renderDatePickerContainer()}
          </div>
          <div className="menu-container mt-2">
            {this.renderMultiSelectLocations()}
          </div>
          <div className="chart-container">
            {isLoadingLineChart ? (
              <div className="loading-container">
                <LoadingSpinner />
              </div>
            ) : (
              this.renderLineChart({
                chartData,
                filterData,
              })
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const LocationListView = this.LocationListView;
    const ChartView = this.ChartView;
    return (
      <div className="config-container custom-config-container employee-attendance">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">
              {COMMON_TEXT.EMPLOYEE_ATTENDANCE_RATE}
            </div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="main-container">
                <div className="chart-container">
                  <div className="select-btn-container">
                    <div className="select-chart-btn-container">
                      {this.renderLocationTypeSelectButton()}
                    </div>
                  </div>
                  <div className="location-chart-view-container">
                    <LocationListView></LocationListView>
                    <ChartView></ChartView>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

EmployeeAttendance.propTypes = {};

export default EmployeeAttendance;
