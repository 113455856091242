import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildRequestQueryUserTenant = userEmail => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        user_id: ['select'],
        user_name: ['select'],
        privilege: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      user_name: [userEmail],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.USERS,
  };
};

const buildRequestQueryFloor = branchIdList => {
  return {
    data: {
      fields: {
        floor_id: ['select'],
        floorplan_id: ['select'],
        floor_name: ['select'],
        branch_id: ['select'],
        floor_map: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      branch_id: branchIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.FLOORS,
  };
};

const buildRequestQueryTenant = tenantIdList => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: tenantIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildRequestQueryTenantWithSupervisor = () => {
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      allow_supervisor: 1,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildRequestQueryBranch = tenantIdList => {
  return {
    data: {
      fields: {
        branch_id: ['select'],
        branch_name: ['select'],
        tenant_id: ['select'],
        branch_order: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: tenantIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      branch_order: 'asc',
    },
    table_name: QUERY_TABLES.BRANCHES,
  };
};

export const fetchDataUserTenant = async userEmail => {
  const request = buildRequestQueryUserTenant(userEmail);
  return executeQuery(request);
};

export const fetchDataTenant = async payload => {
  const { tenantIdList } = payload;
  const request = buildRequestQueryTenant(tenantIdList);
  return executeQuery(request);
};

export const fetchTenantWithSupervisor = async payload => {
  const request = buildRequestQueryTenantWithSupervisor();
  return executeQuery(request);
};

export const fetchDataBranch = async payload => {
  const { tenantIdList } = payload;
  const request = buildRequestQueryBranch(tenantIdList);
  return executeQuery(request);
};

export const fetchDataFloor = async payload => {
  const { branchIdList } = payload;
  const request = buildRequestQueryFloor(branchIdList);
  return executeQuery(request);
};
