/**
 *
 * VerkadaNetworkEvents
 *
 */

import VerkadaNetworkEvents from './VerkadaNetworkEvents.jsx';
import './VerkadaNetworkEvents.less';

export default VerkadaNetworkEvents;
