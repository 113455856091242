import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  PC_PHONE_TYPE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
} from '@/helpers/constants';
import { addDays, compareAsc, format } from 'date-fns';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep } from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { generateRandomString } from 'src/helpers/utility';
import CsvExport from './CsvExport';
import {
  fetchEmployees,
  fetchMerakiAccessLogs,
  fetchVerkadaAccessLogs,
  fetchSecureConnectStartBeforeStartAndEndAfterStart,
  fetchSecureConnectStartBeforeStartAndEndAfterEnd,
  fetchSecureConnectStartAfterStartAndEndAfterEnd,
  fetchSecureConnectStartAfterStartAndEndBeforerEnd,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeAccessLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: addDays(new Date(), 0),
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      loadingStates: {},
      employeeLogMap: {},

      //new
      sliderValues: [0, 24],
      selectedTimeRange: {
        from: '00:00',
        to: '23:59',
      },

      // new 2
      departmentSeachText: '',
      teamSearchText: '',
      employeeSearchText: '',
      departmentActiveIndex: [],
      teamActiveIndex: {},
      departmentList: [],
      filteredEmpLocGroupedByDeptAndTeam: {},
      isPcToggleChecked: true,
      isMobileToggleChecked: false,
    };

    // new
    this.employeeLocationList = [];
    this.empLocGroupedByDeptAndTeam = {};
    this.totalEmployeeMap = {};
  }

  componentDidMount() {
    const { tenant } = this.state;
    const prevSliderState =
      this.props.sessionStore?.[SESSION_STORAGE_KEY.EMPLOYEE_LOG_SLIDER_STATE];
    if (prevSliderState) {
      const { sliderValues, selectedTimeRange } = JSON.parse(prevSliderState);
      this.setState({
        sliderValues: sliderValues,
        selectedTimeRange: selectedTimeRange,
      });
    }
    if (tenant) {
      this.getEmployeesData({ tenantId: tenant });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getEmployeesData({ tenantId: tenant });
      });
    }
  }

  calculateMinutesBetween = ({ startTime, endTime }) => {
    // startTime, endTime is string, format 'HH:mm'
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;
    const diff = totalEndMinutes - totalStartMinutes;
    return diff >= 0 ? diff : -1;
  };

  calculateSecondsBetween = ({ startTime, endTime }) => {
    // startTime, endTime is string, format 'HH:mm'
    const [startHours, startMinutes, startSeconds] = startTime
      .split(':')
      .map(Number);
    const [endHours, endMinutes, endSeconds] = endTime.split(':').map(Number);

    const totalStartSeconds =
      startHours * 60 * 60 + startMinutes * 60 + startSeconds;
    const totalEndSeconds = endHours * 60 * 60 + endMinutes * 60 + endSeconds;
    const diff = totalEndSeconds - totalStartSeconds;
    return diff >= 0 ? diff : -1;
  };

  dateRangeCheck = ({ start, end, initialStart, initialEnd }) => {
    if (end < initialStart) {
      // 'Case 1: start and end are completely before initialStart and initialEnd.'
      return {
        width: -1,
        leftOffset: -99999,
      };
    } else if (start > initialEnd) {
      // 'Case 2: start and end are completely after initialStart and initialEnd.'
      return {
        width: -1,
        leftOffset: -99999,
      };
    } else if (start < initialStart && end > initialStart && end < initialEnd) {
      // 'Case 3: start and end overlap at the start of initialStart and initialEnd.'
      return this.calculateOffsetAndWidth({
        startDateTime: initialStart,
        endDateTime: end,
        initialStartTime: initialStart,
        initialEndTime: initialEnd,
      });
    } else if (start > initialStart && start < initialEnd && end > initialEnd) {
      // 'Case 4: start and end overlap at the end of initialStart and initialEnd.'
      return this.calculateOffsetAndWidth({
        startDateTime: start,
        endDateTime: initialEnd,
        initialStartTime: initialStart,
        initialEndTime: initialEnd,
      });
    } else if (start >= initialStart && end <= initialEnd) {
      // 'Case 5: start and end are completely within initialStart and initialEnd.'
      return this.calculateOffsetAndWidth({
        startDateTime: start,
        endDateTime: end,
        initialStartTime: initialStart,
        initialEndTime: initialEnd,
      });
    } else if (start <= initialStart && end >= initialEnd) {
      //  'Case 6: start and end encompass initialStart and initialEnd.'
      return this.calculateOffsetAndWidth({
        startDateTime: initialStart,
        endDateTime: initialEnd,
        initialStartTime: initialStart,
        initialEndTime: initialEnd,
      });
    }
  };

  calculateOffsetAndWidth = ({
    startDateTime,
    endDateTime,
    initialStartTime,
    initialEndTime,
  }) => {
    const totalSeconds = this.calculateSecondsBetween({
      startTime: format(initialStartTime, 'HH:mm:ss'),
      endTime: format(initialEndTime, 'HH:mm:ss'),
    });
    const totalSecondsBetween = this.calculateSecondsBetween({
      startTime: format(startDateTime, 'HH:mm:ss'),
      endTime: format(endDateTime, 'HH:mm:ss'),
    });
    const width = (totalSecondsBetween / totalSeconds) * 100;
    const minutesBetweenStart = this.calculateMinutesBetween({
      startTime: format(initialStartTime, 'HH:mm'),
      endTime: format(startDateTime, 'HH:mm'),
    });

    let leftOffset = -99999;
    if (minutesBetweenStart >= 0) {
      leftOffset = (minutesBetweenStart / (totalSeconds / 60)) * 100;
    }

    return {
      width: leftOffset === -99999 ? -1 : width,
      leftOffset: leftOffset,
    };
  };

  calculateOffset({
    info,
    startDateTime: startDateTimeStr,
    endDateTime: endDateTimeStr,
    initialStartTime: initialStartTimeStr,
    initialEndTime: initialEndTimeStr,
    selectedDate,
  }) {
    // startTime, endTime is string, format 'HH:mm:ss'
    const selectedDateString = format(selectedDate, 'yyyy-MM-dd');
    initialStartTimeStr = `${selectedDateString} ${initialStartTimeStr}:00`;
    if (initialEndTimeStr === '23:59') {
      initialEndTimeStr = `${selectedDateString} ${initialEndTimeStr}:59`;
    } else {
      initialEndTimeStr = `${selectedDateString} ${initialEndTimeStr}:00`;
    }

    const startDateTime = new Date(startDateTimeStr);
    const endDateTime = new Date(endDateTimeStr);
    const initialStartTime = new Date(initialStartTimeStr);
    const initialEndTime = new Date(initialEndTimeStr);

    const { width, leftOffset } = this.dateRangeCheck({
      start: startDateTime,
      end: endDateTime,
      initialStart: initialStartTime,
      initialEnd: initialEndTime,
    });

    return {
      reservedWidth: width,
      reservedLeftOffset: leftOffset,
    };
  }

  groupEmployeeToDepartmentAndTeam = dataArray => {
    let resultMap = {};
    // Group the data
    dataArray.forEach(employeeInfo => {
      let { department, team } = employeeInfo;
      if (!department) {
        department = 'NO_DEPARTMENT'; // 部署なし
      }
      if (!team) {
        team = 'NO_TEAM'; // チームなし
      }

      if (!resultMap[department]) {
        resultMap[department] = {};
      }

      if (!resultMap[department][team]) {
        resultMap[department][team] = [];
      }

      resultMap[department][team].push(employeeInfo);
    });

    const totalEmployeeMap = {};
    // count the number of employees for each department
    Object.keys(resultMap).forEach(department => {
      let employeeCount = 0;
      Object.keys(resultMap[department]).forEach(team => {
        const employeeList = resultMap[department][team];
        const customIdSet = new Set(
          employeeList.map(item => item.employee_identifer)
        );
        employeeCount += customIdSet.size;
        if (!totalEmployeeMap[department]) {
          totalEmployeeMap[department] = {
            count: 0,
            [team]: 0,
          };
        }
        totalEmployeeMap[department][team] = customIdSet.size;
      });
      totalEmployeeMap[department]['count'] = employeeCount;
    });

    // if resultMap has no key is NO_DEPARTMENT, add NO_DEPARTMENT
    if (!resultMap['NO_DEPARTMENT']) {
      resultMap['NO_DEPARTMENT'] = {};
    }
    // for each key in resultMap, if any key result has no NO_TEAM, add NO_TEAM
    // Object.keys(resultMap).forEach(department => {
    //   if (!resultMap[department]['NO_TEAM']) {
    //     resultMap[department]['NO_TEAM'] = [];
    //   }
    // });
    return {
      empLocGroupedByDeptAndTeam: resultMap,
      totalEmployeeMap,
    };
  };

  getEmployeesData = async ({ tenantId }) => {
    this.setState({ isLoading: true });
    if (!tenantId) {
      return;
    }
    const employeeRes = await fetchEmployees({
      tenantId,
    });
    this.employeeLocationList = cloneDeep(employeeRes);

    const { empLocGroupedByDeptAndTeam, totalEmployeeMap } =
      this.groupEmployeeToDepartmentAndTeam(this.employeeLocationList);
    this.empLocGroupedByDeptAndTeam = cloneDeep(empLocGroupedByDeptAndTeam);
    this.totalEmployeeMap = cloneDeep(totalEmployeeMap);

    let newDepartmentList = Object.keys(this.empLocGroupedByDeptAndTeam)
      .filter(item => item !== 'NO_DEPARTMENT')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    newDepartmentList.push('NO_DEPARTMENT');
    const newUpdatedState = {
      departmentList: newDepartmentList,
      filteredEmpLocGroupedByDeptAndTeam: cloneDeep(
        this.empLocGroupedByDeptAndTeam
      ),
      isLoading: false,
    };
    this.setState(newUpdatedState);
  };

  getVerkadaAccessLogByEmployee = async employeeInfo => {
    const { employee_identifer: employeeIdentifier } = employeeInfo;
    const { tenant, selectedDate } = this.state;
    const verkadaLogs = await fetchVerkadaAccessLogs({
      tenantId: tenant,
      employeeIdentifier: employeeIdentifier,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
    });
    return verkadaLogs;
  };

  getMerakiAccessLogByEmployee = async employeeInfo => {
    const { employee_id: employeeId } = employeeInfo;
    const { tenant, selectedDate } = this.state;
    const merakiLogs = await fetchMerakiAccessLogs({
      tenantId: tenant,
      employeeId,
      fromDate: format(selectedDate, 'yyyy-MM-dd 00:00:00'),
      toDate: format(selectedDate, 'yyyy-MM-dd 23:59:59'),
    });
    return merakiLogs;
  };

  getSecureConnectByEmployee = async employeeInfo => {
    const { employee_id: employeeId } = employeeInfo;
    const { selectedDate } = this.state;

    const startDateTime = format(selectedDate, 'yyyy-MM-dd 00:00:00');
    const endDateTime = format(selectedDate, 'yyyy-MM-dd 23:59:59');

    // case 1: fetch data with start is before startDateTime
    // and end is after startDateTime and is before endDateTime
    const res1 = await fetchSecureConnectStartBeforeStartAndEndAfterStart({
      employeeId,
      fromDate: startDateTime,
      toDate: endDateTime,
    });
    // case 2: fetch data with start is before startDateTime and end is after endDateTime
    const res2 = await fetchSecureConnectStartBeforeStartAndEndAfterEnd({
      employeeId,
      fromDate: startDateTime,
      toDate: endDateTime,
    });
    // case 3: fetch data with start is after startDateTime and end is before endDateTime
    const res3 = await fetchSecureConnectStartAfterStartAndEndBeforerEnd({
      employeeId,
      fromDate: startDateTime,
      toDate: endDateTime,
    });
    // case 4: fetch data with start is after fromDate and is before EndTime and end is after toDate
    const res4 = await fetchSecureConnectStartAfterStartAndEndAfterEnd({
      employeeId,
      fromDate: startDateTime,
      toDate: endDateTime,
    });
    return [...res1, ...res2, ...res3, ...res4];
  };

  datePickerOnChanged = e => {
    const BOUNCE_IN_MILISECONDS = 300;
    if (e.value) {
      this.setState({ selectedDate: e.value, departmentActiveIndex: [] });
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }
  };

  renderSliderTimeRange() {
    // Map the numeric values to time strings
    const convertSliderValuesToTimeStrings = vals => {
      return vals.map(val => {
        if (val === 24) {
          return '23:59';
        }
        const hours = val;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        return `${formattedHours}:00`;
      });
    };

    const handleSliderChange = e => {
      if (e.value[0] > e.value[1]) {
        return;
      }
      const timeStrings = convertSliderValuesToTimeStrings(e.value);
      const updatedSliderState = {
        sliderValues: e.value,
        selectedTimeRange: {
          from: timeStrings[0],
          to: timeStrings[1],
        },
      };
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.EMPLOYEE_LOG_SLIDER_STATE,
          JSON.stringify(updatedSliderState)
        )
      );
      this.setState(updatedSliderState);
    };

    const { sliderValues, selectedTimeRange } = this.state;

    return (
      <div className="slider-container">
        <Slider
          value={sliderValues}
          onChange={handleSliderChange}
          range
          min={0}
          max={24}
          step={1}
        />
        <div
          className="time-slider-tooltip-start"
          style={{ left: `calc(${(sliderValues[0] / 24) * 100}% - 1.2rem)` }}
        >
          {selectedTimeRange.from}
        </div>
        <div
          className="time-slider-tooltip-end"
          style={{ left: `calc(${(sliderValues[1] / 24) * 100}% - 1.2rem)` }}
        >
          {selectedTimeRange.to === '24:00' ? '23:59' : selectedTimeRange.to}
        </div>
      </div>
    );
  }

  renderDatePicker() {
    const { selectedDate } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        value={selectedDate}
        onChange={this.datePickerOnChanged}
        maxDate={maxDate}
      ></CustomCalendar>
    );
  }

  renderMessageError = () => {
    const { tenant } = this.state;
    if (!tenant) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderCollapseAllButton() {
    const { tenant } = this.state;
    return (
      <Button
        className="mr-2"
        label={COMMON_TEXT.COLLAPSE_ALL}
        disabled={!tenant}
        icon="pi pi-angle-double-up"
        severity="info"
        size="small"
        onClick={() => {
          this.setState({ departmentActiveIndex: [] });
        }}
      />
    );
  }

  refreshButtonOnClicked = () => {
    const { tenant } = this.state;

    this.setState({ departmentActiveIndex: [] }, () => {
      this.getEmployeesData({ tenantId: tenant });
    });
  };

  renderRefreshButton() {
    const { isLoading, tenant } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!tenant}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => this.refreshButtonOnClicked()}
      />
    );
  }

  renderPcMobileToggleContainer = () => {
    const { isPcToggleChecked, isMobileToggleChecked } = this.state;
    return (
      <div className="pc-mobile-toggle-container">
        <div className="pc-toggle-container">
          <InputSwitch
            checked={isPcToggleChecked}
            onChange={e => this.setState({ isPcToggleChecked: e.target.value })}
          />
          <label>PC</label>
        </div>
        <div className="mobile-toggle-container">
          <InputSwitch
            checked={isMobileToggleChecked}
            onChange={e =>
              this.setState({ isMobileToggleChecked: e.target.value })
            }
          />
          <label>スマートフォン</label>
        </div>
      </div>
    );
  };

  handleSearchTextChange = (e, type) => {
    const searchText = e.target.value;
    let {
      departmentSeachText: currentSepartmentSeachText,
      teamSearchText: currentTeamSearchText,
      employeeSearchText: currentEmployeeSearchText,
    } = this.state;

    if (type === 'department') {
      currentSepartmentSeachText = searchText;
    } else if (type === 'team') {
      currentTeamSearchText = searchText;
    } else if (type === 'employee') {
      currentEmployeeSearchText = searchText;
    }

    // filter employee by search text
    const filteredEmpLocGroupedByDeptAndTeam = cloneDeep(
      this.empLocGroupedByDeptAndTeam
    );
    if (currentSepartmentSeachText.length > 0) {
      // filter filteredEmpLocGroupedByDeptAndTeam by departmentSeachText
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        if (
          !departmentName
            .toLocaleLowerCase()
            .includes(currentSepartmentSeachText.toLocaleLowerCase())
        ) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }
    if (currentTeamSearchText.length > 0) {
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        const teamMap = filteredEmpLocGroupedByDeptAndTeam[departmentName];
        const teamList = Object.keys(teamMap);
        let isTeamHasMatchedEmployee = false;
        teamList.forEach(teamName => {
          if (
            !teamName
              .toLocaleLowerCase()
              .includes(currentTeamSearchText.toLocaleLowerCase())
          ) {
            delete filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          } else {
            isTeamHasMatchedEmployee = true;
          }
        });
        if (!isTeamHasMatchedEmployee) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }
    if (currentEmployeeSearchText.length > 0) {
      let deparmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam);
      deparmentList.forEach(departmentName => {
        const teamMap = filteredEmpLocGroupedByDeptAndTeam[departmentName];
        const teamList = Object.keys(teamMap);
        let isTeamHasMatchedEmployee = false;
        teamList.forEach(teamName => {
          const employeeList =
            filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          const filteredEmployeeList = employeeList.filter(item =>
            item.employee_name
              .toLocaleLowerCase()
              .includes(currentEmployeeSearchText.toLocaleLowerCase())
          );
          if (filteredEmployeeList.length === 0) {
            delete filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName];
          } else {
            filteredEmpLocGroupedByDeptAndTeam[departmentName][teamName] =
              filteredEmployeeList;
            isTeamHasMatchedEmployee = true;
          }
        });
        if (!isTeamHasMatchedEmployee) {
          delete filteredEmpLocGroupedByDeptAndTeam[departmentName];
        }
      });
    }

    let newDepartmentList = Object.keys(filteredEmpLocGroupedByDeptAndTeam)
      .filter(item => item !== 'NO_DEPARTMENT')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });

    if (type === 'department') {
      this.setState({
        departmentSeachText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'team') {
      this.setState({
        teamSearchText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    } else if (type === 'employee') {
      this.setState({
        employeeSearchText: searchText,
        departmentList: newDepartmentList,
        filteredEmpLocGroupedByDeptAndTeam,
      });
    }
  };

  renderSearchContainer = () => {
    const { departmentSeachText, teamSearchText, employeeSearchText } =
      this.state;
    return (
      <div className="search-container">
        <div className="input-container">
          <InputText
            className="department-search"
            value={departmentSeachText}
            placeholder="部署"
            onChange={e => this.handleSearchTextChange(e, 'department')}
          />
          <InputText
            className="team-search"
            value={teamSearchText}
            placeholder="チーム"
            onChange={e => this.handleSearchTextChange(e, 'team')}
          />
          <InputText
            className="employee-search"
            value={employeeSearchText}
            placeholder="名前"
            onChange={e => this.handleSearchTextChange(e, 'employee')}
          />
        </div>
      </div>
    );
  };

  renderDepartmentGroup = ({ departmentList }) => {
    if (!departmentList || departmentList.length === 0) {
      return <div className="no-data-container">{COMMON_TEXT.NO_DATA}</div>;
    }
    const { departmentActiveIndex, filteredEmpLocGroupedByDeptAndTeam } =
      this.state;
    let newDepartmentList = [...departmentList];
    return (
      <>
        <div className="department-container" style={{ width: '100%' }}>
          <Accordion
            multiple
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            activeIndex={departmentActiveIndex}
            onTabChange={e => this.setState({ departmentActiveIndex: e.index })}
          >
            {newDepartmentList.map((departmentName, index) => {
              const teamGroupByDept =
                filteredEmpLocGroupedByDeptAndTeam[departmentName] ?? {};
              // calculate employee count for each department
              const employeeCount =
                this.totalEmployeeMap[departmentName]?.['count'] ?? 0;
              return (
                employeeCount > 0 && (
                  <AccordionTab
                    key={`department-header-${index}`}
                    header={
                      <div className="header-container">
                        <div className={`title-container`}>
                          <i className="pi pi-building mr-2"></i>
                          <span className="vertical-align-middle">{`${
                            departmentName === 'NO_DEPARTMENT'
                              ? '部署なし'
                              : departmentName
                          }`}</span>
                          <span className="count-title">{`${employeeCount}`}</span>
                        </div>
                      </div>
                    }
                  >
                    {this.renderTeamGroup({
                      department: departmentName,
                      empLocGroupedByTeam: teamGroupByDept,
                    })}
                  </AccordionTab>
                )
              );
            })}
          </Accordion>
        </div>
      </>
    );
  };

  renderTeamGroup = ({ department, empLocGroupedByTeam }) => {
    let teamList = Object.keys(empLocGroupedByTeam);
    const newTeamList = teamList
      .filter(item => item !== 'NO_TEAM')
      .sort((a, b) => {
        return a.localeCompare(b, 'ja');
      });
    if (teamList.includes('NO_TEAM')) {
      newTeamList.push('NO_TEAM');
    }
    const { teamActiveIndex, teamSearchText } = this.state;
    if (teamSearchText?.length > 0) {
      // set teamActiveIndex for all department
    }
    return (
      <div className="team-container" style={{ width: '100%' }}>
        <Accordion
          multiple
          activeIndex={teamActiveIndex[department]}
          onTabChange={e => {
            const newTeamActiveIndex = { ...teamActiveIndex };
            newTeamActiveIndex[department] = e.index;
            this.setState({ teamActiveIndex: newTeamActiveIndex });
          }}
        >
          {newTeamList.map((entry, index) => {
            const employeeListCount =
              this.totalEmployeeMap[department]?.[entry] ?? 0;
            return (
              <AccordionTab
                key={`team-header-${index}`}
                header={
                  <div className="header-container">
                    <div className={`title-container`}>
                      <i className="pi pi-users mr-2"></i>
                      <span className="vertical-align-middle">{`${
                        entry === 'NO_TEAM' ? 'チームなし' : entry
                      }`}</span>
                      <span className="count-title">{`${employeeListCount}`}</span>
                    </div>
                  </div>
                }
              >
                {this.renderEmployeeGroup({
                  employeeList: empLocGroupedByTeam[entry],
                })}
              </AccordionTab>
            );
          })}
        </Accordion>
      </div>
    );
  };

  handleEmployeeAccordionTabOpen = async employeeInfo => {
    const { employee_identifer: employeeIdentifier } = employeeInfo;
    this.setState(prevState => {
      const newLoadingStates = {
        ...prevState.loadingStates,
        [employeeIdentifier]: true,
      };
      return { loadingStates: newLoadingStates };
    });

    const verkadaLogs = await this.getVerkadaAccessLogByEmployee(employeeInfo);
    const merakiLogs = await this.getMerakiAccessLogByEmployee(employeeInfo);
    const secureConnect = await this.getSecureConnectByEmployee(employeeInfo);

    this.setState(prevState => {
      const newLoadingStates = {
        ...prevState.loadingStates,
        [employeeIdentifier]: false,
      };
      const newVerkadataLogStates = {
        ...prevState.employeeLogMap,
        [employeeIdentifier]: {
          verkada: verkadaLogs,
          meraki: merakiLogs,
          secureConnect: secureConnect,
        },
      };
      return {
        loadingStates: newLoadingStates,
        employeeLogMap: newVerkadataLogStates,
      };
    });
  };

  renderEmployeeGroup = ({ employeeList }) => {
    if (!employeeList || employeeList.length === 0) {
      return <></>;
    }
    employeeList = employeeList.sort((a, b) => {
      const aName = a['employee_name'] ?? '';
      const bName = b['employee_name'] ?? '';
      return aName.localeCompare(bName, 'ja');
    });
    const {
      loadingStates,
      employeeLogMap,
      isPcToggleChecked,
      isMobileToggleChecked,
    } = this.state;
    return (
      <div className="employee-container" style={{ width: '100%' }}>
        <Accordion
          multiple
          onTabOpen={e =>
            this.handleEmployeeAccordionTabOpen(employeeList[e.index])
          }
        >
          {employeeList.map((entry, index) => {
            const employeeIdentifer = entry['employee_identifer'];
            const isLoading = loadingStates[employeeIdentifer];
            const hasLogInfo = employeeLogMap[employeeIdentifer];
            return (
              <AccordionTab
                header={
                  <div className="flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <span className="vertical-align-middle">{`${entry['employee_name']}`}</span>
                  </div>
                }
                key={`employee-header-${index}`}
              >
                {isLoading ? (
                  <div>
                    <ProgressSpinner
                      style={{ width: '50px', height: '50px' }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration="1.5s"
                    />
                  </div>
                ) : (
                  <>
                    {hasLogInfo ? (
                      <>
                        {this.renderVerkadaLogs({
                          verkadaLogs:
                            employeeLogMap[employeeIdentifer]?.['verkada'],
                        })}
                        {this.renderMerakiLogs({
                          merakiLogs:
                            employeeLogMap[employeeIdentifer]?.['meraki'],
                          isPcToggleChecked,
                          isMobileToggleChecked,
                        })}
                        {this.renderSecureConnect({
                          secureConnect:
                            employeeLogMap[employeeIdentifer]?.[
                              'secureConnect'
                            ],
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </AccordionTab>
            );
          })}
        </Accordion>
      </div>
    );
  };

  renderVerkadaLogs = ({ verkadaLogs }) => {
    const { selectedTimeRange } = this.state;
    const initialTotalMinutes = this.calculateMinutesBetween({
      startTime: selectedTimeRange.from,
      endTime: selectedTimeRange.to,
    });

    verkadaLogs = verkadaLogs || [];
    const verkadaLogsGroupedByTimeStamp = verkadaLogs.reduce((result, log) => {
      const { timestamp, door_type } = log;
      const tsObj = new Date(timestamp);
      const timestampGroup = format(tsObj, 'yyyy-MM-dd HH:mm');
      const timePart = format(tsObj, 'HH:mm:ss');
      log['tsObj'] = tsObj;
      log['timePart'] = timePart;
      // Calculate left position
      const totalMinutes = this.calculateMinutesBetween({
        startTime: selectedTimeRange.from,
        endTime: format(tsObj, 'HH:mm'),
      });
      let leftPosition = -99999999;
      if (+totalMinutes >= 0) {
        leftPosition = (totalMinutes / initialTotalMinutes) * 100;
      }

      log['leftPosition'] = leftPosition;
      log['bgColor'] =
        door_type === 'IN' ? 'rgba(0, 128, 0, 0.7)' : 'rgba(255, 0, 0, 0.7)';
      log['randomTargetId'] = generateRandomString(8);

      if (!result[timestampGroup]) {
        result[timestampGroup] = [];
      }

      result[timestampGroup].push(log);

      return result;
    }, {});
    return (
      <div className="verkada-logs-container">
        <div className="verkada-row">
          <div className="verkada-row-title">{`入退室`}</div>
          {verkadaLogs.length === 0 ? (
            <>
              <div className="w-full grid grid-nogutter align-items-center justify-content-center">
                {COMMON_TEXT.NO_DATA}
              </div>
            </>
          ) : (
            <>
              <div className="verkada-row-content">
                {Object.keys(verkadaLogsGroupedByTimeStamp).map(
                  (entry, index) => {
                    const logs = verkadaLogsGroupedByTimeStamp[entry];
                    if (logs.length === 0) {
                      return <></>;
                    } else if (logs.length === 1) {
                      const log = logs[0];
                      const {
                        timePart,
                        leftPosition,
                        bgColor,
                        randomTargetId,
                        door_type,
                        door_name,
                      } = log;
                      return (
                        <Fragment
                          key={`fragment-verkara-row-content-${randomTargetId}-${index}`}
                        >
                          <div
                            className={`verkada-in-out-div verkada-in-out-target-${randomTargetId}`}
                            style={{
                              backgroundColor: bgColor,
                              left: `calc(${leftPosition}% - 2px)`,
                            }}
                            key={`verkada-log-${index}`}
                          ></div>
                          <Tooltip
                            className="child-div-tooltip"
                            target={`.verkada-in-out-target-${randomTargetId}`}
                            baseZIndex={2000}
                            autoHide={false}
                            position="top"
                            event="hover"
                          >
                            <div className="tooltip-content">
                              <div className="tooltip-content-item">
                                <div>{timePart}</div>
                                <div style={{ color: bgColor }}>
                                  <b>{door_type}</b>
                                </div>
                              </div>
                              <div className="tooltip-content-item">
                                <div>{door_name}</div>
                              </div>
                            </div>
                          </Tooltip>
                        </Fragment>
                      );
                    } else {
                      const log = logs[0];
                      logs.sort((a, b) => compareAsc(a.tsObj, b.tsObj));
                      const { leftPosition, randomTargetId } = log;
                      const lastLog = logs[logs.length - 1];
                      const { bgColor: lastBgColor } = lastLog;
                      return (
                        <Fragment
                          key={`fragment-verkara-row-content-${randomTargetId}-${index}`}
                        >
                          <div
                            className={`verkada-in-out-div verkada-in-out-target-${randomTargetId}`}
                            style={{
                              left: `calc(${leftPosition}% - 2px)`,
                              backgroundColor: lastBgColor,
                            }}
                            key={`verkada-log-${index}`}
                          ></div>
                          <Tooltip
                            className="child-div-tooltip"
                            target={`.verkada-in-out-target-${randomTargetId}`}
                            baseZIndex={2000}
                            autoHide={false}
                            position="top"
                            event="hover"
                          >
                            <div className="tooltip-content">
                              {logs.map((logItem, index) => {
                                const {
                                  timePart,
                                  bgColor,
                                  door_type,
                                  door_name,
                                } = logItem;
                                return (
                                  <Fragment key={`logItem-logs-${index}`}>
                                    <div className="tooltip-content-item">
                                      <div>{timePart}</div>
                                      <div style={{ color: bgColor }}>
                                        <b>{door_type}</b>
                                      </div>
                                    </div>
                                    <div className="tooltip-content-item">
                                      <div>{door_name}</div>
                                    </div>
                                    {index < logs.length - 1 && (
                                      <div
                                        style={{
                                          width: '100%',
                                          paddingLeft: '1rem',
                                          paddingRight: '1rem',
                                          marginTop: 4,
                                          marginBottom: 4,
                                          height: 1,
                                          background: '#dddddd',
                                        }}
                                      ></div>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                          </Tooltip>
                        </Fragment>
                      );
                    }
                  }
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderMerakiLogs = ({
    merakiLogs,
    isPcToggleChecked,
    isMobileToggleChecked,
  }) => {
    merakiLogs = merakiLogs || [];
    if (merakiLogs.length === 0) {
      return (
        <div className="meraki-logs-container">
          <div className="meraki-row">
            <div className="meraki-row-title">{`無線LAN`}</div>
            <div className="w-full grid grid-nogutter align-items-center justify-content-center">
              {COMMON_TEXT.NO_DATA}
            </div>
          </div>
        </div>
      );
    }
    // filter merakiLogs based on isPcToggleChecked and isMobileToggleChecked
    if (!isPcToggleChecked && !isMobileToggleChecked) {
      merakiLogs = merakiLogs.filter(
        item =>
          item.device_type !== PC_PHONE_TYPE.PC &&
          item.device_type !== PC_PHONE_TYPE.PHONE
      );
    } else if (isPcToggleChecked && isMobileToggleChecked) {
      merakiLogs = merakiLogs.filter(
        item =>
          item.device_type === PC_PHONE_TYPE.PC ||
          item.device_type === PC_PHONE_TYPE.PHONE
      );
    } else if (isPcToggleChecked) {
      merakiLogs = merakiLogs.filter(
        item => item.device_type === PC_PHONE_TYPE.PC
      );
    } else if (isMobileToggleChecked) {
      merakiLogs = merakiLogs.filter(
        item => item.device_type === PC_PHONE_TYPE.PHONE
      );
    }
    const merakiMapByDeviceName = merakiLogs.reduce((acc, cur) => {
      const { device_name } = cur;
      if (!acc[device_name]) {
        acc[device_name] = [];
      }
      acc[device_name].push(cur);
      return acc;
    }, {});
    let deviceNameList = Object.keys(merakiMapByDeviceName);
    deviceNameList = deviceNameList.sort((a, b) => {
      return a.localeCompare(b);
    });

    const { selectedTimeRange, selectedDate } = this.state;
    return (
      <div className="meraki-logs-container">
        {deviceNameList.map((entry, index) => {
          const merakiData = merakiMapByDeviceName[entry];
          return (
            <Fragment key={`meraki-device-name-${index}`}>
              <div className="meraki-row">
                <div className="meraki-row-title">{entry}</div>
                <div className="meraki-row-content">
                  {merakiData.map((item, merakiIndex) => {
                    const { start_time, end_time, meraki_device_name } = item;
                    const { reservedWidth, reservedLeftOffset } =
                      this.calculateOffset({
                        info: item,
                        startDateTime: start_time,
                        endDateTime: end_time,
                        initialStartTime: selectedTimeRange.from,
                        initialEndTime: selectedTimeRange.to,
                        selectedDate,
                      });
                    const widthVal =
                      reservedWidth === -1 ? 0 : reservedWidth + '%';
                    const leftOffsetVal =
                      reservedLeftOffset === -99999
                        ? reservedLeftOffset + 'px'
                        : reservedLeftOffset + '%';
                    const targetId = generateRandomString(8);
                    return (
                      <Fragment
                        key={`fragment-meraki-row-content-${merakiIndex}`}
                      >
                        <div
                          className={`meraki-in-out-div meraki-in-out-target-${targetId}`}
                          style={{
                            width: `${widthVal}`,
                            left: `${leftOffsetVal}`,
                          }}
                          key={`meraki-log-${index}`}
                        ></div>
                        <Tooltip
                          className="child-div-tooltip"
                          target={`.meraki-in-out-target-${targetId}`}
                          baseZIndex={2000}
                          autoHide={false}
                          position="top"
                          event="hover"
                        >
                          <div className="tooltip-content">
                            <div className="tooltip-content-item">
                              <div>{`${start_time?.split(' ')?.[1]} - ${
                                end_time?.split(' ')?.[1]
                              }`}</div>
                            </div>
                            <div className="tooltip-content-item">
                              <div>{COMMON_TEXT.MERAKI_DEVICE_NAME}</div>
                              <div>{meraki_device_name}</div>
                            </div>
                          </div>
                        </Tooltip>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };

  renderSecureConnect = ({ secureConnect }) => {
    const { selectedTimeRange, selectedDate } = this.state;

    secureConnect = secureConnect || [];

    if (secureConnect.length === 0) {
      return (
        <div className="secureconnect-container">
          <div className="secureconnect-row">
            <div className="secureconnect-row-title">{`SecureConnect`}</div>
            <div className="w-full grid grid-nogutter align-items-center justify-content-center">
              {COMMON_TEXT.NO_DATA}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="secureconnect-container">
        <div className="secureconnect-row">
          <div className="secureconnect-row-title">{`SecureConnect`}</div>
          <div className="secureconnect-row-content">
            {secureConnect.map((item, secureIndex) => {
              const { start_time, end_time, ip_address } = item;
              const { reservedWidth, reservedLeftOffset } =
                this.calculateOffset({
                  info: item,
                  startDateTime: start_time,
                  endDateTime: end_time,
                  initialStartTime: selectedTimeRange.from,
                  initialEndTime: selectedTimeRange.to,
                  selectedDate,
                });
              const widthVal = reservedWidth === -1 ? 0 : reservedWidth + '%';
              const leftOffsetVal =
                reservedLeftOffset === -99999
                  ? reservedLeftOffset + 'px'
                  : reservedLeftOffset + '%';
              const targetId = generateRandomString(8);
              return (
                <Fragment
                  key={`fragment-secureconnect-row-content-${secureIndex}`}
                >
                  <div
                    className={`secureconnect-in-out-div secureconnect-in-out-target-${targetId}`}
                    style={{
                      width: `${widthVal}`,
                      left: `${leftOffsetVal}`,
                    }}
                    key={`secureconnect-${secureIndex}`}
                  ></div>
                  <Tooltip
                    className="child-div-tooltip"
                    target={`.secureconnect-in-out-target-${targetId}`}
                    baseZIndex={2000}
                    autoHide={false}
                    position="top"
                    event="hover"
                  >
                    <div className="tooltip-content">
                      <div className="tooltip-content-item">
                        <div>{`${start_time?.split(' ')?.[1]} - ${
                          end_time?.split(' ')?.[1]
                        }`}</div>
                      </div>
                      <div className="tooltip-content-item">
                        <div>{'IPアドレス: ' + ip_address}</div>
                      </div>
                    </div>
                  </Tooltip>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, departmentList, tenant } = this.state;
    return (
      <div className="config-container custom-config-container employee-access-log-container">
        <div className="config-title-container">
          <div className="title-text">{COMMON_TEXT.EMPLOYEE_ACCESS_LOG}</div>
          <div className="button-container">{this.renderRefreshButton()}</div>
        </div>
        <div className="button-menulist-container">
          {this.renderPcMobileToggleContainer()}
          {this.renderSliderTimeRange()}
          {this.renderDatePicker()}
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="w-full">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {this.renderSearchContainer()}
                    {this.renderDepartmentGroup({ departmentList })}
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <CsvExport tenant={tenant} />
      </div>
    );
  }
}

EmployeeAccessLog.propTypes = {};

export default EmployeeAccessLog;
