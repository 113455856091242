import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryOrganizations = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        org_id: ['select'],
        org_identifer: ['select'],
        org_name: ['select'],
        product: ['select'],
        tenant_id: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.ORGANIZATIONS,
  };
};

export const fetchOrganizationList = async payload => {
  const { tenantId } = payload;
  const request = buildQueryOrganizations({ tenantId });
  return executeQuery(request);
};
