import FpQueryBuilder from 'forepaas/query-builder';
import { extractValueFromQueryResponse } from 'src/helpers/utility';

export async function executeQuery(request) {
  request.tableName = request.tableName || '';
  request.filter = request.filter || {};

  const query = new FpQueryBuilder.FpQuery(request);
  try {
    const data = await query.compute();

    if (data['success']) {
      return extractValueFromQueryResponse(data);
    } else {
      console.error('query error');
    }
  } catch (err) {
    console.error(err);
  }
  return null;
}
