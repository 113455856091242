import {
  fetchAllEmployees,
  fetchAllEmployeesDeviceByEmployeeIdList,
} from '@/components/organization/Configuration/EmployeeSetting/query-request';
import { COMMON_TEXT } from '@/helpers/common-text';
import { EMPLOYEE_CSV_COLUMN, MEMORY_STORE } from '@/helpers/constants';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { isObjectEmpty } from 'src/helpers/utility';

const CSV_ERROR_CODE = {
  DUPLICATED: 1,
  CONTENT: 2,
  COLUMNS: 3,
  INVALID_DATA: 4,
};

const DUPLICATED_FIELD_CHECK = [
  'employee_id',
  'employee_number',
  'employee_device_pc_mac',
  'employee_device_mobile_mac',
];

const EXPECTED_CSV_HEADERS = {
  employee_name: EMPLOYEE_CSV_COLUMN.NAME,
  employee_name_reading: EMPLOYEE_CSV_COLUMN.NAME_READING,
  employee_number: EMPLOYEE_CSV_COLUMN.EMPLOYEE_NUMBER,
  phone_number: EMPLOYEE_CSV_COLUMN.PHONE_NUMBER,
  phone_number2: EMPLOYEE_CSV_COLUMN.PHONE_NUMBER2,
  email: EMPLOYEE_CSV_COLUMN.EMAIL,
  chat_tools: EMPLOYEE_CSV_COLUMN.CHAT_TOOL,
  department: EMPLOYEE_CSV_COLUMN.DEPARTMENT,
  team: EMPLOYEE_CSV_COLUMN.TEAM,
  branch_name: EMPLOYEE_CSV_COLUMN.BRANCH,
  floor_name: EMPLOYEE_CSV_COLUMN.FLOOR,
  employee_identifer: EMPLOYEE_CSV_COLUMN.VERKADA_ID,
  employee_device_pc_mac: EMPLOYEE_CSV_COLUMN.PC_MAC_ADDRESS,
  employee_device_pc_name: EMPLOYEE_CSV_COLUMN.PC_HOST_NAME,
  employee_device_mobile_mac: EMPLOYEE_CSV_COLUMN.MOBILE_MAC_ADDRESS,
  employee_device_mobile_name: EMPLOYEE_CSV_COLUMN.MOBILE_HOST_NAME,
  employee_id: EMPLOYEE_CSV_COLUMN.ID,
};

const CSV_DATA_ROW_STATUS = {
  UNCHANGED: 0,
  NEW: 1,
  MODIFIED: 2,
  DELETED: 3,
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class EmployeeCSVUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCsvErrorDialogVisible: false,
      csvError: {},
      isCsvImportDialogVisible: false,
      csvData: [],
      importedCsvInfo: {
        newCount: 0,
        modifiedCount: 0,
        deletedCount: 0,
      },
      isUpdatingData: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  getEmployeeData = async () => {
    const { tenantId } = this.props;
    const employees = await fetchAllEmployees({
      tenantId,
    });
    const emplDevices = await fetchAllEmployeesDeviceByEmployeeIdList({
      employeeIdList: employees.map(employee => +employee.employee_id),
    });
    const emplList = employees.map(employee => {
      let employeeDeviceMap = {
        employee_device_pc_mac: '',
        employee_device_pc_name: '',
        employee_device_mobile_mac: '',
        employee_device_mobile_name: '',
      };

      emplDevices
        .filter(device => device.employee_id === employee.employee_id)
        .forEach(employeeDevice => {
          if (employeeDevice.device_type === 'pc') {
            employeeDeviceMap.employee_device_pc_mac =
              employeeDevice.employee_device_mac;
            employeeDeviceMap.employee_device_pc_name =
              employeeDevice.employee_device_name;
          } else if (employeeDevice.device_type === 'phone') {
            employeeDeviceMap.employee_device_mobile_mac =
              employeeDevice.employee_device_mac;
            employeeDeviceMap.employee_device_mobile_name =
              employeeDevice.employee_device_name;
          }
        });

      return {
        ...employee,
        ...employeeDeviceMap,
      };
    });
    return emplList;
  };

  mappingCsvDataToCurrentEmployeeList = ({ data, employeeList }) => {
    // update employee id to number
    const csvData = data.map(data => {
      return {
        ...data,
        employee_id: data.employee_id ? Number(data.employee_id) : '',
      };
    });

    // find new data (employeeId is empty)
    const newEmplList = csvData
      .filter(data => !data.employee_id)
      .map(data => {
        return {
          ...data,
          status: CSV_DATA_ROW_STATUS.NEW,
        };
      });
    // find deleted data
    const currentEmplIdList = csvData.map(employee => +employee.employee_id);
    const deletedEmplList = employeeList
      .filter(employee => !currentEmplIdList.includes(+employee.employee_id))
      .map(employee => {
        return {
          ...employee,
          status: CSV_DATA_ROW_STATUS.DELETED,
        };
      });

    const modifiedEmplList = [];
    employeeList
      .filter(employee => currentEmplIdList.includes(+employee.employee_id))
      .forEach(employeeData => {
        const { employee_id } = employeeData;
        const filteredCsvData = csvData.find(
          data => +data.employee_id === +employee_id
        );
        if (filteredCsvData) {
          // remove unnecessary fields for comparison
          const transformEmployeeData = {
            ...employeeData,
          };
          delete transformEmployeeData.floor_id;
          delete transformEmployeeData.branch_id;
          delete transformEmployeeData.tenant_id;
          delete transformEmployeeData.url_teams;
          delete transformEmployeeData.employee_image;

          // check if any value of object transformEmployeeData is null, set to empty string
          for (const [key, value] of Object.entries(transformEmployeeData)) {
            if (!value) {
              transformEmployeeData[key] = '';
            }
          }
          if (!isEqual(filteredCsvData, transformEmployeeData)) {
            modifiedEmplList.push({
              ...filteredCsvData,
              init_employee: { ...employeeData },
              status: CSV_DATA_ROW_STATUS.MODIFIED,
            });
          }
        }
      });
    const finalEmployeeList = [];
    finalEmployeeList.push(...newEmplList);
    employeeList.forEach(employee => {
      let cloneEmployee = cloneDeep(employee);
      const { status } = cloneEmployee;
      if (!status) {
        cloneEmployee.status = CSV_DATA_ROW_STATUS.UNCHANGED;
      }
      // update status for employee in employeeList
      const modifiedEmployee = modifiedEmplList.find(
        modifiedEmployee =>
          modifiedEmployee.employee_id === cloneEmployee.employee_id
      );
      if (modifiedEmployee) {
        cloneEmployee = {
          ...cloneEmployee,
          ...modifiedEmployee,
        };
        cloneEmployee.status = modifiedEmployee.status;
        cloneEmployee.initEmployee = modifiedEmployee.init_employee;
      }

      // update status for employee in employeeList
      const deletedEmployee = deletedEmplList.find(
        deletedEmployee =>
          deletedEmployee.employee_id === cloneEmployee.employee_id
      );
      if (deletedEmployee) {
        cloneEmployee.status = deletedEmployee.status;
      }
      finalEmployeeList.push(cloneEmployee);
    });

    finalEmployeeList.forEach(employee => {
      const { branch_name, floor_name } = employee;
      const { hasError, branch_id, floor_id } = this.findBranchFloorByName({
        branch_name,
        floor_name,
      });
      if (!hasError) {
        employee.branch_id = branch_id;
        employee.floor_id = floor_id;
      }
    });
    return finalEmployeeList;
  };

  csvImportDialogOnShow = async () => {
    const { csvData, importedCsvInfo } = this.state;
    this.setState({
      isUpdatingData: true,
    });
    const employeeList = await this.getEmployeeData();
    const newDataList = this.mappingCsvDataToCurrentEmployeeList({
      data: csvData,
      employeeList,
    });
    newDataList.forEach(employee => {
      // update importedCsvInfo
      const { status } = employee;
      if (status === CSV_DATA_ROW_STATUS.NEW) {
        importedCsvInfo.newCount++;
      } else if (status === CSV_DATA_ROW_STATUS.MODIFIED) {
        importedCsvInfo.modifiedCount++;
      } else if (status === CSV_DATA_ROW_STATUS.DELETED) {
        importedCsvInfo.deletedCount++;
      }
    });
    this.setState(
      {
        isUpdatingData: false,
        importedCsvInfo: { ...importedCsvInfo },
      },
      () => {
        this.props.updateCsvData(newDataList);
      }
    );
  };

  renderDuplicateTable = ({ duplicatedMap }) => {
    const duplicatedFieldNames = Object.keys(duplicatedMap);
    const tableLViewList = [];
    for (const fieldName of duplicatedFieldNames) {
      const duplicatedList = duplicatedMap[fieldName];
      const tableView = (
        <div
          className="duplicated-table-container"
          key={`table-view-${fieldName}`}
        >
          <h6>{EXPECTED_CSV_HEADERS[fieldName]}</h6>
          <DataTable
            value={duplicatedList}
            size="small"
            showGridlines={false}
            stripedRows
          >
            <Column
              style={{ width: '40%' }}
              field="employee_name"
              header={EXPECTED_CSV_HEADERS['employee_name']}
            ></Column>
            <Column
              style={{ width: '60%' }}
              field={fieldName}
              header={EXPECTED_CSV_HEADERS[fieldName]}
            ></Column>
          </DataTable>
        </div>
      );
      tableLViewList.push(tableView);
    }
    return tableLViewList;
  };

  renderMissingCsvColumnError = ({ errorData }) => {
    return (
      <div className="missing-column-error">
        <ul>
          {errorData.map((item, index) => {
            return (
              <li className="mb-1" key={`missing-column-${index}`}>
                {item}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderInvalidBranchFloor = ({ errorData }) => {
    const { invalidBranchFloorList } = errorData;
    return (
      <div className="invalid-branch-floor-error">
        {invalidBranchFloorList && invalidBranchFloorList.length > 0 && (
          <div>
            <h6>{`無効支店フロア (${COMMON_TEXT.BRANCH} - ${COMMON_TEXT.FLOOR})`}</h6>
            <ul>
              {invalidBranchFloorList.map((item, index) => {
                return (
                  <li className="mb-1" key={`invalid-branch-${index}`}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  findDuplicates = ({ employees, fieldName }) => {
    const counts = {};
    const duplicates = [];

    try {
      employees.forEach(item => {
        const value = item[fieldName];
        if (value !== '' && value in counts) {
          counts[value]++;
        } else {
          counts[value] = 1;
        }
      });
      for (const value in counts) {
        if (counts[value] > 1) {
          employees
            .filter(data => data[fieldName] === value)
            .forEach(duplicateData => {
              duplicates.push(duplicateData);
            });
        }
      }
    } catch (error) {}
    return duplicates;
  };

  getDuplicatesData = ({ employees }) => {
    let duplicatedMap = {};
    try {
      DUPLICATED_FIELD_CHECK.forEach(fieldName => {
        const duplicates = this.findDuplicates({ employees, fieldName });
        if (duplicates.length > 0) {
          duplicatedMap[fieldName] = [...duplicates];
        }
      });
    } catch (error) {}
    return duplicatedMap;
  };

  findBranchFloorByName = ({ branch_name, floor_name }) => {
    const { sessionStore, tenantId } = this.props;
    const branches = sessionStore[MEMORY_STORE.BRANCHES] || [];
    const floors = sessionStore[MEMORY_STORE.FLOORS] || [];
    const res = {
      hasError: false,
      branch_name: branch_name ?? '',
      branch_id: '',
      floor_name: floor_name ?? '',
      floor_id: '',
    };
    // case 1: no branch - no floor => ignore/valid
    // case 2: no branch - has floor => invalid
    // case 3: has branch - no floor => valid
    // case 4: has branch - has floor => check if combination of branch_name and floor_name is valid
    if (!branch_name && !floor_name) {
      res.hasError = false;
    } else if (!branch_name && floor_name) {
      res.hasError = true;
    } else if (branch_name && !floor_name) {
      const findBranch = branches.find(
        branch =>
          branch.branch_name === branch_name && +branch.tenant_id === +tenantId
      );
      if (findBranch) {
        res.branch_id = findBranch.branch_id;
        res.hasError = false;
      } else {
        res.hasError = true;
      }
    } else if (branch_name && floor_name) {
      const findBranch = branches.find(
        branch =>
          branch.branch_name === branch_name && +branch.tenant_id === +tenantId
      );
      if (!findBranch) {
        res.hasError = true;
      } else {
        res.branch_id = findBranch.branch_id;
        const validFloor = floors.find(
          floor =>
            +floor.branch_id === +findBranch.branch_id &&
            floor.floor_name === floor_name
        );
        if (validFloor) {
          res.floor_id = validFloor.floor_id;
          res.hasError = false;
        } else {
          res.hasError = true;
        }
      }
    }
    return res;
  };

  findInvalidBranchFloor = ({ employees }) => {
    const invalidCsvEmployees = employees.filter(employee => {
      const { hasError } = this.findBranchFloorByName({
        branch_name: employee.branch_name,
        floor_name: employee.floor_name,
      });
      return hasError;
    });

    const invalidBranchFloorList = invalidCsvEmployees.map(employee => {
      const { branch_name, floor_name } = employee;
      return `${isEmpty(branch_name) ? '(空)' : branch_name} - ${
        isEmpty(floor_name) ? '(空)' : floor_name
      }`;
    });

    if (invalidBranchFloorList.length > 0) {
      return {
        hasError: true,
        invalidBranchFloorList,
      };
    }
    return {
      hasError: false,
    };
  };

  validateCsvData = ({ csvContentString }) => {
    const replaceSemicolonWithComma = text => {
      return text.replace(/;/g, ',');
    };

    try {
      if (!csvContentString) {
        throw new Error();
      }
      let csvRows = csvContentString.split('\n');
      if (!csvRows || csvRows.length === 0) {
        throw new Error();
      }
      csvRows = csvRows.map(row => replaceSemicolonWithComma(row));
      const headers = csvRows[0].split(',').map(header => header.trim());
      const missingHeaders = Object.values(EXPECTED_CSV_HEADERS).filter(
        headerValue => !headers.includes(headerValue)
      );

      // Check if the CSV file has all the required headers
      if (missingHeaders.length > 0) {
        const error = {
          code: CSV_ERROR_CODE.COLUMNS,
          data: missingHeaders,
        };
        return {
          isError: true,
          error,
        };
      }

      // Create a mapping between header values and their corresponding keys
      const headerValueToKey = {};
      for (const [key, value] of Object.entries(EXPECTED_CSV_HEADERS)) {
        headerValueToKey[value] = key;
      }
      // Process rows and create objects
      const csvEmployees = [];
      for (let i = 1; i < csvRows.length; i++) {
        const values = csvRows[i].trim().split(',');
        const isAllValueEmpty = values.every((value, index) => {
          return isEmpty(value);
        });
        if (!isAllValueEmpty) {
          const empl = {};
          for (let j = 0; j < values.length; j++) {
            const headerValue = headers[j];
            const key = headerValueToKey[headerValue];
            empl[key] = values[j];
          }
          csvEmployees.push(empl);
        }
      }
      const duplicatedMap = this.getDuplicatesData({ employees: csvEmployees });
      if (!isObjectEmpty(duplicatedMap)) {
        const error = {
          code: CSV_ERROR_CODE.DUPLICATED,
          data: duplicatedMap,
          message: '',
        };
        return {
          isError: true,
          error,
        };
      }

      // find invalid branch_name or floor_name
      const invalidBranchFloor = this.findInvalidBranchFloor({
        employees: csvEmployees,
      });
      const { hasError } = invalidBranchFloor;
      if (hasError) {
        return {
          isError: true,
          error: {
            code: CSV_ERROR_CODE.INVALID_DATA,
            data: invalidBranchFloor,
            message: '',
          },
        };
      }
      return {
        isError: false,
        data: csvEmployees,
      };
    } catch (error) {
      const err = {
        code: CSV_ERROR_CODE.CONTENT,
      };
      return {
        isError: true,
        error: err,
      };
    }
  };

  readCsvData = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const contents = e.target.result;
        const { isError, error, data } = this.validateCsvData({
          csvContentString: contents,
        });
        if (isError) {
          reject(error);
        } else {
          resolve(data);
        }
      };

      reader.onerror = err => {
        reject(err);
      };

      reader.readAsText(file);
    });
  };

  csvUploadButtonOnClicked = async e => {
    try {
      const csvData = await this.readCsvData(e.files[0]);
      this.setState({
        isCsvImportDialogVisible: true,
        isUpdatingData: true,
        csvData,
      });
    } catch (error) {
      this.setState({
        isCsvErrorDialogVisible: true,
        csvError: error,
      });
    }
  };

  renderCSVErrorContentDialog = ({ error }) => {
    if (!error) {
      return null;
    }
    const { code, data } = error;
    if (code === CSV_ERROR_CODE.CONTENT) {
      return (
        <>
          <div className="error-information">
            <h4>{`このCSVファイルの内容の形式は互換性がありません。`}</h4>
          </div>
        </>
      );
    } else if (code === CSV_ERROR_CODE.COLUMNS) {
      return (
        <>
          <div className="error-information">
            <h4>{`CSVヘッダの形式が正しくない`}</h4>
            {this.renderMissingCsvColumnError({ errorData: data })}
          </div>
        </>
      );
    } else if (code === CSV_ERROR_CODE.DUPLICATED) {
      return (
        <>
          <div className="error-information">
            <h4>{`CSVに重複した値があります`}</h4>
            {this.renderDuplicateTable({ duplicatedMap: data })}
          </div>
        </>
      );
    } else if (code === CSV_ERROR_CODE.INVALID_DATA) {
      return (
        <>
          <div className="error-information">
            <h4>{`CSVに無効なブランチ/フロア名が含まれている`}</h4>
            {this.renderInvalidBranchFloor({ errorData: data })}
          </div>
        </>
      );
    }
  };

  errorDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label={COMMON_TEXT.CANCEL}
          severity="secondary"
          size="small"
          className='has-shadow'
          onClick={() => {
            this.setState({
              isCsvErrorDialogVisible: false,
              csvError: {},
            });
          }}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      isCsvErrorDialogVisible,
      isCsvImportDialogVisible,
      csvError,
      importedCsvInfo,
      isUpdatingData,
    } = this.state;
    const { newCount, modifiedCount, deletedCount } = importedCsvInfo;
    const chooseOptions = {
      icon: 'pi',
      className: 'csv-button csv-upload has-shadow p-button-info',
    };

    return (
      <>
        <Dialog
          header="CSVデータインポート"
          headerStyle={{}}
          visible={isCsvImportDialogVisible}
          style={{ width: '60vw', maxWidth: '500px', minWidth: '320px' }}
          onHide={() =>
            this.setState({
              isCsvImportDialogVisible: false,
              csvData: [],
            })
          }
          onShow={() => {
            this.csvImportDialogOnShow();
          }}
        >
          <div className="m-0">
            {isUpdatingData ? (
              <div className="flex align-items-center justify-content-center">
                <LoadingSpinner loadingText={`インポート中`} />
              </div>
            ) : (
              <>
                <div className="csv-data-imported">
                  <h6>{`CSVデータのインポート完了`}</h6>
                  <div className="csv-data-imported-row">
                    <div className="csv-data-imported-row-label">{`新規追加`}</div>
                    <div className="csv-data-imported-row-value">
                      {newCount}
                    </div>
                  </div>
                  <div className="csv-data-imported-row">
                    <div className="csv-data-imported-row-label">{`変更`}</div>
                    <div className="csv-data-imported-row-value">
                      {modifiedCount}
                    </div>
                  </div>
                  <div className="csv-data-imported-row">
                    <div className="csv-data-imported-row-label">{`削除`}</div>
                    <div className="csv-data-imported-row-value">
                      {deletedCount}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex justify-content-center mt-3">
            <Button
              style={{ width: '120px' }}
              label={`OK`}
              severity="primary"
              disabled={isUpdatingData}
              size="small"
              onClick={() => {
                this.setState({
                  isCsvImportDialogVisible: false,
                  csvData: [],
                });
              }}
            />
          </div>
        </Dialog>
        <Dialog
          header="CSVアップロードエラー"
          headerStyle={{ color: '#EF4444' }}
          visible={isCsvErrorDialogVisible}
          style={{ width: '60vw' }}
          onHide={() =>
            this.setState({
              isCsvErrorDialogVisible: false,
              csvError: {},
            })
          }
          footer={this.errorDialogFooter()}
        >
          <div className="m-0">
            {this.renderCSVErrorContentDialog({ error: csvError })}
          </div>
        </Dialog>
        <FileUpload
          name="csvUpload[]"
          chooseLabel="CSVアップロード"
          mode="basic"
          accept=".csv"
          onSelect={e => {
            this.csvUploadButtonOnClicked(e);
          }}
          chooseOptions={chooseOptions}
          auto
        />
      </>
    );
  }
}

EmployeeCSVUpload.propTypes = {
  updateCsvData: PropTypes.func,
  tenantId: PropTypes.number,
};

export default EmployeeCSVUpload;
