export const SIDEBAR_CATEGORY = {
  COMMUNICATION: 'communication',
  FACILITY: 'facility',
  LABOR: 'labor',
  ORGANIZATION: 'organization',
};

export const SIDEBAR_CATEGORIES = [
  { name: 'コミュニケーション', value: 'communication' },
  { name: '設備管理', value: 'facility' },
  { name: '労務管理', value: 'labor' },
  { name: '組織', value: 'organization' },
];
