/**
 *
 * BCPOverviewFloor
 *
 */

import BCPOverviewFloor from './BCPOverviewFloor.jsx';
import './BCPOverviewFloor.less';

export default BCPOverviewFloor;
