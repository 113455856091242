import CustomCSVDownload from '@/components/CustomComponent/CustomCSVDownload/CustomCSVDownload';
import CustomCalendar from '@/components/CustomComponent/CustomCalendar/CustomCalendar';
import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import CustomLineChart from '@/components/CustomComponent/CustomLineChart';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CSV_DOWNLOAD_COMPONENT,
  LINE_CHART_COLOR_LIST,
  PEOPLE_COUNT_ANALYSIS_TYPE,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { getRandomHexColor } from '@/helpers/utility';
import { compareAsc, format, isSameDay } from 'date-fns';
import { round } from 'lodash';
import debounce from 'lodash/debounce';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPeopleCountAreaMartLine } from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class PeopleCounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      isLoading: false,
      lineChartData: [],
      isLoadingLineChart: false,
      selectedDate: new Date(),
      selectedTimeRange: {
        fromTime: '00:00',
        toTime: '01:00',
      },
      selectedAreas: [],
      areaOptions: [],
      csvDownload: false,
      selectPeopleCountChartType: PEOPLE_COUNT_ANALYSIS_TYPE.COUNTS,
    };
    this.periodDropDownOptions = [
      { label: '24時間', value: 'last24' },
      { label: '1週間', value: 'lastweek' },
      { label: '1ヶ月', value: 'lastmonth' },
    ];
    this.timeRangeOptions = [
      { label: '01:00', value: '01:00' },
      { label: '02:00', value: '02:00' },
      { label: '03:00', value: '03:00' },
      { label: '04:00', value: '04:00' },
      { label: '05:00', value: '05:00' },
      { label: '06:00', value: '06:00' },
      { label: '07:00', value: '07:00' },
      { label: '08:00', value: '08:00' },
      { label: '09:00', value: '09:00' },
      { label: '10:00', value: '10:00' },
      { label: '11:00', value: '11:00' },
      { label: '12:00', value: '12:00' },
      { label: '13:00', value: '13:00' },
      { label: '14:00', value: '14:00' },
      { label: '15:00', value: '15:00' },
      { label: '16:00', value: '16:00' },
      { label: '17:00', value: '17:00' },
      { label: '18:00', value: '18:00' },
      { label: '19:00', value: '19:00' },
      { label: '20:00', value: '20:00' },
      { label: '21:00', value: '21:00' },
      { label: '22:00', value: '22:00' },
      { label: '23:00', value: '23:00' },
    ];
    this.fromTimeRangeOptions = [
      { label: '00:00', value: '00:00' },
      ...this.timeRangeOptions,
    ];
    this.toTimeRangeOptions = [
      ...this.timeRangeOptions,
      { label: '23:59', value: '23:59' },
    ];
    this.peopleCountOptions = [
      {
        label: COMMON_TEXT.COUNTS,
        value: PEOPLE_COUNT_ANALYSIS_TYPE.COUNTS,
      },
      {
        label: COMMON_TEXT.CAPACITY_RATE,
        value: PEOPLE_COUNT_ANALYSIS_TYPE.CAPACITY_RATE,
      },
    ];
  }

  getDefaultTimeRange = () => {
    const currentDate = new Date();
    let currentHour = currentDate.getHours();
    currentHour = currentHour < 10 ? `0${currentHour}` : `${currentHour}`;
    const fromTime = `00:00`;
    const toTime = `${currentHour}:00`;
    return { fromTime, toTime };
  };

  componentDidMount() {
    const { floor } = this.state;
    const { fromTime, toTime } = this.getDefaultTimeRange();
    this.setState(
      {
        selectedTimeRange: {
          fromTime,
          toTime,
        },
      },
      () => {
        if (floor) {
          this.getLineChartData();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const isFloorChanged = floor !== prevFloor;
    if (isFloorChanged) {
      this.setState({ floor }, () => {
        this.getLineChartData();
      });
    }
  }

  extractAreaInfo(data) {
    const areaIdSet = new Set();
    const idSet = new Set();
    data.forEach(obj => {
      const { area_id, area_name } = obj;
      if (!idSet.has(area_id)) {
        idSet.add(area_id);
        areaIdSet.add({ id: area_id, name: area_name });
      }
    });
    const arr = Array.from(areaIdSet);
    for (let i = 0; i < arr.length; i++) {
      const color = LINE_CHART_COLOR_LIST[i];
      arr[i]['fillColor'] = color ?? getRandomHexColor();
    }
    return arr.reduce((acc, obj) => {
      const { id, name, fillColor } = obj;
      acc[id] = { id, name, fillColor };
      return acc;
    }, {});
  }

  fillMissingData = ({ data }) => {
    const timeValueSet = new Set();
    for (const chartData of data) {
      const { data } = chartData;
      data.forEach(item => {
        const { date, timestamp } = item;
        if (date) {
          timeValueSet.add(date);
        } else if (timestamp) {
          timeValueSet.add(timestamp);
        }
      });
    }
    for (const chartData of data) {
      const { data } = chartData;
      const currentTimeValues = data.map(item => item.date ?? item.timestamp);
      // filter value that is in timeValueSet but not in currentTimeValues
      const missingTimeValues = Array.from(timeValueSet).filter(
        item => !currentTimeValues.includes(item)
      );
      if (missingTimeValues.length > 0) {
        for (const missingTimeValue of missingTimeValues) {
          if (data?.[0]?.date) {
            data.push({
              date: missingTimeValue,
              counts: 0,
              capacity_rate: 0,
            });
          } else if (data?.[0]?.timestamp) {
            data.push({
              timestamp: missingTimeValue,
              counts: 0,
              capacity_rate: 0,
            });
          }
        }
      }
      data.sort((a, b) => {
        const { date: dateA, timestamp: timestampA } = a;
        const { date: dateB, timestamp: timestampB } = b;
        if (dateA && dateB) {
          return compareAsc(new Date(dateA), new Date(dateB));
        } else if (timestampA && timestampB) {
          return compareAsc(new Date(timestampA), new Date(timestampB));
        }
        return 0;
      });
    }
    return data;
  };

  getLineChartData = async () => {
    const { selectedDate, selectedTimeRange, floor } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    this.setState({ isLoadingLineChart: true });
    const selectedDateString = format(selectedDate, 'yyyy-MM-dd');
    let res = await fetchPeopleCountAreaMartLine({
      floorId: floor,
      fromDate: `${selectedDateString} ${fromTime}:00`,
      toDate: `${selectedDateString} ${toTime}:00`,
    });
    res = res || [];
    const areaInfoMap = this.extractAreaInfo(res);
    let newLineChartData = [];
    if (res && res.length > 0) {
      for (const key in areaInfoMap) {
        if (Object.hasOwnProperty.call(areaInfoMap, key)) {
          const areaId = areaInfoMap[key]['id'];
          const areaName = areaInfoMap[key]['name'];
          let filteredData = res
            .filter(item => item.area_id === areaId)
            .map(item => ({
              timestamp: item.timestamp,
              counts: item.counts,
              capacity_rate: round(item.capacity_rate * 100, 2),
            }))
            .sort((a, b) =>
              compareAsc(new Date(a.timestamp), new Date(b.timestamp))
            );
          newLineChartData.push({
            name: areaName,
            areaId: areaId,
            data: filteredData,
            fillColor: areaInfoMap[key]['fillColor'],
          });
        }
      }
    }

    newLineChartData = this.fillMissingData({ data: newLineChartData });

    // Update line multiselect options
    const newLineOptions = newLineChartData
      .map(item => ({
        name: item?.name?.toString().toUpperCase(),
        code: item?.areaId,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    this.setState({
      lineChartData: newLineChartData,
      isLoadingLineChart: false,
      areaOptions: newLineOptions,
      selectedAreas: newLineOptions.slice(0, 5),
    });
  };

  datePickerOnChanged = e => {
    const BOUNCE_IN_MILISECONDS = 300;
    const { selectedDate } = this.state;
    if (!isSameDay(e.value, selectedDate)) {
      const debouncedFunc = debounce(() => {
        this.getLineChartData();
      }, BOUNCE_IN_MILISECONDS);
      if (this.pendingRequest) {
        // Cancel any pending requests
        clearTimeout(this.pendingRequest);
      }
      // Schedule the debounced function to run after 500ms
      this.pendingRequest = setTimeout(() => {
        debouncedFunc();
        this.pendingRequest = null;
      }, BOUNCE_IN_MILISECONDS);
    }

    this.setState({ selectedDate: e.value });
  };

  renderDatePicker() {
    const { selectedDate, isLoadingLineChart } = this.state;
    const maxDate = new Date();
    return (
      <CustomCalendar
        disabled={isLoadingLineChart}
        value={selectedDate}
        onChange={this.datePickerOnChanged}
        maxDate={maxDate}
      ></CustomCalendar>
    );
  }

  timeRangeOnChanged = (e, type) => {
    let newVal = e.value;
    const { selectedTimeRange } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    let updatedState = {};
    if (type === 'fromTime') {
      if (newVal >= toTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex + 1;
        newIndex =
          newIndex > this.timeRangeOptions.length - 1
            ? this.timeRangeOptions.length - 1
            : newIndex;
        const newToTime = this.toTimeRangeOptions[newIndex].value;
        updatedState = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: newToTime,
          },
        };
      } else {
        updatedState = {
          selectedTimeRange: {
            fromTime: newVal,
            toTime: this.state.selectedTimeRange.toTime,
          },
        };
      }
    } else if (type === 'toTime') {
      if (newVal <= fromTime) {
        const foundIndex = this.toTimeRangeOptions.findIndex(
          item => item.value === newVal
        );
        let newIndex = foundIndex;
        newIndex = newIndex < 0 ? 0 : newIndex;
        const newFromTime = this.fromTimeRangeOptions[newIndex].value;
        updatedState = {
          selectedTimeRange: {
            fromTime: newFromTime,
            toTime: newVal,
          },
        };
      } else {
        updatedState = {
          selectedTimeRange: {
            fromTime: this.state.selectedTimeRange.fromTime,
            toTime: newVal,
          },
        };
      }
    }
    this.setState(
      updatedState,
      debounce(() => {
        this.getLineChartData();
      }, 500)
    );
  };

  renderTimeRangeDropdown() {
    const { selectedTimeRange } = this.state;
    const { fromTime, toTime } = selectedTimeRange;
    return (
      <div className="timerange-select-container">
        <CustomDropdown
          label="開始"
          value={fromTime}
          options={this.fromTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'fromTime')}
        ></CustomDropdown>
        <CustomDropdown
          label="終了"
          value={toTime}
          options={this.toTimeRangeOptions}
          onChange={e => this.timeRangeOnChanged(e, 'toTime')}
        ></CustomDropdown>
      </div>
    );
  }

  multiSelectAreasOnChanged = e => {
    this.setState({ selectedAreas: e.value });
  };

  renderMultiSelectAreas = () => {
    const { selectedAreas, areaOptions } = this.state;

    const itemTemplate = option => {
      return (
        <div className="multi-select-item-template" style={{backgroundColor: option.fillColor}}>
          {option.name}
        </div>
      );
    };
    
    const footerTemplate = () => {
      const length = selectedAreas ? selectedAreas.length : 0;

      return (
        <div className="py-2 px-3">
          <span>
            <b>{length}</b> アイテムが選択されました
          </span>
        </div>
      );
    };
    return (
      <MultiSelect
        value={selectedAreas}
        options={areaOptions}
        disabled={areaOptions.length === 0}
        onChange={this.multiSelectAreasOnChanged}
        optionLabel="name"
        placeholder={COMMON_TEXT.SELECT}
        itemTemplate={itemTemplate}
        panelFooterTemplate={footerTemplate}
        className=""
        display="chip"
        style={{ minWidth: '220px', maxWidth: '520px' }}
      />
    );
  };

  renderLineChart({ lineChartData }) {
    const {
      isLoadingLineChart: isLoadingData,
      selectedAreas,
      selectPeopleCountChartType,
    } = this.state;
    lineChartData = lineChartData ?? [];
    const filteredDisplayItems = lineChartData.filter(item =>
      selectedAreas.some(areaItem => areaItem?.code === item.areaId)
    );
    const sortedDisplayItems = filteredDisplayItems.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const yAxisLabel = selectPeopleCountChartType === 'counts' ? '人' : '%';

    return (
      <CustomLineChart
        data={sortedDisplayItems}
        xAxisDataKey={'timestamp'}
        yAxisDataKey={selectPeopleCountChartType}
        yAxisLabel={yAxisLabel}
        containerHeight={440}
        isLoading={isLoadingData}
      />
    );
  }

  renderMessageError = () => {
    const { floor } = this.state;
    if (!floor) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.FLOOR_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderRefreshButton() {
    const { isLoading, floor } = this.state;
    return (
      <Button
        label={COMMON_TEXT.REFRESH_BUTTON_TEXT}
        loading={isLoading}
        disabled={!floor}
        className="refresh-button has-shadow"
        severity="info"
        size="small"
        onClick={() => this.getLineChartData()}
      />
    );
  }

  renderDownloadButton() {
    const { csvDownload } = this.state;
    return (
      <>
        <Button
          type="button"
          severity={csvDownload ? "secondary" : "info"}
          label={csvDownload ? `戻る` : 'CSVダウンロード'}
          className={csvDownload ? 'back-button has-shadow' : 'submit-button csv-download has-shadow'}
          onClick={() => {
            this.setState({
              csvDownload: !csvDownload,
            });
          }}
        />
      </>
    );
  }

  renderPeopleCountChartSelectButton = () => {
    const { selectPeopleCountChartType, csvDownload } = this.state;
    if (csvDownload) {
      return <></>;
    }
    return (
      <div className="card flex justify-content-center">
        <SelectButton
          value={selectPeopleCountChartType}
          onChange={e => this.setState({ selectPeopleCountChartType: e.value })}
          options={this.peopleCountOptions}
          allowEmpty={false}
        />
      </div>
    );
  };

  render() {
    const {
      lineChartData,
      csvDownload,
      floor,
      selectedDate,
      selectedAreas,
      selectPeopleCountChartType,
      selectedTimeRange,
    } = this.state;
    const areaIdList = selectedAreas.map(item => item.code);
    return (
      <div className="config-container custom-config-container people-counts-analysis">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">
              {COMMON_TEXT.PEOPLE_COUNTS_ANALYSIS}
            </div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end">
            {this.renderRefreshButton()}
          </div>
        </div>
        <div className="config-content">
          {this.renderMessageError() || (
            <>
              <div className="main-container">
                <div className="people-counts-chart-container">
                  <div className="menu-container">
                    {this.renderDatePicker()}
                    {this.renderTimeRangeDropdown()}
                  </div>
                  <div className="menu-container mt-2">
                    {this.renderMultiSelectAreas()}
                  </div>
                  <div className="select-btn-container">
                    <div className="empty-download-btn-container"></div>
                    <div className="select-chart-btn-container">
                      {this.renderPeopleCountChartSelectButton()}
                    </div>
                    <div className="download-btn-container">
                      {this.renderDownloadButton()}
                    </div>
                  </div>
                  {csvDownload ? (
                    <CustomCSVDownload
                      fromDate={selectedDate}
                      toDate={selectedDate}
                      type={CSV_DOWNLOAD_COMPONENT.PEOPLE_COUNTS_ANALYSIS}
                      deviceIdList={[...areaIdList]}
                      selectedOption={selectPeopleCountChartType}
                      selectedTimeRange={selectedTimeRange}
                      floorId={floor}
                    ></CustomCSVDownload>
                  ) : (
                    <div className="data-container">
                      <div className="data-container__item">
                        <div className="chart-container">
                          {this.renderLineChart({
                            lineChartData,
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

PeopleCounts.propTypes = {};

export default PeopleCounts;
