import FpSdk from 'forepaas/sdk'

import FpAuthentication from './FpAuthentication.js'

export default {
  async onRequest (request) {
    let token, appId
    if (FpSdk.config && FpSdk.config.authenticationToken) {
      token = FpSdk.config.authenticationToken
    }
    if (FpAuthentication.localSession) {
      token = FpAuthentication.token
    }
    token = request.queryString.token || token
    appId = request.queryString.app_id || FpAuthentication.getAppId()

    if (appId) request.queryString.app_id = appId
    if (token) {
      request.headers = request.headers || {}
      request.headers.Authorization = `Bearer ${token}`
    }
    if (request.queryString.token) delete request.queryString.token
    return request
  },
  async onError (options, error) {
    let url = options.url.split('?')[0]
    if (error.response && error.response.data && !['logout', 'v4/logout'].includes(url)) {
      // We add no permission for this user for the dropzone
      let messages = ['Invalid token', 'No permission for this user', 'FpCam::Invalid token', 'Unauthorized']
      let check = messages.indexOf(error.response.data.message) !== -1 || messages.indexOf(error.response.data.error) !== -1
      let status = error.response.data.status || error.response.status
      if (check && (status === 403 || status === 401)) {
        try {
          await FpAuthentication.logout()
          console.info('Session not valid: logout')
        } catch (_) { }
      }
    }
    throw error
  }
}
