import CustomBarChart from '@/components/CustomComponent/CustomBarChart';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  LINE_CHART_COLOR_LIST,
  QUERY_STRING_STORE,
} from '@/helpers/constants';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatNumberWithCommas, getRandomHexColor } from '@/helpers/utility';
import {
  getMeetingRoomMartUnusedDataWeeklyByBranchId,
  getMeetingRoomMartUnusedDataMonthlyByBranchId,
} from './service';

const BAR_CHART_TYPE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class MeetingRoomOutlookUnusedBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {
        data: [],
        xAxisDataKey: 'organizer',
      },
      lineChartData: {
        xAxisDataKey: 'date',
        displayItems: [],
      },
      branch:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0],
      isLoadingBarChart: false,
      isLoadingLineChart: false,
      selectedLineList: [],
      selectedLimit: 10,
      lineOptions: [],
      deviceInfoMap: {},
    };
    this.dataColumns = [
      {
        field: 'organizer',
        header: COMMON_TEXT.NAME,
        align: 'left',
        headerClassName: '',
        bodyClassName: '',
        headerStyle: '',
        bodyStyle: { fontSize: '0.85rem' },
      },
      {
        field: 'unused_minutes',
        header: COMMON_TEXT.FREE_BOOKING_TIME,
        align: 'right',
        headerClassName: '',
        bodyClassName: '',
        headerStyle: { width: '160px' },
        bodyStyle: { fontSize: '0.85rem', fontWeight: 'bold' },
      },
    ];
  }

  componentDidMount() {
    const { branch } = this.state;
    if (branch) {
      this.getBarChartData();
    }
  }

  componentDidUpdate(prevProps) {
    const branch =
      this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const prevBranch =
      prevProps?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_BRANCH]?.[0];
    const { barChartType } = this.props;
    if (branch !== prevBranch) {
      this.setState({ branch }, () => {
        this.getBarChartData();
      });
    } else if (barChartType !== prevProps.barChartType) {
      this.getBarChartData();
    }
  }

  extractDeviceInfo(data) {
    const deviceIdentifiers = new Set();
    const idSet = new Set();

    data.forEach(obj => {
      const { organizer } = obj;
      if (!idSet.has(organizer)) {
        idSet.add(organizer);
        deviceIdentifiers.add({ id: organizer, name: organizer });
      }
    });
    const arr = Array.from(deviceIdentifiers);
    for (let i = 0; i < arr.length; i++) {
      const color = LINE_CHART_COLOR_LIST[i];
      arr[i]['fillColor'] = color ?? getRandomHexColor();
    }
    return arr.reduce((acc, obj) => {
      const { id, name, fillColor } = obj;
      acc[id] = { id, name, fillColor };
      return acc;
    }, {});
  }

  getBarChartData = async () => {
    this.setState({ isLoadingBarChart: true });
    const { barChartData, branch, selectedLimit } = this.state;
    const { barChartType } = this.props;
    let res = [];
    if (barChartType === BAR_CHART_TYPE.WEEKLY) {
      res = await getMeetingRoomMartUnusedDataWeeklyByBranchId({
        branchId: branch,
        limit: selectedLimit,
      });
    } else if (barChartType === BAR_CHART_TYPE.MONTHLY) {
      res = await getMeetingRoomMartUnusedDataMonthlyByBranchId({
        branchId: branch,
        limit: selectedLimit,
      });
    }

    let deviceInfoMap = {};
    if (res && res.length > 0) {
      deviceInfoMap = this.extractDeviceInfo(res);
      barChartData.data = res
        .map(item => ({
          ...item,
          unused_minutes: item.unused_minutes,
          fillColor: deviceInfoMap[item.organizer]['fillColor'],
        }))
        .sort((a, b) => b.unused_minutes - a.unused_minutes);
    } else {
      barChartData.data = []
    }

    this.setState(
      {
        barChartData,
        deviceInfoMap,
        isLoadingBarChart: false,
      },
      () => {}
    );
  };

  renderMessageError = () => {
    const { branch } = this.state;
    if (!branch) {
      return (
        <Message
          severity="error"
          text={COMMON_TEXT.BRANCH_NOT_SELECTED_PLEASE_SELECT}
        />
      );
    }
  };

  renderBarChart(barChartData) {
    const { data = [], xAxisDataKey } = barChartData;
    const { isLoadingBarChart } = this.state;
    return (
      <CustomBarChart
        data={data}
        xAxisDataKey={xAxisDataKey}
        yAxisDataKey={'unused_minutes'}
        axisHeight={200}
        chartHeight={500}
        yAxisLabel={`空予約・分`}
        isLoading={isLoadingBarChart}
      />
    );
  }

  freeBookingTimeBodyTemplate = rowData => {
    return (
      <div className="">
        <span>{formatNumberWithCommas(rowData['unused_minutes'] ?? 0)}</span>
      </div>
    );
  };

  renderDataTable({ data }) {
    const { isLoadingBarChart } = this.state;
    return (
      <DataTable
        className="outlook-data-table"
        value={data}
        tableStyle={{ width: '100%', maxWidth: '440px' }}
        size="small"
        stripedRows
        rowHover
        scrollable
        scrollHeight="500px"
        showGridlines
        loading={isLoadingBarChart}
      >
        {this.dataColumns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable
            align={col.align}
            alignHeader={col.align}
            headerClassName={col.headerClassName}
            bodyClassName={col.bodyClassName}
            headerStyle={col.headerStyle}
            bodyStyle={col.bodyStyle}
            body={
              col.field === 'unused_minutes'
                ? this.freeBookingTimeBodyTemplate
                : null
            }
          />
        ))}
      </DataTable>
    );
  }

  render() {
    let { barChartData } = this.state;

    return (
      <div className="network-container meeting-room-outlook-unused-booking">
        <div className="network-tool-container">
          <div className="col-fixed text-left grid grid-nogutter align-items-center">
            <b>{COMMON_TEXT.MEETING_ROOM_OUTLOOK_UNUSED_BOOKING}</b>
          </div>
          <div className="col grid grid-nogutter align-items-center justify-content-end"></div>
        </div>
        <div className="network-content">
          {this.renderMessageError() || (
            <>
              <div className="data-container">
                <div className="data-container__item chart-type">
                  <div className="title-chart-container">
                    <div className="title-chart"></div>
                    <div className="title-chart__right"></div>
                  </div>
                  <div
                    className="chart-container"
                    style={{ minHeight: '300px' }}
                  >
                    {this.renderBarChart(barChartData)}
                  </div>
                </div>
                <div className="data-container__item table-type">
                  <div
                    className="datatable-container"
                    style={{ minHeight: '300px' }}
                  >
                    {this.renderDataTable(barChartData)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

MeetingRoomOutlookUnusedBooking.propTypes = {};

export default MeetingRoomOutlookUnusedBooking;
