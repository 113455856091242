import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Toast } from 'primereact/toast';

const CustomFormikErrorNotification = props => {
  const { isValid, isValidating, isSubmitting } = useFormikContext();
  const errorToast = useRef();

  useEffect(() => {
    const { errors, values } = props;
    if (!isValid && !isValidating && isSubmitting) {
      let errorFloor = [];
      errorFloor = errors.verkadaDevicesList.map((device, index) => {
        if (device) {
          return values.verkadaDevicesList[index].data.floor_id;
        } else {
          return null;
        }
      });
      errorFloor = [...new Set(errorFloor.filter(floor => floor))];
      props.calculateFloorError(errorFloor);
      errorToast.current.show({
        severity: 'error',
        summary: 'Error',
        life: 5000,
        message: 'Hi',
        content: (
          <div className="w-full text-left" style={{ fontSize: '16px' }}>
            <div
              className="mb-2"
              style={{ fontSize: '18px', fontWeight: 'bold' }}
            >
              Errors in following floors
            </div>
            <ul>
              {errorFloor.map((floor_id, index) => (
                <li key={index} className="mb-1">
                  {getBranchAndFloorName(floor_id)}
                </li>
              ))}
            </ul>
          </div>
        ),
      });
    }
    // eslint-disable-next-line
  }, [isSubmitting, isValid, props.errors]);

  const getBranchAndFloorName = floor_id => {
    const branchName = props.getBranchName(floor_id);
    const floorName = props.getFloorName(floor_id);
    return branchName + '' + floorName;
  };

  return <Toast ref={errorToast} position="top-center" />;
};

export default CustomFormikErrorNotification;
