import ConfigUpdateStatus from '@/components/organization/Configuration/ConfigUpdateStatus';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  CONFIGURATION_COMPONENT_LIST,
  SESSION_STORAGE_KEY,
} from '@/helpers/constants';
import { Button } from 'primereact/button';
import React, { Component } from 'react';
import { connect } from 'react-redux';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActionUpdating: false,
    };
    this.toastRef = React.createRef();
    this.configComponentList = CONFIGURATION_COMPONENT_LIST;
  }

  componentDidMount() {
    const { sessionStore } = this.props;
    const sessionExecutionList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (sessionExecutionList && sessionExecutionList.length > 0) {
      this.setState({ isActionUpdating: true });
    }
  }

  componentDidUpdate(prevProps) {}

  configButtonOnClicked = item => {
    // eslint-disable-next-line no-unused-vars
    const { index, name, component, url } = item;
    // get current querystring
    const { querystring } = this.props;

    // set window location href to url with querystring
    let qString = '';
    if (querystring) {
      const querystringKeys = Object.keys(querystring);
      for (let i = 0; i < querystringKeys.length; i++) {
        const queryStrKey = querystringKeys[i];
        if (
          queryStrKey &&
          queryStrKey.includes('select-') &&
          querystring[queryStrKey]
        ) {
          qString += `${queryStrKey}=[${querystring[queryStrKey]}]&`;
        }
      }
    }
    // replace last character & with empty string if it exists
    if (qString && qString[qString.length - 1] === '&') {
      qString = qString.slice(0, -1);
    }
    window.location.href = `#${url}?${qString}`;
  };

  updateConfigStatus = ({ isActionUpdating }) => {
    this.setState({ isActionUpdating });
  };

  render() {
    const { isActionUpdating } = this.state;
    return (
      <>
        <div className="config-container custom-config-container configuration-page">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.DATA_CONFIGURATION}</div>
            </div>
          </div>
          <div className="config-content">
            <>
              {isActionUpdating ? (
                <ConfigUpdateStatus
                  updateConfigStatus={this.updateConfigStatus}
                ></ConfigUpdateStatus>
              ) : (
                <div
                  style={{ width: '100%' }}
                  className="config-button-item-container"
                >
                  {this.configComponentList.map((item, idx) => {
                    return (
                      <div
                        className="config-button-item"
                        key={`config-button-item-idx-${idx}`}
                      >
                        <Button
                          label={item.name}
                          className="config-button"
                          severity="info"
                          text
                          onClick={() => {
                            this.configButtonOnClicked(item);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          </div>
        </div>
      </>
    );
  }
}

Configuration.propTypes = {};

export default Configuration;
