/**
 *
 * MeetingRoomOutlookTable
 *
 */

import MeetingRoomOutlookTable from './MeetingRoomOutlookTable.jsx';
import './MeetingRoomOutlookTable.less';

export default MeetingRoomOutlookTable;
