import React, { Component } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  CartesianGrid,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { CustomYAxisTick } from '@/components/CustomComponent/CustomYAxisTick';
import { CustomTooltipBarChart } from './CustomTooltipBarChart';
import { Message } from 'primereact/message';
import { COMMON_TEXT } from '@/helpers/common-text';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';

class NetworkPerformanceCustomBarChart extends Component {
  loadingLayerStyle = {
    position: 'absolute',
    height: '397px',
    left: 60,
    top: 46,
    right: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  renderLabelContent = props => {
    return (
      <text
        fill="#333333"
        offset="0"
        x={props.viewBox.cx}
        y={props.viewBox.cy}
        textAnchor="middle"
        fontWeight={700}
      >
        <tspan x="56" dy="16">
          {`(${props.value})`}
        </tspan>
      </text>
    );
  };

  render() {
    let {
      data = [],
      xAxisDataKey,
      yAxisDataKey,
      yAxisUnit,
      yAxisLabel,
      isLoading,
    } = this.props;
    yAxisLabel = yAxisLabel ?? yAxisDataKey;
    const isEmptyData = data.length === 0;

    return (
      <div style={{ position: 'relative' }}>
        <ResponsiveContainer
          width={'100%'}
          height={510}
          minHeight={510}
          className={'barchart-responsive-container-network-performance'}
        >
          {data.length === 0 ? (
            <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
          ) : (
            <BarChart
              data={data || []}
              margin={{ top: 30, right: 50, left: 0, bottom: 37 }}
              layout="vertical"
              barGap={0}
              barCategoryGap={0}
            >
              <Tooltip content={<CustomTooltipBarChart />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                orientation="top"
                type="number"
                tick={
                  <CustomYAxisTick
                    dy={-6}
                    textAnchor={'middle'}
                    yAxisUnit={yAxisUnit ?? ''}
                  />
                }
                padding={{ top: 0, bottom: 0, left: 0, right: 10 }}
                domain={[0, yAxisUnit === '%' ? 100 : 'auto']}
              >
                <Label
                  content={this.renderLabelContent}
                  offset={0}
                  position="top"
                  value={yAxisLabel}
                />
              </XAxis>
              <YAxis
                dataKey={xAxisDataKey}
                type="category"
                tickFormatter={() => ''}
              />
              <Bar dataKey={yAxisDataKey}>
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.fillColor}
                    stroke={'#ffffff'}
                    strokeWidth={1}
                  />
                ))}
              </Bar>
            </BarChart>
          )}
        </ResponsiveContainer>
        {!isLoading && isEmptyData && (
          <div style={this.loadingLayerStyle}>
            <Message severity="warn" text={COMMON_TEXT.NO_DATA} />
          </div>
        )}
        {isLoading && (
          <div style={this.loadingLayerStyle}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    );
  }
}

export default NetworkPerformanceCustomBarChart;
