import { COMMON_TEXT } from '@/helpers/common-text';
import { QUERY_STRING_STORE } from '@/helpers/constants';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AirQualityHistory from './AirQualityHistory';
import AirQualityNow from './AirQualityNow';
import { fetchSensorDataThreshold } from './AirQualityNow/query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class AirQuality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floor:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0],
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      tabActiveIndex: 0,
      sensorDataThreshold: [],
    };
  }

  componentDidMount() {
    const { floor } = this.state;
    if (floor) {
      this.getSensorDataThreshold({
        floor,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const floor = querystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const prevFloor =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_FLOOR]?.[0];
    const isFloorChanged = floor !== prevFloor;
    if (isFloorChanged) {
      this.setState({ floor }, () => {
        this.getSensorDataThreshold({
          floor,
        });
      });
    }
  }

  getSensorDataThreshold = async ({ floor }) => {
    let sensorDataThresholdRes = await fetchSensorDataThreshold({
      floorId: floor,
    });
    sensorDataThresholdRes = sensorDataThresholdRes?.[0] ?? [];
    this.setState({ sensorDataThreshold: sensorDataThresholdRes });
  };

  tabHeaderTemplate = options => {
    const icon =
      options.titleElement.props.children === 'NOW'
        ? 'pi pi-clock'
        : 'pi pi-history';
    const label =
      options.titleElement.props.children === 'NOW' ? '現在' : '履歴';
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <i className={`pi ${icon} mr-2`} />
        {label}
      </button>
    );
  };

  render() {
    const { tabActiveIndex, floor, sensorDataThreshold } = this.state;
    return (
      <div className="config-container custom-config-container air-quality">
        <div className="config-title-container">
          <div className="text-left">
            <div className="title-text">{COMMON_TEXT.AIR_QUALITY}</div>
          </div>
          <div className="grid grid-nogutter align-items-center justify-content-end"></div>
        </div>
        <div className="config-content">
          <TabView
            className=""
            activeIndex={tabActiveIndex}
            renderActiveOnly={true}
            onTabChange={e => this.setState({ tabActiveIndex: e.index })}
          >
            <TabPanel header="NOW" headerTemplate={this.tabHeaderTemplate}>
              <div className="m-0">
                {tabActiveIndex === 0 && (
                  <AirQualityNow
                    sensorDataThreshold={sensorDataThreshold}
                    floor={floor}
                  ></AirQualityNow>
                )}
              </div>
            </TabPanel>
            <TabPanel header="HISTORY" headerTemplate={this.tabHeaderTemplate}>
              <div className="m-0">
                {tabActiveIndex === 1 && (
                  <AirQualityHistory
                    sensorDataThreshold={sensorDataThreshold}
                    floor={floor}
                  ></AirQualityHistory>
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}

AirQuality.propTypes = {};

export default AirQuality;
