class Data {
  constructor (data) {
    Object.assign(this, data)
  }
  merge (data) {
    Object.assign(this, data)
  }
}

export default Data
