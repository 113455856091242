/**
 *
 * AirQualityHistory
 *
 */

import AirQualityHistory from './AirQualityHistory.jsx';
import './AirQualityHistory.less';

export default AirQualityHistory;
