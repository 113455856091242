/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';

import FpLoader from 'forepaas/core-ui/loader';
import FpToaster from 'forepaas/toaster';
import FpSdk from 'forepaas/sdk';
import { set } from 'forepaas/store/local/action';
import FpTranslate from 'forepaas/translate';
import FpAuthentication from '../FpAuthentication';
import FpMfa from '../FpMfa/Mfa.jsx';
import FpPasswordForgot from '../FpPasswordForgot/FpPasswordForgot.jsx';

class FpClientAuthorityManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        login: '',
        password: '',
        auth_mode_id: props.auth_mode._id,
      },
      forgotPassword: false,
      // Add new isLoading
      isLoading: false,
      mfa: props.preferences.mfa?.enforce,
      return:
        props.preferences.auth_mode.filter(
          auth =>
            (typeof auth.visible === 'undefined' || auth.visible) &&
            auth.type !== 'mfa' &&
            !auth.hidden
        ).length > 1,
      color: this.props.preferences.color || '#00CCF9',
      login: {}, // Use to store session and message
    };
    this.loginAction = this.loginAction.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var form = this.state.form;
    form[name] = value;
    this.setState({
      form: form,
    });
  }

  afterLoginSuccess(login) {
    document.location.hash = `${FpSdk.config.root || ''}?${
      document.location.hash.split('?')[1] || ''
    }`;
    window.history.pushState({}, '', '');
    FpSdk.modules.store.dispatch(
      set('client-authority-manager-session', login.session)
    );
    FpToaster.success(login.message);
  }

  logout() {
    this.setState({ mfa: false });
    FpAuthentication.logout();
  }

  async loginAction(event) {
    if (event) event.preventDefault();
    if (!this.state.form.login || !this.state.form.password) return;
    // Add new isLoading
    this.setState({ isLoading: true });
    try {
      let login = await FpAuthentication.standardLogin(this.state.form);
      // Add new isLoading
      this.setState({ login, isLoading: false });

      if (!this.state.mfa) this.afterLoginSuccess(login);
    } catch (err) {
      // Add new isLoading
      this.setState({ isLoading: false });
      let data = err.response?.data;
      FpToaster.error(data.message);
    }
  }

  renderMfa() {
    return (
      <FpMfa
        color={this.state.color}
        login={this.state.form.login}
        password={this.state.form.password}
        authModeId={this.state.form.auth_mode_id}
        preferences={this.props.preferences}
        phone={this.state.login.session?.phone}
        close={this.logout}
        loginInfo={this.state.login}
        afterLoginSuccess={this.afterLoginSuccess}
      />
    );
  }

  renderLoginForm() {
    return (
      <div className="standard-login">
        <form className="login-screen" onSubmit={this.loginAction}>
          <label style={{ color: this.state.color }}>
            {FpTranslate('Username')}
          </label>
          <input
            placeholder={FpTranslate('Your username')}
            type="text"
            value={this.state.form.login}
            onChange={this.handleInputChange}
            name="login"
          />
          <br />
          <label style={{ color: this.state.color }}>
            {FpTranslate('Password')}
          </label>
          <input
            placeholder={FpTranslate('Your password')}
            type="password"
            value={this.state.form.password}
            onChange={this.handleInputChange}
            name="password"
          />
          <br />
          <div className="login-actions-container">
            <div className="login-action-container send-container">
              <button
                className="btn-login btn btn-primary"
                style={{ backgroundColor: this.state.color }}
                type="submit"
                // Add new isLoading
                disabled={this.state.isLoading}
              >
                {FpTranslate('signin')}
              </button>
            </div>
            <div className="login-action-container return-container">
              <div className="forgot-password-link-container">
                <a
                  onClick={() => this.setState({ forgotPassword: true })}
                  className="forgot-password-link"
                >
                  {FpTranslate('Forgot password')}
                </a>
              </div>
              {this.state.return && (
                <button
                  className="btn return-button"
                  type="button"
                  onClick={this.props.close}
                >
                  {FpTranslate('Return')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }

  render() {
    if (this.props.preferences) {
      if (this.state.forgotPassword) {
        return (
          <FpPasswordForgot
            close={() => this.setState({ forgotPassword: false })}
            color={this.state.color}
          />
        );
      }
      if (!this.state.login.session) {
        return this.renderLoginForm();
      }
      if (this.state.mfa && this.state.login.session?.acr === 'aal1') {
        return this.renderMfa();
      }
    }
    return <FpLoader />;
  }
}

FpClientAuthorityManager.propTypes = {
  auth_mode: PropTypes.shape({
    _id: PropTypes.string,
  }),
  preferences: PropTypes.object,
  close: PropTypes.func,
};

export default FpClientAuthorityManager;
