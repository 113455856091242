/**
 *
 * PeopleCounts
 *
 */

import PeopleCounts from './PeopleCounts.jsx';
import './PeopleCounts.less';

export default PeopleCounts;
