
class FpResponse {
  constructor (request) {
    throw new Error('FpResponse is not implemented')
  }

  compute () {
  }
}

export default FpResponse
