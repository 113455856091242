/**
 *
 * AirQualityNow
 *
 */

import AirQualityNow from './AirQualityNow.jsx';
import './AirQualityNow.less';

export default AirQualityNow;
