import { Button } from 'primereact/button';
import React from 'react';

class HideShowSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  toggleSidebar = () => {
    const sidebar = document.querySelector('.sidebar');
    if (sidebar) {
      sidebar.classList.toggle('sidebar--hidden');
    }
    const pageWrapper = document.querySelector('.page');
    if (pageWrapper) {
      pageWrapper.classList.toggle('page--sidebar-hidden');
    }
    const hideshowsidebar = document.querySelector('.hideshowsidebar');
    if (hideshowsidebar) {
      hideshowsidebar.classList.toggle('hideshowsidebar-sidebar-hidden');
    }
    const header = document.querySelector('.header');
    if (header) {
      header.classList.toggle('header--sidebar-hidden');
    }
  };

  render() {
    return (
      <div className="toggle-button-container">
        <Button
          text
          severity="info"
          icon="pi pi-bars"
          onClick={this.toggleSidebar}
        ></Button>
      </div>
    );
  }
}

HideShowSidebar.propTypes = {};
export default HideShowSidebar;
