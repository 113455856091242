/**
 *
 * EmployeeAttendance
 *
 */

import EmployeeAttendance from './EmployeeAttendance.jsx';
import './EmployeeAttendance.less';

export default EmployeeAttendance;
