import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';
import { getRandomNumber900000to999999 } from '@/helpers/utility';

const buildQuerySignageComponents = ({ tenantId, userId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        branch_id: ['select'],
        component_child_id: ['select'],
        component_id: ['select'],
        display_order: ['select'],
        floor_id: ['select'],
        interval: ['select'],
        signage_components_id: ['select'],
        user_id: ['select'],
      },
      skip: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
      user_id: [userId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.SIGNAGE_COMPONENTS,
  };
};

export const fetchSignageComponents = async payload => {
  const { tenantId, userId } = payload;
  const request = buildQuerySignageComponents({ tenantId, userId });
  return executeQuery(request);
};
