import CustomDropdown from '@/components/CustomComponent/CustomDropdown/CustomDropdown';
import DeviceMarker from '@/components/CustomComponent/DeviceMarker';
import LoadingSpinner from '@/components/CustomComponent/LoadingSpinner';
import ImageMarker from '@/components/CustomComponent/ReactImageMarker';
import { COMMON_TEXT } from '@/helpers/common-text';
import {
  DEVICE_CATEGORY,
  EXECUTION_ID_TIMEOUT,
  MEMORY_STORE,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import {
  generateRandomString,
  getConfigurationUrlWithParam,
  transformPositionToMarkerCoordinate,
} from '@/helpers/utility';
import AuthToken from '@/services/auth-token';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { cloneDeep } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchBranchListByTenant,
  fetchDevicePositionByFloorId,
  fetchFloorListByBranchIdList,
  fetchMerakiNetworks,
  fetchVerkadaDevicesByType,
} from './query-request';

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class CameraZoneSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],

      isLoading: false,
      isUpdating: false,
      merakiNetworkList: [],
      branchList: [],
      floorList: [],
      verkadaDevicesList: [],
      merakiDevicesList: [],
      isLoadingDevice: false,
      deviceMarkerPositionList: [],
      selectedFloor: null,
      floorMapUrl: '',
      floorMapError: false,
      isFloorMapLoading: false,
      tabIndex: 0,
      isUpdateDialogVisible: false,
      updateDataPayload: { values: [] },
      zoneUpdatePayloadInfo: [],
      hasError: false,
    };
    this.toastRef = React.createRef();
    this.deviceStateMap = {};
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.setState({ deviceList: [] });
        this.getData();
      });
    }
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getData = async () => {
    this.setState({
      isLoading: true,
      isFloorMapLoading: true,
      isLoadingDevice: true,
    });
    const { tenant } = this.state;

    const merakiNetworkRes = await fetchMerakiNetworks({ tenantId: tenant });
    if (merakiNetworkRes && merakiNetworkRes.length > 0) {
      this.setState({ merakiNetworkList: merakiNetworkRes });
    }

    await fetchBranchListByTenant(tenant).then(branchList => {
      if (branchList && branchList.length > 0) {
        this.setState({ branchList });
        const branchIdList = this.state.branchList.map(
          branch => branch.branch_id
        );
        fetchFloorListByBranchIdList(branchIdList).then(floorList => {
          const sortedFloorListByBranchOrder = floorList.sort(
            (a, b) =>
              branchIdList.indexOf(a.branch_id) -
              branchIdList.indexOf(b.branch_id)
          );
          if (floorList && floorList.length > 0) {
            this.setState({
              floorList: sortedFloorListByBranchOrder,
              selectedFloor: sortedFloorListByBranchOrder[0],
            });
            this.getFloorMapUrl(this.state.selectedFloor.floor_id);
            this.getDeviceList(this.state.selectedFloor);
          }
        });
      }
    });

    this.setState({
      isLoading: false,
    });
  };

  getFloorMapUrl = floorId => {
    const floors = this.props?.sessionStore[MEMORY_STORE.FLOORS] || [];
    const floor = floors.find(floor => floor.floor_id === floorId);
    this.validateFloorMapUrl(
      floor?.floor_map,
      () => {
        this.setState({
          floorMapUrl: floor?.floor_map,
          floorMapError: false,
          isFloorMapLoading: false,
        });
      },
      () => {
        this.setState({
          floorMapUrl: '',
          floorMapError: false,
          isFloorMapLoading: false,
        });
      }
    );
  };

  validateFloorMapUrl(url, successCallback, errorCallback) {
    var img = new Image();
    img.onload = function () {
      successCallback(url);
    };
    img.onerror = function () {
      errorCallback(url);
    };
    img.src = url;
  }

  getDeviceList = async selectedFloor => {
    this.setState({ isLoadingDevice: true });
    const floorId = selectedFloor.floor_id;
    const deviceStateByFloorId = this.retrieveSavedDeviceStateByFloorId({
      floorId,
    });
    let verkadaDevicesList = [];
    let merakiDevicesList = [];
    let deviceMarkerPositionList = [];

    if (deviceStateByFloorId) {
      verkadaDevicesList = cloneDeep(deviceStateByFloorId.verkadaDevicesList);
      merakiDevicesList = cloneDeep(deviceStateByFloorId.merakiDevicesList);
      deviceMarkerPositionList = cloneDeep(
        deviceStateByFloorId.deviceMarkerPositionList
      );
    } else {
      const devicePositions = await fetchDevicePositionByFloorId(floorId);
      const verkadaList = await this.getVerkadaDevicesListByFloorId(
        floorId,
        devicePositions
      );
      const merakiList = await this.getMerakiDevicesList(
        floorId,
        devicePositions
      );
      verkadaDevicesList = cloneDeep(verkadaList);
      merakiDevicesList = cloneDeep(merakiList);
      deviceMarkerPositionList.push(
        ...this.setDevicePositionToMap(merakiDevicesList)
      );
      deviceMarkerPositionList.push(
        ...this.setDevicePositionToMap(verkadaDevicesList)
      );
      this.deviceStateMap[floorId] = {
        deviceMarkerPositionList,
        merakiDevicesList,
        verkadaDevicesList,
      };
    }

    this.setState({
      verkadaDevicesList,
      merakiDevicesList,
      deviceMarkerPositionList,
      isLoadingDevice: false,
    });
  };

  setDevicePositionToMap = deviceList => {
    const copyDeviceList = cloneDeep(deviceList);
    const deviceMarkerPositionList = [];
    copyDeviceList.forEach(device => {
      const { hasPosition, position } = device;
      if (hasPosition) {
        const { top, left } = transformPositionToMarkerCoordinate({
          x: position.position_x,
          y: position.position_y,
        });
        deviceMarkerPositionList.push({
          top,
          left,
          isDelete: false,
          device,
        });
      }
    });
    return deviceMarkerPositionList;
  };

  getVerkadaDevicesListByFloorId = async (floorId, devicePositions) => {
    const devicesRes = await fetchVerkadaDevicesByType({
      floorId,
      deviceType: 'camera',
    });
    const deviceList = devicesRes.map(device => {
      let devicePosition = devicePositions.filter(
        deviceposition =>
          deviceposition.device_identifer === device.device_identifer
      );
      if (devicePosition && devicePosition.length > 0) {
        const position = cloneDeep(devicePosition[0]);
        return {
          hasPosition: true,
          deviceCategory: DEVICE_CATEGORY.VERKADA,
          position,
          ...device,
          initZones: device.zones ?? '',
          zones: device.zones ?? '',
        };
      } else {
        const position = { position_x: 0.5, position_y: -0.02 };
        return {
          hasPosition: false,
          deviceCategory: DEVICE_CATEGORY.VERKADA,
          position,
          ...device,
          initZones: device.zones ?? '',
          zones: device.zones ?? '',
        };
      }
    });
    return deviceList;
  };

  getMerakiDevicesList = async (branchId, devicePositions) => {
    // NOTE: temporary hide the meraki tab, so we don't need to fetch meraki device list
    return [];
  };

  getBranchName = branchId => {
    if (branchId) {
      const branch = this.state.branchList.filter(
        branch => branch.branch_id === branchId
      );
      return branch[0].branch_name + ' - ';
    }
  };

  onChangeSelectedFloorAction = selectedFloor => {
    const {
      selectedFloor: prevFloor,
      merakiDevicesList,
      verkadaDevicesList,
    } = this.state;
    const { floor_id: prevFloorId } = prevFloor;
    this.updateFloorDeviceState({
      floorId: prevFloorId,
      merakiDevicesList,
      verkadaDevicesList,
    });
    this.setState({ selectedFloor, isFloorMapLoading: true }, () => {
      this.getFloorMapUrl(selectedFloor.floor_id);
      this.getDeviceList(selectedFloor);
    });
  };

  editZoneButtonOnClicked = async (e, { device: selectedDevice }) => {
    const { merakiDevicesList, verkadaDevicesList } = this.state;
    // change edit mode all to false
    merakiDevicesList.forEach(device => {
      const { device_identifer } = device;
      if (device_identifer !== selectedDevice.device_identifer) {
        device.isEdit = false;
      } else {
        if (!device.isEdit) {
          device.isEdit = true;
        } else {
          device.isEdit = false;
        }
      }
    });
    verkadaDevicesList.forEach(device => {
      const { device_identifer } = device;
      if (device_identifer !== selectedDevice.device_identifer) {
        device.isEdit = false;
      } else {
        if (!device.isEdit) {
          device.isEdit = true;
        } else {
          device.isEdit = false;
        }
      }
    });
    this.setState({ merakiDevicesList, verkadaDevicesList });
  };

  merakiDeviceZoneOnChanged = (e, { newValue, deviceIndex }) => {
    const { merakiDevicesList } = this.state;
    const device = merakiDevicesList[deviceIndex];
    if (!device) {
      console.error('device not found');
    }
    const { merakiZones } = device;
    const foundZone = merakiZones.find(zone => zone.zone_id === newValue);
    if (foundZone) {
      device.zone_label = foundZone.label;
    } else {
      device.zone_label = COMMON_TEXT.NO_ZONE;
    }
    device.zone_id = e.value;
    merakiDevicesList[deviceIndex] = device;
    this.setState({ merakiDevicesList });
  };

  verkadaDeviceZoneOnChanged = ({ deviceIndex, newValue }) => {
    // validate if newValue is match with regex ^(10|[0-9])\.(10|[0-9])(\.(10|[0-9])\.(10|[0-9]))*$
    const { verkadaDevicesList } = this.state;
    const device = verkadaDevicesList[deviceIndex];
    if (!device) {
      console.error('device not found');
    }
    device.zones = newValue;
    const regex = /^(10|[0-9])\.(10|[0-9])(\.(10|[0-9])\.(10|[0-9]))*$/;
    if (device.zones && !regex.test(device.zones)) {
      device.zones_error = true;
    } else {
      device.zones_error = false;
    }
    verkadaDevicesList[deviceIndex] = device;
    this.setState({ verkadaDevicesList, hasError: device.zones_error });
  };

  retrieveSavedDeviceStateByFloorId = ({ floorId }) => {
    return this.deviceStateMap[floorId] || null;
  };

  updateFloorDeviceState = ({
    floorId,
    merakiDevicesList,
    verkadaDevicesList,
  }) => {
    floorId = +floorId;
    this.deviceStateMap[floorId] = {
      ...this.deviceStateMap[floorId],
      merakiDevicesList,
      verkadaDevicesList,
    };
  };

  updateButtonOnClicked = () => {
    const {
      selectedFloor,
      floorList,
      merakiDevicesList,
      verkadaDevicesList,
      hasError,
    } = this.state;
    // change edit mode all to false
    merakiDevicesList.forEach(device => {
      device.isEdit = false;
    });
    verkadaDevicesList.forEach(device => {
      device.isEdit = false;
    });
    this.setState({ verkadaDevicesList, merakiDevicesList });
    this.updateFloorDeviceState({
      floorId: selectedFloor.floor_id,
      merakiDevicesList,
      verkadaDevicesList,
    });
    if (hasError) {
      this.toastRef.current.show({
        severity: 'error',
        summary: 'エラー',
        detail: `There is error in zone format`,
        life: 3000,
        closable: true,
        icon: 'pi pi-exclamation-circle',
      });
      return;
    }
    const updatePayload = {
      values: [],
    };
    let zonePayload = [];
    let zoneUpdatePayloadInfo = [];
    for (const floorId in this.deviceStateMap) {
      const floorName =
        floorList.find(floor => +floor.floor_id === +floorId)?.floor_name ??
        '-';
      const deviceState = this.deviceStateMap[+floorId];
      const { merakiDevicesList, verkadaDevicesList } = deviceState;
      merakiDevicesList.forEach(merakiDevice => {
        const { zone_id, initZoneId } = merakiDevice;
        if (zone_id !== initZoneId) {
          zonePayload.push({
            device_identifer: merakiDevice.device_identifer,
            zone_id: zone_id,
          });
          zoneUpdatePayloadInfo.push({
            floorName: floorName,
            deviceName: merakiDevice.device_name,
            zone: merakiDevice.zone_label,
          });
        }
      });

      verkadaDevicesList.forEach(verkadaDevice => {
        const { zones, initZones } = verkadaDevice;
        if (zones !== initZones) {
          zonePayload.push({
            device_identifer: verkadaDevice.device_identifer,
            zones: zones,
          });
          zoneUpdatePayloadInfo.push({
            floorName: floorName,
            deviceName: verkadaDevice.device_name,
            zone: zones,
          });
        }
      });
    }
    if (zonePayload.length > 0 || zonePayload.length > 0) {
      updatePayload.values.push(...zonePayload);
    }
    if (updatePayload.values.length > 0) {
      this.setState({
        isUpdateDialogVisible: true,
        updateDataPayload: updatePayload,
        zoneUpdatePayloadInfo: zoneUpdatePayloadInfo,
      });
    } else {
      this.toastRef.current.show({
        severity: 'info',
        summary: '情報',
        detail: COMMON_TEXT.NO_CHANGE_TO_BE_SAVED,
        life: 3000,
        closable: true,
      });
    }
  };

  updateCameraZoneInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.CAMERA_ZONE,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    const { isSuccess, executionId } = await this.updateCameraZoneInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    } else {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderMap = props => {
    const { device } = props;
    const randomTooltipString = generateRandomString(10);
    if (device) {
      const { device_name } = device;
      return (
        <>
          <Tooltip
            target={`.map-marker-id-${randomTooltipString}`}
            mouseTrack
            mouseTrackLeft={10}
          />
          <DeviceMarker
            deviceinfo={device}
            className={[
              `pi pi-map-marker map-marker-style map-marker-id-${randomTooltipString}`,
            ]}
            data-pr-tooltip={device_name}
            data-pr-classname="child-div-tooltip"
          />
        </>
      );
    }
    return <></>;
  };

  renderMapMarker = props => {
    const { isDelete, device } = props;
    if (isDelete) {
      return <></>;
    }
    if (device) {
      return this.renderMap(props);
    }
    return <></>;
  };

  renderMerakiDeviceList = deviceList => {
    return (
      <>
        {deviceList.length > 0 ? (
          <>
            {deviceList.map((device, index) => (
              <div
                className={`device-item ${device.isEdit ? 'is-edit' : ''}`}
                key={index}
              >
                <div className="device-label">
                  <div
                    className={`marker-button-container ${
                      !device.hasPosition ? 'not-set-position' : ''
                    }`}
                  >
                    <div className="marker-button-icon">
                      <DeviceMarker
                        deviceinfo={device}
                        size={24}
                        disabled={!device.hasPosition}
                      />
                    </div>
                    <div
                      className={`ml-2 text-left device-zone-name-container`}
                    >
                      <div className="device-name">{device.device_name}</div>
                      <>
                        {device.isEdit ? (
                          <>
                            <CustomDropdown
                              className="zone-dropdown"
                              optionLabel={'label'}
                              optionValue={'zone_id'}
                              value={device.zone_id}
                              options={device.merakiZones}
                              onChange={e => {
                                this.merakiDeviceZoneOnChanged(e, {
                                  newValue: e.target.value,
                                  deviceIndex: index,
                                });
                              }}
                            ></CustomDropdown>
                          </>
                        ) : (
                          <>
                            <div className="zone-name">{`${device.zone_label}`}</div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
                <div className="edit-button-container">
                  {device.merakiZones && device.merakiZones.length > 0 && (
                    <Button
                      className="edit-button"
                      onClick={e => this.editZoneButtonOnClicked(e, { device })}
                      text
                      icon={`${device.isEdit ? 'pi pi-save' : 'pi pi-pencil'}`}
                      severity="info"
                    ></Button>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-2">{COMMON_TEXT.NO_DEVICE}</div>
        )}
      </>
    );
  };

  renderVerkadaDeviceList = deviceList => {
    const { hasError } = this.state;
    return (
      <>
        {deviceList.length > 0 ? (
          <>
            {deviceList.map((device, index) => (
              <div
                className={`device-item ${device.isEdit ? 'is-edit' : ''}`}
                key={index}
              >
                <div className="device-label">
                  <div
                    className={`marker-button-container ${
                      !device.hasPosition ? 'not-set-position' : ''
                    }`}
                  >
                    <div className="marker-button-icon">
                      <DeviceMarker
                        deviceinfo={device}
                        size={24}
                        disabled={!device.hasPosition}
                      />
                    </div>
                    <div
                      className={`ml-2 text-left device-zone-name-container`}
                    >
                      <div className="device-name">{device.device_name}</div>
                      <>
                        {device.isEdit ? (
                          <>
                            <InputTextarea
                              rows={2}
                              autoResize={true}
                              className={`verkada-zone-input ${
                                device.zones_error ? 'has-error' : ''
                              }`}
                              value={device.zones}
                              onChange={e =>
                                this.verkadaDeviceZoneOnChanged({
                                  deviceIndex: index,
                                  newValue: e.target.value,
                                })
                              }
                            />
                          </>
                        ) : (
                          <>
                            <div
                              className={`zone-name ${
                                device.zones_error ? 'has-error' : ''
                              }`}
                            >
                              {device.zones_error ? (
                                <>
                                  <i className="pi pi-exclamation-triangle"></i>
                                  <span className="ml-2">
                                    {`${
                                      device.zones
                                        ? device.zones
                                        : COMMON_TEXT.NO_ZONE
                                    }`}
                                  </span>
                                </>
                              ) : (
                                <span>
                                  {`${
                                    device.zones
                                      ? device.zones
                                      : COMMON_TEXT.NO_ZONE
                                  }`}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
                <div className="edit-button-container">
                  <Button
                    disabled={hasError}
                    className="edit-button"
                    onClick={e => this.editZoneButtonOnClicked(e, { device })}
                    text
                    icon={`${device.isEdit ? 'pi pi-save' : 'pi pi-pencil'}`}
                    severity="info"
                  ></Button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-2">{COMMON_TEXT.NO_DEVICE}</div>
        )}
      </>
    );
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      floorList,
      selectedFloor,
      verkadaDevicesList,
      // merakiDevicesList,
      floorMapUrl,
      floorMapError,
      isFloorMapLoading,
      deviceMarkerPositionList,
      isLoadingDevice,
      tabIndex,
      hasError,
    } = this.state;

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="setting-content">
            <div className="panel">
              <div className="floor-list-container">
                <div className="title-text">{`${COMMON_TEXT.BRANCH} - ${COMMON_TEXT.FLOOR}`}</div>
                <div className="floor-list">
                  {floorList.length > 0 &&
                    floorList.map((floor, index) => (
                      <div
                        key={`floor-item-index-${index}`}
                        className={`floor-item ${
                          selectedFloor?.floor_id === floor.floor_id &&
                          'checked'
                        }`}
                      >
                        <RadioButton
                          inputId={floor.floor_id}
                          name="floor"
                          value={floor}
                          checked={selectedFloor?.floor_id === floor.floor_id}
                          onChange={e =>
                            this.onChangeSelectedFloorAction(e.value)
                          }
                        ></RadioButton>
                        <label htmlFor={floor.floor_id} className="ml-2">
                          {this.getBranchName(floor.branch_id)}
                          {floor.floor_name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="floor-map-container">
                <div className="title-text">フロアマップ</div>
                <div className="floor-map">
                  {isFloorMapLoading || isLoadingDevice ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {floorMapUrl && (
                        <ImageMarker
                          bufferLeft={0}
                          bufferTop={0}
                          src={floorMapUrl}
                          markerComponent={this.renderMapMarker}
                          markers={
                            !floorMapUrl || floorMapError
                              ? []
                              : deviceMarkerPositionList || []
                          }
                          isDisableDragMarker={true}
                        />
                      )}
                      <>
                        {(!floorMapUrl || floorMapError) && (
                          <Message
                            severity="error"
                            text={COMMON_TEXT.CANNOT_LOAD_FLOORMAP}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className="device-list-container">
                <div className="title-text">{COMMON_TEXT.DEVICE}</div>
                <div className="device-list">
                  {isLoadingDevice ? (
                    <LoadingSpinner />
                  ) : (
                    <TabView
                      activeIndex={tabIndex}
                      onTabChange={e => this.setState({ tabIndex: e.index })}
                    >
                      {/* <TabPanel header="Meraki">
                        {this.renderMerakiDeviceList(merakiDevicesList)}
                      </TabPanel> */}
                      <TabPanel header="Verkada">
                        {this.renderVerkadaDeviceList(verkadaDevicesList)}
                      </TabPanel>
                    </TabView>
                  )}
                </div>
              </div>
            </div>
            <div className="button-container">
              <Button
                type="submit"
                severity="info"
                label="保存"
                disabled={hasError}
                loading={isUpdating}
                className="submit-button has-shadow"
                onClick={this.updateButtonOnClicked}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  renderUpdatePayloadTable = () => {
    const { zoneUpdatePayloadInfo } = this.state;
    return (
      <div className="payload-info-container">
        <DataTable
          value={zoneUpdatePayloadInfo}
          sortOrder={0}
          scrollable
          scrollHeight="400px"
          header={null}
          tableStyle={{ width: '100%' }}
          size="small"
        >
          <Column
            field="deviceName"
            header={COMMON_TEXT.DEVICE_NAME}
            style={{}}
          ></Column>
          <Column field="zone" header={COMMON_TEXT.ZONE} style={{}}></Column>
        </DataTable>
      </div>
    );
  };

  render() {
    const { isUpdateDialogVisible, updateDataPayload, isUpdating } = this.state;
    const updateDataDialogFooter = () => {
      return (
        <React.Fragment>
          <Button
            label={COMMON_TEXT.CANCEL}
            icon="pi pi-times"
            text
            disabled={isUpdating}
            severity="secondary"
            onClick={() => this.setState({ isUpdateDialogVisible: false })}
          />
          <Button
            label={COMMON_TEXT.SAVE}
            severity="info"
            className="submit-button has-shadow"
            loading={isUpdating}
            icon="pi pi-check"
            onClick={e => this.updateData({ updatePayLoad: updateDataPayload })}
          />
        </React.Fragment>
      );
    };
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <Dialog
          visible={isUpdateDialogVisible}
          style={{ width: '44rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={COMMON_TEXT.DEVICE_LOCATION_CHANGE_HEADER}
          modal
          className="p-fluid"
          footer={updateDataDialogFooter}
          onHide={() => this.setState({ isUpdateDialogVisible: false })}
          appendTo={'self'}
        >
          <div className="confirmation-content">
            {this.renderUpdatePayloadTable()}
          </div>
        </Dialog>
        <div className="config-container custom-config-container camera-zone-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">
                {COMMON_TEXT.CAMERA_ZONE_SETTING}
              </div>
            </div>
            <div className="grid-nogutter align-items-center justify-content-end grid">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">{this.renderSettingContent()}</div>
        </div>
      </>
    );
  }
}

CameraZoneSetting.propTypes = {};

export default CameraZoneSetting;
