import { COMMON_TEXT } from '@/helpers/common-text';
import {
  EXECUTION_ID_TIMEOUT,
  QUERY_STRING_STORE,
  SESSION_STORAGE_KEY,
  SETTING_FLOW_ID,
} from '@/helpers/constants';
import { set as sessionStoreActionSet } from 'forepaas/store/session/action';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import LoadingSpinner from 'src/components/CustomComponent/LoadingSpinner';
import { getConfigurationUrlWithParam } from 'src/helpers/utility';
import AuthToken from 'src/services/auth-token';
import * as Yup from 'yup';
import {
  fetchBranchList,
  fetchMerakiNetworks,
  fetchOrganizationList,
} from './query-request';

Yup.addMethod(Yup.array, 'merakiNetworkUnique', function (field, message) {
  return this.test('merakiNetworkUnique', message, function (array) {
    // Flatten all merakiNetworkList items into a single list
    const merakiNetworkListFlat = array.flatMap(item => item.merakiNetworkList);
    const seen = new Set();
    let duplicateValue = null;

    for (let i = 0; i < merakiNetworkListFlat.length; i++) {
      const value = merakiNetworkListFlat[i][field]?.toLowerCase();
      if (seen.has(value)) {
        duplicateValue = value;
        break;
      }
      if (value) seen.add(value);
    }

    if (!duplicateValue) {
      return true;
    }

    const branchIndex = array.findIndex(branch =>
      branch.merakiNetworkList.some(
        device => device[field]?.toLowerCase() === duplicateValue
      )
    );

    const deviceIndex = array[branchIndex].merakiNetworkList.findIndex(
      device => device[field]?.toLowerCase() === duplicateValue
    );

    return this.createError({
      path: `${this.path}[${branchIndex}].merakiNetworkList[${deviceIndex}].${field}`,
      message,
    });
  });
});

Yup.addMethod(Yup.array, 'validMerakiNetworkList', function (message) {
  return this.test('validMerakiNetworkList', message, function (array) {
    if (array.length >= 2) {
      // find index of item that has networkId is empty
      const deviceIndex = array.findIndex(branch =>
        array.some(item => !item.networkId)
      );
      if (deviceIndex >= 0) {
        return this.createError({
          path: `${this.path}.[${deviceIndex}].networkId`,
          message,
        });
      }
    }
    return true;
  });
});

@connect(state => ({
  querystring: state.querystring,
  sessionStore: state.session,
}))
class LocationSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenant:
        this.props?.querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0],
      isLoading: false,
      merakiNetworkOptions: [{ label: 'なし', value: '' }],
      branchMerakiNetworkList: [],
    };
    this.toastRef = React.createRef();
    this.cloneBranchMerakiNetworkList = [];
    this.authToken = new AuthToken();
  }

  componentDidMount() {
    const { tenant } = this.state;
    if (tenant) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    const { querystring } = this.props;
    const { querystring: prevQuerystring } = prevProps;
    const tenant = querystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const prevTenant =
      prevQuerystring?.[QUERY_STRING_STORE.SELECT_BOX_TENANT]?.[0];
    const isTenantChanged = tenant !== prevTenant;
    if (isTenantChanged) {
      this.setState({ tenant }, () => {
        this.getData();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  goBackToConfigPage = () => {
    const { querystring } = this.props;
    window.location.href = getConfigurationUrlWithParam({ querystring });
  };

  renderBackToConfigButton = () => {
    const { isLoading } = this.state;
    return (
      <Button
        label={`戻る`}
        loading={isLoading}
        className="back-button has-shadow"
        severity="secondary"
        size="small"
        onClick={() => {
          this.goBackToConfigPage();
        }}
      />
    );
  };

  getMerakiNetworkListByTenantId = async ({ tenantId }) => {
    let organizationRes = await fetchOrganizationList({ tenantId });
    organizationRes = organizationRes || [];
    const orgIdentiferList = organizationRes.map(item => item.org_identifer);
    let merakiNetworkList = [];
    if (orgIdentiferList && orgIdentiferList.length > 0) {
      let networkRes = await fetchMerakiNetworks({ orgIdentiferList });

      merakiNetworkList = networkRes || [];
    }
    return { merakiNetworkList };
  };

  getBranchListByTenantId = async ({ tenantId }) => {
    const branchesRes = await fetchBranchList({ tenantId });
    return branchesRes || [];
  };

  getData = async () => {
    const { tenant } = this.state;
    this.setState({ isLoading: true });
    const branchList = await this.getBranchListByTenantId({ tenantId: tenant });
    const branchMerakiNetworkList = branchList.map(item => ({
      tenantId: item.tenant_id,
      branchId: item.branch_id,
      branchName: item.branch_name,
      branchOrder: item.branch_order,
      merakiNetworkList: [],
    }));
    const { merakiNetworkList } = await this.getMerakiNetworkListByTenantId({
      tenantId: tenant,
    });
    for (let branchMerakiDeviceItem of branchMerakiNetworkList) {
      let { branchId } = branchMerakiDeviceItem;
      const merakiNetworkListByBranchId = merakiNetworkList.filter(
        item => item.branch_id === branchId
      );
      if (
        merakiNetworkListByBranchId &&
        merakiNetworkListByBranchId.length > 0
      ) {
        const merakiNetworkList = merakiNetworkListByBranchId.map(item => ({
          networkId: item.network_identifer,
          networkName: item.network_name,
          branchId: branchId,
        }));
        branchMerakiDeviceItem.merakiNetworkList = cloneDeep(merakiNetworkList);
      } else {
        branchMerakiDeviceItem.merakiNetworkList = [
          {
            networkId: '',
            networkName: '',
            branchId: branchId,
          },
        ];
      }
    }

    const merakiNetworkOptions = merakiNetworkList.map(item => ({
      label: item.network_name,
      value: item.network_identifer,
    }));
    merakiNetworkOptions.unshift({ label: 'なし', value: '' });
    this.cloneBranchMerakiNetworkList = cloneDeep(branchMerakiNetworkList);
    this.setState({
      branchMerakiNetworkList: cloneDeep(branchMerakiNetworkList),
      merakiNetworkOptions: cloneDeep(merakiNetworkOptions),
    });
    this.setState({ isLoading: false });
  };

  findItemChanges({ updatedArray, originalArray }) {
    // Helper function to check if merakiNetworkList has changed
    const isMerakiListChanged = (list1, list2) => {
      if (list1.length !== list2.length) return true;
      for (let i = 0; i < list1.length; i++) {
        if (
          list1[i].networkId !== list2[i].networkId ||
          list1[i].networkName !== list2[i].networkName
        ) {
          return true;
        }
      }
      return false;
    };

    // Finding newItems
    const newItems = updatedArray.filter(item => item.branchId === null);

    // Finding deletedItems
    const deletedItems = originalArray.filter(originalItem => {
      return !updatedArray.some(
        uItem => uItem.branchId === originalItem.branchId
      );
    });

    // Finding updatedItems
    const updatedItems = updatedArray.filter(uItem => {
      // If the item is in the deleted list, it can't be in the updated list
      if (deletedItems.some(dItem => dItem.branchId === uItem.branchId))
        return false;

      const originalItem = originalArray.find(
        oItem => oItem.branchId === uItem.branchId
      );
      if (!originalItem) return false; // if not found in originalArray, it can't be updated

      // Check for branchName or merakiNetworkList changes
      return (
        originalItem.branchName !== uItem.branchName ||
        originalItem.branchOrder !== uItem.branchOrder ||
        isMerakiListChanged(
          originalItem.merakiNetworkList,
          uItem.merakiNetworkList
        )
      );
    });

    return {
      newItems,
      deletedItems,
      updatedItems,
    };
  }

  getUpdateDataPayload = ({ newItems, deletedItems, updatedItems }) => {
    let payload = {
      values: [],
    };
    for (let item of newItems) {
      let { merakiNetworkList } = item;
      merakiNetworkList = merakiNetworkList.filter(
        item => item.networkId !== ''
      );
      const merakiNetworkIdList = merakiNetworkList.map(item => item.networkId);
      payload.values.push({
        id: null,
        name: item.branchName,
        order: item.branchOrder,
        network_identifers: merakiNetworkIdList,
      });
    }

    for (let item of deletedItems) {
      payload.values.push({
        id: item.branchId,
        network_identifers: null,
      });
    }

    for (let item of updatedItems) {
      let { merakiNetworkList } = item;
      merakiNetworkList = merakiNetworkList.filter(
        item => item.networkId !== ''
      );
      const merakiNetworkIdList = merakiNetworkList.map(item => item.networkId);
      payload.values.push({
        id: item.branchId,
        name: item.branchName,
        order: item.branchOrder,
        network_identifers: merakiNetworkIdList,
      });
    }
    return payload;
  };

  updateBranchInfo = async ({ updatePayLoad }) => {
    try {
      const updateRes = await this.authToken.updateConfiguration({
        flowId: SETTING_FLOW_ID.BRANCHES,
        payload: updatePayLoad,
      });

      const { success, execution_id } = updateRes;
      if (!success) {
        throw Error('UPDATE SETTING ERROR');
      }
      return { isSuccess: true, executionId: execution_id };
    } catch (error) {
      console.error('UPDATE SETTING ERROR', error);
    }
    return { isSuccess: false };
  };

  updateData = async ({ updatePayLoad }) => {
    const { sessionStore } = this.props;
    this.setState({ isUpdating: true });
    // eslint-disable-next-line
    const { isSuccess, executionId } = await this.updateBranchInfo({
      updatePayLoad,
    });

    const executionIdList =
      sessionStore?.[SESSION_STORAGE_KEY.SETTING_EXECUTION_ID] || [];
    if (isSuccess) {
      const executionObj = {
        id: executionId,
        expiredAt: Date.now() + EXECUTION_ID_TIMEOUT,
      };
      executionIdList.push(executionObj);
      this.props.dispatch(
        sessionStoreActionSet(
          SESSION_STORAGE_KEY.SETTING_EXECUTION_ID,
          executionIdList
        )
      );
      this.setState({ isUpdating: false }, () => {
        this.goBackToConfigPage();
      });
    }

    // eslint-disable-next-line
    if (!isSuccess) {
      this.setState({ isUpdating: false }, () => {
        this.toastRef.current.show({
          severity: 'error',
          summary: 'エラー',
          detail: `変更に失敗しました. ERROR_MESSAGE_HERE`,
          life: 3000,
          closable: true,
          icon: 'pi pi-exclamation-circle',
        });
      });
    }
  };

  renderSettingContent() {
    const {
      isLoading,
      isUpdating,
      branchMerakiNetworkList,
      merakiNetworkOptions,
      tenant,
    } = this.state;

    const addNewBranch = ({ arrayHelpers, branches }) => {
      arrayHelpers.push({
        tenantId: tenant,
        branchId: null,
        branchName: '',
        branchOrder: branches.length + 1,
        merakiNetworkList: [
          {
            networkId: '',
            networkName: '',
          },
        ],
      });
    };

    const addNewMerakiNetwork = ({ arrayHelpers, index }) => {
      arrayHelpers.push({
        networkId: '',
        networkName: '',
      });
    };

    const deleteAccepted = ({ arrayHelpers, index }) => {
      arrayHelpers.remove(index);
      // delete index
      const branchList = arrayHelpers.form.values.branches;
      branchList.splice(index, 1);
      // rearrange branchOrder
      branchList.map((branch, branchIndex) => {
        branch.branchOrder = branchIndex + 1;
        return '';
      });
    };

    const merakiDeleteAccepted = ({ arrayHelpers, index, dataList }) => {
      if (dataList.length === 1) {
        arrayHelpers.replace(index, {
          networkId: '',
          networkName: '',
        });
      } else {
        arrayHelpers.remove(index);
      }
    };

    const deleteButtonOnClicked = (e, { arrayHelpers, item, index }) => {
      const { branchId } = item;

      if (branchId > 0) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () => deleteAccepted({ arrayHelpers, index }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        deleteAccepted({ arrayHelpers, index });
      }
    };

    const deleteMerakiButtonOnClicked = (
      e,
      { arrayHelpers, item, index, dataList }
    ) => {
      const { networkId } = item;
      if (!isEmpty(networkId)) {
        confirmDialog({
          message: COMMON_TEXT.DELETE_CONFIRM_MESSAGE,
          header: COMMON_TEXT.DELETE_CONFIRMATION_HEADER,
          icon: '',
          accept: () =>
            merakiDeleteAccepted({
              arrayHelpers,
              index,
              dataList,
            }),
          reject: () => {},
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-secondary p-button-text',
          appendTo: 'self',
        });
      } else {
        merakiDeleteAccepted({ arrayHelpers, index, dataList });
      }
    };

    const handleSubmit = (values, { setSubmitting, errors }) => {
      if (!errors) {
        const { newItems, deletedItems, updatedItems } = this.findItemChanges({
          updatedArray: values.branches,
          originalArray: this.cloneBranchMerakiNetworkList,
        });
        const updatePayLoad = this.getUpdateDataPayload({
          newItems,
          deletedItems,
          updatedItems,
        });
        const { values: payloadValues } = updatePayLoad;
        if (payloadValues.length === 0) {
          return;
        }
        this.updateData({ updatePayLoad });
      } else {
      }
    };

    const isFormFieldInvalid = ({ name, touched, errors }) => {
      const error = getIn(errors, name);
      const touch = getIn(touched, name);
      return touch && error ? 'p-invalid' : '';
    };

    const getFormErrorMessage = ({ name, touched, errors }) => {
      return isFormFieldInvalid({ name, touched, errors }) ? (
        <span className="validation-error-message">{getIn(errors, name)}</span>
      ) : (
        <></>
      );
    };

    const branchSchema = Yup.object().shape({
      branchName: Yup.string().required('Branch Name is required'),
      merakiNetworkList: Yup.array().validMerakiNetworkList(
        'invalid merakiNetworkList'
      ),
    });

    const formSchema = Yup.object().shape({
      branches: Yup.array()
        .merakiNetworkUnique('networkId', 'networkId is duplicated')
        .of(branchSchema)
        .required('Branches are required'),
    });

    const onDragEnd = (result, { arrayHelpers, branches }) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const sourceItem = branches[result.source.index];
      const destinationItem = branches[result.destination.index];
      sourceItem.branchOrder = result.destination.index + 1;
      destinationItem.branchOrder = result.source.index + 1;
      arrayHelpers.replace(result.source.index, destinationItem);
      arrayHelpers.replace(result.destination.index, sourceItem);
    };

    const renderMerakiItem = ({
      index,
      merakiDeviceIndex,
      merakiDeviceInfo,
      handleChange,
      touched,
      errors,
      merakiNetworkListHelpers,
      branchInfo,
    }) => {
      return (
        <Fragment key={`meraki-device-item-${merakiDeviceIndex}`}>
          <div className="meraki-field-container">
            <Dropdown
              id={`branches[${index}].merakiNetworkList[${merakiDeviceIndex}].networkId`}
              value={merakiDeviceInfo.networkId}
              options={merakiNetworkOptions}
              onChange={handleChange}
              style={{
                width: '320px',
              }}
              className={`${isFormFieldInvalid({
                name: `branches[${index}].merakiNetworkList[${merakiDeviceIndex}].networkId`,
                touched,
                errors,
              })}`}
            ></Dropdown>
            {getFormErrorMessage({
              name: `branches[${index}].merakiNetworkList[${merakiDeviceIndex}].networkId`,
              touched,
              errors,
            })}
            <Button
              type="button"
              icon="pi pi-trash"
              text
              severity="danger"
              className="icon-only-delete-button"
              onClick={e =>
                deleteMerakiButtonOnClicked(e, {
                  arrayHelpers: merakiNetworkListHelpers,
                  item: merakiDeviceInfo,
                  dataList: branchInfo.merakiNetworkList,
                  index: merakiDeviceIndex,
                })
              }
            />
          </div>
        </Fragment>
      );
    };

    const renderMainContent = ({
      values,
      arrayHelpers,
      handleChange,
      touched,
      errors,
    }) => {
      return (
        <div className="main-content">
          <DragDropContext
            onDragEnd={e =>
              onDragEnd(e, {
                arrayHelpers,
                branches: values.branches,
              })
            }
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {values.branches
                    .sort((a, b) => a.branchOrder - b.branchOrder)
                    .map((branchInfo, index) => (
                      <Draggable
                        key={branchInfo.branchOrder}
                        draggableId={`draggable-${branchInfo.branchOrder}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <div
                              className={`main-row-container ${
                                snapshot.isDragging ? `is-dragging` : ''
                              }`}
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="input-text-field-container index-field">
                                <span>{branchInfo.branchOrder}</span>
                              </div>
                              <div className="input-text-field-container name-field">
                                <InputText
                                  id={`branches[${index}].branchName`}
                                  value={branchInfo.branchName}
                                  onChange={handleChange}
                                  className={`${isFormFieldInvalid({
                                    name: `branches[${index}].branchName`,
                                    touched,
                                    errors,
                                  })}`}
                                />
                                {getFormErrorMessage({
                                  name: `branches[${index}].branchName`,
                                  touched,
                                  errors,
                                })}
                              </div>
                              <div className="input-text-field-container action-field">
                                <Button
                                  type="button"
                                  icon="pi pi-trash"
                                  text
                                  severity="danger"
                                  className="icon-only-delete-button"
                                  onClick={e =>
                                    deleteButtonOnClicked(e, {
                                      arrayHelpers,
                                      item: branchInfo,
                                      index,
                                    })
                                  }
                                />
                              </div>
                              <div className="input-text-field-container meraki-field">
                                <FieldArray
                                  name={`branches[${index}].merakiNetworkList`}
                                  render={merakiNetworkListHelpers => (
                                    <>
                                      {branchInfo.merakiNetworkList.map(
                                        (merakiDeviceInfo, merakiDeviceIndex) =>
                                          renderMerakiItem({
                                            index,
                                            merakiDeviceIndex,
                                            merakiDeviceInfo,
                                            handleChange,
                                            touched,
                                            errors,
                                            merakiNetworkListHelpers,
                                            branchInfo,
                                          })
                                      )}
                                      <div className="add-meraki-button-container">
                                        <Button
                                          severity="secondary"
                                          type="button"
                                          label="追加"
                                          className="add-new-button has-shadow"
                                          onClick={() =>
                                            addNewMerakiNetwork({
                                              arrayHelpers:
                                                merakiNetworkListHelpers,
                                            })
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
    };

    const cloneBranchList = cloneDeep(branchMerakiNetworkList);

    return (
      <>
        {isLoading || isUpdating ? (
          <LoadingSpinner
            loadingText={isUpdating ? COMMON_TEXT.UPDATING_DATA : null}
          />
        ) : (
          <Formik
            initialValues={{
              branches: cloneBranchList
                .sort((a, b) => a.branchOrder - b.branchOrder)
                .map(branchItem => ({
                  tenantId: branchItem.tenantId,
                  branchId: branchItem.branchId,
                  branchName: branchItem.branchName,
                  branchOrder: branchItem.branchOrder,
                  merakiNetworkList: branchItem.merakiNetworkList,
                })),
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, touched, errors, resetForm }) => (
              <Form>
                <FieldArray
                  name="branches"
                  render={arrayHelpers => (
                    <>
                      <div className="main-row-container is-header">
                        <div className="index-field">表示順</div>
                        <div className="name-field">拠点名</div>
                        <div className="meraki-field">Merakiネットワーク</div>
                        <div className="action-field"></div>
                      </div>
                      {renderMainContent({
                        values,
                        arrayHelpers,
                        handleChange,
                        touched,
                        errors,
                      })}
                      <div className="main-row-container is-submit-button-container">
                        <Button
                          severity="info"
                          type="submit"
                          label="保存"
                          className="submit-button has-shadow"
                          loading={isUpdating}
                        />
                        <Button
                          severity="success"
                          type="button"
                          label="追加"
                          className="add-new-button has-shadow"
                          onClick={() =>
                            addNewBranch({
                              arrayHelpers,
                              branches: values.branches,
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }

  render() {
    const { tenant } = this.state;
    return (
      <>
        <Toast ref={this.toastRef} position="top-center" />
        <ConfirmDialog />
        <div className="config-container custom-config-container location-setting-container">
          <div className="config-title-container">
            <div className="text-left">
              <div className="title-text">{COMMON_TEXT.LOCATION_SETTING}</div>
            </div>
            <div className="grid grid-nogutter align-items-center justify-content-end">
              {this.renderBackToConfigButton()}
            </div>
          </div>
          <div className="config-content">
            <div className="setting-content">
              {tenant ? (
                this.renderSettingContent()
              ) : (
                <Message
                  severity="error"
                  text={COMMON_TEXT.TENANT_NOT_SELECTED_PLEASE_SELECT}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

LocationSetting.propTypes = {};

export default LocationSetting;
