import { QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildQuerySensorDataMartLine = ({ floorId, fromDate, toDate }) => {
  return {
    data: {
      fields: {
        device_identifer: ['select'],
        device_name: ['select'],
        tenant_id: ['select'],
        floor_id: ['select'],
        timestamp: ['select'],
        carbon_dioxide: ['select'],
        humidity: ['select'],
        temperature: ['select'],
        noise_level: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: [floorId],
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      timestamp: 'asc',
    },
    table_name: QUERY_TABLES.SENSOR_DATA_MART_LINE,
  };
};

export const fetchSensorDataHistory = async payload => {
  const { floorId, fromDate, toDate } = payload;
  const request = buildQuerySensorDataMartLine({
    floorId,
    fromDate,
    toDate,
  });
  return executeQuery(request);
};
