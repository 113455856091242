import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react';
import './CustomDropdown.less';

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    const { props } = this;
    const { label } = props;
    return (
      <div className="custom-dropdown">
        <div className="title">{label}</div>
        <Dropdown {...props} />
      </div>
    );
  }
}

CustomDropdown.propTypes = {};

export default CustomDropdown;
