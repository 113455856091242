import React from 'react';
import { COMMON_TEXT } from '@/helpers/common-text';
import { Tooltip } from 'primereact/tooltip';
import { generateRandomString } from '@/helpers/utility';
import { format } from 'date-fns';

export const CustomMarker = props => {
  const { item, type } = props;
  const { color, data } = item;
  const channel24 = data.channel_24;
  const channel5 = data.channel_5;
  const counts = type === 'channel_24' ? channel24 : channel5;

  const targetId = generateRandomString(12);

  return (
    <div
      className={`custom-marker custom-marker-tooltip-target-${targetId}`}
      style={{ backgroundColor: color ?? '#333333' }}
    >
      <Tooltip
        className="custom-marker-tooltip"
        target={`.custom-marker-tooltip-target-${targetId}`}
        baseZIndex={2000}
        autoHide={false}
        position="top"
        event="hover"
      >
        <div className="display-marker">
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.DEVICE_NAME}</div>
            <div className="text-value">
              <b>{data.device_name}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">Grade</div>
            <div className="text-value">
              <b>{data.grade}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">SNR</div>
            <div className="text-value">
              <b>{data.snr}</b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">2.4 Ghz / 5.0 Ghz</div>
            <div className="text-value">
              <b>
                {channel24} / {channel5}
              </b>
            </div>
          </div>
          <div className="display-marker__container">
            <div className="text-label">{COMMON_TEXT.LAST_UPDATED_TIME}</div>
            <div className="text-value">
              <b>{format(new Date(data.timestamp), 'MM月dd日 - HH:mm')}</b>
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="text-count" style={{ fontSize: '30px' }}>
        {counts ?? '-'}
      </div>
    </div>
  );
};
