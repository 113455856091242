import { QUERY_TABLES } from '@/helpers/constants';
import { getRandomNumber900000to999999 } from '@/helpers/utility';
import { executeQuery } from 'src/helpers/request';

const buildQueryTenant = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        tenant_name: ['select'],
        allow_supervisor: ['select'],
        business_hour_begin: ['select'],
        components: ['select'],
        holidays: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {
      tenant_name: 'asc',
    },
    table_name: QUERY_TABLES.TENANTS,
  };
};

const buildQueryCalendarOverride = ({ tenantId }) => {
  const randomTenantId = getRandomNumber900000to999999();
  return {
    data: {
      fields: {
        tenant_id: ['select'],
        is_holiday: ['select'],
        date: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      tenant_id: [tenantId, randomTenantId],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.CALENDAR_OVERRIDES,
  };
};

export const fetchTenantById = async payload => {
  const { tenantId } = payload;
  const request = buildQueryTenant({ tenantId });
  return executeQuery(request);
};

export const fetchCalendarOverridesByTenantId = async payload => {
  const { tenantId } = payload;
  const request = buildQueryCalendarOverride({ tenantId });
  return executeQuery(request);
};
