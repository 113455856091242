import { NETWORK_SORT_OPTIONS, QUERY_TABLES } from '@/helpers/constants';
import { executeQuery } from '@/helpers/request';

const buildRequestQueryTableAndBarChart = ({
  floorId,
  selectedLimit,
  sortOption,
  selectedDate,
}) => {
  let order = {};
  if (sortOption === NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC) {
    order = {
      device_name: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_ASC
  ) {
    order = {
      score: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_DESC
  ) {
    order = {
      score: 'desc',
    };
  }
  return {
    data: {
      fields: {
        date: ['select'],
        score: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
        grade: ['select'],
      },
      skip: 0,
      limit: selectedLimit,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      date: [selectedDate],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: order,
    table_name: QUERY_TABLES.PERFORMANCES_MART_BAR_V2,
  };
};

const buildRequestQueryTableAndBarChartByDeviceIdList = ({
  floorId,
  sortOption,
  selectedDate,
  deviceIdList,
}) => {
  let order = {};
  if (sortOption === NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC) {
    order = {
      device_name: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_ASC
  ) {
    order = {
      score: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_DESC
  ) {
    order = {
      score: 'desc',
    };
  }
  return {
    data: {
      fields: {
        date: ['select'],
        score: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
        floor_id: ['select'],
        tenant_id: ['select'],
        grade: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      date: [selectedDate],
      device_identifer: deviceIdList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: order,
    table_name: QUERY_TABLES.PERFORMANCES_MART_BAR_V2,
  };
};

const buildRequestQueryTableAndBarChartLite = ({
  floorId,
  sortOption,
  selectedDate,
}) => {
  let order = {};
  if (sortOption === NETWORK_SORT_OPTIONS.DEVICE_NAME_ASC) {
    order = {
      device_name: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_ASC
  ) {
    order = {
      score: 'asc',
    };
  } else if (
    sortOption === NETWORK_SORT_OPTIONS.PERFORMANCE_SCORE_BAR_DESC
  ) {
    order = {
      score: 'desc',
    };
  }
  return {
    data: {
      fields: {
        score: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      date: [selectedDate],
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: order,
    table_name: QUERY_TABLES.PERFORMANCES_MART_BAR_V2,
  };
};

const buildRequestQueryHeatMap = (
  floorId,
  fromDate,
  toDate,
  deviceIdentiferList
) => {
  return {
    data: {
      fields: {
        timestamp: ['select'],
        score: ['select'],
        device_identifer: ['select'],
        device_name: ['select'],
        tenant_id: ['select'],
        floor_id: ['select'],
        grade: ['select'],
      },
      skip: 0,
      limit: 0,
    },
    filter: {
      floor_id: {
        eq: [floorId],
      },
      timestamp: {
        gte: [fromDate],
        lte: [toDate],
      },
      device_identifer: deviceIdentiferList,
    },
    scale: {},
    evol: {},
    total: {
      all: [],
    },
    order: {},
    table_name: QUERY_TABLES.PERFORMANCES_MART_LINE,
  };
};

export const fetchDataTableAndBarChart = async payload => {
  const { floorId, selectedLimit, sortOption, selectedDate } = payload;
  const request = buildRequestQueryTableAndBarChart({
    floorId,
    selectedLimit,
    sortOption,
    selectedDate,
  });
  return executeQuery(request);
};

export const fetchDataTableAndBarChartByDeviceIdList = async payload => {
  const { floorId, sortOption, selectedDate, deviceIdList } = payload;
  const request = buildRequestQueryTableAndBarChartByDeviceIdList({
    floorId,
    sortOption,
    selectedDate,
    deviceIdList,
  });
  return executeQuery(request);
};

// same as fetchDataTableAndBarChart but with less information (to improve performance)
export const fetchDataTableAndBarChartLite = async payload => {
  const { floorId, sortOption, selectedDate } = payload;
  const request = buildRequestQueryTableAndBarChartLite({
    floorId,
    sortOption,
    selectedDate,
  });
  return executeQuery(request);
};

export const fetchDataHeatMap = async payload => {
  const { floorId, fromDate, toDate, deviceIdentiferList } = payload;
  const request = buildRequestQueryHeatMap(
    floorId,
    fromDate,
    toDate,
    deviceIdentiferList
  );
  return executeQuery(request);
};
